export enum CollectionVisibilityTypes {
    personal = "personal",
    public = "public",
}
export interface CollectionData {
    displayName: string;
    description?: string;
    visibility: CollectionVisibilityTypes;
}
export interface CollectionEditData extends CollectionData {
    id: string;
}

export enum SharingType {
    clipboard = "clipboard",
    email = "email",
    qrcode = "qrcode",
}
