interface DateTimeFormatOptions {
    year?: "numeric" | "2-digit" | undefined;
    month?: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;
    day?: "numeric" | "2-digit" | undefined;
    hour?: "numeric" | "2-digit" | undefined;
    minute?: "numeric" | "2-digit" | undefined;
    second?: "numeric" | "2-digit" | undefined;
}

export function formateDate(dateString: string, locale: string, dateTime: boolean): string {
    const date = new Date(dateString);

    const dateOptions: DateTimeFormatOptions = dateTime
        ? {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
          }
        : { year: "numeric", month: "2-digit", day: "2-digit" };

    switch (locale) {
        case "de":
            return Intl.DateTimeFormat("de-DE", dateOptions).format(date);
        default:
            return Intl.DateTimeFormat("en-US", dateOptions).format(date);
    }
}
