import { CommonRouteAliases } from "@/shared/environment/common-route-aliases.ts";
import { useAbilityRoute } from "@/shared/environment/ability-route.ts";
import { RouteElementWithParam } from "@/shared/environment/ability.types.ts";
import { encodeParamArrayToString } from "@/shared/environment/ability-route-helper.ts";

export const useMechanicArticleRoute = () => {
    const { getPathToChildrenWithoutParents } = useAbilityRoute();

    const getPathToMechanicArticle = (
        productId: string,
        assetId: string | undefined | null,
        mechanicId: string,
        assemblyIdsPath: string[]
    ) => {
        const routeElements: RouteElementWithParam[] = [
            {
                alias: CommonRouteAliases.product,
                namedParams: { [CommonRouteAliases.product]: productId },
            },
        ];

        if (assetId)
            routeElements.push({
                alias: CommonRouteAliases.asset,
                namedParams: { [CommonRouteAliases.asset]: assetId as string },
            });

        if (assemblyIdsPath.length === 0) {
            routeElements.push({
                alias: CommonRouteAliases.searchToMechanicArticle,
                namedParams: {
                    [CommonRouteAliases.searchToMechanicArticle]: mechanicId,
                },
            });

            return getPathToChildrenWithoutParents(routeElements);
        }

        //The last entry is the parts list entry itself and does not belong to the list
        const paths = [...assemblyIdsPath];
        paths.pop();

        return getPathToChildrenWithoutParents([
            {
                alias: CommonRouteAliases.product,
                namedParams: { [CommonRouteAliases.product]: productId },
            },
            ...(assetId
                ? [
                      {
                          alias: CommonRouteAliases.asset,
                          namedParams: { [CommonRouteAliases.asset]: assetId },
                      },
                  ]
                : []),
            {
                alias: CommonRouteAliases.mechanic,
                namedParams: { [CommonRouteAliases.mechanic]: encodeParamArrayToString(paths) },
            },
            {
                alias: CommonRouteAliases.mechanicArticle,
                namedParams: { [CommonRouteAliases.mechanicArticle]: mechanicId },
            },
        ]);
    };

    return {
        getPathToMechanicArticle,
    };
};
