<template>
    <QitButton
        @click.stop.prevent
        v-if="hasVisibleElements"
        icon="fa-solid fa-ellipsis-vertical default-grey-icon-color"
        size="13px"
        padding="sm"
        color="none"
        class="more-menu-button"
        data-qs="more-menu-button"
    >
        <q-menu>
            <q-list>
                <template v-for="entry in entries" :key="entry.id">
                    <template v-if="entry.visible === undefined || entry.visible">
                        <template v-if="'text' in entry">
                            <q-item
                                clickable
                                v-close-popup
                                class="items-center q-px-md q-py-sm item"
                                @click="entry.click()"
                                v-bind="entry.elementAttrs"
                                :class="{ 'item-invisible': !(entry.visible === undefined || entry.visible) }"
                                :data-qs="entry.id"
                            >
                                <div class="q-mr-xs" v-if="entry.icon">
                                    <q-icon color="primary" :name="entry.icon" size="xs" />
                                </div>
                                <div>{{ entry.text }}</div>
                            </q-item>
                        </template>
                        <template v-else>
                            <component
                                :is="entry.component.getComponent()"
                                v-bind="entry.component.props"
                                v-on="entry.component.emits"
                                :data-qs="entry.id"
                            />
                        </template>
                    </template>
                </template>
            </q-list>
        </q-menu>
    </QitButton>
</template>

<script setup lang="ts">
import { computed } from "vue";
import QitButton from "@/shared/components/buttons/qit-button.vue";
import { MenuEntry } from "@/shared/components/menu/menu.model.ts";

const props = withDefaults(
    defineProps<{
        entries: MenuEntry[];
        open?: boolean;
    }>(),
    {
        open: false,
    }
);

const hasVisibleElements = computed(() => {
    return (
        props.entries.length > 0 &&
        props.entries.some((entry: any) => {
            return entry.visible ?? true;
        })
    );
});
</script>

<style lang="scss" scoped>
.item {
    min-height: unset;
    max-width: 350px;
}

.more-menu-button {
    background: transparent;
    margin: 0 0 auto 0;

    &:hover {
        background: rgba(0, 0, 0, 0.04);
    }
}

.item-invisible {
    display: none;
}
</style>
