import { Ref } from "vue";
import { Facet, FacetSelected } from "@/shared/facets/facets.model.ts";
import { SortEntry } from "@/shared/components/sort-dropdown/sort-dropdown.model.ts";

export enum FieldDefinitionTypes {
    dateTime = "dateTime",
    dateTimeRange = "dateTimeRange",
    enum = "enum",
    keyword = "keyword",
    number = "number",
    numberRange = "numberRange",
    taxonomy = "taxonomy",
    text = "text",
}

export enum FullTextSearchType {
    bestFields = "best_fields",
    mostFields = "most_fields",
    phrase = "phrase",
    prefix = "prefix",
}

export interface SearchContext {
    product?: string;
    asset?: string;
    text?: string; //TODO remove text property
    article?: string;
}

export interface SearchField {
    referencedId: string;
    fieldType: FieldDefinitionTypes;
    boost?: number | null;
}

export interface SearchParams {
    selected: Ref<FacetSelected[]>;
    searchContext?: Ref<SearchContext>;
    searchPhrase?: Ref<string>;
    sort?: Ref<SortEntry | undefined>;
}

export interface SearchFilteredResultsParams extends SearchParams {
    facet: Ref<Facet | undefined>;
}
