import gql from "graphql-tag";
import { DataDisplayResult } from "@/shared/data-display-config/composable/data-display-config.ts";

gql`
    query collections {
        collections {
            collections(first: 100) {
                cursor
                node {
                    id
                    displayName
                    visibility
                    showInDashboard
                    entries {
                        total {
                            count
                            countRelation
                        }
                    }
                    user {
                        id
                    }
                }
            }
        }
    }
`;

gql`
    query collectionsByFilter($filter: CollectionFilter) {
        collections(filter: $filter) {
            collections {
                cursor
                node {
                    id
                    displayName
                    description
                    visibility
                    showInDashboard
                }
            }
        }
    }
`;

gql`
    query editableCollections {
        editableCollections {
            total {
                count
                countRelation
            }
            collections(first: 100, sort: { field: displayName, order: asc }) {
                cursor
                node {
                    id
                    displayName
                    visibility
                }
            }
        }
    }
`;

gql`
    query titlesForMechanicPath(
        $productId: String!
        $productIdForMechanic: ID
        $acceptedLanguages: [LanguageWithWildcard!]
        $assetId: ID
        $filter: MechanicFilter
    ) {
        product(id: $productId) {
            id
            localizations {
                title(acceptedLanguages: $acceptedLanguages)
            }
        }
        mechanics(assetId: $assetId, productId: $productIdForMechanic, filter: $filter) {
            mechanics {
                node {
                    articleId
                    articleNumber
                    assemblyId
                    localizations {
                        title(acceptedLanguages: $acceptedLanguages)
                    }
                }
            }
        }
    }
`;

export const getContentsOfCollection = (
    documentDataDisplayResult?: DataDisplayResult,
    articleDataDisplayResult?: DataDisplayResult,
    partsDataDisplayResult?: DataDisplayResult,
    activityDataDisplayResult?: DataDisplayResult
) => {
    let topicFields = "",
        contentFields = "",
        topicLocalizationFragment = "",
        contentMapLocalizationFragment = "",
        allDataFieldFragments = "",
        topicDataFieldFragments = "",
        contentMapDataFieldFragments = "";

    const allArticleDataFieldFragments = articleDataDisplayResult?.datafieldsFragment?.datafields
        ? Object.values(articleDataDisplayResult.datafieldsFragment.datafields).join(" ")
        : "";
    const allArticleLocalizedDataFieldFragments = articleDataDisplayResult?.datafieldsFragment?.localizationDatafields
        ? Object.values(articleDataDisplayResult.datafieldsFragment.localizationDatafields).join(" ")
        : "";

    const allPartsDataFieldFragments = partsDataDisplayResult?.datafieldsFragment?.datafields
        ? Object.values(partsDataDisplayResult.datafieldsFragment.datafields).join(" ")
        : "";
    const allPartsLocalizedDataFieldFragments = partsDataDisplayResult?.datafieldsFragment?.localizationDatafields
        ? Object.values(partsDataDisplayResult.datafieldsFragment.localizationDatafields).join(" ")
        : "";

    const allActivityDataFieldFragments = activityDataDisplayResult?.datafieldsFragment?.datafields
        ? Object.values(activityDataDisplayResult.datafieldsFragment?.datafields).join(" ")
        : "";
    const allActivityLocalizedDataFieldFragments = activityDataDisplayResult?.datafieldsFragment?.localizationDatafields
        ? Object.values(activityDataDisplayResult.datafieldsFragment.localizationDatafields).join(" ")
        : "";

    if (documentDataDisplayResult) {
        const { topic, contentMap } = documentDataDisplayResult.nodeFieldsDataFragment;
        const dataFieldsFragment = documentDataDisplayResult.datafieldsFragment.datafields;
        const localizationDatafieldsFragment = documentDataDisplayResult.datafieldsFragment.localizationDatafields;
        allDataFieldFragments = dataFieldsFragment["all"]?.join(" ") ?? "";
        const allLocalizedDataFieldFragments = localizationDatafieldsFragment["all"]?.join(" ") ?? "";
        topicDataFieldFragments = dataFieldsFragment["topic"]?.join(" ") ?? "";
        const topicLocalizedDataFieldFragments = localizationDatafieldsFragment["topic"]?.join(" ") ?? "";
        contentMapDataFieldFragments = dataFieldsFragment["contentMap"]?.join(" ") ?? "";
        const contentMapLocalizedDataFieldFragments = localizationDatafieldsFragment["contentMap"]?.join(" ") ?? "";

        topicLocalizationFragment = `teasers {
            title(length: 256) 
            ${allLocalizedDataFieldFragments} 
            ${topicLocalizedDataFieldFragments} 
        }`;

        contentMapLocalizationFragment = `teasers {
            title(length: 256) 
            ${allLocalizedDataFieldFragments} 
            ${contentMapLocalizedDataFieldFragments} 
        }`;

        topicFields = topic ?? "";
        contentFields = contentMap ?? "";
    }

    const articleNodeFragment = articleDataDisplayResult
        ? `  ... on ArticleCollectionEntryNode {
                article {
                    id
                    articleNumber
                    referenceId
                    orderable
                    previewImage {
                        url
                    }
                    localizations {
                        title(acceptedLanguages: $acceptedLanguages)
                        ${allArticleLocalizedDataFieldFragments}
                    }
                    ${allArticleDataFieldFragments}
                }
                navContext {
                    asset {
                        assetId
                    }
                    product {
                        id
                    }
                }
          }`
        : "";

    const mechanicNodeFragment = partsDataDisplayResult
        ? `... on MechanicCollectionEntryNode {
                mechanic {
                    id
                    assemblyId
                    parentAssemblyId
                    articleId
                    quantity
                    option
                    isAssembly
                    itemNumber
                    reference2d
                    reference3d
                    unit
                    articleNumber
                    referenceId
                    previewImage {
                        url
                    }
                    localizations {
                        title(acceptedLanguages: $acceptedLanguages)
                        ${allPartsLocalizedDataFieldFragments}
                    }
                    pathsToRoot {
                        path {
                            mechanic {
                                assemblyId
                                localizations {
                                    title(acceptedLanguages: $acceptedLanguages)
                                }
                            }
                        }
                    }
                    ${allPartsDataFieldFragments}
                }
                navContext {
                    asset {
                        assetId
                    }
                    product {
                        id
                        localizations {
                            title(acceptedLanguages: $acceptedLanguages)
                        }
                    }
                    path
                }
            }`
        : "";

    const topicFragment = `... on Topic {
                                        type
                                        id     
                                        mimeType   
                                        useContext {
                                            rootContentMap {
                                                teasers {
                                                    title(length: 256)
                                                }
                                            }
                                        }                            
                                        ${topicFields}        
                                        ${topicLocalizationFragment}             
                                        ${topicDataFieldFragments}             
                                        ${allDataFieldFragments}             
                                  }`;

    const contentMapFragment = ` ... on ContentMap {
                                        type 
                                        id
                                        contentId
                                        locale                                                      
                                        ${contentMapLocalizationFragment}                         
                                        ${contentFields}
                                        ${allDataFieldFragments}
                                        ${contentMapDataFieldFragments}
                                  }  `;

    const activityNodeFragment = activityDataDisplayResult
        ? ` ... on ActivityCollectionEntryNode {
            activity{                
               id
                language
                creationDate
                uploadDate
                modificationDate                
                localizations {
                    title(acceptedLanguages: $acceptedLanguages)
                    ${allActivityLocalizedDataFieldFragments}
                }             
                ${allActivityDataFieldFragments}     
            }
             navContext {
                    asset {
                        assetId
                    }
                    product {
                        id
                        localizations {
                            title(acceptedLanguages: $acceptedLanguages)
                        }
                    }
                }
        }`
        : "";

    const query = `
    query contentsOfCollection($id: ID!, $first: NonNegativeInt, $after: String, $sort: [CollectionEntrySort], $acceptedLanguages: [LanguageWithWildcard!])  {
        collection(id: $id) {
            id
            displayName
            user {
                id
            }
            visibility
            description
            entries {
                total {
                  count
                  countRelation
                }
                entries(after: $after, first: $first, sort: $sort) {
                    cursor                  
                    node {       
                        ... on ContentCollectionEntryNode {                           
                             content {
                                  ${topicFragment}
                                  ${contentMapFragment}                                 
                             }           
                             navContext {
                                asset {
                                    assetId
                                }
                                product {
                                    id
                                    localizations {
                                        title(acceptedLanguages: $acceptedLanguages)
                                    }
                                }
                             }                                                   
                        }
                        ${articleNodeFragment}
                        ${mechanicNodeFragment}
                        ${activityNodeFragment}
                    }
                }
            }     
        }    
    }`;

    return gql(query);
};

gql`
    query statusExportCollection($jobId: ID!) {
        statusExportCollection(jobId: $jobId) {
            status
            url
        }
    }
`;
