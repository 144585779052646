import { Maybe, ProductEdge } from "@/shared/services/graphql/generated/admin-graph-types";
import { ProductSortFields, SortOrder } from "@/shared/services/graphql/generated/consumer-graph-types";
import { ComponentDefinition } from "@/shared/components/component-definition.model";
import { LazyLoadingDataSource } from "@/shared/components/scroll/lazy-loading-datasource";
import { computed, ComputedRef } from "vue";
import { advProduct } from "../data-objects/advanced-product";
import ProductResponsiveCard from "./components/product-selection/product-responsive-card.vue";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access";
import { useI18n } from "vue-i18n";
import { AccessAction, AccessResource } from "@/shared/access-control/access-control";
import { MenuTextEntry } from "@/shared/components/menu/menu.model";
import { router } from "@/app/router.ts";

//TODO move functions and composables in separate files
export function createDataSource(
    emitSelection: boolean,
    showButtons: boolean
): LazyLoadingDataSource<Maybe<ProductEdge>, ComponentDefinition> {
    const result = new LazyLoadingDataSource<Maybe<ProductEdge>, ComponentDefinition>((item) => {
        return item
            ? {
                  getComponent: () => ProductResponsiveCard,
                  props: { product: advProduct(item.node), showButtons: showButtons, emitSelection: emitSelection },
              }
            : undefined;
    });
    return result;
}

export function createTiles(
    dataSource: LazyLoadingDataSource<Maybe<ProductEdge>, ComponentDefinition>,
    products: Array<Maybe<ProductEdge>>
): Array<ComponentDefinition> {
    dataSource.setSourceValues(products ?? []);
    return dataSource.getResults();
}

export function useProductsOverviewSort(isSearch: boolean) {
    const { t } = useI18n();

    const sortOrderItems = computed(() => {
        return [
            ...(isSearch
                ? [
                      {
                          id: "Ranking",
                          title: t("core.Ranking"),
                          value: {
                              field: ProductSortFields.score,
                              order: SortOrder.desc,
                          },
                      },
                  ]
                : []),
            {
                id: "Product id ascending",
                title: t("product-and-asset.Product id ascending"),
                value: {
                    field: ProductSortFields.id,
                    order: SortOrder.asc,
                },
            },
            {
                id: "Product id descending",
                title: t("product-and-asset.Product id descending"),
                value: {
                    field: ProductSortFields.id,
                    order: SortOrder.desc,
                },
            },
            {
                id: "Title ascending",
                title: t("product-and-asset.Title ascending"),
                value: {
                    field: ProductSortFields.title,
                    order: SortOrder.asc,
                },
            },
            {
                id: "Title descending",
                title: t("product-and-asset.Title descending"),
                value: {
                    field: ProductSortFields.title,
                    order: SortOrder.desc,
                },
            },
        ];
    });

    return {
        sortOrderItems,
    };
}

export function useProductsOverviewConfig() {
    const { t } = useI18n();

    const { hasAccess: hasConfigAccess } = useHasAccess({
        resource: AccessResource.data_display_configs,
        action: AccessAction.write,
    });

    const { hasAccess: hasFilterConfigAccess } = useHasAccess({
        resource: AccessResource.facet_collections,
        action: AccessAction.write,
    });

    const configMenuEntries: ComputedRef<MenuTextEntry[]> = computed(() => {
        return [
            {
                id: "configure-view",
                text: t("core.Configure view"),
                icon: "fa-regular fa-sliders-simple icon-color",
                click: () => {
                    const route = router.resolve({ path: "/admin/datadisplay/product-selection" });
                    window.open(route.href, "_blank");
                },
                visible: hasConfigAccess.value,
                elementAttrs: { "data-qs": "product-settings-toolbar-configure-button" },
            },
            {
                id: "configure-filter",
                text: t("core.Configure filter"),
                icon: "fa-regular fa-sliders-simple icon-color",
                click: () => {
                    const route = router.resolve({ path: "/admin/filter/product-selection" });
                    window.open(route.href, "_blank");
                },
                visible: hasFilterConfigAccess.value,
                elementAttrs: { "data-qs": "product-settings-toolbar-configure-filter-button" },
            },
        ];
    });

    return {
        configMenuEntries,
    };
}
