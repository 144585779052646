<template>
    <div ref="containerEl" class="row flex-grow" data-qs="list-filter">
        <template v-if="displayedFacets.length > 0">
            <div
                @scroll="onScroll"
                data-qs="facets-filter-container"
                class="facets-filter-container"
                :class="{
                    'flex-basis-auto': isCollapsed,
                    'flex-basis-100': !isCollapsed || isAlwaysVisibleFacetsMoreThanVisibleItemsCount,
                }"
            >
                <FacetDropdown
                    v-for="(facet, index) in displayedFacets"
                    :key="facet.id"
                    v-model="dropdownStates[facet.id]"
                    :is-last="index === displayedFacets.length - 1"
                    v-bind="getFacetDropdownProps(facet)"
                    @on-value-changed="valueChangedHandler"
                    @on-open-filter="onOpenFilterHandler"
                    @on-last-element-mounted="onLastElementMounted"
                />
                <div class="filter-delete-button">
                    <DeleteSelectionButton
                        :disabled="disableDeleteButton"
                        @delete-filter-selection="onDeleteFilterSelection"
                    />
                </div>
            </div>
            <div v-if="isToggleButtonVisible" class="flex-basis-30">
                <ToggleCollapseButton
                    :style="{ width: `${COLLAPSE_BUTTON_WIDTH}px` }"
                    :collapsed="isCollapsed"
                    :count="selectedHiddenFacets.length"
                    :expanded-text="$t('core.Less filters')"
                    :collapsed-text="$t('core.More filters')"
                    name="facets-filter"
                    @on-click="isCollapsed = !isCollapsed"
                />
            </div>
        </template>
        <div
            v-if="moreMenuEntries || sortOrderEntries"
            class="further-actions-container"
            :class="{ 'items-center': isCollapsible, 'items-end': !isCollapsible }"
        >
            <ListActions
                :initial-selected-sort-entry="initialSelectedSortEntry"
                :more-menu-entries="moreMenuEntries"
                :sort-order-entries="sortOrderEntries"
                @on-sort-selection-changed="onSelectionChangedHandler"
                :sort-save-key="sortSaveKey"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import FacetDropdown, { FacetDropdownProps } from "@/shared/facets/dropdown/facet-dropdown.vue";
import DeleteSelectionButton from "@/shared/list-filter/delete-selection-button.vue";
import { Facet, FacetSelected } from "@/shared/facets/facets.model";
import { computed, nextTick, onMounted, onUnmounted, ref, toRefs } from "vue";
import ToggleCollapseButton from "@/shared/components/buttons/toggle-collapse-button.vue";
import { SortEntry, SortOrderEntry } from "@/shared/components/sort-dropdown/sort-dropdown.model";
import ListActions from "@/shared/list-filter/list-actions.vue";
import { MenuComponentEntry, MenuTextEntry } from "@/shared/components/menu/menu.model";
import { SortSaveKey } from "@/shared/components/sort-dropdown/composables/sort-settings";
import { watchImmediate } from "@vueuse/core";
import { throttle } from "lodash";

const props = defineProps<{
    facets: Facet[];
    selected: FacetSelected[];
    getFacetDropdownProps: (facet: Facet) => FacetDropdownProps;
    disableDeleteButton: boolean;
    sortOrderEntries?: SortOrderEntry[];
    initialSelectedSortEntry?: SortEntry;
    moreMenuEntries?: (MenuTextEntry | MenuComponentEntry)[];
    sortSaveKey: SortSaveKey;
}>();

const emits = defineEmits<{
    onResetFilterSelection: [];
    onSortSelectionChanged: [sortEntry: SortEntry];
    onOpenFilter: [facet: Facet];
    onValueChanged: [facetSelected: FacetSelected];
}>();

const COLLAPSE_BUTTON_WIDTH = 120;
const FACET_BUTTON_WIDTH = 190;
const BUTTON_WIDTH_STYLE_VALUE = `${FACET_BUTTON_WIDTH}px`;
const { disableDeleteButton, facets, selected, sortOrderEntries, moreMenuEntries } = toRefs(props);
const containerEl = ref<HTMLElement | null>(null);
const isCollapsed = ref<boolean>(true);
const visibleItemsCount = ref<number>(facets.value.length);
const dropdownStates = ref<Record<string, boolean>>({});

watchImmediate(
    () => props.facets,
    () => {
        let newDropdownStates: Record<string, boolean> = {};
        props.facets.forEach((facet) => (newDropdownStates[facet.id.toString()] = false));
        dropdownStates.value = newDropdownStates;
    }
);

const throttleOnScrollFn = throttle(() => {
    for (const key in dropdownStates.value) {
        if (dropdownStates.value[key]) dropdownStates.value[key] = false;
    }
}, 100);

const onScroll = () => {
    throttleOnScrollFn();
};

onMounted(async () => {
    window.addEventListener("resize", setVisibleItems);
});

onUnmounted(() => {
    window.removeEventListener("resize", setVisibleItems);
});

const valueChangedHandler = (facetSelected: FacetSelected) => {
    emits("onValueChanged", facetSelected);
};

const onOpenFilterHandler = (facet: Facet) => {
    emits("onOpenFilter", facet);
};

const setVisibleItems = () => {
    if (!containerEl.value) return;

    visibleItemsCount.value =
        containerEl.value.clientWidth === 0
            ? facets.value.length
            : Math.floor((containerEl.value.clientWidth - COLLAPSE_BUTTON_WIDTH) / FACET_BUTTON_WIDTH);
};

const onLastElementMounted = async () => {
    await nextTick(() => {
        setVisibleItems();
    });
};

const alwaysVisibleFacets = computed(() => {
    return facets.value.filter((x) => x.displayOptions.alwaysVisible);
});

const notAlwaysVisibleFacets = computed(() => {
    return facets.value.filter((x) => !x.displayOptions.alwaysVisible);
});

const isCollapsible = computed(() => {
    if (alwaysVisibleFacets.value.length > visibleItemsCount.value) {
        return facets.value.length - alwaysVisibleFacets.value.length > 0;
    }

    return facets.value.length > visibleItemsCount.value;
});

const isToggleButtonVisible = computed(() => {
    return isCollapsible.value && !forceDisplayAllFacets.value;
});

const selectedVisibleFacets = computed(() => {
    return facets.value
        .slice(0, visibleItemsCount.value)
        .filter((facet) => selected.value.some((x) => x.referencedId === facet.referencedId && x.values.length > 0));
});

const selectedHiddenFacets = computed(() => {
    return facets.value
        .slice(visibleItemsCount.value)
        .filter((facet) => selected.value.some((x) => x.referencedId === facet.referencedId && x.values.length > 0));
});

const forceDisplayAllFacets = computed(() => {
    if (facets.value.length === 0 || visibleItemsCount.value === 0 || !selected.value) return false;

    return selectedVisibleFacets.value.length === visibleItemsCount.value && selectedHiddenFacets.value.length > 0;
});

const isAlwaysVisibleFacetsMoreThanVisibleItemsCount = computed(() => {
    return alwaysVisibleFacets.value.length > visibleItemsCount.value;
});

const displayedFacets = computed(() => {
    if (alwaysVisibleFacets.value.length === 0) {
        //facets collapsed
        if (!forceDisplayAllFacets.value && isCollapsible.value && isCollapsed.value) {
            return facets.value.slice(0, visibleItemsCount.value);
        }

        return facets.value;
    }

    const visibleFacets = [...alwaysVisibleFacets.value, ...notAlwaysVisibleFacets.value];
    if (!forceDisplayAllFacets.value && isCollapsible.value && isCollapsed.value) {
        //facets collapsed
        if (alwaysVisibleFacets.value.length > visibleItemsCount.value) return alwaysVisibleFacets.value;

        return visibleFacets.slice(0, visibleItemsCount.value);
    }

    return visibleFacets;
});

const onSelectionChangedHandler = async (sortEntry: SortEntry) => {
    emits("onSortSelectionChanged", sortEntry);
};

const onDeleteFilterSelection = () => {
    emits("onResetFilterSelection");
};
</script>

<style lang="scss" scoped>
.facets-filter-container {
    display: flex;
    flex: 0 0;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: $spacing-m;
    margin-right: $spacing-s;

    .filter-delete-button {
        margin-top: 19px;
        .q-btn {
            height: 36px;
        }
    }
    :deep(.facet-dropdown),
    :deep(.qit-property-key) {
        width: v-bind(BUTTON_WIDTH_STYLE_VALUE);
    }
    :deep(.selected-container > .q-chip) {
        max-width: 149px;
    }
}

.further-actions-container {
    flex: 1 0;
    justify-content: flex-end;
    display: flex;
}
.flex-basis-auto {
    flex-basis: auto;
}
.flex-basis-30 {
    flex-basis: 30%;
}
.flex-basis-100 {
    flex-basis: 100%;
}
</style>
