import { CollectionEntryEdge } from "@/shared/services/graphql/generated/consumer-graph-types.ts";
import { PropertyItem } from "@/shared/components/views/property-item.model.ts";
import { useMechanicArticleRoute } from "@/shared/router/mechanic-article-route.ts";
import { MechanicArticleCollectionEntry } from "@/abilities/collections/components/collection-entry.types.ts";
import { MechanicCollectionEntryNode } from "@/shared/services/graphql/generated/admin-graph-types.ts";

export const mapToMechanicArticleCollectionEntry = async (
    collectionEntryEdge: CollectionEntryEdge,
    getDataDisplayPropertiesByConfig: (graphqlResultObject: any) => PropertyItem[],
    mechanicText: string
): Promise<MechanicArticleCollectionEntry | undefined> => {
    if (!collectionEntryEdge.node.navContext?.product?.id) return undefined;

    const { getPathToMechanicArticle } = useMechanicArticleRoute();

    let mechanicArticlePathTitles: string[] = [];
    const mechanicArticle = (collectionEntryEdge.node as MechanicCollectionEntryNode).mechanic;

    if (
        mechanicArticle.pathsToRoot &&
        mechanicArticle.pathsToRoot.length > 0 &&
        mechanicArticle.pathsToRoot[0]?.path &&
        mechanicArticle.pathsToRoot[0]?.path.length > 0
    ) {
        mechanicArticlePathTitles = mechanicArticle.pathsToRoot[0].path.map((path) => {
            return path?.mechanic?.localizations?.title || "";
        });
        mechanicArticlePathTitles.pop();
    }

    let subheading = collectionEntryEdge.node.navContext?.product?.localizations?.title || "";
    if (collectionEntryEdge.node.navContext.asset?.assetId)
        subheading += ` / ${collectionEntryEdge.node.navContext.asset.assetId}`;

    subheading += ` / ${mechanicText} / ${mechanicArticlePathTitles.join(" / ")}`;

    const mechanicNode = (collectionEntryEdge.node as MechanicCollectionEntryNode).mechanic;

    const mechanicArticleCollectionEntry: MechanicArticleCollectionEntry = {
        articleId: mechanicNode.articleId || undefined,
        mechanicId: mechanicNode.id,
        //@ts-expect-error
        assemblyIdsPath: collectionEntryEdge.node.navContext?.path || [],
        title: mechanicNode.localizations?.title || mechanicNode.id,
        subheading,
        cursor: collectionEntryEdge.cursor,
        routeLocation: "",
        properties: getDataDisplayPropertiesByConfig(mechanicNode),
        previewImageUrl: mechanicNode.previewImage?.url ? (mechanicNode.previewImage?.url as string) : undefined,
        __typename: "MechanicArticle",
    };

    const mechanicContext = collectionEntryEdge.node as MechanicCollectionEntryNode;

    if (collectionEntryEdge.node.navContext?.asset?.assetId || collectionEntryEdge.node.navContext?.product?.id) {
        mechanicArticleCollectionEntry.useContext = {
            assetId: collectionEntryEdge.node.navContext?.asset?.assetId || undefined,
            productId: collectionEntryEdge.node.navContext?.product?.id || undefined,
            path: mechanicContext.navContext?.path || undefined,
        };
    }

    return {
        ...mechanicArticleCollectionEntry,
        routeLocation: getPathToMechanicArticle(
            collectionEntryEdge.node.navContext.product.id,
            collectionEntryEdge.node.navContext.asset?.assetId,
            mechanicNode.id,
            mechanicContext.navContext?.path ?? []
        ),
    };
};
