import { DatafieldType, FieldDefinitionTypes } from "@/shared/services/graphql/generated/consumer-graph-types";

export interface DatafieldFragments {
    datafields: Record<string, string[]>;
    localizationDatafields: Record<string, string[]>;
}

export interface DisplayOptions {
    dateOptions?: "date" | "dateTime";
}

export enum DataDisplayConfigId {
    productSelection = "product-selection",
    assetSelection = "asset-selection",
    parts = "parts",
    partDetails = "part-details",
    documentSelection = "document-selection",
    documentGo = "document-go",
    documentDetails = "document-details",
    product = "product",
    asset = "asset",
    article = "article",
    articleSelection = "article-selection",
    activitiesSelection = "activities-selection",
}

export interface DataDisplayField {
    key: string;
    title: string;
    fieldTypeInfo: {
        type: DatafieldType;
        fieldType: FieldDefinitionTypes;
        displayOptions?: DisplayOptions;
    };
    displayValues: Record<string, string>;
    isPriority: boolean;
}

export interface DataDisplayEntry {
    referencedId: string;
    fieldType: FieldDefinitionTypes;
}
