import { getStore } from "@/shared/store/store";
import { Router } from "vue-router";

const store = getStore();

const apmTraceId =
    store.state.apmTrace.traceId !== "" ? store.state.apmTrace.traceId : Math.random().toString(16).slice(2);
store.commit("apmTrace/setTraceId", apmTraceId);

export const getApmOptions = (router: Router) => {
    return {
        router,
        config: {
            serviceName: "info-twin",
            serverUrl: (<HTMLInputElement>document.getElementById("apm_server"))?.value ?? "",
            pageLoadTraceId: apmTraceId,
            serviceVersion: (window as any).__APP_VERSION__,
        },
    };
};
