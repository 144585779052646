<template>
    <q-card
        class="list-card"
        :class="{
            'no-shadow': noHoverShadow,
            container: container,
            small: actualCardType === 'small',
            default: actualCardType === 'default',
            compact: actualCardType === 'compact',
        }"
        :data-qs="testIdentifier"
    >
        <q-card-section
            horizontal
            class="flex row no-wrap qit-text-ellipsis"
            :class="{ 'list-card-selected': isSelectedCard }"
        >
            <q-card-section class="image-section" v-if="fallbackIcon">
                <div class="flex-column image-container">
                    <img v-if="imageSrc" :src="imageSrc" />
                    <i v-else :class="fallbackIcon" class="fa-light fa-3x" />
                    <div v-if="localeTag" class="locale flex justify-center">
                        {{ localeTag.toUpperCase() }}
                    </div>
                </div>
            </q-card-section>
            <q-separator v-if="imageSeparator" vertical />
            <q-card-section class="middle-section" :class="{ 'flex items-center': !subtitle && !$slots.content }">
                <SanitizeHtml
                    v-if="highlights?.title"
                    tag="div"
                    class="title"
                    :data-qs="`list-card-highlight-title-${highlights.title}`"
                    :content="highlights.title"
                />
                <div v-else-if="title" class="title" :class="titleClass" :data-qs="`list-card-title-${title}`">
                    {{ title }}
                </div>
                <div v-if="subtitle" class="col subtitle qit-text-ellipsis light-text-color">
                    {{ subtitle }}
                </div>
                <slot name="subtitle" />
                <template v-if="highlightsTexts.length > 0">
                    <SanitizeHtml
                        v-for="(highlightText, highlightTextIndex) in highlightsTexts"
                        :key="highlightTextIndex"
                        tag="div"
                        class="highlight-content"
                        :content="highlightText"
                    />
                    <div v-if="highlights?.text && highlights?.text.length > 1">
                        <QitButton
                            flat
                            :label="highlightsToggleLabel"
                            :icon="isHighlightsCollapse ? 'fa-regular fa-chevron-down' : 'fa-regular fa-chevron-up'"
                            color="primary"
                            class="q-pl-none toggle-highlights"
                            @click.prevent="onToggleHighlights"
                        />
                    </div>
                </template>
                <div v-if="$slots.content">
                    <slot name="content" />
                </div>
            </q-card-section>
            <template v-if="$slots.buttons">
                <q-card-section class="buttons-section">
                    <slot name="buttons" />
                </q-card-section>
            </template>
        </q-card-section>
    </q-card>
</template>

<script setup lang="ts">
import { ListCardProps } from "@/shared/components/card/list-card.types.ts";
import { computed, ref, toRefs } from "vue";
import { useScreenSize } from "@/shared/screen/composables/screen-size";
import SanitizeHtml from "@/shared/components/sanitize/sanitize-html.vue";
import QitButton from "@/shared/components/buttons/qit-button.vue";
import { useI18n } from "vue-i18n";

const props = withDefaults(defineProps<ListCardProps>(), { isSelectedCard: false });

const { index, testId, cardType } = toRefs(props);
const isHighlightsCollapse = ref<boolean>(true);

const { isLessThanMedium } = useScreenSize();
const { t } = useI18n();

const testIdentifier = computed(() => {
    if (testId.value) return testId.value;

    if (index.value !== undefined) return `list-card-${index.value}`;

    return null;
});

const actualCardType = computed(() => {
    if (cardType.value === "small") {
        return cardType.value;
    } else {
        return isLessThanMedium.value ? "compact" : "default";
    }
});

const highlightsTexts = computed(() => {
    if (!props.highlights?.text || props.highlights?.text.length < 1) return [];

    return isHighlightsCollapse.value ? [props.highlights.text[0]] : props.highlights.text;
});

const highlightsToggleLabel = computed<string>(() => {
    if (!props.highlights?.text || props.highlights?.text.length < 2) return "";

    return isHighlightsCollapse.value
        ? `${(props.highlights.text.length - 1).toString()} ${t("core.more matches to show")}`
        : t("core.Hide");
});

const onToggleHighlights = () => {
    isHighlightsCollapse.value = !isHighlightsCollapse.value;
};
</script>

<style lang="scss" scoped>
.list-card {
    box-shadow: $card-shadow;
    min-height: 70px;
    align-content: center;
    width: 100%;

    //small-card
    &.small {
        padding: 12px;

        .q-card__section {
            padding: 0;
            min-height: initial;
        }
    }

    .q-card__section {
        padding: 0;
        min-height: 66px;
    }

    &:not(.container) {
        &:not(.no-shadow):hover {
            box-shadow: $card-shadow-hover;
        }
        &.no-shadow:hover {
            background: var(--q-secondary);
        }
    }
}

.image-section {
    &:has(i) {
        background-color: $icon-background-color;
    }

    //small-card
    .small & {
        background: initial;
    }
}

.image-container {
    display: flex;
    align-items: center;

    img {
        object-fit: contain;
        width: inherit;
        height: inherit;
    }

    i {
        margin-top: $spacing-l;
        color: $light-icon-color;

        .small & {
            font-size: 20px;
        }
    }

    .default & {
        aspect-ratio: 4 / 3;
        width: $preview-image-width-default;
        height: $preview-image-height-default;
    }

    .compact & {
        aspect-ratio: 4 / 3;
        width: $preview-image-width-compact;
        height: $preview-image-height-compact;
    }

    .locale {
        font-size: 12px;
        line-height: 20px;

        .default & {
            margin-top: 2px;
        }
        .small & {
            margin-top: 2px;
        }
    }
}

.middle-section {
    margin: 12px;
    white-space: normal;
    width: 100%;
    overflow: hidden;
    .title {
        font-size: 16px;
        font-weight: $semi-bold;
        word-break: break-word;

        //small-card
        .small & {
            line-height: 24px;
            font-size: 14px;
        }
    }

    //small-card
    .small & {
        background: initial;
        margin: 0 0 0 12px;
    }
}

.highlight-content {
    background-color: $grey-95;
    padding: $spacing-m;
    margin-top: $spacing-l;
    margin-bottom: $spacing-l;
    border-radius: $default-border-radius;
    white-space: normal;
}
.list-card-selected {
    background-color: var(--q-highlight);

    /* TODO: may be another highlight version - add border to the right of the card and remove above background-color */
    /*.q-separator.q-separator--vertical {
        border-right: 4px solid var(--q-link) !important;
        color: var(--q-link) !important;
    }*/
}

.toggle-highlights {
    :deep(.q-icon) {
        font-size: 17px;

        &.on-left {
            margin-right: $spacing-m;
        }
    }
}
</style>
