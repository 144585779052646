<template>
    <AddEntryToCollectionOverlay
        :visible="true"
        :collections="result?.collections || []"
        :loading="isLoading"
        :selected="selected"
        @on-close="emits('onClose')"
        @on-save="onSave"
        @on-collection-selection-changed="onCollectionSelect"
        @on-collection-created="onCollectionCreated"
    >
        <template #entry>
            <div class="collections-radio-button-group">
                <q-option-group
                    :options="options"
                    v-model="selectedType"
                    @value-changed="setType"
                    item-template="radio"
                >
                    <template #label="opt">
                        <ListCard
                            no-hover-shadow
                            container
                            card-type="small"
                            :title="opt.label"
                            :locale-tag="opt.language"
                            :subtitle="
                                opt.parentTitle ? $t('documentation.in {path}', { path: opt.parentTitle }) : undefined
                            "
                            :fallback-icon="getIcon(opt.type)"
                            :title-class="'ellipsis'"
                        >
                            <template #content>
                                <div class="q-mt-sm">
                                    <DocumentTypeText :type="opt.type" class-names="document-type" />
                                </div>
                            </template>
                        </ListCard>
                    </template>
                </q-option-group>
            </div>
        </template>
    </AddEntryToCollectionOverlay>
</template>

<script setup lang="ts">
import {
    CollectionEntryNavigationContextInput,
    useAddContentsToCollectionMutation,
} from "@/shared/services/graphql/generated/consumer-graph-types";
import DocumentTypeText from "@/shared/documentation/document-type-text.vue";
import { computed, ref, toRefs, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useCollectionsOverlay } from "../../composables/collections-overlay";
import { useQuasar } from "quasar";
import { updateCollectionCache } from "@/abilities/collections/graphql/collection-cache.ts";
import AddEntryToCollectionOverlay from "@/abilities/collections/components/overlay/add-entry-to-collection-overlay.vue";
import ListCard from "@/shared/components/card/list-card.vue";

type DocumentType = "Topic" | "ContentMap";

const { t } = useI18n();
const { notify } = useQuasar();
const selected = ref<Record<string, boolean>>({});
const saving = ref<boolean>(false);
const allSelected = ref<boolean>(false);

const props = defineProps<{
    contentMapId: string;
    topicId?: string;
    assetId?: string;
    productId?: string;
}>();

const emits = defineEmits<{
    (e: "onSaveSucceeded"): void;
    (e: "onClose"): void;
}>();

const { topicId, contentMapId, assetId, productId } = toRefs(props);
const selectedType = ref<DocumentType>("ContentMap");

watchEffect(() => {
    selectedType.value = topicId.value ? "Topic" : "ContentMap";
});

watch(
    () => [topicId.value, contentMapId.value],
    () => {
        selected.value = {};
        allSelected.value = false;
    }
);

const { loading, result, refetch } = useCollectionsOverlay(contentMapId, topicId);
const { mutate } = useAddContentsToCollectionMutation({});

const isLoading = computed(() => {
    return loading.value || saving.value;
});

const options = computed(() => {
    if (!result.value?.items) return [];

    return result.value.items.map((x) => {
        return {
            value: x.type,
            language: x.language,
            label: x.title,
            parentTitle: x.parentTitle,
            type: x.type,
        };
    });
});

function getIcon(type: DocumentType) {
    if (type === "Topic") {
        return "fa-file-lines default-grey-icon-color";
    } else if (type === "ContentMap") {
        return "fa-book-blank info-color";
    }
    return "";
}

const setType = (e: any) => {
    selectedType.value = e.value;
};

const onCollectionSelect = (newSelected: Record<string, boolean>) => {
    selected.value = newSelected;
};

const onSave = async () => {
    const selectedCollections = Object.entries(selected.value);
    try {
        saving.value = true;
        let context: CollectionEntryNavigationContextInput | undefined = undefined;
        if (assetId.value || productId.value) {
            context = {};
            if (assetId.value) {
                context.assetId = assetId.value;
            }
            if (productId.value) {
                context.productId = productId.value;
            }
        }
        for (const [key] of selectedCollections) {
            const id =
                selectedType.value === "Topic"
                    ? `${props.topicId!}@${props.contentMapId}`
                    : `${props.contentMapId}@${props.contentMapId}`;

            await mutate(
                { collectionId: key, idsOfContents: [id], context },
                {
                    update: updateCollectionCache(key),
                }
            );
        }
        allSelected.value = false;
        notify({
            message: t("collections.Added to collection successfully"),
            type: "positive",
        });
        emits("onSaveSucceeded");
    } catch (e: any) {
        notify({ message: e.message ? e.message : e });
        console.error(e);
    } finally {
        selected.value = {};
        saving.value = false;
    }
};

const onCollectionCreated = async () => {
    await refetch();
};
</script>

<style lang="scss" scoped>
.checkbox-container {
    border: 1px solid $default-border-color;

    .q-checkbox {
        border-bottom: 1px solid $default-border-color;
        padding-left: 12px;
        margin: 0;
    }
}

.collections-radio-button-group {
    &:deep(.q-option-group) {
        width: 100%;
        :hover {
            background-color: $highlight-color;
            .q-card {
                background-color: $highlight-color;
            }
        }

        .q-radio {
            width: 100%;
            padding: $spacing-m;
        }

        .q-radio__label {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .document-type {
            font-size: $smaller-font-size;
            font-weight: $semi-bold;
            background-color: $grey-85;
            border-radius: $default-border-radius;
            padding: 2px 4px 2px 4px;
        }

        i {
            font-size: $icon-m;
        }
    }
}
</style>
