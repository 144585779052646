<template>
    <q-dialog
        v-model="visible"
        :maximized="isXSmall"
        no-route-dismiss
        no-backdrop-dismiss
        no-shake
        :full-width="fullWidth"
        :full-height="fullHeight"
    >
        <q-card class="dialog-card column">
            <div class="q-mx-md q-mt-md col-auto">
                <slot name="header" />
                <div v-if="!$slots.header" class="row no-wrap align-center">
                    <div class="flex-grow text-weight-bold text-h6">
                        {{ title }}
                    </div>
                    <div>
                        <q-btn
                            round
                            size="sm"
                            icon="fa fa-solid fa-xmark icon-color"
                            @click="() => emits('onClose')"
                            data-qs="dialog-close-btn"
                        />
                    </div>
                </div>
            </div>
            <q-card-section class="col">
                <slot name="content" />
            </q-card-section>
            <template v-if="slots.footer">
                <q-separator />
                <q-card-section class="col-auto">
                    <slot name="footer" />
                </q-card-section>
            </template>
        </q-card>
    </q-dialog>
</template>

<script setup lang="ts">
import { useScreenSize } from "@/shared/screen/composables/screen-size.ts";
import { toRefs, useSlots } from "vue";

const props = defineProps<{
    visible: boolean;
    title?: string;
    fullWidth?: boolean;
    fullHeight?: boolean;
}>();
const emits = defineEmits<{ onClose: [] }>();

const { visible, fullHeight, fullWidth } = toRefs(props);
const { isXSmall } = useScreenSize();

const slots = useSlots();
</script>

<style scoped>
.dialog-card {
    .screen--md &,
    .screen--xl &,
    .screen--lg & {
        min-width: 500px;
    }
}
</style>
