import { getFdiShopBaseUrl } from "../graphql/fdi-shop-client";

export async function isFDIShop(): Promise<boolean> {
    const shopBaseUrl = await getFdiShopBaseUrl();
    return shopBaseUrl?.includes("genie-ecommerce") ?? false;
}

// länderkürzel die sich zwischen infotwin und fdi unterscheiden
// key: infotwin | value: fdi
export const FDI_LANGUAGE_ALIAS: Record<string, string> = {
    ja: "jp",
};
