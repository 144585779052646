import { get, set, useSessionStorage } from "@vueuse/core";
import { gql } from "@apollo/client/core";
import { getShopClient } from "../graphql/fdi-shop-client";
import { defineStore } from "pinia";
import { isFDIShop } from "../settings/fdi-shop-config-settings";

const SHOP_STATUS_STORE_ID = "shopStatus";

export const useShopStatusStore = defineStore(SHOP_STATUS_STORE_ID, () => {
    const isShopOnline = useSessionStorage(SHOP_STATUS_STORE_ID + "isOnline", false);

    /**
     * Mock function to test shop offline behaviour in frontend.
     * Use it instead of the checkConnection function in config/fdi-shop.ability.ts
     */
    function mockStoreOffline() {
        set(isShopOnline, false);
        return false;
    }

    function mockStoreOnline() {
        set(isShopOnline, true);
        return true;
    }

    /**
     * function to check, if shop can be reached via currency query
     * @returns true, if shop could be reached without errors, otherwise false
     */
    const checkConnection = async () => {
        //We only do a healthcheck if the shop is really fdi, as the healthcheck is a custom public endpoint from fdi and not compatible with other shops
        //Thus there is no healthcheck for other stores and we assume they are online, but may run into the problem of a redirect to the login page that leaves us stranded when the store is not available
        const isFdiShop = await isFDIShop();
        if (!isFdiShop) {
            set(isShopOnline, true);
            // return status flag for direct use in caller component
            return get(isShopOnline);
        }

        // try to get client without token & query currencies endpoint
        try {
            // try getting apollo client without any token
            const client = await getShopClient(true);
            if (client) {
                const { data } = await client.query({
                    query: gql`
                        query healthCheck {
                            currencies {
                                name
                            }
                        }
                    `,
                });
                // for debug purpose
                const { currencies } = data;
                // when query returns data, we assume, that the shop is online
                if (currencies?.length >= 0) {
                    set(isShopOnline, true);
                }
            }
        } catch (e: any) {
            console.warn(`Currencies Query on provided shop url is not working properly.`);
            console.error(e);
            set(isShopOnline, false);
        }
        // return status flag for direct use in caller component
        return get(isShopOnline);
    };

    return {
        isShopOnline,
        checkConnection,
        mockStoreOffline,
        mockStoreOnline,
    };
});
