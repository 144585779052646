import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from 'vue';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  BigInt: any;
  /**
   * An unique identifier for datafield definitions
   * It must adhere to the following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  DatafieldDefinitionID: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  DateTime: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  EmailAddress: any;
  /**
   * Reserved characters
   * The regular expression engine supports all Unicode characters. However, the following characters are reserved as operators:
   * . ? + * | { } [ ] ( ) " \ # @ & < >  ~
   * To use one of these characters literally, escape it with a preceding backslash or surround it with double quotes. For example:
   *
   * \@                  # renders as a literal '@'
   * \\                  # renders as a literal '\'
   * "john@smith.com"    # renders as 'john@smith.com'
   *
   *
   * The regular expression engine does not use the Perl Compatible Regular Expressions (PCRE) library, but it does support the following operators:
   *
   * .
   * Matches any character. For example:
   *
   * ab.     # matches 'aba', 'abb', 'abz', etc.
   * ?
   * Repeat the preceding character zero or one times. Often used to make the preceding character optional. For example:
   *
   * abc?     # matches 'ab' and 'abc'
   * +
   * Repeat the preceding character one or more times. For example:
   *
   * ab+     # matches 'ab', 'abb', 'abbb', etc.
   * *
   * Repeat the preceding character zero or more times. For example:
   *
   * ab*     # matches 'a', 'ab', 'abb', 'abbb', etc.
   *
   *
   * {}
   * Minimum and maximum number of times the preceding character can repeat. For example:
   *
   * a{2}    # matches 'aa'
   * a{2,4}  # matches 'aa', 'aaa', and 'aaaa'
   * a{2,}   # matches 'a` repeated two or more times
   *
   *
   * |
   * OR operator. The match will succeed if the longest pattern on either the left side OR the right side matches. For example:
   *
   * abc|xyz  # matches 'abc' and 'xyz'
   *
   *
   * ( … )
   * Forms a group. You can use a group to treat part of the expression as a single character. For example:
   *
   * abc(def)?  # matches 'abc' and 'abcdef' but not 'abcd'
   *
   *
   * [ … ]
   * Match one of the characters in the brackets. For example:
   *
   * [abc]   # matches 'a', 'b', 'c'
   *
   * Inside the brackets, - indicates a range unless - is the first character or escaped. For example:
   *
   * [a-c]   # matches 'a', 'b', or 'c'
   * [-abc]  # '-' is first character. Matches '-', 'a', 'b', or 'c'
   * [abc\-] # Escapes '-'. Matches 'a', 'b', 'c', or '-'
   *
   * A ^ before a character in the brackets negates the character or range. For example:
   *
   * [^abc]      # matches any character except 'a', 'b', or 'c'
   * [^a-c]      # matches any character except 'a', 'b', or 'c'
   * [^-abc]     # matches any character except '-', 'a', 'b', or 'c'
   * [^abc\-]    # matches any character except 'a', 'b', 'c', or '-'
   *
   *
   * COMPLEMENT
   * You can use ~ to negate the shortest following pattern. For example:
   * a~bc   # matches 'adc' and 'aec' but not 'abc'
   *
   *
   * INTERVAL
   * You can use <> to match a numeric range. For example:
   *
   * foo<1-100>      # matches 'foo1', 'foo2' ... 'foo99', 'foo100'
   * foo<01-100>     # matches 'foo01', 'foo02' ... 'foo99', 'foo100'
   *
   *
   * INTERSECTION
   * The match will succeed if patterns on both the left side AND the right side matches. For example:
   *
   * aaa.+&.+bbb  # matches 'aaabbb'
   *
   *
   * ANYSTRING
   * You can use @ to match any entire string.
   * You can combine the @ operator with & and ~ operators to create an "everything except" logic. For example:
   *
   * @&~(abc.+)  # matches everything except terms beginning with 'abc'
   */
  FilterRegex: any;
  /** Full text query type which is length limited */
  FullTextSearchQuery: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  HexColorCode: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  JSON: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  JSONObject: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  Long: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  NegativeFloat: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  NegativeInt: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  NoDynamicValueDefined: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  NonNegativeFloat: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  NonNegativeInt: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  NonPositiveFloat: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  NonPositiveInt: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  PhoneNumber: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  PositiveFloat: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  PositiveInt: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  PostalCode: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  RGB: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  RGBA: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  URL: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  UnsignedFloat: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  UnsignedInt: any;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  Void: any;
};

/** Sub graph: [https://access-control-graph.qcc-auth.svc.cluster.local:8443](https://access-control-graph.qcc-auth.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AccessControlSettings = {
  __typename?: 'AccessControlSettings';
  /** The unique id */
  id: Scalars['ID'];
  /**
   * The role configuration for the access control
   *
   * The result contains a dictionary of roles.
   * Each of the roles has a grants array which contains the specific resource,
   * action and allowed attributes.
   */
  rolesConfig?: Maybe<Scalars['JSON']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AccessFilter = {
  __typename?: 'AccessFilter';
  /** All users affected by the access filter */
  affectedUsers?: Maybe<UserQueryResult>;
  /** Comment containing optional information for the access filter */
  comment?: Maybe<Scalars['String']>;
  /**
   * Graphql query specifying the content affected by the access filter.
   * This property is deprecated and will be removed in a future version.
   * For now it will return the same value as `query`.
   * @deprecated Please use 'query' in combination with 'domains' instead
   */
  contentQuery?: Maybe<Scalars['String']>;
  /** Date on which the access filter was created */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** Readable name for the access filter */
  displayName: Scalars['String'];
  /** All domains this access filter applies to. If empty, no domain is affected, but filter will be generated. */
  domains?: Maybe<Array<Maybe<AccessFilterDomains>>>;
  /** Unique identifier for the access filter. */
  id: Scalars['ID'];
  /** All matching contents for the access filter */
  matchingContents?: Maybe<ContentQueryResult>;
  /** Date of the latest modification made to the access filter */
  modificationDate?: Maybe<Scalars['DateTime']>;
  /** Graphql query specifying the content affected by the access filter */
  query?: Maybe<Scalars['String']>;
  /** Allowed resource actions for users on the specified rendition of the content */
  resourceActions?: Maybe<Array<Maybe<ResourceActions>>>;
  /** Graphql query specifying the users affected by the access filter */
  userQuery: Scalars['String'];
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AccessFilterAffectedUsersArgs = {
  filter?: InputMaybe<UserFilter>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AccessFilterMatchingContentsArgs = {
  filter?: InputMaybe<ContentFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum AccessFilterDomains {
  activity = 'activity',
  asset = 'asset',
  content = 'content',
  product = 'product'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AccessFilterEdge = {
  __typename?: 'AccessFilterEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** The result's access filter. */
  node: AccessFilter;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AccessFilterEqualsOneOfValueFilter = {
  /** Returns only access filters which have one of the specified domains */
  domains?: InputMaybe<Array<InputMaybe<AccessFilterDomains>>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AccessFilterEqualsValueFilter = {
  /** Returns only access filters with the specified creation date */
  creationDate?: InputMaybe<Scalars['DateTime']>;
  /** Returns only access filters with the specified display name */
  displayName?: InputMaybe<Scalars['String']>;
  /** Returns only access filters with the specified email address */
  email?: InputMaybe<Scalars['EmailAddress']>;
  /** Returns only access filters with the specified modification date */
  modificationDate?: InputMaybe<Scalars['DateTime']>;
};

/**
 * The `AccessFilterFilter` is used to specify filter criteria for access filters.
 * If you specify more than one criterion, all criteria are grouped together and connected with a logical AND operator.
 * Further logical operations are possible by using `not` `andGroup` and `orGroup`.
 */
export type AccessFilterFilter = {
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<AccessFilterFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns only access filters which have all of the specified role, either by direct assignment or by inheritance */
  effectiveRoles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Returns access filters that contain all of the exact values specified */
  equals?: InputMaybe<AccessFilterEqualsValueFilter>;
  /** Restricts access to accessfilter that contain all of the exact values specified */
  equalsOneOf?: InputMaybe<AccessFilterEqualsOneOfValueFilter>;
  /** Returns access filters with one of the specified unique identifiers */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<AccessFilterFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<AccessFilterFilter>>>;
  /** Returns access filters that match all of the regular expressions */
  regex?: InputMaybe<AccessFilterRegexFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AccessFilterQueryResult = {
  __typename?: 'AccessFilterQueryResult';
  /**
   * The query's matching access filter.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  accessFilters: Array<Maybe<AccessFilterEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AccessFilterQueryResultAccessFiltersArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<AccessFilterSort>>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AccessFilterQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AccessFilterRangeFilter = {
  /** Returns only access filters with a creation date in the specified range */
  creationDate?: InputMaybe<DateRange>;
  /** Returns only access filters with a modification date in the specified range */
  modificationDate?: InputMaybe<DateRange>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AccessFilterRegexFilter = {
  /** Returns only access filters whose display name matches the specified Regex */
  displayName?: InputMaybe<Scalars['FilterRegex']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AccessFilterSort = {
  /** Sort field */
  field: AccessFilterSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum AccessFilterSortFields {
  /** Sort by date on which the access filter was created */
  creationDate = 'creationDate',
  /** Sort by readable name */
  displayName = 'displayName',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by date of the latest modification made to the access filter */
  modificationDate = 'modificationDate',
  /** Sort by relevance score */
  score = 'score'
}

/**
 * An activity is a guided and logged action instruction.
 * It lists
 * - a title
 * - a brief introduction
 * - pre-requisites to perform the task (like material)
 * - steps to perform the action
 * - (intermediate) results
 * - safety notices
 * - indexed fields (custom fields)
 * - references to related products, assets and articles
 * - attachments which are required by the activity (e.g. images, style sheets, etc.)
 */
export type Activity = {
  __typename?: 'Activity';
  /** The related article ids */
  articleIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The related asset ids */
  assetIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Date and time when the activity was created */
  creationDate: Scalars['String'];
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Introduction information on the activity */
  introduction?: Maybe<Scalars['JSONObject']>;
  /** Language of the activity */
  language: Language;
  /** Text from the activity for use e.g. in details. */
  localizations?: Maybe<ActivityLocalizations>;
  /** Date and time of the last modification */
  modificationDate?: Maybe<Scalars['DateTime']>;
  /** Steps to perform the activity */
  procedure?: Maybe<Scalars['JSONObject']>;
  /** Related information of the activity */
  relations?: Maybe<Array<Maybe<ActivityRelationGroup>>>;
  /** Length limited text from the activity for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<ActivityTeasers>;
  /** Title of the activity */
  title: MultilingualString;
  /** Most recent date and time when the activity was uploaded */
  uploadDate?: Maybe<Scalars['DateTime']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityAccessFilter = {
  /**
   * TODO: if activityAccessFilter is removed this input can be made _internal. If the activityAccessFilter is removed the ActivityAccessFilter can be marked as internal only. the begin_template and end_template can be removed after the deprecation. Other domains should be handled in the same way. deprecated
   * Returns all activities if set to true
   */
  all?: InputMaybe<Scalars['Boolean']>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<ActivityFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns activities that contain all of the exact values specified */
  equals?: InputMaybe<ActivityEqualsValueFilter>;
  /** Returns activities that match all of the specified full text searches */
  exists?: InputMaybe<Array<InputMaybe<ActivityExistsValueFields>>>;
  /** Returns activities that match all of the specified full text searches */
  fullText?: InputMaybe<ActivityFullTextFilter>;
  /** Returns activities with one of the specified unique identifiers */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<ActivityFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<ActivityFilter>>>;
  /** Returns activities with values within all of the ranges specified */
  range?: InputMaybe<ActivityRangeFilter>;
  /** Returns activities that match all of the regular expressions */
  regex?: InputMaybe<ActivityRegexFilter>;
  /** Returns activities that match a taxonomy value according to the taxonomy's evaluation type */
  taxonomy?: InputMaybe<ActivityTaxonomyFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityAggregations = {
  __typename?: 'ActivityAggregations';
  /**
   * Provides aggregations for the language field
   * @deprecated API is likely to change in future versions
   */
  language?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the content's use context */
  useContext?: Maybe<DatafieldAggregations>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityAggregationsLanguageArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityAutocompleteHits = {
  __typename?: 'ActivityAutocompleteHits';
  /** The count of docs found containing the suggestion */
  docCount: Scalars['NonNegativeInt'];
  /** The found suggestion */
  suggest: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityAutocompleteResult = {
  __typename?: 'ActivityAutocompleteResult';
  /** The hits found for the query */
  hits?: Maybe<Array<ActivityAutocompleteHits>>;
  /** The query used for this result */
  query: Scalars['String'];
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityCollectionEntryNode = CollectionEntryNode & {
  __typename?: 'ActivityCollectionEntryNode';
  /** The activity object */
  activity: Activity;
  /** The result's collection object context. */
  navContext?: Maybe<CollectionEntryCommonNavigationContext>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityEdge = {
  __typename?: 'ActivityEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** Highlighted matches for equality and full text filters. Matched terms are wrapped in <em>...</em> Tags. */
  highlights?: Maybe<ActivityHighlights>;
  /** The result's activity type. */
  node: Activity;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityEqualsValueFilter = {
  /** Returns only activities with the specified articleId */
  articleId?: InputMaybe<Scalars['String']>;
  /** Returns only activities with the specified assetId */
  assetId?: InputMaybe<Scalars['String']>;
  /** Returns only activities with the specified creation date */
  creationDate?: InputMaybe<Scalars['DateTime']>;
  /** Returns only activities with the specified language */
  language?: InputMaybe<Language>;
  /** Returns only activities with the specified modification date */
  modificationDate?: InputMaybe<Scalars['DateTime']>;
  /** Returns only activities with the specified title */
  title?: InputMaybe<Scalars['String']>;
  /** Returns only activities with the specified upload date */
  uploadDate?: InputMaybe<Scalars['DateTime']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum ActivityExistsValueFields {
  /** Returns only activities which have one or more article ids specified */
  articleIds = 'articleIds',
  /** Returns only activities which have one or more asset ids specified */
  assetIds = 'assetIds',
  /** Returns only activities which have a language specified */
  language = 'language',
  /** Returns only activities which have a title specified */
  title = 'title'
}

/**
 * The `ActivityFilter` is used to specify filter criteria for activities.
 * If you specify more than one criterion, all criteria are grouped together and connected with a logical AND operator.
 * Further logical operations are possible by using `not` `andGroup` and `orGroup`.
 */
export type ActivityFilter = {
  /** Returns all activities if set to true */
  all?: InputMaybe<Scalars['Boolean']>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<ActivityFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns activities that contain all of the exact values specified */
  equals?: InputMaybe<ActivityEqualsValueFilter>;
  /** Returns activities that match all of the specified full text searches */
  exists?: InputMaybe<Array<InputMaybe<ActivityExistsValueFields>>>;
  /** Returns activities that match all of the specified full text searches */
  fullText?: InputMaybe<ActivityFullTextFilter>;
  /** Returns activities with one of the specified unique identifiers */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<ActivityFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<ActivityFilter>>>;
  /** Returns activities with values within all of the ranges specified */
  range?: InputMaybe<ActivityRangeFilter>;
  /** Returns activities that match all of the regular expressions */
  regex?: InputMaybe<ActivityRegexFilter>;
  /** Returns activities that match a taxonomy value according to the taxonomy's evaluation type */
  taxonomy?: InputMaybe<ActivityTaxonomyFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: InputMaybe<Scalars['Float']>;
  /** Match the query against the text in any language and rank the results with the specified boost */
  text?: InputMaybe<Scalars['Float']>;
  /** Match the query against the title in any language and rank the results with the specified boost */
  title?: InputMaybe<Scalars['Float']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityFullTextFilter = {
  /** Fields to search in with their respective score weights */
  fields?: InputMaybe<ActivityFullTextFields>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: InputMaybe<Scalars['Boolean']>;
  /** Specifies if either all or any query words need to match */
  operator?: InputMaybe<FullTextOperator>;
  /**
   * Text to search for
   * Be aware: The Limit for full text search queries is 1024 characters and
   * anything above that will result in an error.
   */
  query: Scalars['FullTextSearchQuery'];
  /** Type of fulltext search to perform */
  searchType?: InputMaybe<FullTextSearchType>;
  /** Options to configure the synonym search */
  synonymOptions?: InputMaybe<SynonymOptions>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityHighlights = {
  __typename?: 'ActivityHighlights';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  text?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityHighlightsTextArgs = {
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityHighlightsTitleArgs = {
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Text from the activity for use e.g. in details. */
export type ActivityLocalizations = {
  __typename?: 'ActivityLocalizations';
  /** The activity's title */
  title?: Maybe<Scalars['String']>;
};


/** Text from the activity for use e.g. in details. */
export type ActivityLocalizationsTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityNavigationContextInput = {
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  assetId?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  productId?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityQueryResult = {
  __typename?: 'ActivityQueryResult';
  /**
   * The query's matching activities.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  activities: Array<Maybe<ActivityEdge>>;
  /**
   * Matched datafield values and number of matching activities per value
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   */
  aggregations: ActivityAggregations;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityQueryResultActivitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<ActivitySort>>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityRangeFilter = {
  /** Returns only activities with a creation date in the specified range */
  creationDate?: InputMaybe<DateRange>;
  /** Returns only activities with a modification date in the specified range */
  modificationDate?: InputMaybe<DateRange>;
  /** No number or dateTime datafield definitions exist. As soon as the first such datafield definition is defined, it's automatically listed here. */
  not_applicable_as_no_number_or_date_datafield_definitions_exist?: InputMaybe<Scalars['NoDynamicValueDefined']>;
  /** Returns only activities with a upload date in the specified range */
  uploadDate?: InputMaybe<DateRange>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityReference = Article | UnresolvedArticleActivityReference;

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityRegexFilter = {
  /** Returns only activities whose articleId matches the specified Regex */
  articleId?: InputMaybe<Scalars['String']>;
  /** Returns only activities whose assetId matches the specified Regex */
  assetId?: InputMaybe<Scalars['String']>;
  /** Returns only activities whose title matches the specified Regex */
  title?: InputMaybe<Scalars['FilterRegex']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityRelationGroup = {
  __typename?: 'ActivityRelationGroup';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  localizations?: Maybe<ActivityRelationGroupLocalizations>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  references?: Maybe<Array<Maybe<ActivityReference>>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  teasers?: Maybe<ActivityRelationGroupTeasers>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  title: MultilingualString;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityRelationGroupLocalizations = {
  __typename?: 'ActivityRelationGroupLocalizations';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  title?: Maybe<Scalars['String']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityRelationGroupLocalizationsTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityRelationGroupTeasers = {
  __typename?: 'ActivityRelationGroupTeasers';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  title?: Maybe<Scalars['String']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityRelationGroupTeasersTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivitySort = {
  /** Sort field */
  field: ActivitySortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum ActivitySortFields {
  /** Sort by assetId */
  assetId = 'assetId',
  /** Sort by creation date */
  creationDate = 'creationDate',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by language */
  language = 'language',
  /** Sort by modification date */
  modificationDate = 'modificationDate',
  /** Sort by productId */
  productId = 'productId',
  /** Sort by relevance score */
  score = 'score',
  /** Sort by title */
  title = 'title',
  /** Sort by language-ar title */
  titleAr = 'title_ar',
  /** Sort by language-bg title */
  titleBg = 'title_bg',
  /** Sort by language-bn title */
  titleBn = 'title_bn',
  /** Sort by language-bs title */
  titleBs = 'title_bs',
  /** Sort by language-ca title */
  titleCa = 'title_ca',
  /** Sort by language-cs title */
  titleCs = 'title_cs',
  /** Sort by language-da title */
  titleDa = 'title_da',
  /** Sort by language-de title */
  titleDe = 'title_de',
  /** Sort by language-el title */
  titleEl = 'title_el',
  /** Sort by language-en title */
  titleEn = 'title_en',
  /** Sort by language-es title */
  titleEs = 'title_es',
  /** Sort by language-et title */
  titleEt = 'title_et',
  /** Sort by language-eu title */
  titleEu = 'title_eu',
  /** Sort by language-fa title */
  titleFa = 'title_fa',
  /** Sort by language-fi title */
  titleFi = 'title_fi',
  /** Sort by language-fr title */
  titleFr = 'title_fr',
  /** Sort by language-ga title */
  titleGa = 'title_ga',
  /** Sort by language-he title */
  titleHe = 'title_he',
  /** Sort by language-hi title */
  titleHi = 'title_hi',
  /** Sort by language-hr title */
  titleHr = 'title_hr',
  /** Sort by language-hu title */
  titleHu = 'title_hu',
  /** Sort by language-hy title */
  titleHy = 'title_hy',
  /** Sort by language-id title */
  titleId = 'title_id',
  /** Sort by language-is title */
  titleIs = 'title_is',
  /** Sort by language-it title */
  titleIt = 'title_it',
  /** Sort by language-ja title */
  titleJa = 'title_ja',
  /** Sort by language-ko title */
  titleKo = 'title_ko',
  /** Sort by language-ku title */
  titleKu = 'title_ku',
  /** Sort by language-lt title */
  titleLt = 'title_lt',
  /** Sort by language-lv title */
  titleLv = 'title_lv',
  /** Sort by language-ms title */
  titleMs = 'title_ms',
  /** Sort by language-mt title */
  titleMt = 'title_mt',
  /** Sort by language-nb title */
  titleNb = 'title_nb',
  /** Sort by language-nl title */
  titleNl = 'title_nl',
  /** Sort by language-no title */
  titleNo = 'title_no',
  /** Sort by language-pb title */
  titlePb = 'title_pb',
  /** Sort by language-pl title */
  titlePl = 'title_pl',
  /** Sort by language-pt title */
  titlePt = 'title_pt',
  /** Sort by language-ro title */
  titleRo = 'title_ro',
  /** Sort by language-ru title */
  titleRu = 'title_ru',
  /** Sort by language-sk title */
  titleSk = 'title_sk',
  /** Sort by language-sl title */
  titleSl = 'title_sl',
  /** Sort by language-sq title */
  titleSq = 'title_sq',
  /** Sort by language-sr title */
  titleSr = 'title_sr',
  /** Sort by language-sv title */
  titleSv = 'title_sv',
  /** Sort by language-th title */
  titleTh = 'title_th',
  /** Sort by language-tr title */
  titleTr = 'title_tr',
  /** Sort by language-uk title */
  titleUk = 'title_uk',
  /** Sort by language-vi title */
  titleVi = 'title_vi',
  /** Sort by language-zh title */
  titleZh = 'title_zh',
  /** Sort by upload date */
  uploadDate = 'uploadDate'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ActivityTaxonomyFilter = {
  /** No taxonomy datafield definitions exist. As soon as the first taxonomy is defined, it's automatically listed here. */
  not_applicable_as_no_taxonomy_datafield_definitions_exist?: InputMaybe<Scalars['NoDynamicValueDefined']>;
};

/** Length limited text from the activity for use e.g. in result lists, previews, etc. */
export type ActivityTeasers = {
  __typename?: 'ActivityTeasers';
  /** The activity's title limited to `length` characters */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the activity for use e.g. in result lists, previews, etc. */
export type ActivityTeasersTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AggregationEdge = {
  __typename?: 'AggregationEdge';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  count: Scalars['NonNegativeInt'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  cursor: Scalars['String'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  value: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Aggregations = {
  __typename?: 'Aggregations';
  /** Provides aggregations for the contentId field */
  contentId?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the documentId field */
  documentId?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the language field */
  language?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the locale field */
  locale?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the mimeType field */
  mimeType?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the rendition field */
  rendition?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the source field */
  source?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the sourceId field */
  sourceId?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the type field */
  type?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the content's use context */
  useContext?: Maybe<UseContextAggregations>;
  /** Provides aggregations for the version field */
  version?: Maybe<Array<AggregationEdge>>;
  /** Provides aggregations for the versionLabel field */
  versionLabel?: Maybe<Array<AggregationEdge>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AggregationsContentIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AggregationsDocumentIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AggregationsLanguageArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AggregationsLocaleArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AggregationsMimeTypeArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AggregationsRenditionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AggregationsSourceArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AggregationsSourceIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AggregationsTypeArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AggregationsVersionArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AggregationsVersionLabelArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ApiKey = {
  __typename?: 'ApiKey';
  /** Readable name for the api key */
  displayName: Scalars['String'];
  /** The api key's unique identifier */
  id: Scalars['ID'];
  /** Date until which the api key is valid */
  validUntil?: Maybe<Scalars['DateTime']>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Article = {
  __typename?: 'Article';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  articleNumber?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  files?: Maybe<Array<File>>;
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  localizations?: Maybe<ArticleLocalizations>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  orderable?: Maybe<Scalars['Boolean']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  previewImage?: Maybe<BlobRef>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  referenceId?: Maybe<Scalars['String']>;
  /**
   * Related activity to the article id. The given activity filter is optional and is combined (AND clause) with
   * a static filter:
   * { equals: { custom_articles: [article.id] } }
   * This returns only activities related to the specific article.
   */
  relatedActivities: ActivityQueryResult;
  /**
   * WARNING: Does not work for now because the required filter could not be defined 100%
   *
   * Related content to the product id. The given content filter is optional and is combined (AND clause) with
   * a static filter:
   * const articleFilter = { articleIds: [article.id] };
   * This returns only contents related to the specific product.
   * The product id is used because an asset is always related to a product.
   */
  relatedContents: ContentQueryResult;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  teasers?: Maybe<ArticleTeasers>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  title?: Maybe<MultilingualString>;
};


/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ArticleFilesArgs = {
  fileTypes?: InputMaybe<Array<InputMaybe<FileType>>>;
};


/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ArticleRelatedActivitiesArgs = {
  filter?: InputMaybe<ActivityFilter>;
};


/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ArticleRelatedContentsArgs = {
  filter?: InputMaybe<ContentFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ArticleAutocompleteHits = {
  __typename?: 'ArticleAutocompleteHits';
  /** The count of docs found containing the suggestion */
  docCount: Scalars['NonNegativeInt'];
  /** The language this suggestion stems from */
  language?: Maybe<Language>;
  /** The found suggestion */
  suggest: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ArticleAutocompleteResult = {
  __typename?: 'ArticleAutocompleteResult';
  /** The hits found for the query */
  hits?: Maybe<Array<ArticleAutocompleteHits>>;
  /** The query used for this result */
  query: Scalars['String'];
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ArticleCollectionEntryNode = CollectionEntryNode & {
  __typename?: 'ArticleCollectionEntryNode';
  /** The article object */
  article: Article;
  /** The result's collection object context. */
  navContext?: Maybe<CollectionEntryCommonNavigationContext>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ArticleEdge = {
  __typename?: 'ArticleEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** The result's article. */
  node: Article;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ArticleEqualsValueFilter = {
  /** Returns only articles with the specified article number */
  articleNumber?: InputMaybe<Scalars['String']>;
  /** No equals filterable datafield definitions exist. As soon as the first matching datafield definition is defined, it's automatically listed here. */
  not_applicable_as_no_equals_filterable_datafield_definitions_exist?: InputMaybe<Scalars['NoDynamicValueDefined']>;
  /**
   * Returns only articles with the specified title
   * @deprecated Use two title_xx fields for InfoTwin language and tenant default language instead
   */
  title?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified ar title */
  title_ar?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified bg title */
  title_bg?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified bn title */
  title_bn?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified bs title */
  title_bs?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified ca title */
  title_ca?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified cs title */
  title_cs?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified da title */
  title_da?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified de title */
  title_de?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified el title */
  title_el?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified en title */
  title_en?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified es title */
  title_es?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified et title */
  title_et?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified eu title */
  title_eu?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified fa title */
  title_fa?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified fi title */
  title_fi?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified fr title */
  title_fr?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified ga title */
  title_ga?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified he title */
  title_he?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified hi title */
  title_hi?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified hr title */
  title_hr?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified hu title */
  title_hu?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified hy title */
  title_hy?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified id title */
  title_id?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified is title */
  title_is?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified it title */
  title_it?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified ja title */
  title_ja?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified ko title */
  title_ko?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified ku title */
  title_ku?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified lt title */
  title_lt?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified lv title */
  title_lv?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified ms title */
  title_ms?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified mt title */
  title_mt?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified nb title */
  title_nb?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified nl title */
  title_nl?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified no title */
  title_no?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified pb title */
  title_pb?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified pl title */
  title_pl?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified pt title */
  title_pt?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified ro title */
  title_ro?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified ru title */
  title_ru?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified sk title */
  title_sk?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified sl title */
  title_sl?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified sq title */
  title_sq?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified sr title */
  title_sr?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified sv title */
  title_sv?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified th title */
  title_th?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified tr title */
  title_tr?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified uk title */
  title_uk?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified vi title */
  title_vi?: InputMaybe<Scalars['String']>;
  /** Returns only articles with the specified zh title */
  title_zh?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum ArticleExistsValueFields {
  /** Returns only articles which have an article number specified */
  articleNumber = 'articleNumber',
  /** Returns only articles which have a title specified */
  title = 'title'
}

/**
 * The `ArticleFilter` is used to specify filter criteria for articles.
 * If you specify more than one criterion, all criteria are grouped together and connected with a logical AND operator.
 * Further logical operations are possible by using `not` `andGroup` and `orGroup`.
 */
export type ArticleFilter = {
  /** Returns all articles if set to true */
  all?: InputMaybe<Scalars['Boolean']>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<ArticleFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns articles that contain all of the exact values specified */
  equals?: InputMaybe<ArticleEqualsValueFilter>;
  /**
   * Returns articles that have a value in all of the specified fields
   * @deprecated Please use 'has' filter instead
   */
  exists?: InputMaybe<Array<InputMaybe<ArticleExistsValueFields>>>;
  /** Returns articles that match all of the specified full text searches */
  fullText?: InputMaybe<ArticleFullTextFilter>;
  /** Returns articles that have a value in all of the specified fields */
  has?: InputMaybe<ArticleHasValueFilter>;
  /** Returns articles with one of the specified unique identifiers */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<ArticleFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<ArticleFilter>>>;
  /** Returns articles with values within all of the ranges specified */
  range?: InputMaybe<ArticleRangeFilter>;
  /** Returns articles that match all of the regular expressions */
  regex?: InputMaybe<ArticleRegexFilter>;
  /** Returns articles that match a taxonomy value according to the taxonomy's evaluation type */
  taxonomy?: InputMaybe<ArticleTaxonomyFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ArticleFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: InputMaybe<Scalars['Float']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  articleNumber?: InputMaybe<Scalars['Float']>;
  /**
   * Match the query against the title and rank the results with the specified boost
   * @deprecated Use two title_xx fields for InfoTwin language and tenant default language instead
   */
  title?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ar?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_bg?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_bn?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_bs?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ca?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_cs?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_da?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_de?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_el?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_en?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_es?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_et?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_eu?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_fa?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_fi?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_fr?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ga?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_he?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_hi?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_hr?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_hu?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_hy?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_id?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_is?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_it?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ja?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ko?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ku?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_lt?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_lv?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ms?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_mt?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_nb?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_nl?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_no?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_pb?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_pl?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_pt?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ro?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ru?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sk?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sl?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sq?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sr?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sv?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_th?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_tr?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_uk?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_vi?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_zh?: InputMaybe<Scalars['Float']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ArticleFullTextFilter = {
  /** Fields to search in with their respective score weights */
  fields?: InputMaybe<ArticleFullTextFields>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: InputMaybe<Scalars['Boolean']>;
  /** Specifies if either all or any query words need to match */
  operator?: InputMaybe<FullTextOperator>;
  /** Text to search for */
  query: Scalars['String'];
  /** Type of fulltext search to perform */
  searchType?: InputMaybe<FullTextSearchType>;
};

/** Returns articles that have a value in all of the specified fields */
export type ArticleHasValueFilter = {
  /** Specify the fields that the returned articles must have a value in */
  valueInFields?: InputMaybe<Array<InputMaybe<ArticleHasValueInFields>>>;
};

/** Specify the fields that the returned articles must have a value in */
export enum ArticleHasValueInFields {
  /** Returns only articles which have an article number specified */
  articleNumber = 'articleNumber',
  /** Returns only articles which have a title specified */
  title = 'title'
}

/** identifier for the articleinformation in hoops */
export type ArticleHoopsRef = {
  __typename?: 'ArticleHoopsRef';
  /** name of the node property containing the article information */
  nodeProperty?: Maybe<Scalars['String']>;
};

/** Articlereference for hoops */
export type ArticleHoopsRefSpec = {
  /** Name of the node property containing the article information */
  nodeProperty: Scalars['String'];
};

/** identifier for the articleinformation in lattice */
export type ArticleLatticeRef = {
  __typename?: 'ArticleLatticeRef';
  /** datafield of the article */
  field?: Maybe<Scalars['String']>;
  /** the section type in 3d-Model */
  section?: Maybe<Scalars['String']>;
};

/** Articlereference for lattice */
export type ArticleLatticeRefSpec = {
  /** Datafield */
  field: Scalars['String'];
  /** Section in 3D-Model */
  section: Scalars['String'];
};

/** Text from the article for use e.g. in details. */
export type ArticleLocalizations = {
  __typename?: 'ArticleLocalizations';
  /** The article's title */
  title?: Maybe<Scalars['String']>;
};


/** Text from the article for use e.g. in details. */
export type ArticleLocalizationsTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ArticleQueryResult = {
  __typename?: 'ArticleQueryResult';
  /**
   * Matched datafield values and number of matching articles per value
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   */
  aggregations: DatafieldAggregations;
  /**
   * The query's matching articles.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  articles: Array<Maybe<ArticleEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ArticleQueryResultArticlesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<ArticleSort>>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ArticleQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ArticleRangeFilter = {
  /** No number or dateTime datafield definitions exist. As soon as the first such datafield definition is defined, it's automatically listed here. */
  not_applicable_as_no_number_or_date_datafield_definitions_exist?: InputMaybe<Scalars['NoDynamicValueDefined']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ArticleRegexFilter = {
  /** Returns only articles whose article numbers matches the specified Regex */
  articleNumber?: InputMaybe<Scalars['FilterRegex']>;
  /**
   * Returns only articles whose title matches the specified Regex
   * @deprecated Use two title_xx fields for InfoTwin language and tenant default language instead
   */
  title?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose ar title matches the specified Regex */
  title_ar?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose bg title matches the specified Regex */
  title_bg?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose bn title matches the specified Regex */
  title_bn?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose bs title matches the specified Regex */
  title_bs?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose ca title matches the specified Regex */
  title_ca?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose cs title matches the specified Regex */
  title_cs?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose da title matches the specified Regex */
  title_da?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose de title matches the specified Regex */
  title_de?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose el title matches the specified Regex */
  title_el?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose en title matches the specified Regex */
  title_en?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose es title matches the specified Regex */
  title_es?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose et title matches the specified Regex */
  title_et?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose eu title matches the specified Regex */
  title_eu?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose fa title matches the specified Regex */
  title_fa?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose fi title matches the specified Regex */
  title_fi?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose fr title matches the specified Regex */
  title_fr?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose ga title matches the specified Regex */
  title_ga?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose he title matches the specified Regex */
  title_he?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose hi title matches the specified Regex */
  title_hi?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose hr title matches the specified Regex */
  title_hr?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose hu title matches the specified Regex */
  title_hu?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose hy title matches the specified Regex */
  title_hy?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose id title matches the specified Regex */
  title_id?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose is title matches the specified Regex */
  title_is?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose it title matches the specified Regex */
  title_it?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose ja title matches the specified Regex */
  title_ja?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose ko title matches the specified Regex */
  title_ko?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose ku title matches the specified Regex */
  title_ku?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose lt title matches the specified Regex */
  title_lt?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose lv title matches the specified Regex */
  title_lv?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose ms title matches the specified Regex */
  title_ms?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose mt title matches the specified Regex */
  title_mt?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose nb title matches the specified Regex */
  title_nb?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose nl title matches the specified Regex */
  title_nl?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose no title matches the specified Regex */
  title_no?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose pb title matches the specified Regex */
  title_pb?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose pl title matches the specified Regex */
  title_pl?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose pt title matches the specified Regex */
  title_pt?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose ro title matches the specified Regex */
  title_ro?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose ru title matches the specified Regex */
  title_ru?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose sk title matches the specified Regex */
  title_sk?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose sl title matches the specified Regex */
  title_sl?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose sq title matches the specified Regex */
  title_sq?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose sr title matches the specified Regex */
  title_sr?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose sv title matches the specified Regex */
  title_sv?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose th title matches the specified Regex */
  title_th?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose tr title matches the specified Regex */
  title_tr?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose uk title matches the specified Regex */
  title_uk?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose vi title matches the specified Regex */
  title_vi?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only articles whose zh title matches the specified Regex */
  title_zh?: InputMaybe<Scalars['FilterRegex']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ArticleSort = {
  /** Sort field */
  field: ArticleSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum ArticleSortFields {
  /** Sort by article number */
  articleNumber = 'articleNumber',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by relevance score */
  score = 'score',
  /**
   * Sort by title
   * @deprecated Use two title_xx fields for InfoTwin language and tenant default language instead
   */
  title = 'title',
  /** Sort by language-ar title */
  titleAr = 'title_ar',
  /** Sort by language-bg title */
  titleBg = 'title_bg',
  /** Sort by language-bn title */
  titleBn = 'title_bn',
  /** Sort by language-bs title */
  titleBs = 'title_bs',
  /** Sort by language-ca title */
  titleCa = 'title_ca',
  /** Sort by language-cs title */
  titleCs = 'title_cs',
  /** Sort by language-da title */
  titleDa = 'title_da',
  /** Sort by language-de title */
  titleDe = 'title_de',
  /** Sort by language-el title */
  titleEl = 'title_el',
  /** Sort by language-en title */
  titleEn = 'title_en',
  /** Sort by language-es title */
  titleEs = 'title_es',
  /** Sort by language-et title */
  titleEt = 'title_et',
  /** Sort by language-eu title */
  titleEu = 'title_eu',
  /** Sort by language-fa title */
  titleFa = 'title_fa',
  /** Sort by language-fi title */
  titleFi = 'title_fi',
  /** Sort by language-fr title */
  titleFr = 'title_fr',
  /** Sort by language-ga title */
  titleGa = 'title_ga',
  /** Sort by language-he title */
  titleHe = 'title_he',
  /** Sort by language-hi title */
  titleHi = 'title_hi',
  /** Sort by language-hr title */
  titleHr = 'title_hr',
  /** Sort by language-hu title */
  titleHu = 'title_hu',
  /** Sort by language-hy title */
  titleHy = 'title_hy',
  /** Sort by language-id title */
  titleId = 'title_id',
  /** Sort by language-is title */
  titleIs = 'title_is',
  /** Sort by language-it title */
  titleIt = 'title_it',
  /** Sort by language-ja title */
  titleJa = 'title_ja',
  /** Sort by language-ko title */
  titleKo = 'title_ko',
  /** Sort by language-ku title */
  titleKu = 'title_ku',
  /** Sort by language-lt title */
  titleLt = 'title_lt',
  /** Sort by language-lv title */
  titleLv = 'title_lv',
  /** Sort by language-ms title */
  titleMs = 'title_ms',
  /** Sort by language-mt title */
  titleMt = 'title_mt',
  /** Sort by language-nb title */
  titleNb = 'title_nb',
  /** Sort by language-nl title */
  titleNl = 'title_nl',
  /** Sort by language-no title */
  titleNo = 'title_no',
  /** Sort by language-pb title */
  titlePb = 'title_pb',
  /** Sort by language-pl title */
  titlePl = 'title_pl',
  /** Sort by language-pt title */
  titlePt = 'title_pt',
  /** Sort by language-ro title */
  titleRo = 'title_ro',
  /** Sort by language-ru title */
  titleRu = 'title_ru',
  /** Sort by language-sk title */
  titleSk = 'title_sk',
  /** Sort by language-sl title */
  titleSl = 'title_sl',
  /** Sort by language-sq title */
  titleSq = 'title_sq',
  /** Sort by language-sr title */
  titleSr = 'title_sr',
  /** Sort by language-sv title */
  titleSv = 'title_sv',
  /** Sort by language-th title */
  titleTh = 'title_th',
  /** Sort by language-tr title */
  titleTr = 'title_tr',
  /** Sort by language-uk title */
  titleUk = 'title_uk',
  /** Sort by language-vi title */
  titleVi = 'title_vi',
  /** Sort by language-zh title */
  titleZh = 'title_zh'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ArticleSpec = {
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  orderable?: InputMaybe<Scalars['Boolean']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  rawCustomFields?: InputMaybe<Array<RawDatafieldSpec>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  title?: InputMaybe<MultilingualStringInput>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ArticleTaxonomyFilter = {
  /** No taxonomy datafield definitions exist. As soon as the first taxonomy is defined, it's automatically listed here. */
  not_applicable_as_no_taxonomy_datafield_definitions_exist?: InputMaybe<Scalars['NoDynamicValueDefined']>;
};

/** Length limited text from the article for use e.g. in result lists, previews, etc. */
export type ArticleTeasers = {
  __typename?: 'ArticleTeasers';
  /** The article's title limited to `length` characters */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the article for use e.g. in result lists, previews, etc. */
export type ArticleTeasersTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Asset = {
  __typename?: 'Asset';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  assetId?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  files?: Maybe<Array<File>>;
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  localizations?: Maybe<AssetLocalizations>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  previewImage?: Maybe<BlobRef>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  productId?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  productionDate?: Maybe<Scalars['DateTime']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  referenceId?: Maybe<Scalars['String']>;
  /**
   * Related activity to the asset id. The given activity filter is optional and is combined (AND clause) with
   * a static filter:
   * { equals: { custom_assets: [asset.id] } }
   * This returns only activities related to the specific asset.
   */
  relatedActivities: ActivityQueryResult;
  /**
   * WARNING: Does not work for now because the required filter could not be defined 100%
   *
   * Related content to the product id. The given content filter is optional and is combined (AND clause) with
   * a static filter:
   * const assetFilter = { assetIds: [asset.id] };
   * This returns only contents related to the specific product.
   * The product id is used because an asset is always related to a product.
   */
  relatedContents: ContentQueryResult;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  serialNumber?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  teasers?: Maybe<AssetTeasers>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  title?: Maybe<MultilingualString>;
};


/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetFilesArgs = {
  fileTypes?: InputMaybe<Array<InputMaybe<FileType>>>;
};


/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetRelatedActivitiesArgs = {
  filter?: InputMaybe<ActivityFilter>;
};


/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetRelatedContentsArgs = {
  filter?: InputMaybe<ContentFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetAccessFilter = {
  /** Allows access to all asset maps if set to true */
  all?: InputMaybe<Scalars['Boolean']>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<AssetAccessFilter>>>;
  /** Restricts access to asset maps that contain all of the exact values specified */
  equals?: InputMaybe<AssetEqualsValueFilter>;
  /** Restricts access to assets that have a value in all of the specified fields */
  exists?: InputMaybe<Array<InputMaybe<AssetExistsValueFields>>>;
  /** Restricts access to asset maps with the specified asset identifiers */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<AssetAccessFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<AssetAccessFilter>>>;
  /** Restricts access to assets with values within all of the ranges specified */
  range?: InputMaybe<AssetRangeFilter>;
  /** Restricts access to asset maps that match all of the regular expressions */
  regex?: InputMaybe<AssetRegexFilter>;
  /** Restricts access to assets that match a taxonomy value according to the taxonomy's evaluation type */
  taxonomy?: InputMaybe<AssetTaxonomyFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetAutocompleteHits = {
  __typename?: 'AssetAutocompleteHits';
  /** The count of docs found containing the suggestion */
  docCount: Scalars['NonNegativeInt'];
  /** The language this suggestion stems from */
  language?: Maybe<Language>;
  /** The found suggestion */
  suggest: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetAutocompleteResult = {
  __typename?: 'AssetAutocompleteResult';
  /** The hits found for the query */
  hits?: Maybe<Array<AssetAutocompleteHits>>;
  /** The query used for this result */
  query: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetDatafieldAggregations = {
  __typename?: 'AssetDatafieldAggregations';
  /** Provides composite aggregations with pagination support */
  composite?: Maybe<AssetDatafieldCompositeAggregation>;
  /**
   * Provides terms aggregations for custom datafields.
   * Supports prefix search and the results are ordered by count.
   */
  terms?: Maybe<DatafieldTermsAggregation>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetDatafieldCompositeAggregation = {
  __typename?: 'AssetDatafieldCompositeAggregation';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  assetId?: Maybe<Array<AggregationEdge>>;
  /** No field definitions exist. As soon as the first field definition is defined, it's automatically listed here. */
  not_applicable_as_no_field_definitions_exist?: Maybe<Scalars['NoDynamicValueDefined']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetDatafieldCompositeAggregationAssetIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetEdge = {
  __typename?: 'AssetEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** The result's asset type. */
  node: Asset;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetEqualsValueFilter = {
  /** Returns only asset types with the specified assetId */
  assetId?: InputMaybe<Scalars['String']>;
  /** Returns only asset types with the specified productId */
  productId?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified production date */
  productionDate?: InputMaybe<Scalars['DateTime']>;
  /** Returns only asset types with the specified serial number */
  serialNumber?: InputMaybe<Scalars['String']>;
  /**
   * Returns only assets with the specified (product) title
   * @deprecated Use two title_xx fields for InfoTwin language and tenant default language instead
   */
  title?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified ar title */
  title_ar?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified bg title */
  title_bg?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified bn title */
  title_bn?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified bs title */
  title_bs?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified ca title */
  title_ca?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified cs title */
  title_cs?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified da title */
  title_da?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified de title */
  title_de?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified el title */
  title_el?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified en title */
  title_en?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified es title */
  title_es?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified et title */
  title_et?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified eu title */
  title_eu?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified fa title */
  title_fa?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified fi title */
  title_fi?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified fr title */
  title_fr?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified ga title */
  title_ga?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified he title */
  title_he?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified hi title */
  title_hi?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified hr title */
  title_hr?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified hu title */
  title_hu?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified hy title */
  title_hy?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified id title */
  title_id?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified is title */
  title_is?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified it title */
  title_it?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified ja title */
  title_ja?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified ko title */
  title_ko?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified ku title */
  title_ku?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified lt title */
  title_lt?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified lv title */
  title_lv?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified ms title */
  title_ms?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified mt title */
  title_mt?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified nb title */
  title_nb?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified nl title */
  title_nl?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified no title */
  title_no?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified pb title */
  title_pb?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified pl title */
  title_pl?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified pt title */
  title_pt?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified ro title */
  title_ro?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified ru title */
  title_ru?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified sk title */
  title_sk?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified sl title */
  title_sl?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified sq title */
  title_sq?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified sr title */
  title_sr?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified sv title */
  title_sv?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified th title */
  title_th?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified tr title */
  title_tr?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified uk title */
  title_uk?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified vi title */
  title_vi?: InputMaybe<Scalars['String']>;
  /** Returns only assets with the specified zh title */
  title_zh?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum AssetExistsValueFields {
  /** Returns only asset types which have a asset Id specified */
  assetId = 'assetId',
  /** Returns only asset types which have a productId specified */
  productId = 'productId',
  /** Returns only assets which have a production date specified */
  productionDate = 'productionDate',
  /** Returns only asset types which have a serial number specified */
  serialNumber = 'serialNumber',
  /** Returns only assets which have a title specified */
  title = 'title'
}

/**
 * The `AssetFilter` is used to specify filter criteria for asset types.
 * If you specify more than one criterion, all criteria are grouped together and connected with a logical AND operator.
 * Further logical operations are possible by using `not` `andGroup` and `orGroup`.
 */
export type AssetFilter = {
  /** Returns all asset types if set to true */
  all?: InputMaybe<Scalars['Boolean']>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns asset types that contain all of the exact values specified */
  equals?: InputMaybe<AssetEqualsValueFilter>;
  /**
   * Returns assets that have a value in all of the specified fields
   * @deprecated Please use 'has' filter instead
   */
  exists?: InputMaybe<Array<InputMaybe<AssetExistsValueFields>>>;
  /** Returns asset types that match all of the specified full text searches */
  fullText?: InputMaybe<AssetFullTextFilter>;
  /** Returns assets that have a value in all of the specified fields */
  has?: InputMaybe<AssetHasValueFilter>;
  /** Returns asset types with one of the specified unique identifiers */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<AssetFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  /** Returns asset types with values within all of the ranges specified */
  range?: InputMaybe<AssetRangeFilter>;
  /** Returns asset types that match all of the regular expressions */
  regex?: InputMaybe<AssetRegexFilter>;
  /** Returns asset types that match a taxonomy value according to the taxonomy's evaluation type */
  taxonomy?: InputMaybe<AssetTaxonomyFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: InputMaybe<Scalars['Float']>;
  /** Match the query against the asset id and rank the results with the specified boost */
  assetId?: InputMaybe<Scalars['Float']>;
  /** Match the query against the product id and rank the results with the specified boost */
  productId?: InputMaybe<Scalars['Float']>;
  /**
   * Match the query against the title and rank the results with the specified boost
   * @deprecated Use two title_xx fields for InfoTwin language and tenant default language instead
   */
  title?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ar?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_bg?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_bn?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_bs?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ca?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_cs?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_da?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_de?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_el?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_en?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_es?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_et?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_eu?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_fa?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_fi?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_fr?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ga?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_he?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_hi?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_hr?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_hu?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_hy?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_id?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_is?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_it?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ja?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ko?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ku?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_lt?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_lv?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ms?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_mt?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_nb?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_nl?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_no?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_pb?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_pl?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_pt?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ro?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ru?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sk?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sl?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sq?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sr?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sv?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_th?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_tr?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_uk?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_vi?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_zh?: InputMaybe<Scalars['Float']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetFullTextFilter = {
  /** Fields to search in with their respective score weights */
  fields?: InputMaybe<AssetFullTextFields>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: InputMaybe<Scalars['Boolean']>;
  /** Specifies if either all or any query words need to match */
  operator?: InputMaybe<FullTextOperator>;
  /**
   * Text to search for
   * Be aware: The Limit for full text search queries is 1024 characters and
   * anything above that will result in an error.
   */
  query: Scalars['FullTextSearchQuery'];
  /** Type of fulltext search to perform */
  searchType?: InputMaybe<FullTextSearchType>;
};

/** Returns assets that have a value in all of the specified fields */
export type AssetHasValueFilter = {
  /** Specify the fields that the returned assets must have a value in */
  valueInFields?: InputMaybe<Array<InputMaybe<AssetHasValueInFields>>>;
};

/** Specify the fields that the returned assets must have a value in */
export enum AssetHasValueInFields {
  /** Returns only asset types which have a asset Id specified */
  assetId = 'assetId',
  /** Returns only asset types which have a productId specified */
  productId = 'productId',
  /** Returns only assets which have a production date specified */
  productionDate = 'productionDate',
  /** Returns only asset types which have a serial number specified */
  serialNumber = 'serialNumber',
  /** Returns only assets which have a title specified */
  title = 'title'
}

/** Text from the asset for use e.g. in details. */
export type AssetLocalizations = {
  __typename?: 'AssetLocalizations';
  /** The asset's title */
  title?: Maybe<Scalars['String']>;
};


/** Text from the asset for use e.g. in details. */
export type AssetLocalizationsTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum AssetNames {
  assets = 'ASSETS',
  devices = 'DEVICES',
  equipments = 'EQUIPMENTS',
  machines = 'MACHINES',
  plants = 'PLANTS',
  products = 'PRODUCTS',
  vehicles = 'VEHICLES',
  versions = 'VERSIONS'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum AssetNamesSpec {
  assets = 'ASSETS',
  devices = 'DEVICES',
  equipments = 'EQUIPMENTS',
  machines = 'MACHINES',
  plants = 'PLANTS',
  products = 'PRODUCTS',
  vehicles = 'VEHICLES',
  versions = 'VERSIONS'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetQueryResult = {
  __typename?: 'AssetQueryResult';
  /**
   * Matched datafield values and number of matching assets per value
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   */
  aggregations: AssetDatafieldAggregations;
  /**
   * The query's matching asset types.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  assets: Array<Maybe<AssetEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetQueryResultAssetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<AssetSort>>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetRangeFilter = {
  /** Returns only asset types with a production date value in the specified range */
  productionDate?: InputMaybe<DateRange>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  serialNumberRange?: InputMaybe<LongInclusiveRangeSpec>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetRegexFilter = {
  /** Returns only asset types whose asset Id matches the specified Regex */
  assetId?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only asset types whose productId matches the specified Regex */
  productId?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only asset types whose serial number matches the specified Regex */
  serialNumber?: InputMaybe<Scalars['FilterRegex']>;
  /**
   * Returns only assets whose title matches the specified Regex
   * @deprecated Use two title_xx fields for InfoTwin language and tenant default language instead
   */
  title?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose ar title matches the specified Regex */
  title_ar?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose bg title matches the specified Regex */
  title_bg?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose bn title matches the specified Regex */
  title_bn?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose bs title matches the specified Regex */
  title_bs?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose ca title matches the specified Regex */
  title_ca?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose cs title matches the specified Regex */
  title_cs?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose da title matches the specified Regex */
  title_da?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose de title matches the specified Regex */
  title_de?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose el title matches the specified Regex */
  title_el?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose en title matches the specified Regex */
  title_en?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose es title matches the specified Regex */
  title_es?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose et title matches the specified Regex */
  title_et?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose eu title matches the specified Regex */
  title_eu?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose fa title matches the specified Regex */
  title_fa?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose fi title matches the specified Regex */
  title_fi?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose fr title matches the specified Regex */
  title_fr?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose ga title matches the specified Regex */
  title_ga?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose he title matches the specified Regex */
  title_he?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose hi title matches the specified Regex */
  title_hi?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose hr title matches the specified Regex */
  title_hr?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose hu title matches the specified Regex */
  title_hu?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose hy title matches the specified Regex */
  title_hy?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose id title matches the specified Regex */
  title_id?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose is title matches the specified Regex */
  title_is?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose it title matches the specified Regex */
  title_it?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose ja title matches the specified Regex */
  title_ja?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose ko title matches the specified Regex */
  title_ko?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose ku title matches the specified Regex */
  title_ku?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose lt title matches the specified Regex */
  title_lt?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose lv title matches the specified Regex */
  title_lv?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose ms title matches the specified Regex */
  title_ms?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose mt title matches the specified Regex */
  title_mt?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose nb title matches the specified Regex */
  title_nb?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose nl title matches the specified Regex */
  title_nl?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose no title matches the specified Regex */
  title_no?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose pb title matches the specified Regex */
  title_pb?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose pl title matches the specified Regex */
  title_pl?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose pt title matches the specified Regex */
  title_pt?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose ro title matches the specified Regex */
  title_ro?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose ru title matches the specified Regex */
  title_ru?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose sk title matches the specified Regex */
  title_sk?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose sl title matches the specified Regex */
  title_sl?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose sq title matches the specified Regex */
  title_sq?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose sr title matches the specified Regex */
  title_sr?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose sv title matches the specified Regex */
  title_sv?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose th title matches the specified Regex */
  title_th?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose tr title matches the specified Regex */
  title_tr?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose uk title matches the specified Regex */
  title_uk?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose vi title matches the specified Regex */
  title_vi?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only assets whose zh title matches the specified Regex */
  title_zh?: InputMaybe<Scalars['FilterRegex']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetSort = {
  /** Sort field */
  field: AssetSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum AssetSortFields {
  /** Sort by assetId */
  assetId = 'assetId',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by productId */
  productId = 'productId',
  /** Sort by production date */
  productionDate = 'productionDate',
  /** Sort by relevance score */
  score = 'score',
  /** Sort by serial number */
  serialNumber = 'serialNumber',
  /**
   * Sort by title
   * @deprecated Use two title_xx fields for InfoTwin language and tenant default language instead
   */
  title = 'title',
  /** Sort by language-ar title */
  titleAr = 'title_ar',
  /** Sort by language-bg title */
  titleBg = 'title_bg',
  /** Sort by language-bn title */
  titleBn = 'title_bn',
  /** Sort by language-bs title */
  titleBs = 'title_bs',
  /** Sort by language-ca title */
  titleCa = 'title_ca',
  /** Sort by language-cs title */
  titleCs = 'title_cs',
  /** Sort by language-da title */
  titleDa = 'title_da',
  /** Sort by language-de title */
  titleDe = 'title_de',
  /** Sort by language-el title */
  titleEl = 'title_el',
  /** Sort by language-en title */
  titleEn = 'title_en',
  /** Sort by language-es title */
  titleEs = 'title_es',
  /** Sort by language-et title */
  titleEt = 'title_et',
  /** Sort by language-eu title */
  titleEu = 'title_eu',
  /** Sort by language-fa title */
  titleFa = 'title_fa',
  /** Sort by language-fi title */
  titleFi = 'title_fi',
  /** Sort by language-fr title */
  titleFr = 'title_fr',
  /** Sort by language-ga title */
  titleGa = 'title_ga',
  /** Sort by language-he title */
  titleHe = 'title_he',
  /** Sort by language-hi title */
  titleHi = 'title_hi',
  /** Sort by language-hr title */
  titleHr = 'title_hr',
  /** Sort by language-hu title */
  titleHu = 'title_hu',
  /** Sort by language-hy title */
  titleHy = 'title_hy',
  /** Sort by language-id title */
  titleId = 'title_id',
  /** Sort by language-is title */
  titleIs = 'title_is',
  /** Sort by language-it title */
  titleIt = 'title_it',
  /** Sort by language-ja title */
  titleJa = 'title_ja',
  /** Sort by language-ko title */
  titleKo = 'title_ko',
  /** Sort by language-ku title */
  titleKu = 'title_ku',
  /** Sort by language-lt title */
  titleLt = 'title_lt',
  /** Sort by language-lv title */
  titleLv = 'title_lv',
  /** Sort by language-ms title */
  titleMs = 'title_ms',
  /** Sort by language-mt title */
  titleMt = 'title_mt',
  /** Sort by language-nb title */
  titleNb = 'title_nb',
  /** Sort by language-nl title */
  titleNl = 'title_nl',
  /** Sort by language-no title */
  titleNo = 'title_no',
  /** Sort by language-pb title */
  titlePb = 'title_pb',
  /** Sort by language-pl title */
  titlePl = 'title_pl',
  /** Sort by language-pt title */
  titlePt = 'title_pt',
  /** Sort by language-ro title */
  titleRo = 'title_ro',
  /** Sort by language-ru title */
  titleRu = 'title_ru',
  /** Sort by language-sk title */
  titleSk = 'title_sk',
  /** Sort by language-sl title */
  titleSl = 'title_sl',
  /** Sort by language-sq title */
  titleSq = 'title_sq',
  /** Sort by language-sr title */
  titleSr = 'title_sr',
  /** Sort by language-sv title */
  titleSv = 'title_sv',
  /** Sort by language-th title */
  titleTh = 'title_th',
  /** Sort by language-tr title */
  titleTr = 'title_tr',
  /** Sort by language-uk title */
  titleUk = 'title_uk',
  /** Sort by language-vi title */
  titleVi = 'title_vi',
  /** Sort by language-zh title */
  titleZh = 'title_zh'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetSpec = {
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  assetId?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  productId?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  productionDate?: InputMaybe<Scalars['DateTime']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  rawCustomFields?: InputMaybe<Array<RawDatafieldSpec>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AssetTaxonomyFilter = {
  /** No taxonomy datafield definitions exist. As soon as the first taxonomy is defined, it's automatically listed here. */
  not_applicable_as_no_taxonomy_datafield_definitions_exist?: InputMaybe<Scalars['NoDynamicValueDefined']>;
};

/** Length limited text from the asset for use e.g. in result lists, previews, etc. */
export type AssetTeasers = {
  __typename?: 'AssetTeasers';
  /** The asset's title limited to `length` characters */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the asset for use e.g. in result lists, previews, etc. */
export type AssetTeasersTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Authenticator = {
  __typename?: 'Authenticator';
  /** Indicates the default authenticator */
  default?: Maybe<Scalars['Boolean']>;
  /** The description for the authenticator */
  description?: Maybe<Scalars['String']>;
  /** Readable name for the authenticator */
  displayName: Scalars['String'];
  /** Image url for display purpose */
  imageUrl?: Maybe<Scalars['String']>;
  /** The sortIndex of the authenticator. Has influence on the order of the authenticators (descending) */
  sortIndex: Scalars['Int'];
  /** The status of the authenticator */
  status?: Maybe<TenantAuthenticatorStatus>;
  /** The strategy used for the authenticator */
  strategyName: Scalars['String'];
  /** The url of the authenticator */
  url: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AuthenticatorEdge = {
  __typename?: 'AuthenticatorEdge';
  /** The result's authenticator */
  node?: Maybe<Authenticator>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type AuthenticatorQueryResult = {
  __typename?: 'AuthenticatorQueryResult';
  /**
   * The authenticators of the tenant.
   *
   * Currently there's no functionality to filter them nor using pagination.
   *
   * But for later compatibility and the ability to add paging or filtering, they are
   * returned in the appropriate format.
   *
   * The field will always contain any authenticator results which exist on the tenant.
   *
   * This might change in the future.
   */
  authenticators?: Maybe<Array<Maybe<AuthenticatorEdge>>>;
  /** Returns the total count of results. */
  total: TotalCount;
};

/** Common interface for autocompleteedge */
export type AutocompleteEdge = {
  /** The full datafield definition */
  datafieldDefinition?: Maybe<DatafieldDefinition>;
  /**
   * Id of the datafield definition in which the value was found
   * This value can be used as field name content query filters
   */
  datafieldDefinitionId: Scalars['DatafieldDefinitionID'];
  /** Display text for this autocomplete hit */
  displayName: Scalars['String'];
  /**
   * Language which this hit is based on
   * Is only returned if an exact language assignment can be made
   */
  language?: Maybe<Language>;
  /**
   * Unique value of the datafield definition value
   * This value can be used as operand in content query filters
   */
  value: Scalars['String'];
};

/** Typed binary data of `Topic`s. E.g. images, HTML and media files, style sheets, etc. */
export type Blob = {
  __typename?: 'Blob';
  /** Type of compression used for the blob or empty if the blob is not compressed. */
  compressionType?: Maybe<CompressionType>;
  /** The blob's unique, content hashed ID. */
  id: Scalars['ID'];
  /** The blob's mime type. May be empty if no mime type coud be detected. */
  mimeType?: Maybe<Scalars['String']>;
  /**
   * The blob's protection class.
   * See `BlobProtectionClass` for further information.
   */
  protectionClass?: Maybe<BlobProtectionClass>;
  /** The blob's (compressed) size in bytes. */
  size?: Maybe<Scalars['Int']>;
  /** Download URL for the blob's binary data */
  url?: Maybe<Scalars['URL']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type BlobEdge = {
  __typename?: 'BlobEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** The result's blob object */
  node: Blob;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type BlobEqualsValueFilter = {
  /** Returns only blobs with the specified mime type */
  mimeType?: InputMaybe<Scalars['String']>;
  /** Returns only blobs with the specified protection class */
  protectionClass?: InputMaybe<BlobProtectionClass>;
  /** Returns only blobs with the specified file size */
  size?: InputMaybe<Scalars['Long']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type BlobFilter = {
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<BlobFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns blobs that contain all of the exact values specified */
  equals?: InputMaybe<BlobEqualsValueFilter>;
  /** Filter by ids */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<BlobFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<BlobFilter>>>;
  /** Returns blobs with values within all of the ranges specified */
  range?: InputMaybe<BlobRangeFilter>;
};

/** Blob protection classes which control who can access the specified blob */
export enum BlobProtectionClass {
  /** Can be accessed by every authenticated user. */
  authenticated = 'authenticated',
  /** Can only be accessed by users who have access to at least one content which uses this blob. */
  contentAuthorized = 'contentAuthorized',
  /** Can be accessed by everyone (including unauthenticated users). */
  public = 'public'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type BlobQueryResult = {
  __typename?: 'BlobQueryResult';
  /**
   * The query's matching blobs.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  blobs: Array<Maybe<BlobEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type BlobQueryResultBlobsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<BlobSort>>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type BlobQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type BlobRangeFilter = {
  /** Returns only blob whose size is in the specified range */
  size?: InputMaybe<LongRange>;
};

/** Reference to a binary blob */
export type BlobRef = {
  __typename?: 'BlobRef';
  /** The referenced `Blob` */
  blob?: Maybe<Blob>;
  /** The referenced blob's unique, content hashed ID. */
  blobId: Scalars['ID'];
  /** The referenced blob's mime type. May be empty if no mime type coud be detected. */
  mimeType?: Maybe<Scalars['String']>;
  /** The path and file name under which the blob is referenced in this context. */
  path?: Maybe<Scalars['String']>;
  /** Download URL for the blob's binary data */
  url?: Maybe<Scalars['URL']>;
};

/** Spec for a reference to a binary blob */
export type BlobRefInput = {
  /** The referenced blob's unique, content hashed ID. */
  blobId: Scalars['ID'];
  /** The referenced blob's mime type. */
  mimeType?: InputMaybe<Scalars['String']>;
  /** The path and file name under which the blob is referenced in this context. */
  path: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type BlobSort = {
  /** Sort field */
  field: BlobSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum BlobSortFields {
  /** Sort by unique ID */
  id = 'id',
  /** Sort by mime type */
  mimeType = 'mimeType',
  /** Sort by protection class */
  protectionClass = 'protectionClass',
  /** Sort by relevance score */
  score = 'score',
  /** Sort by file size */
  size = 'size'
}

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Collection = {
  __typename?: 'Collection';
  /** The access kind to this collection */
  access?: Maybe<CollectionAccessTypes>;
  /**
   * The collection's contents
   * @deprecated Use `entries` instead to query any kind of collection entry
   */
  contents: ContentQueryResult;
  /** The detailed description */
  description?: Maybe<Scalars['String']>;
  /** The display name */
  displayName?: Maybe<Scalars['String']>;
  /** The collection's entries */
  entries: CollectionEntryQueryResult;
  /** The unique id */
  id: Scalars['ID'];
  /** The indicator whether the collection should be shown on the dashboard */
  showInDashboard: Scalars['Boolean'];
  /** The user who owns the collection */
  user?: Maybe<User>;
  /** The visibility of the collection */
  visibility?: Maybe<CollectionVisibilityTypes>;
};


/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionContentsArgs = {
  filter?: InputMaybe<ContentFilter>;
};


/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionEntriesArgs = {
  filter?: InputMaybe<CollectionEntryFilter>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum CollectionAccessTypes {
  /** The collection is a personal collection owned by the requesting user */
  personal = 'personal',
  /** The collection is public */
  public = 'public',
  /** The collection is a personal collection of another user */
  shared = 'shared'
}

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionEdge = {
  __typename?: 'CollectionEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** The result's collection object. */
  node?: Maybe<Collection>;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionEntryCommonNavigationContext = CollectionEntryNavigationContext & {
  __typename?: 'CollectionEntryCommonNavigationContext';
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  asset?: Maybe<Asset>;
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  product?: Maybe<Product>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionEntryEdge = {
  __typename?: 'CollectionEntryEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** The result's collection entry object. */
  node: CollectionEntryNode;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionEntryEqualsValueFilter = {
  /** Returns only collection entries with the specified object type */
  type?: InputMaybe<CollectionEntryType>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionEntryFilter = {
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<CollectionEntryFilter>>>;
  /** Returns collection entries that contain all of the exact values specified */
  equals?: InputMaybe<CollectionEntryEqualsValueFilter>;
  /** Filter by ids */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<CollectionEntryFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<CollectionEntryFilter>>>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionEntryInput = {
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  navContext?: InputMaybe<CollectionEntryNavigationContextInput>;
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  type: CollectionEntryType;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionEntryMechanicNavigationContext = CollectionEntryNavigationContext & {
  __typename?: 'CollectionEntryMechanicNavigationContext';
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  asset?: Maybe<Asset>;
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  path?: Maybe<Array<Scalars['String']>>;
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  product?: Maybe<Product>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionEntryMechanicNavigationContextInput = {
  /**
   * Asset context for the collection entry. Value is the assets assetId, not its id.
   * Please note that productId is always required when specifying the assetId
   */
  assetId?: InputMaybe<Scalars['String']>;
  /** Path of assembly ids to the mechanic entry */
  path?: InputMaybe<Array<Scalars['String']>>;
  /** Product context for the collection entry */
  productId?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionEntryNavigationContext = {
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  asset?: Maybe<Asset>;
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  product?: Maybe<Product>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionEntryNavigationContextInput = {
  /**
   * Asset context for the collection entry. Value is the assets assetId, not its id.
   * Please note that productId is always required when specifying the assetId
   */
  assetId?: InputMaybe<Scalars['String']>;
  /** Product context for the collection entry */
  productId?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionEntryNode = {
  /** The result's collection object context. */
  navContext?: Maybe<CollectionEntryNavigationContext>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionEntryQueryResult = {
  __typename?: 'CollectionEntryQueryResult';
  /**
   * The query's matching collections.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  entries: Array<Maybe<CollectionEntryEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionEntryQueryResultEntriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<CollectionEntrySort>>>;
};


/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionEntryQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionEntrySort = {
  /** Sort field */
  field: CollectionEntrySortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum CollectionEntrySortFields {
  /** Sort by sequence number */
  itemNumber = 'itemNumber'
}

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum CollectionEntryType {
  activity = 'activity',
  article = 'article',
  content = 'content',
  mechanic = 'mechanic'
}

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionEqualsValueFilter = {
  /** Returns only collections with the specified display name */
  displayName?: InputMaybe<Scalars['String']>;
  /** Returns only collections with the specified show in dashboard option */
  showInDashboard?: InputMaybe<Scalars['Boolean']>;
  /** Returns only collections with the specified visibility */
  visibility?: InputMaybe<CollectionVisibilityTypes>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionExportResult = {
  __typename?: 'CollectionExportResult';
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  status: CollectionExportStatus;
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  url?: Maybe<Scalars['String']>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum CollectionExportStatus {
  active = 'active',
  failed = 'failed',
  finished = 'finished',
  new = 'new',
  notfound = 'notfound'
}

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum CollectionExportType {
  csv = 'csv',
  excel = 'excel'
}

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionFilter = {
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<CollectionFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns collections that contain all of the exact values specified */
  equals?: InputMaybe<CollectionEqualsValueFilter>;
  /** Full text search in collections */
  fullText?: InputMaybe<CollectionFullTextFilter>;
  /** Filter by ids */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<CollectionFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<CollectionFilter>>>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: InputMaybe<Scalars['Float']>;
  /** Match the query against the display name and rank the results with the specified boost */
  displayName?: InputMaybe<Scalars['Float']>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionFullTextFilter = {
  /** Fields to search in with their respective score weights */
  fields?: InputMaybe<CollectionFullTextFields>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: InputMaybe<Scalars['Boolean']>;
  /** Specifies if either all or any query words need to match */
  operator?: InputMaybe<FullTextOperator>;
  /** Text to search for */
  query: Scalars['String'];
  /** Type of fulltext search to perform */
  searchType?: InputMaybe<FullTextSearchType>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionQueryResult = {
  __typename?: 'CollectionQueryResult';
  /**
   * The query's matching collections.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  collections: Array<Maybe<CollectionEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionQueryResultCollectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<CollectionSort>>>;
};


/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CollectionSort = {
  /** Sort field */
  field: CollectionSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum CollectionSortFields {
  /** Sort by display name */
  displayName = 'displayName',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by relevance score */
  score = 'score',
  /** Sort by visibility */
  visibility = 'visibility'
}

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum CollectionVisibilityTypes {
  /**
   * Only listed for the collection's creator but accessible for any user via
   * the collection's id
   */
  personal = 'personal',
  /** Listed for everyone */
  public = 'public'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CommonFile = File & {
  __typename?: 'CommonFile';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  file: BlobRef;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  objectId: Scalars['String'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  objectSubType?: Maybe<FileSubType>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  objectType?: Maybe<FileType>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CommonProcessDatafieldMapping = ProcessDatafieldMapping & {
  __typename?: 'CommonProcessDatafieldMapping';
  /** Returns column(s) which also map to the same field. In this case a process cannot be created due to ambigiuousness. */
  ambiguousColumns?: Maybe<Array<Scalars['String']>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  column: Scalars['String'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  field?: Maybe<Datafield>;
};

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ComponentInfo = {
  __typename?: 'ComponentInfo';
  /** Information about the component's sub components */
  components?: Maybe<Array<ComponentInfo>>;
  /** The component's name */
  name: Scalars['String'];
  /** The component's version */
  version: VersionInfo;
};

/** Type of content compression */
export enum CompressionType {
  gzip = 'gzip'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Config = {
  __typename?: 'Config';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  config3dHoops?: Maybe<Config3dHoops>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  config3dLattice?: Maybe<Config3dLattice>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  configFdiShop?: Maybe<ConfigFdiShop>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  configProductAndAsset?: Maybe<ConfigProductAndAsset>;
  /** Ignore this field */
  dummy?: Maybe<Scalars['Boolean']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Config3dHoops = {
  __typename?: 'Config3dHoops';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  articleRef?: Maybe<Array<Maybe<ArticleHoopsRef>>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  camera?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  fps?: Maybe<Scalars['Int']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  hover?: Maybe<Scalars['Boolean']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  outlineColor?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  selectionColor?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  selectionMode?: Maybe<SelectionMode>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  viewport?: Maybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Config3dHoopsSpec = {
  /**
   * Configure which node properties in your 3D models should be assigned to which articles and assemblies in the application.
   * If not configured, the article information is read from the node name
   */
  articleRef?: InputMaybe<Array<InputMaybe<ArticleHoopsRefSpec>>>;
  /** Define the camera for the initial view in the XVL-player */
  camera?: InputMaybe<Scalars['String']>;
  /** Define the fps-parameter of the XVL-player */
  fps?: InputMaybe<Scalars['Int']>;
  /** Define if hover is activated. A tooltip shows when hovering over a part */
  hover?: InputMaybe<Scalars['Boolean']>;
  /** Define in which color selected articles and assemblies should be outlined in the 3D model. */
  outlineColor?: InputMaybe<Scalars['String']>;
  /** Define in which color selected articles and assemblies should be colored in the 3D model. */
  selectionColor?: InputMaybe<Scalars['String']>;
  /** Define the selection mode. Deep selects parts in sub assemblies */
  selectionMode?: InputMaybe<SelectionModeSpec>;
  /** Define the viewport for the initial view in the XVL-player */
  viewport?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Config3dLattice = {
  __typename?: 'Config3dLattice';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  articleRef?: Maybe<Array<Maybe<ArticleLatticeRef>>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  camera?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  fps?: Maybe<Scalars['Int']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  hover?: Maybe<Scalars['Boolean']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  selectionColor?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  selectionMode?: Maybe<SelectionMode>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  viewport?: Maybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Config3dLatticeSpec = {
  /** Configure which selections in your 3D models should be assigned to which articles and assemblies in the application. */
  articleRef?: InputMaybe<Array<ArticleLatticeRefSpec>>;
  /** Define the camera for the initial view in the XVL-player */
  camera?: InputMaybe<Scalars['String']>;
  /** Define the fps-parameter of the XVL-player */
  fps?: InputMaybe<Scalars['Int']>;
  /** Define if hover is activated. A tooltip shows when hovering over a part */
  hover?: InputMaybe<Scalars['Boolean']>;
  /** Define in which color selected articles and assemblies should be colored in the 3D model. */
  selectionColor?: InputMaybe<Scalars['String']>;
  /** Define the selection mode. Deep selects parts in sub assemblies */
  selectionMode?: InputMaybe<SelectionModeSpec>;
  /** Define the viewport for the initial view in the XVL-player */
  viewport?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum Config3dType {
  hoops = 'HOOPS',
  lattice = 'LATTICE'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ConfigFdiShop = {
  __typename?: 'ConfigFdiShop';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  baseUrl?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ConfigFdiShopSpec = {
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  baseUrl?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ConfigProductAndAsset = {
  __typename?: 'ConfigProductAndAsset';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  assetName: AssetNames;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  deactivateAssets?: Maybe<Scalars['Boolean']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  productName: ProductNames;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ConfigProductAndAssetSpec = {
  /** Configure the name of the assets in the application. */
  assetName: AssetNamesSpec;
  /** Configure the deactivate for assets. */
  deactivateAssets?: InputMaybe<Scalars['Boolean']>;
  /** Configure the name of the products in the application. */
  productName: ProductNamesSpec;
};

/** Common interface for contents as e.g. `ContentMap` or `Topic` */
export type Content = {
  /** The related article ids */
  articleIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The related asset ids */
  assetIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Unique identifier of the content itself */
  contentId: Scalars['ID'];
  /** Date and time when the content was created */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Language of the content */
  language?: Maybe<Language>;
  /** Locale of the content */
  locale?: Maybe<Scalars['String']>;
  /** Matching access filters for the content */
  matchingAccessFilters?: Maybe<AccessFilterQueryResult>;
  /** Date and time of the last modification */
  modificationDate?: Maybe<Scalars['DateTime']>;
  /**
   * Relevant notes for the content.
   *
   * This field only shows private, pendingApproval notes created by the requesting user and any public notes
   * that reference the content.
   *
   * Even with administrative privileges this field only shows the above mentioned notes and therefore doesn't include any notes
   * which don't belong to the requesting user except the public ones. To view any note and filter them, refer to the
   * filter query for the notes.
   */
  notes?: Maybe<NoteQueryResult>;
  /** All users allowed to query the content */
  permittedUsers?: Maybe<UserQueryResult>;
  /** The source this content was generated from */
  source?: Maybe<Scalars['String']>;
  /** The unique id of the source */
  sourceId?: Maybe<Scalars['String']>;
  /** Length limited text from the content for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<ContentTeasers>;
  /** Title of the content */
  title?: Maybe<MultilingualString>;
  /** Most recent date and time when the content was uploaded */
  uploadDate?: Maybe<Scalars['DateTime']>;
  /** The use context in which this content is used */
  useContext?: Maybe<UseContext>;
  /** Technical version of the content */
  version?: Maybe<Scalars['Long']>;
};


/** Common interface for contents as e.g. `ContentMap` or `Topic` */
export type ContentMatchingAccessFiltersArgs = {
  filter?: InputMaybe<AccessFilterFilter>;
};


/** Common interface for contents as e.g. `ContentMap` or `Topic` */
export type ContentNotesArgs = {
  filter?: InputMaybe<NoteFilter>;
};


/** Common interface for contents as e.g. `ContentMap` or `Topic` */
export type ContentPermittedUsersArgs = {
  filter?: InputMaybe<UserFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentAccessFilter = {
  /** Allows access to all content maps if set to true */
  all?: InputMaybe<Scalars['Boolean']>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<ContentAccessFilter>>>;
  /** Restricts access to content maps with the specified content identifiers */
  contentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Restricts access to content maps that contain all of the exact values specified */
  equals?: InputMaybe<ContentEqualsValueFilter>;
  /** Restricts access to content maps that contain all of the exact values specified */
  equalsOneOf?: InputMaybe<ContentEqualsOneOfValueFilter>;
  /** Restricts access to contents that have a value in all of the specified fields */
  exists?: InputMaybe<Array<InputMaybe<ContentExistsValueFields>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<ContentAccessFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<ContentAccessFilter>>>;
  /** Restricts access to contents with values within all of the ranges specified */
  range?: InputMaybe<ContentRangeFilter>;
  /** Restricts access to content maps that match all of the regular expressions */
  regex?: InputMaybe<ContentRegexFilter>;
  /** Restricts access to contents that match a taxonomy value according to the taxonomy's evaluation type */
  taxonomy?: InputMaybe<ContentTaxonomyFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentAutocompleteHits = {
  __typename?: 'ContentAutocompleteHits';
  /** The count of docs found containing the suggestion */
  docCount: Scalars['NonNegativeInt'];
  /** The found suggestion */
  suggest: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentAutocompleteResult = {
  __typename?: 'ContentAutocompleteResult';
  /** The hits found for the query */
  hits?: Maybe<Array<ContentAutocompleteHits>>;
  /** The query used for this result */
  query: Scalars['String'];
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentCollectionEntryNode = CollectionEntryNode & {
  __typename?: 'ContentCollectionEntryNode';
  /** The content (content-map or topic) object */
  content: Content;
  /** The result's collection object context. */
  navContext?: Maybe<CollectionEntryCommonNavigationContext>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentEdge = {
  __typename?: 'ContentEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** Highlighted matches for equality and full text filters. Matched terms are wrapped in <em>...</em> Tags. */
  highlights?: Maybe<ContentHighlights>;
  /** The result's content object. */
  node?: Maybe<Content>;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentEqualsOneOfValueFilter = {
  /** No regex filterable indexed field definitions exist. As soon as the first matching indexed field definition is defined, it's automatically listed here. */
  not_applicable_as_no_array_field_definitions_exist?: InputMaybe<Scalars['NoDynamicValueDefined']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentEqualsValueFilter = {
  /** Returns only contents with the specified creation date */
  creationDate?: InputMaybe<Scalars['DateTime']>;
  /** Returns only contents with the specified language */
  language?: InputMaybe<Language>;
  /** Returns only contents with the specified locale */
  locale?: InputMaybe<Scalars['String']>;
  /** Returns only topics with the specified mime type */
  mimeType?: InputMaybe<Scalars['String']>;
  /** Returns only contents with the specified modification date */
  modificationDate?: InputMaybe<Scalars['DateTime']>;
  /** Returns only contents used by the specified root content id */
  rootContentId?: InputMaybe<Scalars['ID']>;
  /** Returns only contents with the specified source */
  source?: InputMaybe<Scalars['String']>;
  /** Returns only contents with the specified source id */
  sourceId?: InputMaybe<Scalars['String']>;
  /** Returns only contents of the specified type */
  type?: InputMaybe<ContentType>;
  /** Returns only contents with the specified upload date */
  uploadDate?: InputMaybe<Scalars['DateTime']>;
  /** Returns only contents with the specified version */
  version?: InputMaybe<Scalars['Long']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum ContentExistsValueFields {
  /** Returns only contents which have one or more article ids specified */
  articleIds = 'articleIds',
  /** Returns only contents which have one or more asset ids specified */
  assetIds = 'assetIds',
  /** Returns only contents which have a locale specified */
  locale = 'locale',
  /** Return only contents which have a version specified */
  version = 'version'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentFilter = {
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<ContentFilter>>>;
  /** Returns only contents with one of the specified article ids */
  articleIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Returns only contents with one of the specified asset ids */
  assetIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns contents with one of the specified content identifiers */
  contentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Returns contents that contain all of the exact values specified */
  equals?: InputMaybe<ContentEqualsValueFilter>;
  /** Returns contents that contain all of the exact values specified */
  equalsOneOf?: InputMaybe<ContentEqualsOneOfValueFilter>;
  /** Returns contents that have a value in all of the specified fields */
  exists?: InputMaybe<Array<InputMaybe<ContentExistsValueFields>>>;
  /** Returns contents that match all of the specified full text searches */
  fullText?: InputMaybe<ContentFullTextFilter>;
  /** Returns contents with one of the specified unique identifiers */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<ContentFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<ContentFilter>>>;
  /** Returns contents with values within all of the ranges specified */
  range?: InputMaybe<ContentRangeFilter>;
  /** Returns contents that match all of the regular expressions */
  regex?: InputMaybe<ContentRegexFilter>;
  /** Returns contents that match a taxonomy value according to the taxonomy's evaluation type */
  taxonomy?: InputMaybe<ContentTaxonomyFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: InputMaybe<Scalars['Float']>;
  /** Match the query against any text language and rank the results with the specified boost */
  text?: InputMaybe<Scalars['Float']>;
  /** Match the query against any title language and rank the results with the specified boost */
  title?: InputMaybe<Scalars['Float']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentFullTextFilter = {
  /** Fields to search in with their respective score weights */
  fields?: InputMaybe<ContentFullTextFields>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: InputMaybe<Scalars['Boolean']>;
  /** Specifies if either all or any query words need to match */
  operator?: InputMaybe<FullTextOperator>;
  /**
   * The text to search for.
   * Be aware: The Limit for full text search queries is 1024 characters and
   * anything above that will result in an error.
   */
  query: Scalars['FullTextSearchQuery'];
  /** Type of fulltext search to perform */
  searchType?: InputMaybe<FullTextSearchType>;
  /** Options to configure the synonym search */
  synonymOptions?: InputMaybe<SynonymOptions>;
};

/** Highlighted matches for equality and full text filters. Matched terms are wrapped in <em>...</em> Tags. */
export type ContentHighlights = {
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  text?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Highlighted matches for equality and full text filters. Matched terms are wrapped in <em>...</em> Tags. */
export type ContentHighlightsTextArgs = {
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Highlighted matches for equality and full text filters. Matched terms are wrapped in <em>...</em> Tags. */
export type ContentHighlightsTitleArgs = {
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** A content map aggregates other content maps or topics into a table of content maps structure. */
export type ContentMap = Content & {
  __typename?: 'ContentMap';
  /** The related article ids */
  articleIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The related asset ids */
  assetIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Unique identifier of the content map itself */
  contentId: Scalars['ID'];
  /** Date and time when the content map was created */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** Document identifier */
  documentId?: Maybe<Scalars['ID']>;
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Language of the content map */
  language?: Maybe<Language>;
  /** Locale of the content map */
  locale?: Maybe<Scalars['String']>;
  /** Matching access filters for the content */
  matchingAccessFilters?: Maybe<AccessFilterQueryResult>;
  /** Date and time of the last modification */
  modificationDate?: Maybe<Scalars['DateTime']>;
  /**
   * Relevant notes for the content map.
   *
   * This field only shows private, pendingApproval notes created by the requesting user and any public notes
   * that reference the content.
   *
   * Even with administrative privileges this field only shows the above mentioned notes and therefore doesn't include any notes
   * which don't belong to the requesting user except the public ones. To view any note and filter them, refer to the
   * filter query for the notes.
   */
  notes?: Maybe<NoteQueryResult>;
  /** All users allowed to query the content */
  permittedUsers?: Maybe<UserQueryResult>;
  /** Flag if the content map is published */
  published?: Maybe<Scalars['Boolean']>;
  /** The rendition */
  rendition?: Maybe<ContentMapRendition>;
  /** The source this content was generated from */
  source?: Maybe<Scalars['String']>;
  /** The unique id of the source */
  sourceId?: Maybe<Scalars['String']>;
  /** Length limited text from the content map for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<ContentMapTeasers>;
  /** Title of the content map */
  title?: Maybe<MultilingualString>;
  /**
   * The content map's table of contents.
   * To fetch specific paths of the toc, specify the `paths` argument.
   * The root path is represented by the '~' character.
   * To fetch children of a `TocEdge` use the edge's `childPath` in the `paths` argument.
   * For each specified path, the immediate child-entries of the specified path will be returned.
   * If no `paths` are provided, the root-entries will be returned.
   */
  toc?: Maybe<TocResult>;
  /**
   * The content map's table of contents
   * @deprecated Field no longer supported
   */
  tocMap?: Maybe<Array<Maybe<TocMapEntry>>>;
  /** The type of the node */
  type?: Maybe<Scalars['String']>;
  /** Most recent date and time when the content map was uploaded */
  uploadDate?: Maybe<Scalars['DateTime']>;
  /** The use context in which this content map is used */
  useContext?: Maybe<UseContext>;
  /** Technical version of the content map */
  version?: Maybe<Scalars['Long']>;
  /** Readable label for the version */
  versionLabel?: Maybe<Scalars['String']>;
};


/** A content map aggregates other content maps or topics into a table of content maps structure. */
export type ContentMapMatchingAccessFiltersArgs = {
  filter?: InputMaybe<AccessFilterFilter>;
};


/** A content map aggregates other content maps or topics into a table of content maps structure. */
export type ContentMapNotesArgs = {
  filter?: InputMaybe<NoteFilter>;
};


/** A content map aggregates other content maps or topics into a table of content maps structure. */
export type ContentMapPermittedUsersArgs = {
  filter?: InputMaybe<UserFilter>;
};


/** A content map aggregates other content maps or topics into a table of content maps structure. */
export type ContentMapTocArgs = {
  paths?: InputMaybe<Array<Scalars['String']>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentMapEdge = {
  __typename?: 'ContentMapEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** Highlighted matches for equality and full text filters. Matched terms are wrapped in <em>...</em> Tags. */
  highlights?: Maybe<ContentMapHighlights>;
  /** The result's content map. */
  node: ContentMap;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentMapEqualsValueFilter = {
  /** Returns only content maps with the specified creation date */
  creationDate?: InputMaybe<Scalars['DateTime']>;
  /** Returns only documents with the specified document id */
  documentId?: InputMaybe<Scalars['String']>;
  /** Return only content maps with the specified language */
  language?: InputMaybe<Language>;
  /** Returns only content maps with the specified locale */
  locale?: InputMaybe<Scalars['String']>;
  /** Returns only content maps with the specified modification date */
  modificationDate?: InputMaybe<Scalars['DateTime']>;
  /** Returns only content maps with the published flag set */
  published?: InputMaybe<Scalars['Boolean']>;
  /** Returns only contents with the specified rendition */
  rendition?: InputMaybe<ContentMapRendition>;
  /** Returns only content maps used by the specified root content id */
  rootContentId?: InputMaybe<Scalars['ID']>;
  /** Returns only contents with the specified source */
  source?: InputMaybe<Scalars['String']>;
  /** Returns only contents with the specified source id */
  sourceId?: InputMaybe<Scalars['String']>;
  /** Returns only content maps with the specified upload date */
  uploadDate?: InputMaybe<Scalars['DateTime']>;
  /** Returns only contents with the specified version */
  version?: InputMaybe<Scalars['Long']>;
  /** Returns only contents with the specified version label */
  versionLabel?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum ContentMapExistsValueFields {
  /** Returns only contents which have one or more article ids specified */
  articleIds = 'articleIds',
  /** Returns only contents which have one or more asset ids specified */
  assetIds = 'assetIds',
  /** Returns only content maps which have a locale specified */
  locale = 'locale',
  /** Returns only content maps which have a rendition specified */
  rendition = 'rendition',
  /** Returns only content maps which have a source specified */
  source = 'source',
  /** Returns only content maps which have a source id specified */
  sourceId = 'sourceId',
  /** Returns only content maps which have a version specified */
  version = 'version',
  /** Returns only content maps which have a version label specified */
  versionLabel = 'versionLabel'
}

/**
 * The `ContentMapFilter` is used to specify filter criteria for content maps.
 * If you specify more than one criterion, all criteria are grouped together and connected with a logical AND operator.
 * Further logical operations are possible by using `not` `andGroup` and `orGroup`.
 *
 * Important: Datafields in this filter is only accessible through the namespaced graphql id defined in the
 * definition of the corresponding datafield. For further information see `DatafieldDefinition`.
 */
export type ContentMapFilter = {
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<ContentMapFilter>>>;
  /** Boosts the relevance score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns content maps with one of the specified content identifiers */
  contentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Returns content maps that contain all of the exact values specified */
  equals?: InputMaybe<ContentMapEqualsValueFilter>;
  /** Returns content maps that have a value in all of the specified fields */
  exists?: InputMaybe<Array<InputMaybe<ContentMapExistsValueFields>>>;
  /** Returns content maps that match all of the specified full text searches */
  fullText?: InputMaybe<ContentMapFullTextFilter>;
  /** Returns content maps with one of the specified unique identifiers */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<ContentMapFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<ContentMapFilter>>>;
  /** Returns content maps with values within all of the ranges specified */
  range?: InputMaybe<ContentMapRangeFilter>;
  /** Returns content maps that match all of the regular expressions */
  regex?: InputMaybe<ContentMapRegexFilter>;
  /** Returns contents that match a taxonomy value according to the taxonomy's evaluation type */
  taxonomy?: InputMaybe<ContentMapTaxonomyFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentMapFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: InputMaybe<Scalars['Float']>;
  /** Match the query against any title language and rank the results with the specified boost */
  title?: InputMaybe<Scalars['Float']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentMapFullTextFilter = {
  /** Fields to search in with their respective score weights */
  fields?: InputMaybe<ContentMapFullTextFields>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: InputMaybe<Scalars['Boolean']>;
  /** Specifies if either all or any query words need to match */
  operator?: InputMaybe<FullTextOperator>;
  /**
   * The text to search for.
   * Be aware: The Limit for full text search queries is 1024 characters and
   * anything above that will result in an error.
   */
  query: Scalars['FullTextSearchQuery'];
  /** Type of fulltext search to perform */
  searchType?: InputMaybe<FullTextSearchType>;
  /** Options for synonyms */
  synonymOptions?: InputMaybe<SynonymOptions>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentMapHighlights = ContentHighlights & {
  __typename?: 'ContentMapHighlights';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  text?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentMapHighlightsTextArgs = {
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentMapHighlightsTitleArgs = {
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentMapQueryResult = {
  __typename?: 'ContentMapQueryResult';
  /**
   * Matched datafield values and number of matching content map uses per value
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   */
  aggregations: Aggregations;
  /**
   * The query's matching content maps.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  contentMaps: Array<Maybe<ContentMapEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentMapQueryResultContentMapsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<ContentMapSort>>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentMapQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentMapRangeFilter = {
  /** Returns only content maps with a creation date in the specified range */
  creationDate?: InputMaybe<DateRange>;
  /** Returns only content maps with a modification date in the specified range */
  modificationDate?: InputMaybe<DateRange>;
  /** Returns only content maps with a upload date in the specified range */
  uploadDate?: InputMaybe<DateRange>;
  /** Returns only contents with a version within the specified range */
  version?: InputMaybe<LongRange>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentMapRegexFilter = {
  /** Returns contents with one of the specified unique identifiers */
  id?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns contents with one of the specified root content identifiers */
  rootContentId?: InputMaybe<Scalars['FilterRegex']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum ContentMapRendition {
  unknown = 'unknown'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentMapSort = {
  /** Sort field */
  field: ContentMapSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum ContentMapSortFields {
  /** Sort by content id */
  contentId = 'contentId',
  /** Sort by creation date */
  creationDate = 'creationDate',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by language */
  language = 'language',
  /** Sort by locale */
  locale = 'locale',
  /** Sort by modification date */
  modificationDate = 'modificationDate',
  /** Sort by published flag */
  published = 'published',
  /** Sort by rendition */
  rendition = 'rendition',
  /** Sort by relevance score */
  score = 'score',
  /** Sort by source */
  source = 'source',
  /** Sort by source id */
  sourceId = 'sourceId',
  /** Sort by title */
  title = 'title',
  /** Sort by upload date */
  uploadDate = 'uploadDate',
  /** Sort by version */
  version = 'version',
  /** Sort by version label */
  versionLabel = 'versionLabel'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentMapTaxonomyFilter = {
  /** No taxonomy indexed field definitions exist. As soon as the first taxonomy is defined, it's automatically listed here. */
  not_applicable_as_no_taxonomy_field_definitions_exist?: InputMaybe<Scalars['NoDynamicValueDefined']>;
};

/** Length limited text from the content map for use e.g. in result lists, previews, etc. */
export type ContentMapTeasers = ContentTeasers & {
  __typename?: 'ContentMapTeasers';
  /** The content map's title limited to `length` characters */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the content map for use e.g. in result lists, previews, etc. */
export type ContentMapTeasersTitleArgs = {
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentQueryResult = {
  __typename?: 'ContentQueryResult';
  /**
   * Matched datafield values and number of matching contents per value
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   */
  aggregations: Aggregations;
  /**
   * The query's matching contents.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  contents: Array<Maybe<ContentEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentQueryResultContentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<ContentSort>>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentRangeFilter = {
  /** Returns only contents with a creation date in the specified range */
  creationDate?: InputMaybe<DateRange>;
  /** Returns only contents with a modification date in the specified range */
  modificationDate?: InputMaybe<DateRange>;
  /** Returns only contents with a upload date in the specified range */
  uploadDate?: InputMaybe<DateRange>;
  /** Returns only contents with a version within the specified range */
  version?: InputMaybe<LongRange>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentRegexFilter = {
  /** No regex filterable indexed field definitions exist. As soon as the first matching indexed field definition is defined, it's automatically listed here. */
  not_applicable_as_no_regex_filterable_field_definitions_exist?: InputMaybe<Scalars['NoDynamicValueDefined']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentSort = {
  /** Sort field */
  field: ContentSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum ContentSortFields {
  /** Sort by content id */
  contentId = 'contentId',
  /** Sort by creation date */
  creationDate = 'creationDate',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by language */
  language = 'language',
  /** Sort by locale */
  locale = 'locale',
  /** Sort by modification date */
  modificationDate = 'modificationDate',
  /** Sort by relevance score */
  score = 'score',
  /** Sort by source */
  source = 'source',
  /** Sort by source id */
  sourceId = 'sourceId',
  /** Sort by title */
  title = 'title',
  /** Sort by upload date */
  uploadDate = 'uploadDate',
  /** Sort by technical version */
  version = 'version'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentTaxonomyFilter = {
  /** No taxonomy indexed field definitions exist. As soon as the first taxonomy is defined, it's automatically listed here. */
  not_applicable_as_no_taxonomy_field_definitions_exist?: InputMaybe<Scalars['NoDynamicValueDefined']>;
};

/** Length limited text from the content for use e.g. in result lists, previews, etc. */
export type ContentTeasers = {
  /** The content's title limited to `length` characters */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the content for use e.g. in result lists, previews, etc. */
export type ContentTeasersTitleArgs = {
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum ContentType {
  /** Specifies the content map */
  contentMap = 'contentMap',
  /** Specifies the topic */
  topic = 'topic'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ContentUploadConfig = {
  __typename?: 'ContentUploadConfig';
  /** The upload config for ms help 2 content */
  msHelp2?: Maybe<UploadConfig>;
};

/** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CopilotSettings = {
  __typename?: 'CopilotSettings';
  /** Number of days to store the conversation. If zero, the conversation will not be stored. */
  conversationMaxDays?: Maybe<Scalars['NonNegativeInt']>;
  /** Number of conversations to store in the history. If zero, the conversation will not be stored. */
  conversationMaxHistory?: Maybe<Scalars['NonNegativeInt']>;
  /** Base URL for the plusmeta insights API */
  plusmetaInsightsBaseUrl?: Maybe<Scalars['String']>;
  /** Security token for the plusmeta insights API */
  plusmetaInsightsSecurityToken?: Maybe<Scalars['String']>;
  /** Request header name of the plusmeta insights security token */
  plusmetaInsightsSecurityTokenHeader?: Maybe<Scalars['String']>;
};

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum CountRelation {
  /** The count is accurate and equals the actual count of hits */
  eq = 'eq',
  /** The result is inaccurate and only a lower bound of the actual possible hits */
  gte = 'gte',
  /** The result is inaccurate and only a upper bound of the actual possible hits */
  lte = 'lte'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CreateAccessFilterInput = {
  /** Comment containing optional information for the access filter */
  comment?: InputMaybe<Scalars['String']>;
  /**
   * Graphql query specifying the content affected by the access filter.
   *
   * Please be aware: Empty filter (e.g. "{}") are evaluated as none filters, which means nothing will be matched.
   * If the filter should match every content use the all filter (e.g. "{ all: true }").
   *
   * This property is deprecated and will be removed in a future version.
   * For now it will act the same as `query`.
   * If both values are configured as input the contentQuery will be ignored.
   * @deprecated Please use 'query' in combination with 'domains' instead
   */
  contentQuery?: InputMaybe<Scalars['String']>;
  /** Readable name for the access filter */
  displayName: Scalars['String'];
  /**
   * All domains this access filter applies to. If empty, no domain is affected, but filter will be generated.
   *
   * If this value is empty the contentQuery must be provided. The domains will be extracted from the contentQuery.
   */
  domains?: InputMaybe<Array<AccessFilterDomains>>;
  /**
   * Graphql query specifying the content affected by the access filter.
   *
   * Please be aware: Empty filter (e.g. "{}") are evaluated as none filters, which means nothing will be matched.
   * If the filter should match every content use the all filter (e.g. "{ all: true }").
   */
  query?: InputMaybe<Scalars['String']>;
  /** Allowed resource actions for users on the specified rendition and content */
  resourceActions?: InputMaybe<Array<InputMaybe<ResourceActionInput>>>;
  /**
   * Graphql query specifying the users affected by the access filter for the new access filter.
   *
   * Please be aware: Empty filter (e.g. "{}") are evaluated as none filters, which means nothing will be matched.
   * If the filter should match every content use the all filter (e.g. "{ all: true }").
   */
  userQuery: Scalars['String'];
};

/** Options to create the anonymous user via `createAnonymousUser` */
export type CreateAnonymousUserInput = {
  /** Readable name for the user */
  displayName?: InputMaybe<Scalars['String']>;
  /** The new user's directly assigned roles */
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CreateApiKeyInput = {
  /** The api key's readable name */
  displayName: Scalars['String'];
  /** The unique identifier of the user for whom the api key will be created */
  userId: Scalars['ID'];
  /** Date until which the api key will be valid */
  validUntil: Scalars['DateTime'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CreateApiKeyResponse = {
  __typename?: 'CreateApiKeyResponse';
  /**
   * The api pass is a verifiable string which contains all authentication information needed to access the application.
   * It can be distributed to trusted applications to provide access to the application without the need to retrieve
   * any other information.
   *
   * The api pass consists of the payload and the checksum part separated by a colon (:).
   * The payload part is a JSON object containing the url and the access token encoded in base 64.
   * To verify the integrity of the payload use the checksum part which is a md5 hash.
   *
   * Please note: Handle the pass with same caution as described in `token`.
   */
  apiPass: Scalars['String'];
  /** The api key's details */
  key?: Maybe<ApiKey>;
  /**
   * The access token for the api key.
   * Please note: The token is a credential which grants access to resources and should be treated like a password.
   * To provide the best security for the token, it isn't stored and can only be retrieved once during creation
   * of an api key.
   */
  token: Scalars['String'];
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CreateCollectionInput = {
  /** The detailed description */
  description?: InputMaybe<Scalars['String']>;
  /** The display name */
  displayName?: InputMaybe<Scalars['String']>;
  /** The indicator whether the collection should be displayed in the dashboard */
  showInDashboard?: InputMaybe<Scalars['Boolean']>;
  /**
   * The visibility of the new collection.
   * Can't be changed after creation
   */
  visibility: CollectionVisibilityTypes;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CreateConnectorKeyInput = {
  /** The unique identifier of the user for whom the connector key will be created */
  userId: Scalars['ID'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CreateFacetsCollectionInput = {
  /**
   * Ordered facets of the collection.
   * The order is specified by the order of the array.
   */
  facets?: InputMaybe<Array<FacetInput>>;
  /** The unique id of the facets collection */
  id: Scalars['ID'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CreateNoteCommentInput = {
  /** The text of the comment */
  text?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CreateNoteInput = {
  /** The id of the object the note is created for */
  objectId: Scalars['ID'];
  /** The note text */
  text?: InputMaybe<MultilingualStringInput>;
  /** The visibility for the note */
  visibility: NoteVisibilityTypesInput;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CreateOrUpdateDataDisplayConfigInput = {
  /**
   * Metadata ids or fields which are included in the data display configuration.
   * The Field is ordered and always returns the entries in the defined order.
   */
  entries?: InputMaybe<Array<InputMaybe<DataDisplayConfigEntryInput>>>;
  /** Unique id of the data display config */
  id: Scalars['ID'];
  /**
   * Defines the separator character with whom the information will be separated.
   * The separator length is limited to 1 character.
   */
  separator?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CreateOrUpdateSearchConfigInput = {
  /**
   * Datafield ids which are included as search scopes.
   * The Field is ordered and always returns the scopes in the defined order.
   */
  entries?: InputMaybe<Array<InputMaybe<SearchConfigEntryInput>>>;
  /** The unique id of the search config */
  id: Scalars['ID'];
};

/** Sub graph: [https://users-settings-graph.qit-settings.svc.cluster.local:8443](https://users-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CreateOrUpdateUserSettings = {
  /**
   * The settings as a string key value map.
   *
   * Be aware: Key and value must of type string. Otherwise
   * an error will be thrown.
   */
  settings: Scalars['JSONObject'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CreateStaticPageInput = {
  /**
   * The language of the static page.
   * Static Pages with the same page types but different locales may exist.
   */
  locale: Language;
  /** The page type to be able to group static pages with different locales */
  pageType: Scalars['String'];
  /** The title of the static page */
  title: Scalars['String'];
};

/** Options to create a new user via `createUser` */
export type CreateUserInput = {
  /** Readable name for the user */
  displayName?: InputMaybe<Scalars['String']>;
  /** The user's email address */
  email?: InputMaybe<Scalars['EmailAddress']>;
  /** The new user's directly assigned roles */
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The user's provider specific identifier */
  sourceUserIdentifier: Scalars['String'];
};

/** Options to create a new webhook config via `createWebhookConfig` */
export type CreateWebhookConfigInput = {
  /** Readable name for the webhook config */
  displayName: Scalars['String'];
  /** The event type for which this webhook config shall be used */
  eventType: WebhookEventTypes;
  /** The shared secret for the triggered webhook */
  sharedSecret?: InputMaybe<Scalars['String']>;
  /** The URL of the webhook to be triggered */
  url: Scalars['URL'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CustomDatafield = Datafield & {
  __typename?: 'CustomDatafield';
  /** The full indexed field definition */
  datafieldDefinition?: Maybe<DatafieldDefinition>;
  /** The indexed datafield definition id */
  datafieldId: Scalars['DatafieldDefinitionID'];
  /** The entries meta type */
  fieldType: FieldDefinitionTypes;
  /** Length unlimited text for the entry for use e.g. in result lists, previews, etc. */
  localizations?: Maybe<DatafieldLocalizations>;
  /** The referenced indexed field definition id */
  referencedId: Scalars['ID'];
  /** Length limited text for the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldTeasers>;
  /** The title of the indexed field definition */
  title?: Maybe<MultilingualString>;
  /** The entries type */
  type: DatafieldType;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CustomDatafieldFacet = Facet & {
  __typename?: 'CustomDatafieldFacet';
  /** The full indexed field definition referenced by the facet */
  datafieldDefinition: DatafieldDefinition;
  /** The facets definition */
  definition?: Maybe<FacetDefinition>;
  /** Details about how the facet should be displayed by a frontend component */
  display: FacetDisplay;
  /** The unique id of the facet */
  id: Scalars['ID'];
  /**
   * Kind of facet, e.g. is it a filter or navigation facet.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  kind: Scalars['String'];
  /** The id of the field, indexed field definition etc. referenced by this facet */
  referencedId: Scalars['ID'];
  /** Length limited text */
  teasers?: Maybe<FacetTeasers>;
  /**
   * The title of the facet.
   * If the facet doesn't have a custom title specified
   * and has a valid datafield id specified, the title of the datafield
   * definition will be used as title.
   */
  title?: Maybe<MultilingualString>;
  /** The type of the facet */
  type: DatafieldType;
};

/** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CustomLink = {
  __typename?: 'CustomLink';
  /** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
  icon: Scalars['String'];
  /** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
  target: Target;
  /** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
  title: Scalars['String'];
  /** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
  url: Scalars['URL'];
};

/** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CustomLinkInput = {
  /** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
  icon: Scalars['String'];
  /** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
  target: Target;
  /** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
  title: Scalars['String'];
  /** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
  url: Scalars['URL'];
};

/** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CustomLinks = {
  __typename?: 'CustomLinks';
  /** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
  customLinks: Array<Maybe<CustomLink>>;
  /** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
  language: Scalars['String'];
};

/** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
export type CustomLinksInput = {
  /** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
  customLinks: Array<CustomLinkInput>;
  /** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
  language: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DataDisplayConfig = {
  __typename?: 'DataDisplayConfig';
  /**
   * Entries of this data display config.
   * The Field is ordered and always returns the entries in the defined order.
   */
  entries?: Maybe<Array<DataDisplayConfigEntry>>;
  /** The unique id */
  id: Scalars['ID'];
  /** Defines the separator characters with whom the information will be separated */
  separator: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DataDisplayConfigDatafieldEntry = DataDisplayConfigEntry & {
  __typename?: 'DataDisplayConfigDatafieldEntry';
  /** The full indexed field definition */
  datafieldDefinition?: Maybe<DatafieldDefinition>;
  /** The indexed field definition id */
  datafieldId: Scalars['DatafieldDefinitionID'];
  /**
   * Details about how the entry should be displayed by a frontend component.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  displayOptions?: Maybe<Scalars['JSONObject']>;
  /** The entries meta type */
  fieldType: FieldDefinitionTypes;
  /** The referenced indexed field definition id */
  referencedId: Scalars['ID'];
  /** Indicates whether the title should be shown as prefix */
  showTitlePrefix: Scalars['Boolean'];
  /** Length limited text for the field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DataDisplayConfigEntryTeasers>;
  /** The title of the indexed field definition */
  title?: Maybe<MultilingualString>;
  /** The entries type */
  type: DatafieldType;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DataDisplayConfigEntry = {
  /**
   * Details about how the entry should be displayed by a frontend component.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  displayOptions?: Maybe<Scalars['JSONObject']>;
  /** The entries meta type */
  fieldType: FieldDefinitionTypes;
  /** The id of the field, indexed field definition, etc. referenced by this entry */
  referencedId: Scalars['ID'];
  /** Indicates whether the title should be shown as prefix */
  showTitlePrefix: Scalars['Boolean'];
  /** Length limited text for the entry for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DataDisplayConfigEntryTeasers>;
  /** The title of the entry */
  title?: Maybe<MultilingualString>;
  /** The entries type */
  type: DatafieldType;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DataDisplayConfigEntryInput = {
  /**
   * Details about how the entry should be displayed by a frontend component.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  displayOptions?: InputMaybe<Scalars['JSONObject']>;
  /** The id of the field, indexed field definition, etc. referenced by this entry */
  referencedId: Scalars['ID'];
  /** Indicates whether the title should be shown as prefix */
  showTitlePrefix?: InputMaybe<Scalars['Boolean']>;
  /** The entries type */
  type: DatafieldType;
};

/** Length limited text value for use e.g. in result lists, previews, etc. */
export type DataDisplayConfigEntryTeasers = {
  __typename?: 'DataDisplayConfigEntryTeasers';
  /**
   * The value's title limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text value for use e.g. in result lists, previews, etc. */
export type DataDisplayConfigEntryTeasersTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DataDisplayConfigFieldEntry = DataDisplayConfigEntry & {
  __typename?: 'DataDisplayConfigFieldEntry';
  /**
   * The full indexed field definition
   * @deprecated This API may change in future releases
   */
  datafieldDefinition?: Maybe<DatafieldDefinition>;
  /**
   * Details about how the entry should be displayed by a frontend component.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  displayOptions?: Maybe<Scalars['JSONObject']>;
  /** The entries meta type */
  fieldType: FieldDefinitionTypes;
  /** Indicates to which data types the entry belongs to. */
  nodeTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The referenced field name */
  referencedId: Scalars['ID'];
  /** Indicates whether the title should be shown as prefix */
  showTitlePrefix: Scalars['Boolean'];
  /** Length limited text for the field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DataDisplayConfigEntryTeasers>;
  /** The title of the indexed field definition */
  title?: Maybe<MultilingualString>;
  /** The entries type */
  type: DatafieldType;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DataUploadConfig = {
  __typename?: 'DataUploadConfig';
  /** Upload config for the general data upload */
  dataUploadConfig?: Maybe<UploadMultipleConfig>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Datafield = {
  /** The entries meta type */
  fieldType: FieldDefinitionTypes;
  /** Length unlimited text for the entry for use e.g. in result lists, previews, etc. */
  localizations?: Maybe<DatafieldLocalizations>;
  /** The id of the field, indexed field definition, etc. referenced by this entry */
  referencedId: Scalars['ID'];
  /** Length limited text for the entry for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldTeasers>;
  /** The title of the entry */
  title?: Maybe<MultilingualString>;
  /** The entries type */
  type: DatafieldType;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DatafieldAggregations = {
  __typename?: 'DatafieldAggregations';
  /** Provides composite aggregations with pagination support */
  composite?: Maybe<DatafieldCompositeAggregation>;
  /**
   * Provides terms aggregations for custom datafields.
   * Supports prefix search and the results are ordered by count.
   */
  terms?: Maybe<DatafieldTermsAggregation>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DatafieldAutocompleteEdge = AutocompleteEdge & {
  __typename?: 'DatafieldAutocompleteEdge';
  /** The full datafield definition */
  datafieldDefinition?: Maybe<DatafieldDefinition>;
  /**
   * Id of the datafield definition in which the value was found
   * This value can be used as field name content query filters
   */
  datafieldDefinitionId: Scalars['DatafieldDefinitionID'];
  /** Display text for this autocomplete hit */
  displayName: Scalars['String'];
  /**
   * Language which this hit is based on
   * Is only returned if an exact language assignment can be made
   */
  language?: Maybe<Language>;
  /**
   * Unique value of the datafield definition value
   * This value can be used as operand in content query filters
   */
  value: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DatafieldAutocompleteFilter = {
  /**
   * Indexed field definitions to search in
   * Only definitions of type `taxonomy` and `enum` are supported
   * The order of datafield definitions specifies their precedence
   */
  datafieldDefinitionIds: Array<Scalars['DatafieldDefinitionID']>;
  /**
   * Languages to search in (if applicable for the datafield definition).
   * The order of languages specifies their precedence.
   */
  languages: Array<Language>;
  /**
   * String to search for
   * The provided query is executed as a prefix search
   * Fulltext features like wildcards, fuzzy search, etc. aren't supported
   */
  query: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DatafieldAutocompleteResult = {
  __typename?: 'DatafieldAutocompleteResult';
  /** The search results */
  hits: Array<DatafieldAutocompleteEdge>;
  /**
   * The search string which produced this result
   * Can be used by clients to correlate queries and responses
   */
  query: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DatafieldCompositeAggregation = {
  __typename?: 'DatafieldCompositeAggregation';
  /** No field definitions exist. As soon as the first field definition is defined, it's automatically listed here. */
  not_applicable_as_no_field_definitions_exist?: Maybe<Scalars['NoDynamicValueDefined']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DatafieldDefinition = {
  /** Type of this datafield definition */
  fieldType?: Maybe<FieldDefinitionTypes>;
  /**
   * Unique identifier of this datafield definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  localizations?: Maybe<DatafieldDefinitionLocalizations>;
  /** Field names which shall be mapped to this datafield definition */
  mappedNames?: Maybe<Array<Scalars['String']>>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: Maybe<Array<Maybe<ObjectType>>>;
  /** Length limited text from the datafield definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldDefinitionTeasers>;
  /** Human readable description of this datafield definition */
  title?: Maybe<MultilingualString>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DatafieldDefinitionEdge = {
  __typename?: 'DatafieldDefinitionEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** The result's datafield definition */
  node: DatafieldDefinition;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DatafieldDefinitionEqualsValueFilter = {
  /** Returns only datafield definitions with the specified type */
  fieldType?: InputMaybe<FieldDefinitionTypes>;
  /** Returns only datafield definitions with the specified title */
  title?: InputMaybe<Scalars['String']>;
};

/** The `DatafieldDefinitionFilter` is used to specify filter criteria for datafield definitions. */
export type DatafieldDefinitionFilter = {
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<DatafieldDefinitionFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns datafield definitions that contain all of the exact values specified */
  equals?: InputMaybe<DatafieldDefinitionEqualsValueFilter>;
  /** Returns datafield definitions with one of the specified unique identifiers */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<DatafieldDefinitionFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<DatafieldDefinitionFilter>>>;
  /** Returns datafield definitions that match all of the regular expressions */
  regex?: InputMaybe<DatafieldDefinitionRegexFilter>;
};

/** Text from the datafield definition for use e.g. in details etc. */
export type DatafieldDefinitionLocalizations = {
  __typename?: 'DatafieldDefinitionLocalizations';
  /**
   * The datafield definition's title.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   */
  title?: Maybe<Scalars['String']>;
};


/** Text from the datafield definition for use e.g. in details etc. */
export type DatafieldDefinitionLocalizationsTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DatafieldDefinitionQueryResult = {
  __typename?: 'DatafieldDefinitionQueryResult';
  /**
   * The query's datafield definitions.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  fieldDefinitions: Array<Maybe<DatafieldDefinitionEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DatafieldDefinitionQueryResultFieldDefinitionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<DatafieldDefinitionSort>>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DatafieldDefinitionQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DatafieldDefinitionRegexFilter = {
  /** Returns only datafield definitions whose event matches the specified Regex */
  fieldType?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns definitions with one of the specified unique identifiers */
  id?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only datafield definitions whose title matches the specified Regex */
  title?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DatafieldDefinitionSort = {
  /** Sort field */
  field: DatafieldDefinitionSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum DatafieldDefinitionSortFields {
  /** Sort by event type */
  eventType = 'eventType',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by relevance score */
  score = 'score',
  /** Sort by title */
  title = 'title'
}

/** Length limited text from the datafield definition for use e.g. in result lists, previews, etc. */
export type DatafieldDefinitionTeasers = {
  __typename?: 'DatafieldDefinitionTeasers';
  /**
   * The datafield definition's title limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the datafield definition for use e.g. in result lists, previews, etc. */
export type DatafieldDefinitionTeasersTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Length unlimited text from the metadata definition value for use e.g. in result lists, previews, etc. */
export type DatafieldLocalizations = {
  __typename?: 'DatafieldLocalizations';
  /**
   * The value's title.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   */
  title?: Maybe<Scalars['String']>;
};


/** Length unlimited text from the metadata definition value for use e.g. in result lists, previews, etc. */
export type DatafieldLocalizationsTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
};

/** Length limited text from the indexed field definition value for use e.g. in result lists, previews, etc. */
export type DatafieldTeasers = {
  __typename?: 'DatafieldTeasers';
  /**
   * The value's title limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the indexed field definition value for use e.g. in result lists, previews, etc. */
export type DatafieldTeasersTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DatafieldTermsAggregation = {
  __typename?: 'DatafieldTermsAggregation';
  /** No field definitions exist. As soon as the first field definition is defined, it's automatically listed here. */
  not_applicable_as_no_field_definitions_exist?: Maybe<Scalars['NoDynamicValueDefined']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum DatafieldType {
  field = 'field',
  metadata = 'metadata'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DatafieldUploadConfig = {
  __typename?: 'DatafieldUploadConfig';
  /** Upload config for datafield model */
  datafieldModel?: Maybe<UploadConfig>;
  /** Upload config for navigation xml */
  navigationXml?: Maybe<UploadConfig>;
};

/** Length limited text from a defined datafield value for use e.g. in result lists, previews, etc. */
export type DatafieldValueTeasers = {
  __typename?: 'DatafieldValueTeasers';
  /**
   * The value's title limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from a defined datafield value for use e.g. in result lists, previews, etc. */
export type DatafieldValueTeasersTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Datafields = {
  __typename?: 'Datafields';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  activity?: Maybe<Array<Datafield>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  article?: Maybe<Array<Datafield>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  asset?: Maybe<Array<Datafield>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  assetOption?: Maybe<Array<Datafield>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  contentMap?: Maybe<Array<Datafield>>;
  /** Ignore this field */
  dummy?: Maybe<Scalars['Boolean']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  mechanic?: Maybe<Array<Datafield>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  product?: Maybe<Array<Datafield>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  topic?: Maybe<Array<Datafield>>;
};

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DateInclusiveRange = {
  __typename?: 'DateInclusiveRange';
  /** Greater than or equal to */
  gte?: Maybe<Scalars['DateTime']>;
  /** Less than or equal to */
  lte?: Maybe<Scalars['DateTime']>;
};

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DateInclusiveRangeSpec = {
  /** Greater than or equal to */
  gte?: InputMaybe<DateSpec>;
  /** Less than or equal to */
  lte?: InputMaybe<DateSpec>;
};

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DateOffset = {
  /** Amount of `unit` to offset. May be positive or negative. */
  amount: Scalars['Int'];
  /** Unit to offset */
  unit: DateOffsetUnit;
};

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum DateOffsetUnit {
  days = 'days',
  hours = 'hours',
  minutes = 'minutes',
  months = 'months',
  seconds = 'seconds',
  weeks = 'weeks',
  years = 'years'
}

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DateRange = {
  /** Greater than */
  gt?: InputMaybe<DateSpec>;
  /** Greater than or equal to */
  gte?: InputMaybe<DateSpec>;
  /** Less than */
  lt?: InputMaybe<DateSpec>;
  /** Less than or equal to */
  lte?: InputMaybe<DateSpec>;
};

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type DateSpec = {
  /** Anchor date for the date spec. If no date is provided defaults to <now>. */
  date?: InputMaybe<Scalars['DateTime']>;
  /** Zero or more date offsets relative to the anchor date. */
  offset?: InputMaybe<Array<InputMaybe<DateOffset>>>;
  /** Specifies if the resulting date should be rounded down to the nearest day. */
  roundDownToNearestDay?: InputMaybe<Scalars['Boolean']>;
};

/** Defines a date time field */
export type DateTimeDatafieldDefinition = DatafieldDefinition & {
  __typename?: 'DateTimeDatafieldDefinition';
  /** Type of this indexed field definition */
  fieldType?: Maybe<FieldDefinitionTypes>;
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Text from the datafield definition for use e.g. in detail views, etc */
  localizations?: Maybe<DatafieldDefinitionLocalizations>;
  /** Field names which shall be mapped to this indexed field definition */
  mappedNames?: Maybe<Array<Scalars['String']>>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: Maybe<Array<Maybe<ObjectType>>>;
  /** Length limited text from the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldDefinitionTeasers>;
  /** Human readable description of this indexed field definition */
  title?: Maybe<MultilingualString>;
};

/**
 * Data to update a date time datafield.
 * All fields except `id` are optional. Only provided fields will be updated.
 * If provided, they will replace the current value.
 */
export type DateTimeDatafieldDefinitionDiff = {
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Field names which shall be mapped to this indexed field definition */
  mappedNames?: InputMaybe<Array<Scalars['String']>>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: InputMaybe<Array<InputMaybe<ObjectType>>>;
  /** Human readable description of this indexed field definition */
  title?: InputMaybe<MultilingualStringInput>;
};

/** Data to create a date time datafield. */
export type DateTimeDatafieldDefinitionSpec = {
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Field names which shall be mapped to this indexed field definition */
  mappedNames: Array<Scalars['String']>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: InputMaybe<Array<InputMaybe<ObjectType>>>;
  /** Human readable description of this indexed field definition */
  title: MultilingualStringInput;
};

/** Defines a date time datafield field */
export type DateTimeRangeDatafieldDefinition = DatafieldDefinition & {
  __typename?: 'DateTimeRangeDatafieldDefinition';
  /** Type of this datafield definition */
  fieldType?: Maybe<FieldDefinitionTypes>;
  /**
   * Unique identifier of this datafield definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Text from the datafield definition for use e.g. in detail views, etc */
  localizations?: Maybe<DatafieldDefinitionLocalizations>;
  /** Datafield names which shall be mapped to this datafield definition */
  mappedNames?: Maybe<Array<Scalars['String']>>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: Maybe<Array<Maybe<ObjectType>>>;
  /** Length limited text from the datafield definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldDefinitionTeasers>;
  /** Human readable description of this datafield definition */
  title?: Maybe<MultilingualString>;
};

/**
 * The `DomainAccessFilterInput` is used to specify the input values for the creation of an domain specific access filter.
 * It is for internal use only at this point in time.
 */
export type DomainAccessFilterInput = {
  /**
   * All domains this access filter applies to. If empty, no domain is affected, but filter will be generated.
   * You can update the access filter afterwards to add domains.
   */
  domains: Array<InputMaybe<AccessFilterDomains>>;
  /** Unique identifier for the access filter. `id` itself can't be updated. */
  id: Scalars['ID'];
  /**
   * Graphql query specifying the content affected by the access filter.
   *
   * Please be aware: Empty filter (e.g. "{}") are evaluated as none filters, which means nothing will be matched.
   * If the filter should match every content use the all filter (e.g. "{ all: true }").
   */
  query: Scalars['String'];
  /**
   * Graphql query specifying the users affected by the access filter for the new access filter.
   *
   * Please be aware: Empty filter (e.g. "{}") are evaluated as none filters, which means nothing will be matched.
   * If the filter should match every content use the all filter (e.g. "{ all: true }").
   */
  userQuery: Scalars['String'];
};

/**
 * Defines a enum datafield.
 * An enum datafield is a string field restricted to a fixed set of values.
 */
export type EnumDatafieldDefinition = DatafieldDefinition & {
  __typename?: 'EnumDatafieldDefinition';
  /** Type of this indexed field definition */
  fieldType?: Maybe<FieldDefinitionTypes>;
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Text from the datafield definition for use e.g. in detail views, etc */
  localizations?: Maybe<DatafieldDefinitionLocalizations>;
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames?: Maybe<Array<Scalars['String']>>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: Maybe<Array<Maybe<ObjectType>>>;
  /** Length limited text from the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldDefinitionTeasers>;
  /** Human readable description of this indexed field definition */
  title?: Maybe<MultilingualString>;
  /** List of valid values */
  values?: Maybe<Array<EnumValue>>;
};

/**
 * Data to update an enum datafield.
 * An enum datafield is a string field restricted to a fixed set of values.
 * All fields except `id` are optional. Only provided fields will be updated.
 * If provided, they will replace the current value.
 */
export type EnumDatafieldDefinitionDiff = {
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames?: InputMaybe<Array<Scalars['String']>>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: InputMaybe<Array<InputMaybe<ObjectType>>>;
  /** Human readable description of this indexed field definition */
  title?: InputMaybe<MultilingualStringInput>;
  /** List of valid values */
  values?: InputMaybe<Array<EnumValueSpec>>;
};

/**
 * Data to create an enum indexed field.
 * An enum indexed field is a string field restricted to a fixed set of values.
 */
export type EnumDatafieldDefinitionSpec = {
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Field names which shall be mapped to this indexed field definition */
  mappedNames: Array<Scalars['String']>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: InputMaybe<Array<InputMaybe<ObjectType>>>;
  /** Human readable description of this indexed field definition */
  title: MultilingualStringInput;
  /** List of valid values */
  values: Array<EnumValueSpec>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type EnumFacetDefinition = FacetDefinition & {
  __typename?: 'EnumFacetDefinition';
  /** The datafield type of the field */
  fieldType: FieldDefinitionTypes;
  /** The values the field represented by this facet may have */
  values?: Maybe<Array<EnumValue>>;
};

/** Valid value for an enum */
export type EnumValue = {
  __typename?: 'EnumValue';
  /**
   * Data value
   * Must be unique within the enum
   */
  key: Scalars['String'];
  /** Length limited text from the enum value for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldValueTeasers>;
  /** Human readable name */
  title?: Maybe<MultilingualString>;
};

/** Valid value for an enum */
export type EnumValueSpec = {
  /**
   * Data value
   * Must be unique within the enum
   */
  key: Scalars['String'];
  /** Human readable name */
  title?: InputMaybe<MultilingualStringInput>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Facet = {
  /** The facets definition */
  definition?: Maybe<FacetDefinition>;
  /** Details about how the facet should be displayed by a frontend component */
  display: FacetDisplay;
  /** The unique id of the facet */
  id: Scalars['ID'];
  /**
   * Kind of facet, e.g. is it a filter or navigation facet.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  kind: Scalars['String'];
  /** The id of the field, indexed field definition etc. referenced by this facet */
  referencedId: Scalars['ID'];
  /** Length limited text */
  teasers?: Maybe<FacetTeasers>;
  /** The title of the facet. */
  title?: Maybe<MultilingualString>;
  /** The type of the facet */
  type: DatafieldType;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FacetDatafieldAutocompleteEdge = AutocompleteEdge & {
  __typename?: 'FacetDatafieldAutocompleteEdge';
  /** The full datafield definition */
  datafieldDefinition?: Maybe<DatafieldDefinition>;
  /**
   * Id of the datafield definition in which the value was found
   * This value can be used as field name content query filters
   */
  datafieldDefinitionId: Scalars['DatafieldDefinitionID'];
  /** Display text for this autocomplete hit */
  displayName: Scalars['String'];
  /** The filter selection. */
  filterSelection?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Language which this hit is based on
   * Is only returned if an exact language assignment can be made
   */
  language?: Maybe<Language>;
  /**
   * Unique value of the datafield definition value
   * This value can be used as operand in content query filters
   */
  value: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FacetDatafieldAutocompleteFilter = {
  /**
   * Filter selections to search in
   * Only active datafield definitions are considered
   */
  filterSelection?: InputMaybe<Array<Scalars['String']>>;
  /**
   * Languages to search in (if applicable for the datafield definition).
   * The order of languages specifies their precedence.
   */
  languages: Array<Language>;
  /**
   * String to search for
   * The provided query is executed as a prefix search
   * Fulltext features like wildcards, fuzzy search, etc. aren't supported
   */
  query: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FacetDatafieldAutocompleteResult = {
  __typename?: 'FacetDatafieldAutocompleteResult';
  /** The search results */
  hits: Array<FacetDatafieldAutocompleteEdge>;
  /**
   * The search string which produced this result
   * Can be used by clients to correlate queries and responses
   */
  query: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FacetDefinition = {
  /** The datafield type of the field */
  fieldType: FieldDefinitionTypes;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FacetDisplay = {
  __typename?: 'FacetDisplay';
  /**
   * Display options for the facet.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  options?: Maybe<Scalars['JSONObject']>;
  /**
   * Display type for the facet.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  type: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FacetDisplayInput = {
  /**
   * Display options for the facet.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  options?: InputMaybe<Scalars['JSONObject']>;
  /**
   * Display type for the facet.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  type: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FacetInput = {
  /** Details about how the facet should be displayed by a frontend component */
  display: FacetDisplayInput;
  /**
   * Kind of facet, e.g. is it a filter or navigation facet.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  kind: Scalars['String'];
  /** The id of the indexed field definition used as facet */
  referencedId: Scalars['ID'];
  /** The type of the facet */
  type: DatafieldType;
};

/** Length limited text from the facet for use e.g. in result lists, previews, etc. */
export type FacetTeasers = {
  __typename?: 'FacetTeasers';
  /**
   * The facets title limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   * For further information about the titel see `Facet`'s title attribute.
   */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the facet for use e.g. in result lists, previews, etc. */
export type FacetTeasersTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FacetsCollection = {
  __typename?: 'FacetsCollection';
  /**
   * Ordered facets of the collection.
   * The order is specified by the order of the array.
   */
  facets?: Maybe<Array<Maybe<Facet>>>;
  /** The unique id of the facets collection */
  id: Scalars['ID'];
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FacetsCollectionFacetsArgs = {
  referencedIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Feature = {
  __typename?: 'Feature';
  /** The unique id of the feature */
  id: Scalars['ID'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum FieldDefinitionTypes {
  dateTime = 'dateTime',
  dateTimeRange = 'dateTimeRange',
  enum = 'enum',
  keyword = 'keyword',
  number = 'number',
  numberRange = 'numberRange',
  taxonomy = 'taxonomy',
  text = 'text'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FieldFacet = Facet & {
  __typename?: 'FieldFacet';
  /** The facets definition */
  definition?: Maybe<FacetDefinition>;
  /** Details about how the facet should be displayed by a frontend component */
  display: FacetDisplay;
  /** The unique id of the facet */
  id: Scalars['ID'];
  /**
   * Kind of facet, e.g. is it a filter or navigation facet.
   * The value isn't interpreted by the API. The frontend component(s) are responsible
   * for the value stored.
   */
  kind: Scalars['String'];
  /** The id of the field, indexed field definition etc. referenced by this facet */
  referencedId: Scalars['ID'];
  /** Length limited text */
  teasers?: Maybe<FacetTeasers>;
  /** The title of the facet. */
  title?: Maybe<MultilingualString>;
  /** The type of the facet */
  type: DatafieldType;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type File = {
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  file: BlobRef;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  objectId: Scalars['String'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  objectSubType?: Maybe<FileSubType>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  objectType?: Maybe<FileType>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FileEdge = {
  __typename?: 'FileEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** The result's file. */
  node: File;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FileEqualsValueFilter = {
  /** Returns only files with the specified text */
  objectId?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  objectType?: InputMaybe<FileType>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum FileExistsValueFields {
  /** Returns only files which have a text specified */
  objectId = 'objectId'
}

/**
 * The `FileFilter` is used to specify filter criteria for files.
 * If you specify more than one criterion, all criteria are grouped together and connected with a logical AND operator.
 * Further logical operations are possible by using `not` `andGroup` and `orGroup`.
 */
export type FileFilter = {
  /** Returns all files if set to true */
  all?: InputMaybe<Scalars['Boolean']>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<FileFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns files that contain all of the exact values specified */
  equals?: InputMaybe<FileEqualsValueFilter>;
  /**
   * Returns files that have a value in all of the specified fields
   * @deprecated Please use 'has' filter instead
   */
  exists?: InputMaybe<Array<InputMaybe<FileExistsValueFields>>>;
  /** Returns files that have a value in all of the specified fields */
  has?: InputMaybe<FileHasValueFilter>;
  /** Returns files with one of the specified unique identifiers */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<FileFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<FileFilter>>>;
  /** Returns files that match all of the regular expressions */
  regex?: InputMaybe<FileRegexFilter>;
};

/** Returns files that have a value in all of the specified fields */
export type FileHasValueFilter = {
  /** Specify the fields that the returned files must have a value in */
  valueInFields?: InputMaybe<Array<InputMaybe<FileHasValueInFields>>>;
};

/** Specify the fields that the returned files must have a value in */
export enum FileHasValueInFields {
  /** Returns only files which have a text specified */
  objectId = 'objectId'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FileInput = {
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  file?: InputMaybe<BlobRefInput>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  objectId?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  objectType?: InputMaybe<FileType>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FileQueryResult = {
  __typename?: 'FileQueryResult';
  /**
   * The query's matching files.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  files: Array<Maybe<FileEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FileQueryResultFilesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<FileSort>>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FileQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FileRegexFilter = {
  /** Returns files with one of the specified unique identifiers */
  id?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only files whose text matches the specified Regex */
  objectId?: InputMaybe<Scalars['FilterRegex']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FileSort = {
  /** Sort field */
  field: FileSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum FileSortFields {
  /** Sort by unique ID */
  id = 'id',
  /** Sort by objectId */
  objectId = 'objectId',
  /** Sort by objectType */
  objectType = 'objectType',
  /** Sort by relevance score */
  score = 'score'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum FileSubType {
  bmp = 'BMP',
  hoopsScs = 'HOOPS_SCS',
  html = 'HTML',
  jpg = 'JPG',
  json = 'JSON',
  latticeXvl = 'LATTICE_XVL',
  /** A file type where a subtype does not make sense */
  none = 'NONE',
  pdf = 'PDF',
  png = 'PNG',
  svg = 'SVG',
  text = 'TEXT',
  /** A file type where the subtype is currently not determined */
  unknown = 'UNKNOWN'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FileSvgWithHotspots = File & {
  __typename?: 'FileSvgWithHotspots';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  file: BlobRef;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  hotspots?: Maybe<Array<Maybe<HotspotSvg>>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  objectId: Scalars['String'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  objectSubType?: Maybe<FileSubType>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  objectType?: Maybe<FileType>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum FileType {
  document = 'DOCUMENT',
  image_2D = 'IMAGE_2D',
  image_3D = 'IMAGE_3D',
  imagePlain = 'IMAGE_PLAIN',
  previewImage = 'PREVIEW_IMAGE'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FileWithHotspots = File & {
  __typename?: 'FileWithHotspots';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  file: BlobRef;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  hotspots?: Maybe<Array<Maybe<Hotspot>>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  objectId: Scalars['String'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  objectSubType?: Maybe<FileSubType>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  objectType?: Maybe<FileType>;
};

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type FloatRange = {
  /** Greater than */
  gt?: InputMaybe<Scalars['Float']>;
  /** Greater than or equal to */
  gte?: InputMaybe<Scalars['Float']>;
  /** Less than */
  lt?: InputMaybe<Scalars['Float']>;
  /** Less than or equal to */
  lte?: InputMaybe<Scalars['Float']>;
};

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum FullTextOperator {
  /** Contents must contain all of the query's words */
  and = 'AND',
  /** Contents must contain only one of the query's words */
  or = 'OR'
}

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum FullTextSearchType {
  /** Finds contents which match any field, but uses the score from the best field */
  bestFields = 'best_fields',
  /** Finds contents which match any field and combines the score from each field */
  mostFields = 'most_fields',
  /** Returns contents that contain the words of a provided text, in the same order as provided. */
  phrase = 'phrase',
  /** Returns contents that match all terms in the provided text except the last term. The last term is used as a prefix search */
  prefix = 'prefix'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Hotspot = {
  __typename?: 'Hotspot';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  linkType?: Maybe<HotspotLinkType>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  linkValue?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  x1?: Maybe<Scalars['NonNegativeInt']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  x2?: Maybe<Scalars['NonNegativeInt']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  y1?: Maybe<Scalars['NonNegativeInt']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  y2?: Maybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum HotspotLinkType {
  /** Refers to another assembly (no reference to the current parts list) */
  g = 'G',
  /** Refers to data field in parts list */
  n = 'N'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type HotspotSvg = {
  __typename?: 'HotspotSvg';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  linkText?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  linkType?: Maybe<HotspotLinkType>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  linkValue?: Maybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Image3dFile = File & {
  __typename?: 'Image3dFile';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  binaryFile?: Maybe<BlobRef>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  file: BlobRef;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  objectId: Scalars['String'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  objectSubType?: Maybe<FileSubType>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  objectType?: Maybe<FileType>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type InherentDatafield = Datafield & {
  __typename?: 'InherentDatafield';
  /**
   * The full indexed field definition
   * @deprecated This API may change in future releases
   */
  datafieldDefinition?: Maybe<DatafieldDefinition>;
  /** The entries meta type */
  fieldType: FieldDefinitionTypes;
  /** Length unlimited text for the entry for use e.g. in result lists, previews, etc. */
  localizations?: Maybe<DatafieldLocalizations>;
  /** The referenced field name */
  referencedId: Scalars['ID'];
  /** Length limited text for the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldTeasers>;
  /** The title of the indexed field definition */
  title?: Maybe<MultilingualString>;
  /** The entries type */
  type: DatafieldType;
};

/** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Insight = {
  __typename?: 'Insight';
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  content: Scalars['String'];
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  context?: Maybe<InsightsConversationContext>;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  role: InsightsRole;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  timestamp?: Maybe<Scalars['String']>;
};

/** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
export type InsightSelector = {
  __typename?: 'InsightSelector';
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  range?: Maybe<InsightSelectorRange>;
};

/** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
export type InsightSelectorRange = {
  __typename?: 'InsightSelectorRange';
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  end?: Maybe<InsightSelectorRangeConformity>;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  start?: Maybe<InsightSelectorRangeConformity>;
};

/** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
export type InsightSelectorRangeConformity = {
  __typename?: 'InsightSelectorRangeConformity';
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  conformsTo?: Maybe<Scalars['String']>;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  value?: Maybe<Scalars['String']>;
};

/** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
export type InsightsConversationContext = {
  __typename?: 'InsightsConversationContext';
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  meta?: Maybe<InsightsConversationMetaContext>;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  object?: Maybe<InsightsConversationObjectContext>;
};

/** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
export type InsightsConversationMetaContext = {
  __typename?: 'InsightsConversationMetaContext';
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  items?: Maybe<Array<Maybe<InsightsConversationMetaContextItem>>>;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  pagination?: Maybe<InsightsPagination>;
};

/** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
export type InsightsConversationMetaContextItem = {
  __typename?: 'InsightsConversationMetaContextItem';
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  uri: Scalars['String'];
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  value: Scalars['String'];
};

/** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
export type InsightsConversationObjectContext = {
  __typename?: 'InsightsConversationObjectContext';
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  items?: Maybe<Array<Maybe<InsightsConversationObjectContextItem>>>;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  pagination?: Maybe<InsightsPagination>;
};

/** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
export type InsightsConversationObjectContextItem = {
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  label: Scalars['String'];
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  match?: Maybe<InsightsConversationObjectContextItemNode>;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  selector?: Maybe<InsightSelector>;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  text?: Maybe<Scalars['String']>;
};

/** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
export type InsightsConversationObjectContextItemNode = Topic;

/** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
export type InsightsConversationObjectContextTopicItem = InsightsConversationObjectContextItem & {
  __typename?: 'InsightsConversationObjectContextTopicItem';
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  label: Scalars['String'];
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  match?: Maybe<Topic>;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  selector?: Maybe<InsightSelector>;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  text?: Maybe<Scalars['String']>;
};

/** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
export type InsightsEdge = {
  __typename?: 'InsightsEdge';
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  node: Insight;
};

/** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
export type InsightsPagination = {
  __typename?: 'InsightsPagination';
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  page?: Maybe<Scalars['NonNegativeInt']>;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  pageCount?: Maybe<Scalars['NonNegativeInt']>;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  pageSize?: Maybe<Scalars['NonNegativeInt']>;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  total?: Maybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
export type InsightsQueryResult = {
  __typename?: 'InsightsQueryResult';
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  insights: Array<Maybe<InsightsEdge>>;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  total: TotalCount;
};


/** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
export type InsightsQueryResultInsightsArgs = {
  after?: InputMaybe<Scalars['NonNegativeInt']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum InsightsRole {
  system = 'system',
  user = 'user'
}

/** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
export type InsightsSearchInput = {
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  conversationId?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  meta?: InputMaybe<Array<InputMaybe<InsightsSearchMetaInput>>>;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  query: Scalars['String'];
};

/** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
export type InsightsSearchMetaInput = {
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  uri: Scalars['String'];
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  value: Scalars['String'];
};

/** A pipeline combines jobs together and allows jobs to be executed in series. By specifying the dependencies in each job the pipeline will start dependent jobs as their predecessors finish. The pipeline also defines a common data bag for all jobs. */
export type JobPipeline = {
  __typename?: 'JobPipeline';
  /** The data bag of this pipeline. Contains the initial input data as well as all the job outputs. */
  bag?: Maybe<Scalars['JSONObject']>;
  /** Timestamp when the pipeline was created. */
  creationDate: Scalars['DateTime'];
  /** The creator of the pipeline */
  creator?: Maybe<User>;
  /** The dependency graph for this pipeline, in mermaid format (https://mermaid-js.github.io/mermaid/#/flowchart?id=graph) */
  dependencyGraph: Scalars['String'];
  /** Human readable name for the pipeline. */
  displayName?: Maybe<Scalars['String']>;
  /** Human readable type for the pipeline. */
  displayType?: Maybe<Scalars['String']>;
  /** The end timestamp of the pipeline. Defined as the end time from the last job execution. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Unique identifier for the job pipeline. */
  id: Scalars['ID'];
  /** The jobs contained in the pipeline. */
  jobs: Array<JobPipelineJob>;
  /** The start timestamp of the pipeline. Defined as the start time from the first job execution. */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The status of the pipeline. Is calculated from all the job execution status. */
  status: JobPipelineStatus;
  /** The type of the pipeline. */
  type: Scalars['String'];
};

/** Defines a pipeline to run. */
export type JobPipelineDefinition = {
  /**
   * The initial data bag for the pipeline.
   *
   * In the job dependencies you can define the data from the bag the job receives as input. The output of finished jobs will also be added to the bag by the pipeline system to be available to the next jobs.
   */
  bag?: InputMaybe<Scalars['JSONObject']>;
  /** Human readable name for the pipeline. */
  displayName?: InputMaybe<Scalars['String']>;
  /** Human readable type for the pipeline. */
  displayType?: InputMaybe<Scalars['String']>;
  /** Unique identifier for the job pipeline. */
  id: Scalars['ID'];
  /**
   * The jobs to run in the pipeline.
   *
   * There must be at least one job that can be started when creating the pipeline, as each job completion causes the job coordinator to calculate the next jobs to start.
   *
   * Jobs can be added to running pipelines with the addJob mutation and not yet started or completed jobs can also be deleted with the deleteJob mutation.
   */
  jobs: Array<JobPipelineJobDefinition>;
  /** The type of the pipeline. */
  type: Scalars['String'];
};

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export type JobPipelineEdge = {
  __typename?: 'JobPipelineEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** The result's pipeline. */
  node: JobPipeline;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export type JobPipelineEqualsValueFilter = {
  /** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
  status?: InputMaybe<JobPipelineStatus>;
  /** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
  type?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export type JobPipelineFilter = {
  /** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
  all?: InputMaybe<Scalars['Boolean']>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<JobPipelineFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
  equals?: InputMaybe<JobPipelineEqualsValueFilter>;
  /** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<JobPipelineFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<JobPipelineFilter>>>;
  /** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
  range?: InputMaybe<JobPipelineRangeFilter>;
};

/** A unit of work to be scheduled by the job system. */
export type JobPipelineJob = {
  __typename?: 'JobPipelineJob';
  /** Timestamp when the job was created. */
  creationDate: Scalars['DateTime'];
  /** The creator of the job */
  creator?: Maybe<User>;
  /** The current and latest job execution. */
  currentExecution: JobPipelineJobExecution;
  /** Other jobs from the same pipeline this job depends on */
  dependsOn?: Maybe<Array<JobPipelineJobDependency>>;
  /** Human readable job name set by the job creator. */
  displayName?: Maybe<Scalars['String']>;
  /** Human readable job type set by the job creator. */
  displayType?: Maybe<Scalars['String']>;
  /** The unique identifier of the job */
  id: Scalars['ID'];
  /** The namespace of the queue where this job runs in */
  namespace: Scalars['String'];
  /** Past job executions. May contain previous job runs that were retried because of errors, etc. */
  pastExecutions: Array<JobPipelineJobExecution>;
  /** Name of the job queue */
  queueName: Scalars['String'];
  /** Amount of retires to try on job error. */
  retries?: Maybe<Scalars['NonNegativeInt']>;
};

/** Defines a job to be run in a pipeline. */
export type JobPipelineJobDefinition = {
  /** Other jobs or data this new job depends on */
  dependsOn?: InputMaybe<Array<JobPipelineJobDependencyDefinition>>;
  /** Human readable job name. */
  displayName?: InputMaybe<Scalars['String']>;
  /** Human readable job type. */
  displayType?: InputMaybe<Scalars['String']>;
  /** A unique id for the job. Must be unique within the pipeline. */
  id: Scalars['ID'];
  /** The namespace this job runs in. Can be used to test new functionality in feature branches. */
  namespace?: InputMaybe<Scalars['String']>;
  /** The queue name of the queue the job will be added to. Determines the consumer to process the job. */
  queueName: Scalars['String'];
  /** Determines if the job be retried on error and how often. By default jobs are not retried. */
  retries?: InputMaybe<Scalars['NonNegativeInt']>;
  /** Delay in ms before a retry of a failed job is attempted. Can be overwritten in the job consumer. By default the retry is attempted immediately. */
  retryDelay?: InputMaybe<Scalars['NonNegativeInt']>;
  /** Delay in ms before the job is started for the first time. */
  startDelay?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Defines one dependency of a job to some other data. */
export type JobPipelineJobDependency = {
  __typename?: 'JobPipelineJobDependency';
  /** Is the data from this key provided as an array? */
  array?: Maybe<Scalars['Boolean']>;
  /** Optional name for this data in the current job input. If left empty key will be used. */
  as?: Maybe<Scalars['String']>;
  /** Determines the dependency. Can be the key of some initial data, a job id or a queue name. */
  key: Scalars['String'];
  /** A JSONPath query can be applied to the data from key to select or rename specific fields. */
  query?: Maybe<Scalars['String']>;
};

/** Defines one dependency of a job to some other data. */
export type JobPipelineJobDependencyDefinition = {
  /** Should the data from this dependency be provided as an array? */
  array?: InputMaybe<Scalars['Boolean']>;
  /**
   * In this job input rename key to this. If not specified the job id/queue name/... from key will be used
   *
   * Set the array flag to true and use the same value in multiple dependencies to merge different keys together.
   */
  as?: InputMaybe<Scalars['String']>;
  /**
   * The key can be another job id, a queue name or a key from the initial bag. It tells the job coordinator where to get the data for this dependency from.
   *
   * A job id causes this job to be dependent on one other job. A queue name will cause this job to wait until all jobs from the same pipeline with the specified queue name have finished. A key from the initial bag just puts the data in the job input.
   */
  key: Scalars['String'];
  /** An additional JSONPath query to filter and/or transform the data from key before passing it on to this job. */
  query?: InputMaybe<Scalars['String']>;
};

/** A job execution contains the data for one job run. If a job is retried there can be multiple executions of one job. */
export type JobPipelineJobExecution = {
  __typename?: 'JobPipelineJobExecution';
  /** The attempt count for this job. */
  attempt: Scalars['Int'];
  /** Timestamp when the job was created. */
  creationDate: Scalars['DateTime'];
  /** Timestamp when the job did end. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The error that caused the job to fail */
  error?: Maybe<Scalars['JSON']>;
  /** Identifier for the job execution. */
  id: Scalars['ID'];
  /** The log messages from this job execution */
  log: JobPipelineJobExecutionLogQueryResult;
  /**
   * The first 300 lines of log output from this job
   *
   * Deprecated. Use "log" to query and filter logs instead.
   * @deprecated Use 'log' to query and filter logs instead.
   */
  logs?: Maybe<Array<JobPipelineJobExecutionLog>>;
  /** True if the retry of this execution was blocked by the job consumer. */
  retryBlocked?: Maybe<Scalars['Boolean']>;
  /** Timestamp when the job was started. */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The delay in ms this execution has to wait before it will be started. */
  startDelay?: Maybe<Scalars['NonNegativeInt']>;
  /** The status of the job. */
  status: JobPipelineJobExecutionStatus;
};


/** A job execution contains the data for one job run. If a job is retried there can be multiple executions of one job. */
export type JobPipelineJobExecutionLogArgs = {
  filter?: InputMaybe<JobPipelineJobExecutionLogFilter>;
};

/** A log entry from a job */
export type JobPipelineJobExecutionLog = {
  __typename?: 'JobPipelineJobExecutionLog';
  /** ID of the log entry. */
  id: Scalars['ID'];
  /**
   * The log level of the log message in numerical form.
   *
   * The higher the number the more severe the log level. Available levels:
   * 0: trace
   * 1: debug
   * 2: info
   * 3: warn
   * 4: error
   * 5: fatal
   */
  level: Scalars['Int'];
  /**
   * The log level in textual form
   *
   * Deprecated. Use "levelDescription" instead.
   * @deprecated Field no longer supported
   */
  levelDesc: Scalars['String'];
  /** The textual log level of the log entry. */
  levelDescription: LogLevelDescription;
  /** The log message. Could contain a stacktrace. */
  message: Array<Scalars['String']>;
  /** The log timestamp wh the log was written. */
  timestamp: Scalars['DateTime'];
};

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export type JobPipelineJobExecutionLogEdge = {
  __typename?: 'JobPipelineJobExecutionLogEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** The results job log */
  node: JobPipelineJobExecutionLog;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export type JobPipelineJobExecutionLogEqualsFilter = {
  /**
   * Filter by log level being equal to the specified value.
   *
   * For a list of possible values see the `level` field of the `JobPipelineJobExecutionLog` type.
   * Available levels depend on the log level set by the pipeline creator or set in the job consumer.
   */
  level?: InputMaybe<Scalars['Long']>;
  /** Filter by the log message containing the exact specified term. */
  message?: InputMaybe<Scalars['String']>;
  /** Filter by log timestamp. */
  timestamp?: InputMaybe<Scalars['DateTime']>;
};

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export type JobPipelineJobExecutionLogFilter = {
  /** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
  all?: InputMaybe<Scalars['Boolean']>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<JobPipelineJobExecutionLogFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
  equals?: InputMaybe<JobPipelineJobExecutionLogEqualsFilter>;
  /** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
  fullText?: InputMaybe<JobPipelineJobExecutionLogFullTextFilter>;
  /** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
  ids?: InputMaybe<Array<Scalars['ID']>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<JobPipelineJobExecutionLogFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<JobPipelineJobExecutionLogFilter>>>;
  /** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
  range?: InputMaybe<JobPipelineJobExecutionLogRangeFilter>;
};

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export type JobPipelineJobExecutionLogFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: InputMaybe<Scalars['Float']>;
  /** Match the query against the log message and rank the results with the specified boost */
  message?: InputMaybe<Scalars['Float']>;
};

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export type JobPipelineJobExecutionLogFullTextFilter = {
  /** Fields to search in with their respective score weights */
  fields?: InputMaybe<JobPipelineJobExecutionLogFullTextFields>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: InputMaybe<Scalars['Boolean']>;
  /** Specifies if either all or any query words need to match */
  operator?: InputMaybe<FullTextOperator>;
  /**
   * The text to search for.
   * Be aware: The Limit for full text search queries is 1024 characters and
   * anything above that will result in an error.
   */
  query: Scalars['FullTextSearchQuery'];
  /** Type of fulltext search to perform */
  searchType?: InputMaybe<FullTextSearchType>;
};

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export type JobPipelineJobExecutionLogQueryResult = {
  __typename?: 'JobPipelineJobExecutionLogQueryResult';
  /** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
  edges: Array<Maybe<JobPipelineJobExecutionLogEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export type JobPipelineJobExecutionLogQueryResultEdgesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<JobPipelineJobExecutionLogSort>>>;
};


/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export type JobPipelineJobExecutionLogQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export type JobPipelineJobExecutionLogRangeFilter = {
  /**
   * Filter by log level being within the specified range.
   *
   * For a list of possible values see the `level` field of the `JobPipelineJobExecutionLog` type.
   * Available levels depend on the log level set by the pipeline creator or set in the job consumer.
   */
  level?: InputMaybe<LongRange>;
  /** Filter by log timestamp being within the specified range. */
  timestamp?: InputMaybe<DateRange>;
};

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export type JobPipelineJobExecutionLogSort = {
  /** Sort field */
  field: JobPipelineJobExecutionLogSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum JobPipelineJobExecutionLogSortFields {
  /** Sort by unique ID */
  id = 'id',
  /** Sort by log level */
  level = 'level',
  /** Sort by relevance score */
  score = 'score',
  /** Sort by timestamp */
  timestamp = 'timestamp'
}

/** The status of a job. */
export enum JobPipelineJobExecutionStatus {
  /** The job is processing. */
  active = 'active',
  /** The job finished successfully. Its output can be found in the pipeline bag. */
  completed = 'completed',
  /** The job is delayed, either by choice (e.g. startDelay) or by external factors (e.g. rate limiting). */
  delayed = 'delayed',
  /** The job failed. More information can be found in the job error and the job logs. */
  failed = 'failed',
  /** The job is new and known to the job system, but was not added to any queue yet. */
  new = 'new',
  /** The job was added to a queue. */
  queued = 'queued',
  /** The job finished with warnings. See the job log for more info. */
  warning = 'warning'
}

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export type JobPipelineQueryResult = {
  __typename?: 'JobPipelineQueryResult';
  /**
   * The query's matching pipelines.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  jobPipelines: Array<Maybe<JobPipelineEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export type JobPipelineQueryResultJobPipelinesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<JobPipelineSort>>>;
};


/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export type JobPipelineQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export type JobPipelineRangeFilter = {
  /** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
  creationDate?: InputMaybe<DateRange>;
  /** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
  endDate?: InputMaybe<DateRange>;
  /** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
  startDate?: InputMaybe<DateRange>;
};

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export type JobPipelineSort = {
  /** Sort field */
  field: JobPipelineSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum JobPipelineSortFields {
  /** Sort by pipeline creation date */
  creationDate = 'creationDate',
  /** Sort by pipeline end date */
  endDate = 'endDate',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by relevance score */
  score = 'score',
  /** Sort by pipeline start date */
  startDate = 'startDate'
}

/** The status of a pipeline */
export enum JobPipelineStatus {
  /** The pipeline has started, at least one job execution is queued or executing. */
  active = 'active',
  /** All jobs from the pipeline completed successfully. */
  completed = 'completed',
  /** The pipeline failed because at least one job has failed. */
  failed = 'failed',
  /** The pipeline is new, none of the job executions has been queued yet. */
  new = 'new',
  /** The pipeline finished with warnings. See job logs for more info. */
  warning = 'warning'
}

/**
 * Defines a keyword datafield.
 * A keyword datafield is a string field which can be used for filtering by exact values or regex matches.
 */
export type KeywordDatafieldDefinition = DatafieldDefinition & {
  __typename?: 'KeywordDatafieldDefinition';
  /** Type of this indexed field definition */
  fieldType?: Maybe<FieldDefinitionTypes>;
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Text from the datafield definition for use e.g. in detail views, etc */
  localizations?: Maybe<DatafieldDefinitionLocalizations>;
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames?: Maybe<Array<Scalars['String']>>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: Maybe<Array<Maybe<ObjectType>>>;
  /** Length limited text from the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldDefinitionTeasers>;
  /** Human readable description of this indexed field definition */
  title?: Maybe<MultilingualString>;
};

/**
 * Data to update a keyword datafield.
 * A keyword datafield is a string field which can be used for filtering by exact values or regex matches.
 * All fields except `id` are optional. Only provided fields will be updated.
 * If provided, they will replace the current value.
 */
export type KeywordDatafieldDefinitionDiff = {
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames?: InputMaybe<Array<Scalars['String']>>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: InputMaybe<Array<InputMaybe<ObjectType>>>;
  /** Human readable description of this indexed field definition */
  title?: InputMaybe<MultilingualStringInput>;
};

/**
 * Data to create a keyword datafield.
 * A keyword datafield is a string field which can be used for filtering by exact values or regex matches.
 */
export type KeywordDatafieldDefinitionSpec = {
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames: Array<Scalars['String']>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: InputMaybe<Array<InputMaybe<ObjectType>>>;
  /** Human readable description of this indexed field definition */
  title: MultilingualStringInput;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type KeywordFacetDefinition = FacetDefinition & {
  __typename?: 'KeywordFacetDefinition';
  /** The datafield type of the field */
  fieldType: FieldDefinitionTypes;
};

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum Language {
  ar = 'ar',
  bg = 'bg',
  bn = 'bn',
  bs = 'bs',
  ca = 'ca',
  cs = 'cs',
  da = 'da',
  de = 'de',
  el = 'el',
  en = 'en',
  es = 'es',
  et = 'et',
  eu = 'eu',
  fa = 'fa',
  fi = 'fi',
  fr = 'fr',
  ga = 'ga',
  he = 'he',
  hi = 'hi',
  hr = 'hr',
  hu = 'hu',
  hy = 'hy',
  id = 'id',
  is = 'is',
  it = 'it',
  ja = 'ja',
  ko = 'ko',
  ku = 'ku',
  lt = 'lt',
  lv = 'lv',
  ms = 'ms',
  mt = 'mt',
  nb = 'nb',
  nl = 'nl',
  no = 'no',
  pb = 'pb',
  pl = 'pl',
  pt = 'pt',
  ro = 'ro',
  ru = 'ru',
  sk = 'sk',
  sl = 'sl',
  sq = 'sq',
  sr = 'sr',
  sv = 'sv',
  th = 'th',
  tr = 'tr',
  uk = 'uk',
  vi = 'vi',
  zh = 'zh'
}

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum LanguageWithWildcard {
  any = 'any',
  ar = 'ar',
  bg = 'bg',
  bn = 'bn',
  bs = 'bs',
  ca = 'ca',
  cs = 'cs',
  da = 'da',
  de = 'de',
  el = 'el',
  en = 'en',
  es = 'es',
  et = 'et',
  eu = 'eu',
  fa = 'fa',
  fi = 'fi',
  fr = 'fr',
  ga = 'ga',
  he = 'he',
  hi = 'hi',
  hr = 'hr',
  hu = 'hu',
  hy = 'hy',
  id = 'id',
  is = 'is',
  it = 'it',
  ja = 'ja',
  ko = 'ko',
  ku = 'ku',
  lt = 'lt',
  lv = 'lv',
  ms = 'ms',
  mt = 'mt',
  nb = 'nb',
  nl = 'nl',
  no = 'no',
  pb = 'pb',
  pl = 'pl',
  pt = 'pt',
  ro = 'ro',
  ru = 'ru',
  sk = 'sk',
  sl = 'sl',
  sq = 'sq',
  sr = 'sr',
  sv = 'sv',
  th = 'th',
  tr = 'tr',
  uk = 'uk',
  vi = 'vi',
  zh = 'zh'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type LanguagesQueryResult = {
  __typename?: 'LanguagesQueryResult';
  /** Available languages for the tenant */
  available?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The tenant´s default language */
  default?: Maybe<Scalars['String']>;
};

/**
 * License information for the InfoTwin.
 *
 * Here information about the current license used by a given tenant can be queried. This contains data about the license model as well as the license usage.
 */
export type License = {
  __typename?: 'License';
  /** The license ID */
  id: Scalars['ID'];
  /**
   * Queries monthly active users.
   *
   * Queries the usage data for the given tenant. Detailed information about single users is not available, only aggregated data is returned. Older data is automatically pruned for privacy reasons.
   *
   * To filter the data before aggregation use the filter argument.
   */
  mau?: Maybe<MauQueryResult>;
  /**
   * User limits set for the license.
   *
   * Contains the maximum monthly active users permitted by the license. Each change in the license plan adds a new entry to the list.
   */
  mauLimits: Array<LicenseMauLimit>;
};


/**
 * License information for the InfoTwin.
 *
 * Here information about the current license used by a given tenant can be queried. This contains data about the license model as well as the license usage.
 */
export type LicenseMauArgs = {
  filter?: InputMaybe<MauFilter>;
};

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type LicenseMauLimit = {
  __typename?: 'LicenseMauLimit';
  /** The maximum number of monthly active users allowed by the license. */
  limit: Scalars['NonNegativeInt'];
  /** The date from which the limit is valid. */
  validSince: LicenseMauMonth;
};

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type LicenseMauMonth = {
  __typename?: 'LicenseMauMonth';
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  month: Scalars['NonNegativeInt'];
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  year: Scalars['NonNegativeInt'];
};

/** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum LogLevelDescription {
  debug = 'debug',
  error = 'error',
  info = 'info',
  silent = 'silent',
  trace = 'trace',
  warn = 'warn'
}

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type LongInclusiveRange = {
  __typename?: 'LongInclusiveRange';
  /** Greater than or equal to */
  gte?: Maybe<Scalars['Float']>;
  /** Less than or equal to */
  lte?: Maybe<Scalars['Float']>;
};

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type LongInclusiveRangeSpec = {
  /** Greater than or equal to */
  gte?: InputMaybe<Scalars['Float']>;
  /** Less than or equal to */
  lte?: InputMaybe<Scalars['Float']>;
};

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type LongRange = {
  /** Greater than */
  gt?: InputMaybe<Scalars['Float']>;
  /** Greater than or equal to */
  gte?: InputMaybe<Scalars['Float']>;
  /** Less than */
  lt?: InputMaybe<Scalars['Float']>;
  /** Less than or equal to */
  lte?: InputMaybe<Scalars['Float']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MappingOption = {
  __typename?: 'MappingOption';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  type?: Maybe<ProcessMappingType>;
  /** Useful only for mapping type `FILENAME_UP_TO_CHAR`. One character expected; more ignored */
  upToChar?: Maybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MappingOptionSpec = {
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  type?: InputMaybe<ProcessMappingType>;
  /** Useful only for mapping type `FILENAME_UP_TO_CHAR`. One character expected; more ignored */
  upToChar?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MauAggregations = {
  __typename?: 'MauAggregations';
  /** Sums up the total count of monthly active users by year and month. */
  mauPerMonth?: Maybe<Array<MauPerMonthAggregation>>;
};

/** Available fields to filter mau by. */
export type MauEqualsFilter = {
  /** Filter mau by month */
  month?: InputMaybe<Scalars['NonNegativeInt']>;
  /** Filter mau by year */
  year?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** A mau filter definition used to create a query. */
export type MauFilter = {
  /** Match all entries. */
  all?: InputMaybe<Scalars['Boolean']>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<MauFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Match entries with values that equal the given value. */
  equals?: InputMaybe<MauEqualsFilter>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<MauFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<MauFilter>>>;
  /** Match entries with values that are in a given filter range. */
  range?: InputMaybe<MauRangeFilter>;
};

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MauPerMonthAggregation = {
  __typename?: 'MauPerMonthAggregation';
  /** The total count of monthly active users that used the InfoTwin in this month. */
  count: Scalars['NonNegativeInt'];
  /** The month. */
  month: Scalars['NonNegativeInt'];
  /** The year. */
  year: Scalars['NonNegativeInt'];
};

/** The result of a mau query. */
export type MauQueryResult = {
  __typename?: 'MauQueryResult';
  /** Returns aggregated data for monthly active users. */
  aggregations: MauAggregations;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** The result of a mau query. */
export type MauQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Available fields to filter mau by. */
export type MauRangeFilter = {
  /** Filter mau by month */
  month?: InputMaybe<LongRange>;
  /** Filter mau by year */
  year?: InputMaybe<LongRange>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Mechanic = {
  __typename?: 'Mechanic';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  articleId?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  articleNumber?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  assemblyId?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  files?: Maybe<Array<File>>;
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  isAssembly?: Maybe<Scalars['Boolean']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  itemNumber?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  localizations?: Maybe<MechanicLocalizations>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  option?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  parentAssemblyId?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  pathsToRoot?: Maybe<Array<Maybe<MechanicPath>>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  previewImage?: Maybe<BlobRef>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  product?: Maybe<Product>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  productionDateRange?: Maybe<DateInclusiveRange>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  quantity?: Maybe<Scalars['NonNegativeFloat']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  reference2d?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  reference3d?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  referenceId?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  serialNumberRange?: Maybe<LongInclusiveRange>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  teasers?: Maybe<MechanicTeasers>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  title?: Maybe<MultilingualString>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  unit?: Maybe<Scalars['String']>;
  /** Mechanics used in these assets */
  usedInAssets?: Maybe<UsedInAssets>;
};


/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicFilesArgs = {
  fileTypes?: InputMaybe<Array<InputMaybe<FileType>>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicAutocompleteHits = {
  __typename?: 'MechanicAutocompleteHits';
  /** The count of docs found containing the suggestion */
  docCount: Scalars['NonNegativeInt'];
  /** The language this suggestion stems from */
  language?: Maybe<Language>;
  /** The found suggestion */
  suggest: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicAutocompleteResult = {
  __typename?: 'MechanicAutocompleteResult';
  /** The hits found for the query */
  hits?: Maybe<Array<MechanicAutocompleteHits>>;
  /** The query used for this result */
  query: Scalars['String'];
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicCollectionEntryNode = CollectionEntryNode & {
  __typename?: 'MechanicCollectionEntryNode';
  /** The mechanic object */
  mechanic: Mechanic;
  /** The result's collection object context. */
  navContext?: Maybe<CollectionEntryMechanicNavigationContext>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicDatafieldAggregations = {
  __typename?: 'MechanicDatafieldAggregations';
  /** Provides composite aggregations with pagination support */
  composite?: Maybe<MechanicDatafieldCompositeAggregation>;
  /**
   * Provides terms aggregations for custom datafields.
   * Supports prefix search and the results are ordered by count.
   */
  terms?: Maybe<DatafieldTermsAggregation>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicDatafieldCompositeAggregation = {
  __typename?: 'MechanicDatafieldCompositeAggregation';
  /** No field definitions exist. As soon as the first field definition is defined, it's automatically listed here. */
  not_applicable_as_no_field_definitions_exist?: Maybe<Scalars['NoDynamicValueDefined']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  productId?: Maybe<Array<AggregationEdge>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicDatafieldCompositeAggregationProductIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicEdge = {
  __typename?: 'MechanicEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** The result's mechanic. */
  node: Mechanic;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicEqualsValueFilter = {
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  articleId?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  articleNumber?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  assemblyId?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  isAssembly?: InputMaybe<Scalars['Boolean']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  itemNumber?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  parentAssemblyId?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified object type (product type) */
  product?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  productionDateRange?: InputMaybe<Scalars['DateTime']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  reference3d?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  serialNumberRange?: InputMaybe<Scalars['Float']>;
  /**
   * Returns only mechanics with the specified title
   * @deprecated Use two title_xx fields for InfoTwin language and tenant default language instead
   */
  title?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified ar title */
  title_ar?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified bg title */
  title_bg?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified bn title */
  title_bn?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified bs title */
  title_bs?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified ca title */
  title_ca?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified cs title */
  title_cs?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified da title */
  title_da?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified de title */
  title_de?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified el title */
  title_el?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified en title */
  title_en?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified es title */
  title_es?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified et title */
  title_et?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified eu title */
  title_eu?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified fa title */
  title_fa?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified fi title */
  title_fi?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified fr title */
  title_fr?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified ga title */
  title_ga?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified he title */
  title_he?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified hi title */
  title_hi?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified hr title */
  title_hr?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified hu title */
  title_hu?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified hy title */
  title_hy?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified id title */
  title_id?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified is title */
  title_is?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified it title */
  title_it?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified ja title */
  title_ja?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified ko title */
  title_ko?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified ku title */
  title_ku?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified lt title */
  title_lt?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified lv title */
  title_lv?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified ms title */
  title_ms?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified mt title */
  title_mt?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified nb title */
  title_nb?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified nl title */
  title_nl?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified no title */
  title_no?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified pb title */
  title_pb?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified pl title */
  title_pl?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified pt title */
  title_pt?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified ro title */
  title_ro?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified ru title */
  title_ru?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified sk title */
  title_sk?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified sl title */
  title_sl?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified sq title */
  title_sq?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified sr title */
  title_sr?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified sv title */
  title_sv?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified th title */
  title_th?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified tr title */
  title_tr?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified uk title */
  title_uk?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified vi title */
  title_vi?: InputMaybe<Scalars['String']>;
  /** Returns only mechanics with the specified zh title */
  title_zh?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum MechanicExistsValueFields {
  /** Returns only mechanics which have an article id specified */
  articleId = 'articleId',
  articleNumber = 'articleNumber',
  /** Returns only mechanics which have an item number specified */
  itemNumber = 'itemNumber',
  /** Returns only mechanics which have a product specified */
  product = 'product',
  /** Returns only mechanics which have a date (range) specified */
  productionDateRange = 'productionDateRange',
  reference3d = 'reference3d',
  /** Returns only mechanics which have a serial number (range) specified */
  serialNumberRange = 'serialNumberRange',
  /** Returns only mechanics which have a text specified */
  text = 'text',
  /** Returns only mechanics which have a title specified */
  title = 'title'
}

/**
 * The `MechanicFilter` is used to specify filter criteria for mechanics.
 * If you specify more than one criterion, all criteria are grouped together and connected with a logical AND operator.
 * Further logical operations are possible by using `not` `andGroup` and `orGroup`.
 */
export type MechanicFilter = {
  /** Returns all mechanics if set to true */
  all?: InputMaybe<Scalars['Boolean']>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<MechanicFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns mechanics that contain all of the exact values specified */
  equals?: InputMaybe<MechanicEqualsValueFilter>;
  /**
   * Returns mechanics that have a value in all of the specified fields
   * @deprecated Please use 'has' filter instead
   */
  exists?: InputMaybe<Array<InputMaybe<MechanicExistsValueFields>>>;
  /** Returns mechanics that match all of the specified full text searches */
  fullText?: InputMaybe<MechanicFullTextFilter>;
  /** Returns mechanics that have a value in all of the specified fields */
  has?: InputMaybe<MechanicHasValueFilter>;
  /** Returns mechanics with one of the specified unique identifiers */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<MechanicFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<MechanicFilter>>>;
  /** Returns mechanics with values within all of the ranges specified */
  range?: InputMaybe<MechanicRangeFilter>;
  /** Returns mechanics that match all of the regular expressions */
  regex?: InputMaybe<MechanicRegexFilter>;
  /** Returns mechanics that match a taxonomy value according to the taxonomy's evaluation type */
  taxonomy?: InputMaybe<MechanicTaxonomyFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: InputMaybe<Scalars['Float']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  articleId?: InputMaybe<Scalars['Float']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  articleNumber?: InputMaybe<Scalars['Float']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  itemNumber?: InputMaybe<Scalars['Float']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  product?: InputMaybe<Scalars['Float']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  reference3d?: InputMaybe<Scalars['Float']>;
  /**
   * Match the query against the title and rank the results with the specified boost
   * @deprecated Use two title_xx fields for InfoTwin language and tenant default language instead
   */
  text?: InputMaybe<Scalars['Float']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  title?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ar?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_bg?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_bn?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_bs?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ca?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_cs?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_da?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_de?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_el?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_en?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_es?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_et?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_eu?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_fa?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_fi?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_fr?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ga?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_he?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_hi?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_hr?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_hu?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_hy?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_id?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_is?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_it?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ja?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ko?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ku?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_lt?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_lv?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ms?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_mt?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_nb?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_nl?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_no?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_pb?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_pl?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_pt?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ro?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ru?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sk?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sl?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sq?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sr?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sv?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_th?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_tr?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_uk?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_vi?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_zh?: InputMaybe<Scalars['Float']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicFullTextFilter = {
  /** Fields to search in with their respective score weights */
  fields?: InputMaybe<MechanicFullTextFields>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: InputMaybe<Scalars['Boolean']>;
  /** Specifies if either all or any query words need to match */
  operator?: InputMaybe<FullTextOperator>;
  /** Text to search for */
  query: Scalars['FullTextSearchQuery'];
  /** Type of fulltext search to perform */
  searchType?: InputMaybe<FullTextSearchType>;
};

/** Returns mechanics that have a value in all of the specified fields */
export type MechanicHasValueFilter = {
  /** Specify the fields that the returned mechanics must have a value in */
  valueInFields?: InputMaybe<Array<InputMaybe<MechanicHasValueInFields>>>;
};

/** Specify the fields that the returned mechanics must have a value in */
export enum MechanicHasValueInFields {
  /** Returns only mechanics which have an article id specified */
  articleId = 'articleId',
  articleNumber = 'articleNumber',
  /** Returns only mechanics which have an item number specified */
  itemNumber = 'itemNumber',
  /** Returns only mechanics which have a product specified */
  product = 'product',
  /** Returns only mechanics which have a date (range) specified */
  productionDateRange = 'productionDateRange',
  reference3d = 'reference3d',
  /** Returns only mechanics which have a serial number (range) specified */
  serialNumberRange = 'serialNumberRange',
  /** Returns only mechanics which have a text specified */
  text = 'text',
  /** Returns only mechanics which have a title specified */
  title = 'title'
}

/** Text from the mechanic for use e.g. in details etc. */
export type MechanicLocalizations = {
  __typename?: 'MechanicLocalizations';
  /** The title from the part */
  title?: Maybe<Scalars['String']>;
  /** The partlist entry's unit */
  unit?: Maybe<Scalars['String']>;
};


/** Text from the mechanic for use e.g. in details etc. */
export type MechanicLocalizationsTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
};


/** Text from the mechanic for use e.g. in details etc. */
export type MechanicLocalizationsUnitArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
};

/** Array of MechanicPathEntry specifying a path through the mechanics graph between a start end a end mechanic */
export type MechanicPath = {
  __typename?: 'MechanicPath';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  path?: Maybe<Array<Maybe<MechanicPathEntry>>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicPathEntry = {
  __typename?: 'MechanicPathEntry';
  /** Returning a mechanic to obtain e.g. description will involve query costs */
  mechanic?: Maybe<Mechanic>;
  /** The mechanic id will be returned without any query costs */
  mechanicId: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicQueryResult = {
  __typename?: 'MechanicQueryResult';
  /**
   * Matched datafield values and number of matching mechanics per value
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   */
  aggregations: MechanicDatafieldAggregations;
  /**
   * The query's matching mechanics.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  mechanics: Array<Maybe<MechanicEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
  /**
   * List products used in any of the mechanics results.
   * Mechanics used in these products limited to a maximum of 10000 products.
   */
  usedInProducts?: Maybe<UsedInProducts>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicQueryResultMechanicsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<MechanicSort>>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicRangeFilter = {
  /** No number or dateTime datafield definitions exist. As soon as the first such datafield definition is defined, it's automatically listed here. */
  not_applicable_as_no_number_or_date_datafield_definitions_exist?: InputMaybe<Scalars['NoDynamicValueDefined']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicRegexFilter = {
  /** Returns only mechanics whose object type matches the specified Regex */
  articleId?: InputMaybe<Scalars['FilterRegex']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  articleNumber?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose object type matches the specified Regex */
  itemNumber?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose object type matches the specified Regex */
  product?: InputMaybe<Scalars['FilterRegex']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  reference3d?: InputMaybe<Scalars['FilterRegex']>;
  /**
   * Returns only mechanics whose title matches the specified Regex
   * @deprecated Use two title_xx fields for InfoTwin language and tenant default language instead
   */
  title?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose ar title matches the specified Regex */
  title_ar?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose bg title matches the specified Regex */
  title_bg?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose bn title matches the specified Regex */
  title_bn?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose bs title matches the specified Regex */
  title_bs?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose ca title matches the specified Regex */
  title_ca?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose cs title matches the specified Regex */
  title_cs?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose da title matches the specified Regex */
  title_da?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose de title matches the specified Regex */
  title_de?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose el title matches the specified Regex */
  title_el?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose en title matches the specified Regex */
  title_en?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose es title matches the specified Regex */
  title_es?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose et title matches the specified Regex */
  title_et?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose eu title matches the specified Regex */
  title_eu?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose fa title matches the specified Regex */
  title_fa?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose fi title matches the specified Regex */
  title_fi?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose fr title matches the specified Regex */
  title_fr?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose ga title matches the specified Regex */
  title_ga?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose he title matches the specified Regex */
  title_he?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose hi title matches the specified Regex */
  title_hi?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose hr title matches the specified Regex */
  title_hr?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose hu title matches the specified Regex */
  title_hu?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose hy title matches the specified Regex */
  title_hy?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose id title matches the specified Regex */
  title_id?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose is title matches the specified Regex */
  title_is?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose it title matches the specified Regex */
  title_it?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose ja title matches the specified Regex */
  title_ja?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose ko title matches the specified Regex */
  title_ko?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose ku title matches the specified Regex */
  title_ku?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose lt title matches the specified Regex */
  title_lt?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose lv title matches the specified Regex */
  title_lv?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose ms title matches the specified Regex */
  title_ms?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose mt title matches the specified Regex */
  title_mt?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose nb title matches the specified Regex */
  title_nb?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose nl title matches the specified Regex */
  title_nl?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose no title matches the specified Regex */
  title_no?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose pb title matches the specified Regex */
  title_pb?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose pl title matches the specified Regex */
  title_pl?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose pt title matches the specified Regex */
  title_pt?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose ro title matches the specified Regex */
  title_ro?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose ru title matches the specified Regex */
  title_ru?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose sk title matches the specified Regex */
  title_sk?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose sl title matches the specified Regex */
  title_sl?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose sq title matches the specified Regex */
  title_sq?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose sr title matches the specified Regex */
  title_sr?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose sv title matches the specified Regex */
  title_sv?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose th title matches the specified Regex */
  title_th?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose tr title matches the specified Regex */
  title_tr?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose uk title matches the specified Regex */
  title_uk?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose vi title matches the specified Regex */
  title_vi?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only mechanics whose zh title matches the specified Regex */
  title_zh?: InputMaybe<Scalars['FilterRegex']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicSort = {
  /** Sort field */
  field: MechanicSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum MechanicSortFields {
  /** Sort by article id */
  articleId = 'articleId',
  /** Sort by article number */
  articleNumber = 'articleNumber',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by item number */
  itemNumber = 'itemNumber',
  /** Sort by object type (product type) */
  product = 'product',
  reference3d = 'reference3d',
  /** Sort by relevance score */
  score = 'score',
  /**
   * Sort by title
   * @deprecated Use two title_xx fields for InfoTwin language and tenant default language instead
   */
  title = 'title',
  /** Sort by language-ar title */
  titleAr = 'title_ar',
  /** Sort by language-bg title */
  titleBg = 'title_bg',
  /** Sort by language-bn title */
  titleBn = 'title_bn',
  /** Sort by language-bs title */
  titleBs = 'title_bs',
  /** Sort by language-ca title */
  titleCa = 'title_ca',
  /** Sort by language-cs title */
  titleCs = 'title_cs',
  /** Sort by language-da title */
  titleDa = 'title_da',
  /** Sort by language-de title */
  titleDe = 'title_de',
  /** Sort by language-el title */
  titleEl = 'title_el',
  /** Sort by language-en title */
  titleEn = 'title_en',
  /** Sort by language-es title */
  titleEs = 'title_es',
  /** Sort by language-et title */
  titleEt = 'title_et',
  /** Sort by language-eu title */
  titleEu = 'title_eu',
  /** Sort by language-fa title */
  titleFa = 'title_fa',
  /** Sort by language-fi title */
  titleFi = 'title_fi',
  /** Sort by language-fr title */
  titleFr = 'title_fr',
  /** Sort by language-ga title */
  titleGa = 'title_ga',
  /** Sort by language-he title */
  titleHe = 'title_he',
  /** Sort by language-hi title */
  titleHi = 'title_hi',
  /** Sort by language-hr title */
  titleHr = 'title_hr',
  /** Sort by language-hu title */
  titleHu = 'title_hu',
  /** Sort by language-hy title */
  titleHy = 'title_hy',
  /** Sort by language-id title */
  titleId = 'title_id',
  /** Sort by language-is title */
  titleIs = 'title_is',
  /** Sort by language-it title */
  titleIt = 'title_it',
  /** Sort by language-ja title */
  titleJa = 'title_ja',
  /** Sort by language-ko title */
  titleKo = 'title_ko',
  /** Sort by language-ku title */
  titleKu = 'title_ku',
  /** Sort by language-lt title */
  titleLt = 'title_lt',
  /** Sort by language-lv title */
  titleLv = 'title_lv',
  /** Sort by language-ms title */
  titleMs = 'title_ms',
  /** Sort by language-mt title */
  titleMt = 'title_mt',
  /** Sort by language-nb title */
  titleNb = 'title_nb',
  /** Sort by language-nl title */
  titleNl = 'title_nl',
  /** Sort by language-no title */
  titleNo = 'title_no',
  /** Sort by language-pb title */
  titlePb = 'title_pb',
  /** Sort by language-pl title */
  titlePl = 'title_pl',
  /** Sort by language-pt title */
  titlePt = 'title_pt',
  /** Sort by language-ro title */
  titleRo = 'title_ro',
  /** Sort by language-ru title */
  titleRu = 'title_ru',
  /** Sort by language-sk title */
  titleSk = 'title_sk',
  /** Sort by language-sl title */
  titleSl = 'title_sl',
  /** Sort by language-sq title */
  titleSq = 'title_sq',
  /** Sort by language-sr title */
  titleSr = 'title_sr',
  /** Sort by language-sv title */
  titleSv = 'title_sv',
  /** Sort by language-th title */
  titleTh = 'title_th',
  /** Sort by language-tr title */
  titleTr = 'title_tr',
  /** Sort by language-uk title */
  titleUk = 'title_uk',
  /** Sort by language-vi title */
  titleVi = 'title_vi',
  /** Sort by language-zh title */
  titleZh = 'title_zh'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicSpec = {
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  articleId?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  assemblyId: Scalars['String'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  option?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  parentAssemblyId: Scalars['String'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  product?: InputMaybe<ProductSpec>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  productionDateRange?: InputMaybe<DateInclusiveRangeSpec>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  quantity?: InputMaybe<Scalars['Int']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  rawCustomFields?: InputMaybe<Array<RawDatafieldSpec>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  serialNumberRange?: InputMaybe<LongInclusiveRangeSpec>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  subAssemblies?: InputMaybe<Array<InputMaybe<MechanicSpec>>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  unit?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MechanicTaxonomyFilter = {
  /** No taxonomy datafield definitions exist. As soon as the first taxonomy is defined, it's automatically listed here. */
  not_applicable_as_no_taxonomy_datafield_definitions_exist?: InputMaybe<Scalars['NoDynamicValueDefined']>;
};

/** Length limited text from the mechanic for use e.g. in result lists, previews, etc. */
export type MechanicTeasers = {
  __typename?: 'MechanicTeasers';
  /** The title limited to `length` characters */
  title?: Maybe<Scalars['String']>;
  /** The partlist entry's unit limited to `length` characters */
  unit?: Maybe<Scalars['String']>;
};


/** Length limited text from the mechanic for use e.g. in result lists, previews, etc. */
export type MechanicTeasersTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Length limited text from the mechanic for use e.g. in result lists, previews, etc. */
export type MechanicTeasersUnitArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MultilingualDatafieldMapping = ProcessDatafieldMapping & {
  __typename?: 'MultilingualDatafieldMapping';
  /** Returns column(s) which also map to the same field. In this case a process cannot be created due to ambigiuousness. */
  ambiguousColumns?: Maybe<Array<Scalars['String']>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  column: Scalars['String'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  field?: Maybe<Datafield>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  language: Language;
};

/** A string in a single or multiple of the available languages */
export type MultilingualString = {
  __typename?: 'MultilingualString';
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  ar?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  bg?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  bn?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  bs?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  ca?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  cs?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  da?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  de?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  el?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  en?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  es?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  et?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  eu?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  fa?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  fi?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  fr?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  ga?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  he?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  hi?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  hr?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  hu?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  hy?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  id?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  is?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  it?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  ja?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  ko?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  ku?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  lt?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  lv?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  ms?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  mt?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  nb?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  nl?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  no?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  pb?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  pl?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  pt?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  ro?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  ru?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  sk?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  sl?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  sq?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  sr?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  sv?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  th?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  tr?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  uk?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  vi?: Maybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  zh?: Maybe<Scalars['String']>;
};

/** A string in a single or multiple of the available languages */
export type MultilingualStringInput = {
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  ar?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  bg?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  bn?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  bs?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  ca?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  cs?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  da?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  de?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  el?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  en?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  es?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  et?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  eu?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  fa?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  fi?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  fr?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  ga?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  he?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  hi?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  hr?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  hu?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  hy?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  id?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  is?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  it?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  ja?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  ko?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  ku?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  lt?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  lv?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  ms?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  mt?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  nb?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  nl?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  no?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  pb?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  pl?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  pt?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  ro?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  ru?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  sk?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  sl?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  sq?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  sr?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  sv?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  th?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  tr?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  uk?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  vi?: InputMaybe<Scalars['String']>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  zh?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Add activities to a collection.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write**
   *
   * See below for additional permission required to add contents to a collection with visibility 'public'.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write.public**
   */
  addActivitiesToCollection?: Maybe<Scalars['Boolean']>;
  /**
   * Add articles to a collection.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write**
   *
   * See below for additional permission required to add contents to a collection with visibility 'public'.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write.public**
   */
  addArticlesToCollection?: Maybe<Scalars['Boolean']>;
  /**
   * Add contents to a collection.
   * `idsOfContents` takes a list of ids of content-like objects (e.g. `Topic` and `ContentMap`).
   * Please take care to use the objects' `id`s and not the objects' `contentId`s.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write**
   *
   * See below for additional permission required to add contents to a collection with visibility 'public'.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write.public**
   */
  addContentsToCollection?: Maybe<Scalars['Boolean']>;
  /**
   * Add jobs to a existing pipeline.
   *
   * The pipeline does not have to be in a specific state. The same rules as in "createPipeline" apply here
   * too.
   */
  addJobPipelineJobs: JobPipeline;
  /**
   * Add mechanic articles to a collection.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write**
   *
   * See below for additional permission required to add contents to a collection with visibility 'public'.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write.public**
   */
  addMechanicsToCollection?: Maybe<Scalars['Boolean']>;
  /**
   * Create a new access filter
   *
   * Required permission\
   * Resource: **access-filters**\
   * Action: **write**
   */
  createAccessFilter: Scalars['ID'];
  /**
   * Creates the anonymous user.
   *
   * Required permission\
   * Resource: **users**\
   * Action: **write**
   */
  createAnonymousUser?: Maybe<Scalars['ID']>;
  /**
   * Create an api key for the user
   *
   * Required permission\
   * Resource: **users.api-keys**\
   * Action: **write**
   */
  createApiKey: CreateApiKeyResponse;
  /**
   * Create or replace an app key for the current user.
   * An app key is a special kind of api key which allows the companion app to access the info twin api.
   * Each user can only create his/her own app key and can only have one app key at any time.
   * The app key expires after 1 year.
   *
   * Required permission\
   * Resource: **user.app-key**\
   * Action: **write**
   */
  createAppKey: CreateApiKeyResponse;
  /**
   * Create a new collection.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write**
   *
   * See below for additional permission required to be able to create collections with the visibility set to 'public'.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write.public**
   */
  createCollection: Scalars['ID'];
  /**
   * Create a connector key for the user.
   * A connector key is a special kind of api key which is limited to uploading contents and
   * syncing notes.
   *
   * Required permission\
   * Resource: **users.api-keys**\
   * Action: **write**
   */
  createConnectorKey: CreateApiKeyResponse;
  /**
   * Creates a date time indexed field definition.
   *
   * Required permission\
   * Resource: **datafield-definitions**\
   * Action: **write**
   */
  createDateTimeDatafieldDefinition: Scalars['DatafieldDefinitionID'];
  /**
   * Creates a enum indexed field definition.
   *
   * Required permission\
   * Resource: **datafield-definitions**\
   * Action: **write**
   */
  createEnumDatafieldDefinition: Scalars['DatafieldDefinitionID'];
  /**
   * Creates or updates the existing facets collection.
   * Currently only one facets collection is available.
   * This might change in the future.
   *
   * Required permission\
   * Resource: **facet-collections**\
   * Action: **write**
   */
  createFacetsCollection: Scalars['ID'];
  /**
   * Create a new pipeline
   *
   * This mutation creates a new pipeline, which is started immediately. The progress can be watched
   * using the pipelines query.
   *
   * The jobs in the pipeline must not create a dependency loop and there must be at least one job to start
   * the pipeline. If the new pipeline cannot be started it will be rejected. All jobs in the pipeline need
   * unique ids.
   *
   * To delay the start of a pipeline use the startDelay in the starting jobs.
   */
  createJobPipeline: JobPipeline;
  /**
   * Creates a keyword indexed field definition.
   *
   * Required permission\
   * Resource: **datafield-definitions**\
   * Action: **write**
   */
  createKeywordDatafieldDefinition: Scalars['DatafieldDefinitionID'];
  /**
   * Create a new note.
   *
   * Required permission\
   * Resource: **notes**\
   * Action: **write**
   *
   * See below for additional permission required to be able to create notes with the visibility status set to 'public'.
   * If the invoking user doesn't have the permission below and creates a note with the visibility 'public', the note
   * will be on status 'pendingApproval' (see `VisibilityTypes`).
   *
   * Required permission\
   * Resource: **notes**\
   * Action: **write.public**
   */
  createNote: Scalars['ID'];
  /**
   * Create new comment for a note.
   *
   * Required permission\
   * Resource: **notes.comments**\
   * Action: **write**
   */
  createNoteComment: Scalars['ID'];
  /**
   * Creates a numeric indexed field definition.
   *
   * Required permission\
   * Resource: **datafield-definitions**\
   * Action: **write**
   */
  createNumberDatafieldDefinition: Scalars['DatafieldDefinitionID'];
  /** creates or updates new config3d document for hoops scs files */
  createOrUpdateConfig3dHoops?: Maybe<Scalars['Boolean']>;
  /** creates or updates new config3d document for lattice xvl files */
  createOrUpdateConfig3dLattice?: Maybe<Scalars['Boolean']>;
  /** creates or updates new configFdiShop document */
  createOrUpdateConfigFdiShop?: Maybe<Scalars['Boolean']>;
  /** creates or updates new configProductAndAsset document */
  createOrUpdateConfigProductAndAsset?: Maybe<Scalars['Boolean']>;
  /**
   * Create or update the existing data displays configuration.
   *
   * Required permission\
   * Resource: **data-display-configs**\
   * Action: **write**
   */
  createOrUpdateDataDisplayConfig: Scalars['ID'];
  /**
   * Create or update the existing search configuration.
   *
   * Required permission\
   * Resource: **search-configs**\
   * Action: **write**
   */
  createOrUpdateSearchConfig: Scalars['ID'];
  /**
   * Create or update the static page menu.
   *
   * Required permission\
   * Resource: **static-pages**\
   * Action: **write**
   */
  createOrUpdateStaticPageMenu: Scalars['String'];
  /**
   * Create or update user settings.
   *
   * Required permission\
   * Resource: **users-settings**\
   * Action: **write**
   */
  createOrUpdateUserSettings: UserSettings;
  /**
   * Creates a new import process.
   *
   * Returns the id of the created process.
   *
   * Required permission\
   * Resource: **processtypes**\
   * Action: **write**
   */
  createProcess?: Maybe<Scalars['String']>;
  /**
   * Create a new static page.
   *
   * Required permission\
   * Resource: **static-pages**\
   * Action: **write**
   */
  createStaticPage: Scalars['String'];
  /**
   * Creates a taxonomy indexed field definition.
   *
   * Required permission\
   * Resource: **datafield-definitions**\
   * Action: **write**
   */
  createTaxonomyDatafieldDefinition: Scalars['DatafieldDefinitionID'];
  /**
   * Creates a text indexed field definition.
   *
   * Required permission\
   * Resource: **datafield-definitions**\
   * Action: **write**
   */
  createTextDatafieldDefinition: Scalars['DatafieldDefinitionID'];
  /**
   * Creates a new user.
   *
   * Required permission\
   * Resource: **users**\
   * Action: **write**
   */
  createUser?: Maybe<Scalars['ID']>;
  /**
   * Creates a new webhook config.
   *
   * Required permission\
   * Resource: **webhook-configs**\
   * Action: **write**
   */
  createWebhookConfig: Scalars['ID'];
  /**
   * Delete an existing access filter by it's unique id
   *
   * Required permission\
   * Resource: **access-filters**\
   * Action: **delete**
   */
  deleteAccessFilter: Scalars['ID'];
  /**
   * Delete existing access filters by ids
   *
   * Required permission\
   * Resource: **access-filters**\
   * Action: **delete**
   */
  deleteAccessFilters?: Maybe<Scalars['Boolean']>;
  /**
   * The activities with the given IDs will be deleted including the activities options.
   *
   * Required permission\
   * Resource: **activities**\
   * Action: **delete**
   */
  deleteActivities?: Maybe<Scalars['Boolean']>;
  /**
   * All activities will be deleted and all assets and all mechanics.
   *
   * Required permission\
   * Resource: **activities**\
   * Action: **delete**
   */
  deleteAllActivities?: Maybe<Scalars['Boolean']>;
  /**
   * All assets including the asset options will be deleted.
   *
   * Required permission\
   * Resource: **assets**\
   * Action: **delete**
   */
  deleteAllAssets?: Maybe<Scalars['Boolean']>;
  /**
   * Deletes all contents irrevocably.
   *
   * Required permission\
   * Resource: **contents**\
   * Action: **delete**
   */
  deleteAllContents?: Maybe<Scalars['Boolean']>;
  /**
   * Deletes all (custom) datafield definitions
   *
   * Required permission\
   * Resource: **datafield-definition**\
   * Action: **delete**
   */
  deleteAllDatafieldDefinitions: Scalars['Boolean'];
  /**
   * Deletes all files.
   *
   * Required permission\
   * Resource: **files**\
   * Action: **delete**
   */
  deleteAllFiles?: Maybe<Scalars['Boolean']>;
  /**
   * All mechanics will be delete.
   *
   * Required permission\
   * Resource: **mechanics**\
   * Action: **delete**
   */
  deleteAllMechanics?: Maybe<Scalars['Boolean']>;
  /**
   * All products will be deleted and all assets and all mechanics.
   *
   * Required permission\
   * Resource: **products**\
   * Action: **delete**
   */
  deleteAllProducts?: Maybe<Scalars['Boolean']>;
  /**
   * Deletes articles determined by ArticleFilter.
   *
   * Required permission\
   * FeatureId: **spare_parts**\
   * Resource: **articles**\
   * Action: **delete**
   */
  deleteArticles?: Maybe<Array<Maybe<Article>>>;
  /**
   * The assets with the passed IDs for the passed productID will be deleted including the asset options.
   * An empty Array will delete no assets for the given product. To delete all assets for the product do not provide the field assetIds
   *
   * Required permission\
   * Resource: **assets**\
   * Action: **delete**
   */
  deleteAssets?: Maybe<Scalars['Boolean']>;
  /**
   * Delete an existing collection.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **delete**
   *
   * See below for additional permission required to be able to delete a collection with visibility 'public'.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **delete.public**
   */
  deleteCollection: Scalars['ID'];
  /** deletes config3d document for hoops scs */
  deleteConfig3dHoops?: Maybe<Scalars['Boolean']>;
  /** deletes config3d document for lattice xvl */
  deleteConfig3dLattice?: Maybe<Scalars['Boolean']>;
  /** deletes configFdiShop document */
  deleteConfigFdiShop?: Maybe<Scalars['Boolean']>;
  /** deletes configProductAndAsset document */
  deleteConfigProductAndAsset?: Maybe<Scalars['Boolean']>;
  /**
   * Delete an existing content map and it's associated topics by its content id.
   *
   * Required permission\
   * Resource: **contents**\
   * Action: **delete**
   */
  deleteContentMap: Scalars['ID'];
  /**
   * Delete content maps by filter.
   *
   * Required permission\
   * Resource: **contents**\
   * Action: **delete**
   */
  deleteContentMapsByFilter: Scalars['Boolean'];
  /**
   * Delete the existing data display configuration.
   *
   * Required permission\
   * Resource: **data-display-configs**\
   * Action: **delete**
   */
  deleteDataDisplayConfig: Scalars['ID'];
  /** Deletes a datafield definition */
  deleteDatafieldDefinition: Scalars['DatafieldDefinitionID'];
  /**
   * Deletes the datafield definitions by ids
   *
   * Required permission\
   * Resource: **datafield-definition**\
   * Action: **delete**
   */
  deleteDatafieldDefinitions: Scalars['Boolean'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  deleteEntities?: Maybe<Scalars['Boolean']>;
  /**
   * Deletes a facets collection by id.
   *
   * Required permission\
   * Resource: **facet-collections**\
   * Action: **delete**
   */
  deleteFacetsCollection: Scalars['ID'];
  /**
   * The files with the given IDs will be deleted.
   *
   * Required permission\
   * Resource: **files**\
   * Action: **delete**
   */
  deleteFiles?: Maybe<Scalars['Boolean']>;
  /**
   * Delete a not running pipeline
   *
   * Deletes a single not running pipeline and returns the deleted pipeline.
   */
  deleteJobPipeline?: Maybe<JobPipeline>;
  /**
   * Deletes the jobs with the given ids from the pipeline. The jobs must not be started yet or already finished.
   * Returns the updated pipeline without the deleted jobs.
   */
  deleteJobPipelineJobs?: Maybe<JobPipeline>;
  /**
   * Delete not running pipelines
   *
   * This deletes all pipelines matching the given query. The pipelines must not be running and the user calling
   * the mutation must have the required permissions to delete the pipelines.
   *
   * This mutation does not actually delete the pipelines, but creates a new pipeline that executes the deletion.
   * The created deletion-pipeline is returned and can be watched using the pipelines query.
   */
  deleteJobPipelines: JobPipeline;
  /**
   * The mechanics for the given product IDs will be deleted.
   *
   * Required permission\
   * Resource: **mechanics**\
   * Action: **delete**
   */
  deleteMechanics?: Maybe<Scalars['Boolean']>;
  /**
   * Delete an existing note.
   *
   * Required permission\
   * Resource: **notes**\
   * Action: **delete**
   */
  deleteNote: Scalars['ID'];
  /**
   * Delete multiple attachments from a note.
   *
   * Required permission\
   * Resource: **notes.attachments**\
   * Action: **delete**
   */
  deleteNoteAttachments?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /**
   * Delete an existing note comment.
   *
   * Required permission\
   * Resource: **notes.comments**\
   * Action: **delete**
   */
  deleteNoteComment: Scalars['ID'];
  /**
   * Deletes existing notes by ids.
   *
   * Required permission\
   * Resource: **notes**\
   * Action: **delete**
   */
  deleteNotes?: Maybe<Scalars['Boolean']>;
  /**
   * Deletes a process by id.
   *
   * Returns the id of the deleted process.
   *
   * Required permission\
   * Resource: **processtypes**\
   * Action: **delete**
   */
  deleteProcess?: Maybe<Scalars['String']>;
  /**
   * The products with the given IDs will be deleted including the products options.
   *
   * Required permission\
   * Resource: **products**\
   * Action: **delete**
   */
  deleteProducts?: Maybe<Scalars['Boolean']>;
  /**
   * Deletes a existing search configuration by id.
   *
   * Required permission\
   * Resource: **search-configs**\
   * Action: **delete**
   */
  deleteSearchConfig: Scalars['ID'];
  /**
   * Delete an existing static page by it's unique id
   *
   * Required permission\
   * Resource: **static-pages**\
   * Action: **delete**
   */
  deleteStaticPage: Scalars['ID'];
  /**
   * Deletes the static page menu.
   *
   * Required permission\
   * Resource: **static-pages**\
   * Action: **delete**
   */
  deleteStaticPageMenu: Scalars['String'];
  /**
   * Delete existing static pages by IDs
   *
   * Required permission\
   * Resource: **static-pages**\
   * Action: **delete**
   */
  deleteStaticPages?: Maybe<Scalars['Boolean']>;
  /**
   * Delete all existing synonym definitions.
   *
   * Required permission\
   * Resource: **synonyms**\
   * Action: **delete**
   */
  deleteSynonyms: Scalars['Boolean'];
  /**
   * Deletes a user by its ID.
   *
   * Required permission\
   * Resource: **users**\
   * Action: **delete**
   */
  deleteUser: Scalars['ID'];
  /**
   * Delete entries in the user settings.
   *
   * Required permission\
   * Resource: **users-settings**\
   * Action: **write**
   */
  deleteUserSettingEntries: Scalars['Boolean'];
  /**
   * Delete the user settings.
   *
   * Required permission\
   * Resource: **users-settings**\
   * Action: **delete**
   */
  deleteUserSettings: Scalars['Boolean'];
  /**
   * Deletes multiple user by IDs.
   *
   * Required permission\
   * Resource: **users**\
   * Action: **delete**
   */
  deleteUsers: Array<Maybe<Scalars['ID']>>;
  /**
   * Deletes a webhook config by its ID.
   *
   * Required permission\
   * Resource: **webhook-configs**\
   * Action: **delete**
   */
  deleteWebhookConfig: Scalars['ID'];
  /**
   * Deletes webhook configs by IDs.
   *
   * Required permission\
   * Resource: **webhook-configs**\
   * Action: **delete**
   */
  deleteWebhookConfigs?: Maybe<Scalars['Boolean']>;
  /**
   * Export collection as excel or csv file
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **export**
   */
  exportCollection: Scalars['String'];
  /**
   * Marks notification as read. Gets notification by id and sets read date.
   *
   * Required permission\
   * Resource: **notifications**\
   * Action: **mark-as-read**
   */
  markNotificationsAsRead: Array<Maybe<Scalars['ID']>>;
  /** Connection test. Responds with the provided `tagLine` */
  ping?: Maybe<Scalars['String']>;
  /**
   * Publish certain products.
   * If productKeys array is empty, the products will be determined from the latest imports.
   *
   * Required permission\
   * Resource: **twodbs**\
   * Action: **write**
   */
  publish?: Maybe<Scalars['String']>;
  /**
   * Publish all products in index
   *
   * Required permission\
   * Resource: **twodbs**\
   * Action: **write**
   */
  publishAll?: Maybe<Scalars['String']>;
  /**
   * Publish pending imports (emergency use only)
   *
   * Required permission\
   * Resource: **twodbs**\
   * Action: **write**
   */
  publishPending?: Maybe<Scalars['String']>;
  /**
   * Remove activities from a collection.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write**
   *
   * See below for additional permission required to remove entries from a collection with visibility 'public'.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write.public**
   */
  removeActivitiesFromCollection?: Maybe<Scalars['Boolean']>;
  /**
   * Remove articles from a collection.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write**
   *
   * See below for additional permission required to remove entries from a collection with visibility 'public'.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write.public**
   */
  removeArticlesFromCollection?: Maybe<Scalars['Boolean']>;
  /**
   * Remove contents from a collection.
   * `idsOfContents` takes a list of ids of content-like objects (e.g. `Topic` and `ContentMap`).
   * Please take care to use the objects' `id`s and not the objects' `contentId`s.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write**
   *
   * See below for additional permission required to remove contents from a collection with visibility 'public'.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write.public**
   */
  removeContentsFromCollection?: Maybe<Scalars['Boolean']>;
  /**
   * Removes the custom fav icon from the theme.
   *
   * Required permission\
   * Resource: **themes**\
   * Action: **write**
   */
  removeFavIcon: Scalars['Boolean'];
  /**
   * Remove mechanic articles from a collection.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write**
   *
   * See below for additional permission required to remove entries from a collection with visibility 'public'.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write.public**
   */
  removeMechanicsFromCollection?: Maybe<Scalars['Boolean']>;
  /**
   * Remove publication lock in case of pending lock (emergency use only)
   *
   * Required permission\
   * Resource: **twodbs**\
   * Action: **write**
   */
  removePublicationLock?: Maybe<Scalars['Boolean']>;
  /**
   * Revoke an api key for the user by it's unique id
   *
   * Required permission\
   * Resource: **users.api-keys**\
   * Action: **delete**
   */
  revokeApiKey: Scalars['ID'];
  /**
   * Revoke the app key for the current user
   *
   * Required permission\
   * Resource: **user.app-key**\
   * Action: **delete**
   */
  revokeAppKey?: Maybe<Scalars['Void']>;
  /**
   * Saves a new or updates the existing theme.
   *
   * Required permission\
   * Resource: **themes**\
   * Action: **write**
   */
  saveTheme: Scalars['ID'];
  /**
   * Creates Customlinks.
   * Only three custom links per language are stored.
   * Any additional ones will be disregarded.
   * Required permission\
   * Resource: **custom-links**\
   * Action: **write**
   */
  setCustomLinks: Array<Maybe<CustomLinks>>;
  /**
   * Tests a webhook config. Either an existing config specified by it's ID or an ad hoc config can be tested.
   *
   * Required permission\
   * Resource: **webhook-configs**\
   * Action: **test**
   */
  testWebhookConfig?: Maybe<WebhookCallResult>;
  /**
   * Update an existing access filter with the provided values
   *
   * Required permission\
   * Resource: **access-filters**\
   * Action: **write**
   */
  updateAccessFilter?: Maybe<AccessFilter>;
  /**
   * Update an existing activity. Please note that the API is likely to change in future releases
   *
   * Required permission\
   * Resource: **activities**\
   * Action: **write**
   */
  updateActivityProcess?: Maybe<Scalars['ID']>;
  /** Update an existing blob */
  updateBlob: Blob;
  /**
   * Update an existing collection.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write**
   *
   * See below for additional permission required to be able to update a collection with visibility 'public'.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write.public**
   */
  updateCollection: Collection;
  /**
   * Updates a date time indexed field definition.
   *
   * Required permission\
   * Resource: **datafield-definitions**\
   * Action: **write**
   */
  updateDateTimeDatafieldDefinition?: Maybe<DateTimeDatafieldDefinition>;
  /**
   * Updates a enum indexed field definition.
   *
   * Required permission\
   * Resource: **datafield-definitions**\
   * Action: **write**
   */
  updateEnumDatafieldDefinition?: Maybe<EnumDatafieldDefinition>;
  /**
   * Updates a keyword indexed field definition.
   *
   * Required permission\
   * Resource: **datafield-definitions**\
   * Action: **write**
   */
  updateKeywordDatafieldDefinition?: Maybe<KeywordDatafieldDefinition>;
  /**
   * Update an existing note.
   *
   * Required permission\
   * Resource: **notes**\
   * Action: **write**
   *
   * See below for additional permission required to be able to update the visibility status to 'public'. If the
   * invoking user doesn't have the permission below and creates a note with the visibility 'public', the note will be
   * on status 'pendingApproval' (see `VisibilityTypes`).
   *
   * Required permission\
   * Resource: **notes**\
   * Action: **write.public**
   */
  updateNote: Note;
  /**
   * Update an existing note comment.
   *
   * Required permission\
   * Resource: **notes.comments**\
   * Action: **write**
   */
  updateNoteComment: NoteComment;
  /**
   * Updates a numeric indexed field definition.
   *
   * Required permission\
   * Resource: **datafield-definitions**\
   * Action: **write**
   */
  updateNumberDatafieldDefinition?: Maybe<NumberDatafieldDefinition>;
  /**
   * Updates an existing process.
   *
   * Returns the id of the updated process.
   *
   * Required permission\
   * Resource: **processtypes**\
   * Action: **write**
   */
  updateProcess?: Maybe<Scalars['String']>;
  /**
   * Update an existing static page.
   *
   * Required permission\
   * Resource: **static-pages**\
   * Action: **write**
   */
  updateStaticPage?: Maybe<StaticPage>;
  /**
   * Updates a taxonomy indexed field definition.
   *
   * Required permission\
   * Resource: **datafield-definitions**\
   * Action: **write**
   */
  updateTaxonomyDatafieldDefinition?: Maybe<TaxonomyDatafieldDefinition>;
  /**
   * Update tenant.
   *
   * Required permission\
   * Resource: **tenants**\
   * Action: **write**
   */
  updateTenant?: Maybe<Scalars['ID']>;
  /**
   * Updates a text indexed field definition.
   *
   * Required permission\
   * Resource: **datafield-definitions**\
   * Action: **write**
   */
  updateTextDatafieldDefinition?: Maybe<TextDatafieldDefinition>;
  /**
   * Updates a user.
   *
   * Required permission\
   * Resource: **users**\
   * Action: **write**
   */
  updateUser?: Maybe<User>;
  /**
   * Updates a webhook config.
   *
   * Required permission\
   * Resource: **webhook-configs**\
   * Action: **write**
   */
  updateWebhookConfig: WebhookConfig;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationAddActivitiesToCollectionArgs = {
  activityIds: Array<Scalars['ID']>;
  collectionId: Scalars['ID'];
  navContext?: InputMaybe<CollectionEntryNavigationContextInput>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationAddArticlesToCollectionArgs = {
  articleIds: Array<Scalars['ID']>;
  collectionId: Scalars['ID'];
  navContext?: InputMaybe<CollectionEntryNavigationContextInput>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationAddContentsToCollectionArgs = {
  collectionId: Scalars['ID'];
  idsOfContents: Array<Scalars['ID']>;
  navContext?: InputMaybe<CollectionEntryNavigationContextInput>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationAddJobPipelineJobsArgs = {
  additionalBag?: InputMaybe<Scalars['JSONObject']>;
  pipelineId: Scalars['ID'];
  specs: Array<JobPipelineJobDefinition>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationAddMechanicsToCollectionArgs = {
  collectionId: Scalars['ID'];
  mechanicIds: Array<Scalars['ID']>;
  navContext?: InputMaybe<CollectionEntryMechanicNavigationContextInput>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateAccessFilterArgs = {
  accessFilterSpec?: InputMaybe<CreateAccessFilterInput>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateAnonymousUserArgs = {
  userSpec: CreateAnonymousUserInput;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateApiKeyArgs = {
  apiKeySpec: CreateApiKeyInput;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateCollectionArgs = {
  spec?: InputMaybe<CreateCollectionInput>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateConnectorKeyArgs = {
  connectorKeySpec: CreateConnectorKeyInput;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateDateTimeDatafieldDefinitionArgs = {
  spec?: InputMaybe<DateTimeDatafieldDefinitionSpec>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateEnumDatafieldDefinitionArgs = {
  spec?: InputMaybe<EnumDatafieldDefinitionSpec>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateFacetsCollectionArgs = {
  spec: CreateFacetsCollectionInput;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateJobPipelineArgs = {
  spec: JobPipelineDefinition;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateKeywordDatafieldDefinitionArgs = {
  spec?: InputMaybe<KeywordDatafieldDefinitionSpec>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateNoteArgs = {
  noteSpec: CreateNoteInput;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateNoteCommentArgs = {
  commentSpec: CreateNoteCommentInput;
  noteId: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateNumberDatafieldDefinitionArgs = {
  spec?: InputMaybe<NumberDatafieldDefinitionSpec>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateOrUpdateConfig3dHoopsArgs = {
  config3dSpec?: InputMaybe<Config3dHoopsSpec>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateOrUpdateConfig3dLatticeArgs = {
  config3dSpec?: InputMaybe<Config3dLatticeSpec>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateOrUpdateConfigFdiShopArgs = {
  configFdiShopSpec?: InputMaybe<ConfigFdiShopSpec>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateOrUpdateConfigProductAndAssetArgs = {
  configProductAndAssetSpec?: InputMaybe<ConfigProductAndAssetSpec>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateOrUpdateDataDisplayConfigArgs = {
  spec: CreateOrUpdateDataDisplayConfigInput;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateOrUpdateSearchConfigArgs = {
  spec: CreateOrUpdateSearchConfigInput;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateOrUpdateStaticPageMenuArgs = {
  pageTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateOrUpdateUserSettingsArgs = {
  diff?: InputMaybe<CreateOrUpdateUserSettings>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateProcessArgs = {
  columns?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  delimiter?: InputMaybe<Scalars['String']>;
  encoding?: InputMaybe<ProcessEncodingType>;
  escape?: InputMaybe<Scalars['String']>;
  filenameSchemaName?: InputMaybe<Scalars['String']>;
  mappingOptions?: InputMaybe<Array<InputMaybe<MappingOptionSpec>>>;
  spec: ProcessRefSpec;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateStaticPageArgs = {
  spec?: InputMaybe<CreateStaticPageInput>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateTaxonomyDatafieldDefinitionArgs = {
  spec?: InputMaybe<TaxonomyDatafieldDefinitionSpec>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateTextDatafieldDefinitionArgs = {
  spec?: InputMaybe<TextDatafieldDefinitionSpec>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateUserArgs = {
  userSpec: CreateUserInput;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationCreateWebhookConfigArgs = {
  webhookConfigSpec: CreateWebhookConfigInput;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteAccessFilterArgs = {
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteAccessFiltersArgs = {
  ids: Array<Scalars['ID']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteActivitiesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteArticlesArgs = {
  filter?: InputMaybe<ArticleFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteAssetsArgs = {
  assetIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  productId: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteCollectionArgs = {
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteContentMapArgs = {
  contentId: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteContentMapsByFilterArgs = {
  filter?: InputMaybe<ContentMapFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteDataDisplayConfigArgs = {
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteDatafieldDefinitionArgs = {
  id: Scalars['DatafieldDefinitionID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteDatafieldDefinitionsArgs = {
  ids: Array<Scalars['DatafieldDefinitionID']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteEntitiesArgs = {
  entityTypes?: InputMaybe<Array<InputMaybe<ProcessType>>>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteFacetsCollectionArgs = {
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteFilesArgs = {
  fileTypes?: InputMaybe<Array<InputMaybe<FileType>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  objectIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteJobPipelineArgs = {
  pipelineId: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteJobPipelineJobsArgs = {
  jobIds: Array<Scalars['ID']>;
  pipelineId: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteJobPipelinesArgs = {
  filter: JobPipelineFilter;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteMechanicsArgs = {
  productIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteNoteArgs = {
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteNoteAttachmentsArgs = {
  attachmentIds: Array<InputMaybe<Scalars['ID']>>;
  noteId: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteNoteCommentArgs = {
  commentId: Scalars['ID'];
  noteId: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteNotesArgs = {
  ids: Array<Scalars['ID']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteProcessArgs = {
  id: Scalars['String'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteProductsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteSearchConfigArgs = {
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteStaticPageArgs = {
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteStaticPagesArgs = {
  ids: Array<Scalars['ID']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteUserSettingEntriesArgs = {
  keys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteUsersArgs = {
  ids: Array<Scalars['ID']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteWebhookConfigArgs = {
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationDeleteWebhookConfigsArgs = {
  ids: Array<Scalars['ID']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationExportCollectionArgs = {
  acceptedLanguages: Array<LanguageWithWildcard>;
  collectionExportType: CollectionExportType;
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationMarkNotificationsAsReadArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationPingArgs = {
  tagLine?: InputMaybe<Scalars['String']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationPublishArgs = {
  productKeys?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationRemoveActivitiesFromCollectionArgs = {
  activityIds: Array<Scalars['ID']>;
  collectionId: Scalars['ID'];
  navContext?: InputMaybe<CollectionEntryNavigationContextInput>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationRemoveArticlesFromCollectionArgs = {
  articleIds: Array<Scalars['ID']>;
  collectionId: Scalars['ID'];
  navContext?: InputMaybe<CollectionEntryNavigationContextInput>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationRemoveContentsFromCollectionArgs = {
  collectionId: Scalars['ID'];
  idsOfContents: Array<Scalars['ID']>;
  navContext?: InputMaybe<CollectionEntryNavigationContextInput>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationRemoveMechanicsFromCollectionArgs = {
  collectionId: Scalars['ID'];
  mechanicIds: Array<Scalars['ID']>;
  navContext?: InputMaybe<CollectionEntryMechanicNavigationContextInput>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationRevokeApiKeyArgs = {
  apiKeyId: Scalars['ID'];
  userId: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationSaveThemeArgs = {
  theme?: InputMaybe<ThemeInput>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationSetCustomLinksArgs = {
  spec: Array<CustomLinksInput>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationTestWebhookConfigArgs = {
  affectedItemsPayload?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  webhookConfigId?: InputMaybe<Scalars['ID']>;
  webhookConfigSpec?: InputMaybe<TestWebhookConfigInput>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationUpdateAccessFilterArgs = {
  accessFilterDiff?: InputMaybe<UpdateAccessFilterInput>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationUpdateActivityProcessArgs = {
  spec: UpdateActivityProcessInput;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationUpdateBlobArgs = {
  spec?: InputMaybe<UpdateBlobInput>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationUpdateCollectionArgs = {
  diff?: InputMaybe<UpdateCollectionInput>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationUpdateDateTimeDatafieldDefinitionArgs = {
  diff?: InputMaybe<DateTimeDatafieldDefinitionDiff>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationUpdateEnumDatafieldDefinitionArgs = {
  diff?: InputMaybe<EnumDatafieldDefinitionDiff>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationUpdateKeywordDatafieldDefinitionArgs = {
  diff?: InputMaybe<KeywordDatafieldDefinitionDiff>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationUpdateNoteArgs = {
  partialNote: UpdateNoteInput;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationUpdateNoteCommentArgs = {
  noteId: Scalars['ID'];
  partialComment: UpdateNoteCommentInput;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationUpdateNumberDatafieldDefinitionArgs = {
  diff?: InputMaybe<NumberDatafieldDefinitionDiff>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationUpdateProcessArgs = {
  columns?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  delimiter?: InputMaybe<Scalars['String']>;
  encoding?: InputMaybe<ProcessEncodingType>;
  escape?: InputMaybe<Scalars['String']>;
  filenameSchemaName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  mappingOptions?: InputMaybe<Array<InputMaybe<MappingOptionSpec>>>;
  name: Scalars['String'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationUpdateStaticPageArgs = {
  updateSpec?: InputMaybe<UpdateStaticPageInput>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationUpdateTaxonomyDatafieldDefinitionArgs = {
  diff?: InputMaybe<TaxonomyDatafieldDefinitionDiff>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationUpdateTenantArgs = {
  tenant: UpdateTenantInput;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationUpdateTextDatafieldDefinitionArgs = {
  diff?: InputMaybe<TextDatafieldDefinitionDiff>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationUpdateUserArgs = {
  userDiff: UpdateUserInput;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type MutationUpdateWebhookConfigArgs = {
  webhookConfigDiff: UpdateWebhookConfigInput;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Note = {
  __typename?: 'Note';
  /**
   * The latest date the note got approved for publication.
   *
   * Due to the possibility that the note gets revoked, the field shows the latest approval and is no indicator
   * if the note is currently public.
   */
  approvalDate?: Maybe<Scalars['DateTime']>;
  /** Optional attachments for the note (e.g. images) */
  attachments?: Maybe<Array<Maybe<BlobRef>>>;
  /** Comments for the note */
  comments?: Maybe<Array<Maybe<NoteComment>>>;
  /** Content the note was created for */
  content?: Maybe<Content>;
  /** The creation date */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** The unique id */
  id: Scalars['ID'];
  /** The latest modification date */
  modificationDate?: Maybe<Scalars['DateTime']>;
  /** ID of object referenced by the note */
  objectId?: Maybe<Scalars['ID']>;
  /** Type of object referenced by the note */
  objectType?: Maybe<Scalars['String']>;
  /**
   * User that reviewed the note and approved publication.
   *
   * If the note gets revoked, the reviewer will not be updated until the
   * note is again in review process and is reviewed by eventually another reviewer.
   */
  reviewer?: Maybe<User>;
  /** Length limited text from the note for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<NoteTeasers>;
  /** The note text */
  text?: Maybe<MultilingualString>;
  /** The user who owns the note */
  user?: Maybe<User>;
  /** The visibility for the note */
  visibility?: Maybe<NoteVisibilityTypes>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NoteComment = {
  __typename?: 'NoteComment';
  /** The creation date */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** The unique id of the note comment */
  id: Scalars['ID'];
  /** The latest modification date */
  modificationDate?: Maybe<Scalars['DateTime']>;
  /** The text of the comment */
  text?: Maybe<Scalars['String']>;
  /** The user who created the comment */
  user?: Maybe<User>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NoteEdge = {
  __typename?: 'NoteEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** The result's note object */
  node: Note;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NoteEqualsValueFilter = {
  /** Returns only notes with the specified approval date */
  approvalDate?: InputMaybe<Scalars['DateTime']>;
  /** Returns only notes with the specified creation date */
  creationDate?: InputMaybe<Scalars['DateTime']>;
  /** Returns only notes with the specified modification date */
  modificationDate?: InputMaybe<Scalars['DateTime']>;
  /** Returns only notes which reference the specified object id */
  objectId?: InputMaybe<Scalars['ID']>;
  /** Returns only notes reviewed by an user with the specified id */
  reviewerId?: InputMaybe<Scalars['ID']>;
  /** Returns only notes owned by an user with the specified id */
  userId?: InputMaybe<Scalars['ID']>;
  /** Returns only notes with the specified visibility */
  visibility?: InputMaybe<NoteVisibilityTypes>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NoteFilter = {
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<NoteFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns notes that contain all of the exact values specified */
  equals?: InputMaybe<NoteEqualsValueFilter>;
  /** Full text search in notes */
  fullText?: InputMaybe<NoteFullTextFilter>;
  /** Filter by ids */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<NoteFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<NoteFilter>>>;
  /** Returns notes with values within all of the ranges specified */
  range?: InputMaybe<NoteRangeFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NoteFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: InputMaybe<Scalars['Float']>;
  /** Match the query against the note text and rank the results with the specified boost */
  text?: InputMaybe<Scalars['Float']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NoteFullTextFilter = {
  /** Fields to search in with their respective score weights */
  fields?: InputMaybe<NoteFullTextFields>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: InputMaybe<Scalars['Boolean']>;
  /** Specifies if either all or any query words need to match */
  operator?: InputMaybe<FullTextOperator>;
  /** Text to search for */
  query: Scalars['FullTextSearchQuery'];
  /** Type of fulltext search to perform */
  searchType?: InputMaybe<FullTextSearchType>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NoteQueryResult = {
  __typename?: 'NoteQueryResult';
  /**
   * The query's matching notes.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  notes: Array<Maybe<NoteEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NoteQueryResultNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<NoteSort>>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NoteQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NoteRangeFilter = {
  /** Returns only notes with an approval date in the specified range */
  approvalDate?: InputMaybe<DateRange>;
  /** Returns only notes with a creation date in the specified range */
  creationDate?: InputMaybe<DateRange>;
  /** Returns only notes with a modification date in the specified range */
  modificationDate?: InputMaybe<DateRange>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NoteSort = {
  /** Sort field */
  field: NoteSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum NoteSortFields {
  /** Sort by approval date */
  approvalDate = 'approvalDate',
  /** Sort by creation date */
  creationDate = 'creationDate',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by modification date */
  modificationDate = 'modificationDate',
  /** Sort by object id */
  objectId = 'objectId',
  /** Sort by reviewer id */
  reviewerId = 'reviewerId',
  /** Sort by relevance score */
  score = 'score',
  /** Sort by user id */
  userId = 'userId',
  /** Sort by visibility */
  visibility = 'visibility'
}

/** Length limited text from the note for use e.g. in result lists, previews, etc. */
export type NoteTeasers = {
  __typename?: 'NoteTeasers';
  /**
   * The notes text limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   */
  text?: Maybe<Scalars['String']>;
};


/** Length limited text from the note for use e.g. in result lists, previews, etc. */
export type NoteTeasersTextArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NoteTextUploadConfig = {
  __typename?: 'NoteTextUploadConfig';
  /** The maximal allowed text length per language */
  maxLength: Scalars['NonNegativeInt'];
};

/** The different visibility types for notes */
export enum NoteVisibilityTypes {
  /**
   * Indicates that the note is marked for publication, but has not yet approved
   * by an user with administrative privileges.
   *
   * Visibility is equal to 'private'.
   */
  pendingApproval = 'pendingApproval',
  /** Only visible to users with administrative privileges and the creator itself */
  private = 'private',
  /** Visible to everyone */
  public = 'public'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum NoteVisibilityTypesInput {
  /** Visible to users with administrative privileges and the creator itself */
  private = 'private',
  /**
   * Visible to everyone with access to the referenced content.
   *
   * If the user doesn't have privileges to publish notes, the visibility state is changed to 'pendingApproval'
   * and needs to be reviewed by an user with administrative privileges. If the note gets approved the state is changed
   * to 'public', in case the note doesn't get approved the state is changed to 'private'
   */
  public = 'public'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NotesUploadConfig = {
  __typename?: 'NotesUploadConfig';
  /** Upload config for the note attachments */
  attachments?: Maybe<UploadMultipleConfig>;
  /** Upload config for the text */
  text?: Maybe<NoteTextUploadConfig>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Notification = {
  __typename?: 'Notification';
  /** The creation date */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** The unique id */
  id: Scalars['ID'];
  /** The type of the notification. */
  notificationType?: Maybe<Scalars['String']>;
  /** ID of object referenced by the notification */
  objectId?: Maybe<Scalars['ID']>;
  /** ID of object referenced by the notification */
  objectType?: Maybe<Scalars['String']>;
  /** Date the user read / clicked on the notification */
  readDate?: Maybe<Scalars['DateTime']>;
  /** Length limited text from the notification for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<NotificationTeasers>;
  /** The notification text */
  text?: Maybe<MultilingualString>;
  /** Users that should receive the notification */
  users?: Maybe<Array<Maybe<User>>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** The result's notification object */
  node: Notification;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NotificationEqualsValueFilter = {
  /** Returns only notifications with the specified creation date */
  creationDate?: InputMaybe<Scalars['DateTime']>;
  /** Returns only notifications with the specified type */
  notificationType?: InputMaybe<Scalars['String']>;
  /** Returns only notifications with the specified object id */
  objectId?: InputMaybe<Scalars['ID']>;
  /** Returns only notifications with the specified object type */
  objectType?: InputMaybe<Scalars['String']>;
  /** Returns only notifications with the specified read date */
  readDate?: InputMaybe<Scalars['DateTime']>;
  /** Returns only notifications with the specified user id */
  userId?: InputMaybe<Scalars['ID']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum NotificationExistsValueFields {
  /** Returns only notifications which have a read date specified */
  readDate = 'readDate'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NotificationFilter = {
  /** Allows to filter all notifications */
  all?: InputMaybe<Scalars['Boolean']>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<NotificationFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns notifications that contain all of the exact values specified */
  equals?: InputMaybe<NotificationEqualsValueFilter>;
  /** Returns notifications that have a value in all of the specified fields */
  exists?: InputMaybe<Array<InputMaybe<NotificationExistsValueFields>>>;
  /** Full text search in notifications */
  fullText?: InputMaybe<NotificationFullTextFilter>;
  /** Filter by ids */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<NotificationFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<NotificationFilter>>>;
  /** Returns notifications with values within all of the ranges specified */
  range?: InputMaybe<NotificationRangeFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NotificationFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: InputMaybe<Scalars['Float']>;
  /** Match the query against the notification message and rank the results with the specified boost */
  text?: InputMaybe<Scalars['Float']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NotificationFullTextFilter = {
  /** Fields to search in with their respective score weights */
  fields?: InputMaybe<NotificationFullTextFields>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: InputMaybe<Scalars['Boolean']>;
  /** Specifies if either all or any query words need to match */
  operator?: InputMaybe<FullTextOperator>;
  /**
   * The text to search for.
   * Be aware: The Limit for full text search queries is 1024 characters and
   * anything above that will result in an error.
   */
  query: Scalars['FullTextSearchQuery'];
  /** Type of fulltext search to perform */
  searchType?: InputMaybe<FullTextSearchType>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NotificationQueryResult = {
  __typename?: 'NotificationQueryResult';
  /**
   * The query's matching notifications.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  notifications: Array<Maybe<NotificationEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NotificationQueryResultNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<NotificationSort>>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NotificationQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NotificationRangeFilter = {
  /** Returns only notifications with a creation date in the specified range */
  creationDate?: InputMaybe<DateRange>;
  /** Returns only notifications with a read date in the specified range */
  readDate?: InputMaybe<DateRange>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type NotificationSort = {
  /** Sort field */
  field: NotificationSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum NotificationSortFields {
  /** Sort by creation date */
  creationDate = 'creationDate',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by relevance score */
  score = 'score'
}

/** Length limited text from the notification for use e.g. in result lists, previews, etc. */
export type NotificationTeasers = {
  __typename?: 'NotificationTeasers';
  /**
   * The notifications title limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   */
  text?: Maybe<Scalars['String']>;
};


/** Length limited text from the notification for use e.g. in result lists, previews, etc. */
export type NotificationTeasersTextArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Defines a numeric datafield. */
export type NumberDatafieldDefinition = DatafieldDefinition & {
  __typename?: 'NumberDatafieldDefinition';
  /** Type of this indexed field definition */
  fieldType?: Maybe<FieldDefinitionTypes>;
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Text from the datafield definition for use e.g. in detail views, etc */
  localizations?: Maybe<DatafieldDefinitionLocalizations>;
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames?: Maybe<Array<Scalars['String']>>;
  /** Optional maximum value for this datafield */
  max?: Maybe<Scalars['Float']>;
  /** Optional minimum value for this datafield */
  min?: Maybe<Scalars['Float']>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: Maybe<Array<Maybe<ObjectType>>>;
  /** Length limited text from the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldDefinitionTeasers>;
  /** Human readable description of this indexed field definition */
  title?: Maybe<MultilingualString>;
};

/**
 * Data to update a numeric datafield.
 * All fields except `id` are optional. Only provided fields will be updated.
 * If provided, they will replace the current value.
 */
export type NumberDatafieldDefinitionDiff = {
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames?: InputMaybe<Array<Scalars['String']>>;
  /** Optional maximum value for this datafield */
  max?: InputMaybe<Scalars['Float']>;
  /** Optional minimum value for this datafield */
  min?: InputMaybe<Scalars['Float']>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: InputMaybe<Array<InputMaybe<ObjectType>>>;
  /** Human readable description of this indexed field definition */
  title?: InputMaybe<MultilingualStringInput>;
};

/** Data to create a numeric datafield. */
export type NumberDatafieldDefinitionSpec = {
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames: Array<Scalars['String']>;
  /** Optional maximum value for this datafield */
  max?: InputMaybe<Scalars['Float']>;
  /** Optional minimum value for this datafield */
  min?: InputMaybe<Scalars['Float']>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: InputMaybe<Array<InputMaybe<ObjectType>>>;
  /** Human readable description of this indexed field definition */
  title: MultilingualStringInput;
};

/** Defines a numeric datafield field. */
export type NumberRangeDatafieldDefinition = DatafieldDefinition & {
  __typename?: 'NumberRangeDatafieldDefinition';
  /** Type of this datafield definition */
  fieldType?: Maybe<FieldDefinitionTypes>;
  /**
   * Unique identifier of this datafield definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Text from the datafield definition for use e.g. in detail views, etc */
  localizations?: Maybe<DatafieldDefinitionLocalizations>;
  /** Datafield names which shall be mapped to this datafield definition */
  mappedNames?: Maybe<Array<Scalars['String']>>;
  /** Optional maximum value for this datafield */
  max?: Maybe<Scalars['Float']>;
  /** Optional minimum value for this datafield */
  min?: Maybe<Scalars['Float']>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: Maybe<Array<Maybe<ObjectType>>>;
  /** Length limited text from the datafield definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldDefinitionTeasers>;
  /** Human readable description of this datafield definition */
  title?: Maybe<MultilingualString>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum ObjectType {
  /** No object type definitions exist. As soon as the first object type definition is defined, it's automatically listed here. */
  notApplicableAsNoObjectTypeDefinitionsExist = 'not_applicable_as_no_object_type_definitions_exist'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Process = {
  __typename?: 'Process';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  columns?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  deleteAll?: Maybe<Scalars['Boolean']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  delimiter?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  encoding?: Maybe<ProcessEncodingType>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  escape?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  filenameSchemaName?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  id?: Maybe<Scalars['String']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  mappingOptions?: Maybe<Array<Maybe<MappingOption>>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  name: Scalars['String'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  processType: ProcessType;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProcessDatafieldMapping = {
  /** Returns column(s) which also map to the same field. In this case a process cannot be created due to ambigiuousness. */
  ambiguousColumns?: Maybe<Array<Scalars['String']>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  column: Scalars['String'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  field?: Maybe<Datafield>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum ProcessEncodingType {
  latin1 = 'LATIN1',
  utf8 = 'UTF8',
  utf16Le = 'UTF16LE'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum ProcessMappingType {
  filenameComplete = 'FILENAME_COMPLETE',
  filenameUpToChar = 'FILENAME_UP_TO_CHAR',
  folderName = 'FOLDER_NAME'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProcessRefSpec = {
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  name: Scalars['String'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  processType: ProcessType;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum ProcessType {
  activity = 'ACTIVITY',
  article = 'ARTICLE',
  asset = 'ASSET',
  assetOption = 'ASSET_OPTION',
  documentation = 'DOCUMENTATION',
  file = 'FILE',
  mechanic = 'MECHANIC',
  product = 'PRODUCT'
}

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Product = {
  __typename?: 'Product';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  assets?: Maybe<ProductAssetsQueryResult>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  files?: Maybe<Array<File>>;
  /** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  localizations?: Maybe<ProductLocalizations>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  previewImage?: Maybe<BlobRef>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  referenceId?: Maybe<Scalars['String']>;
  /**
   * Related activity to the product id. The given activity filter is optional and is combined (AND clause) with
   * a static filter:
   * { taxonomy: { custom_products: [product.id] } }
   * This returns only activities related to the specific product.
   */
  relatedActivities: ActivityQueryResult;
  /**
   * Related content to the product id. The given content filter is optional and is combined (AND clause) with
   * a static filter:
   * { taxonomy: { custom_products: [product.id] } }
   * This returns only contents related to the specific product.
   */
  relatedContents: ContentQueryResult;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  teasers?: Maybe<ProductTeasers>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  title?: Maybe<MultilingualString>;
};


/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductFilesArgs = {
  fileTypes?: InputMaybe<Array<InputMaybe<FileType>>>;
};


/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductRelatedActivitiesArgs = {
  filter?: InputMaybe<ActivityFilter>;
};


/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductRelatedContentsArgs = {
  filter?: InputMaybe<ContentFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductAccessFilter = {
  /** Allows access to all product maps if set to true */
  all?: InputMaybe<Scalars['Boolean']>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<ProductAccessFilter>>>;
  /** Restricts access to product maps that contain all of the exact values specified */
  equals?: InputMaybe<ProductEqualsValueFilter>;
  /** Restricts access to products that have a value in all of the specified fields */
  exists?: InputMaybe<Array<InputMaybe<ProductExistsValueFields>>>;
  /** Restricts access to product maps with the specified product identifiers */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<ProductAccessFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<ProductAccessFilter>>>;
  /** Restricts access to products with values within all of the ranges specified */
  range?: InputMaybe<ProductRangeFilter>;
  /** Restricts access to product maps that match all of the regular expressions */
  regex?: InputMaybe<ProductRegexFilter>;
  /** Restricts access to products that match a taxonomy value according to the taxonomy's evaluation type */
  taxonomy?: InputMaybe<ProductTaxonomyFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductAssetsQueryResult = {
  __typename?: 'ProductAssetsQueryResult';
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductAutocompleteHits = {
  __typename?: 'ProductAutocompleteHits';
  /** The count of docs found containing the suggestion */
  docCount: Scalars['NonNegativeInt'];
  /** The language this suggestion stems from */
  language?: Maybe<Language>;
  /** The found suggestion */
  suggest: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductAutocompleteResult = {
  __typename?: 'ProductAutocompleteResult';
  /** The hits found for the query */
  hits?: Maybe<Array<ProductAutocompleteHits>>;
  /** The query used for this result */
  query: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductEdge = {
  __typename?: 'ProductEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** The result's product type. */
  node: Product;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductEqualsValueFilter = {
  /**
   * Returns only products with the specified title
   * @deprecated Use two title_xx fields for InfoTwin language and tenant default language instead
   */
  title?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified ar title */
  title_ar?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified bg title */
  title_bg?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified bn title */
  title_bn?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified bs title */
  title_bs?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified ca title */
  title_ca?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified cs title */
  title_cs?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified da title */
  title_da?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified de title */
  title_de?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified el title */
  title_el?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified en title */
  title_en?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified es title */
  title_es?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified et title */
  title_et?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified eu title */
  title_eu?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified fa title */
  title_fa?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified fi title */
  title_fi?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified fr title */
  title_fr?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified ga title */
  title_ga?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified he title */
  title_he?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified hi title */
  title_hi?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified hr title */
  title_hr?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified hu title */
  title_hu?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified hy title */
  title_hy?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified id title */
  title_id?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified is title */
  title_is?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified it title */
  title_it?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified ja title */
  title_ja?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified ko title */
  title_ko?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified ku title */
  title_ku?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified lt title */
  title_lt?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified lv title */
  title_lv?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified ms title */
  title_ms?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified mt title */
  title_mt?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified nb title */
  title_nb?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified nl title */
  title_nl?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified no title */
  title_no?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified pb title */
  title_pb?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified pl title */
  title_pl?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified pt title */
  title_pt?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified ro title */
  title_ro?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified ru title */
  title_ru?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified sk title */
  title_sk?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified sl title */
  title_sl?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified sq title */
  title_sq?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified sr title */
  title_sr?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified sv title */
  title_sv?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified th title */
  title_th?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified tr title */
  title_tr?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified uk title */
  title_uk?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified vi title */
  title_vi?: InputMaybe<Scalars['String']>;
  /** Returns only products with the specified zh title */
  title_zh?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum ProductExistsValueFields {
  /** Returns only products which have a title specified */
  title = 'title'
}

/**
 * The `ProductFilter` is used to specify filter criteria for products.
 * If you specify more than one criterion, all criteria are grouped together and connected with a logical AND operator.
 * Further logical operations are possible by using `not` `andGroup` and `orGroup`.
 */
export type ProductFilter = {
  /** Returns all products if set to true */
  all?: InputMaybe<Scalars['Boolean']>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<ProductFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns products that contain all of the exact values specified */
  equals?: InputMaybe<ProductEqualsValueFilter>;
  /**
   * Returns products that have a value in all of the specified fields
   * @deprecated Please use 'has' filter instead
   */
  exists?: InputMaybe<Array<InputMaybe<ProductExistsValueFields>>>;
  /** Returns products that match all of the specified full text searches */
  fullText?: InputMaybe<ProductFullTextFilter>;
  /** Returns products that have a value in all of the specified fields */
  has?: InputMaybe<ProductHasValueFilter>;
  /** Returns products with one of the specified unique identifiers */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<ProductFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<ProductFilter>>>;
  /** Returns products with values within all of the ranges specified */
  range?: InputMaybe<ProductRangeFilter>;
  /** Returns products that match all of the regular expressions */
  regex?: InputMaybe<ProductRegexFilter>;
  /** Returns products that match a taxonomy value according to the taxonomy's evaluation type */
  taxonomy?: InputMaybe<ProductTaxonomyFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: InputMaybe<Scalars['Float']>;
  /**
   * Match the query against the title of the current language and the tenant default language and rank the results with the specified boost
   * @deprecated Use two title_xx fields for InfoTwin language and tenant default language instead
   */
  title?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ar?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_bg?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_bn?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_bs?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ca?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_cs?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_da?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_de?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_el?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_en?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_es?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_et?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_eu?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_fa?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_fi?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_fr?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ga?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_he?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_hi?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_hr?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_hu?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_hy?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_id?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_is?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_it?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ja?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ko?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ku?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_lt?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_lv?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ms?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_mt?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_nb?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_nl?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_no?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_pb?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_pl?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_pt?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ro?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_ru?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sk?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sl?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sq?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sr?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_sv?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_th?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_tr?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_uk?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_vi?: InputMaybe<Scalars['Float']>;
  /** Match the query against the  title and rank the results with the specified boost */
  title_zh?: InputMaybe<Scalars['Float']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductFullTextFilter = {
  /** Fields to search in with their respective score weights */
  fields?: InputMaybe<ProductFullTextFields>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: InputMaybe<Scalars['Boolean']>;
  /** Specifies if either all or any query words need to match */
  operator?: InputMaybe<FullTextOperator>;
  /**
   * Text to search for
   * Be aware: The Limit for full text search queries is 1024 characters and
   * anything above that will result in an error.
   */
  query: Scalars['FullTextSearchQuery'];
  /** Type of fulltext search to perform */
  searchType?: InputMaybe<FullTextSearchType>;
};

/** Returns products that have a value in all of the specified fields */
export type ProductHasValueFilter = {
  /** Specify the fields that the returned products must have a value in */
  valueInFields?: InputMaybe<Array<InputMaybe<ProductHasValueInFields>>>;
};

/** Specify the fields that the returned products must have a value in */
export enum ProductHasValueInFields {
  /** Returns only products which have a title specified */
  title = 'title'
}

/** Text from the product for use e.g. in details. */
export type ProductLocalizations = {
  __typename?: 'ProductLocalizations';
  /** The product's title */
  title?: Maybe<Scalars['String']>;
};


/** Text from the product for use e.g. in details. */
export type ProductLocalizationsTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum ProductNames {
  devices = 'DEVICES',
  machines = 'MACHINES',
  models = 'MODELS',
  plants = 'PLANTS',
  products = 'PRODUCTS',
  types = 'TYPES'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum ProductNamesSpec {
  devices = 'DEVICES',
  machines = 'MACHINES',
  models = 'MODELS',
  plants = 'PLANTS',
  products = 'PRODUCTS',
  types = 'TYPES'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductQueryResult = {
  __typename?: 'ProductQueryResult';
  /**
   * Matched datafield values and number of matching products per value
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   */
  aggregations: DatafieldAggregations;
  /**
   * The query's matching products.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  products: Array<Maybe<ProductEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductQueryResultProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<ProductSort>>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductRangeFilter = {
  /** No number or dateTime datafield definitions exist. As soon as the first such datafield definition is defined, it's automatically listed here. */
  not_applicable_as_no_number_or_date_datafield_definitions_exist?: InputMaybe<Scalars['NoDynamicValueDefined']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductRegexFilter = {
  /**
   * Returns only products whose title of the current language and the tenant default language matches the specified Regex
   * @deprecated Use two title_xx fields for InfoTwin language and tenant default language instead
   */
  title?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose ar title matches the specified Regex */
  title_ar?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose bg title matches the specified Regex */
  title_bg?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose bn title matches the specified Regex */
  title_bn?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose bs title matches the specified Regex */
  title_bs?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose ca title matches the specified Regex */
  title_ca?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose cs title matches the specified Regex */
  title_cs?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose da title matches the specified Regex */
  title_da?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose de title matches the specified Regex */
  title_de?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose el title matches the specified Regex */
  title_el?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose en title matches the specified Regex */
  title_en?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose es title matches the specified Regex */
  title_es?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose et title matches the specified Regex */
  title_et?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose eu title matches the specified Regex */
  title_eu?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose fa title matches the specified Regex */
  title_fa?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose fi title matches the specified Regex */
  title_fi?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose fr title matches the specified Regex */
  title_fr?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose ga title matches the specified Regex */
  title_ga?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose he title matches the specified Regex */
  title_he?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose hi title matches the specified Regex */
  title_hi?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose hr title matches the specified Regex */
  title_hr?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose hu title matches the specified Regex */
  title_hu?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose hy title matches the specified Regex */
  title_hy?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose id title matches the specified Regex */
  title_id?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose is title matches the specified Regex */
  title_is?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose it title matches the specified Regex */
  title_it?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose ja title matches the specified Regex */
  title_ja?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose ko title matches the specified Regex */
  title_ko?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose ku title matches the specified Regex */
  title_ku?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose lt title matches the specified Regex */
  title_lt?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose lv title matches the specified Regex */
  title_lv?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose ms title matches the specified Regex */
  title_ms?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose mt title matches the specified Regex */
  title_mt?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose nb title matches the specified Regex */
  title_nb?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose nl title matches the specified Regex */
  title_nl?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose no title matches the specified Regex */
  title_no?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose pb title matches the specified Regex */
  title_pb?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose pl title matches the specified Regex */
  title_pl?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose pt title matches the specified Regex */
  title_pt?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose ro title matches the specified Regex */
  title_ro?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose ru title matches the specified Regex */
  title_ru?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose sk title matches the specified Regex */
  title_sk?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose sl title matches the specified Regex */
  title_sl?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose sq title matches the specified Regex */
  title_sq?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose sr title matches the specified Regex */
  title_sr?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose sv title matches the specified Regex */
  title_sv?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose th title matches the specified Regex */
  title_th?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose tr title matches the specified Regex */
  title_tr?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose uk title matches the specified Regex */
  title_uk?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose vi title matches the specified Regex */
  title_vi?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only products whose zh title matches the specified Regex */
  title_zh?: InputMaybe<Scalars['FilterRegex']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductSort = {
  /** Sort field */
  field: ProductSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum ProductSortFields {
  /** Sort by unique ID */
  id = 'id',
  /** Sort by relevance score */
  score = 'score',
  /**
   * Sort by title
   * @deprecated Use two title_xx fields for InfoTwin language and tenant default language instead
   */
  title = 'title',
  /** Sort by language-ar title */
  titleAr = 'title_ar',
  /** Sort by language-bg title */
  titleBg = 'title_bg',
  /** Sort by language-bn title */
  titleBn = 'title_bn',
  /** Sort by language-bs title */
  titleBs = 'title_bs',
  /** Sort by language-ca title */
  titleCa = 'title_ca',
  /** Sort by language-cs title */
  titleCs = 'title_cs',
  /** Sort by language-da title */
  titleDa = 'title_da',
  /** Sort by language-de title */
  titleDe = 'title_de',
  /** Sort by language-el title */
  titleEl = 'title_el',
  /** Sort by language-en title */
  titleEn = 'title_en',
  /** Sort by language-es title */
  titleEs = 'title_es',
  /** Sort by language-et title */
  titleEt = 'title_et',
  /** Sort by language-eu title */
  titleEu = 'title_eu',
  /** Sort by language-fa title */
  titleFa = 'title_fa',
  /** Sort by language-fi title */
  titleFi = 'title_fi',
  /** Sort by language-fr title */
  titleFr = 'title_fr',
  /** Sort by language-ga title */
  titleGa = 'title_ga',
  /** Sort by language-he title */
  titleHe = 'title_he',
  /** Sort by language-hi title */
  titleHi = 'title_hi',
  /** Sort by language-hr title */
  titleHr = 'title_hr',
  /** Sort by language-hu title */
  titleHu = 'title_hu',
  /** Sort by language-hy title */
  titleHy = 'title_hy',
  /** Sort by language-id title */
  titleId = 'title_id',
  /** Sort by language-is title */
  titleIs = 'title_is',
  /** Sort by language-it title */
  titleIt = 'title_it',
  /** Sort by language-ja title */
  titleJa = 'title_ja',
  /** Sort by language-ko title */
  titleKo = 'title_ko',
  /** Sort by language-ku title */
  titleKu = 'title_ku',
  /** Sort by language-lt title */
  titleLt = 'title_lt',
  /** Sort by language-lv title */
  titleLv = 'title_lv',
  /** Sort by language-ms title */
  titleMs = 'title_ms',
  /** Sort by language-mt title */
  titleMt = 'title_mt',
  /** Sort by language-nb title */
  titleNb = 'title_nb',
  /** Sort by language-nl title */
  titleNl = 'title_nl',
  /** Sort by language-no title */
  titleNo = 'title_no',
  /** Sort by language-pb title */
  titlePb = 'title_pb',
  /** Sort by language-pl title */
  titlePl = 'title_pl',
  /** Sort by language-pt title */
  titlePt = 'title_pt',
  /** Sort by language-ro title */
  titleRo = 'title_ro',
  /** Sort by language-ru title */
  titleRu = 'title_ru',
  /** Sort by language-sk title */
  titleSk = 'title_sk',
  /** Sort by language-sl title */
  titleSl = 'title_sl',
  /** Sort by language-sq title */
  titleSq = 'title_sq',
  /** Sort by language-sr title */
  titleSr = 'title_sr',
  /** Sort by language-sv title */
  titleSv = 'title_sv',
  /** Sort by language-th title */
  titleTh = 'title_th',
  /** Sort by language-tr title */
  titleTr = 'title_tr',
  /** Sort by language-uk title */
  titleUk = 'title_uk',
  /** Sort by language-vi title */
  titleVi = 'title_vi',
  /** Sort by language-zh title */
  titleZh = 'title_zh'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductSpec = {
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  assets?: InputMaybe<Array<InputMaybe<AssetSpec>>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  rawCustomFields?: InputMaybe<Array<RawDatafieldSpec>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  title?: InputMaybe<MultilingualStringInput>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ProductTaxonomyFilter = {
  /** No taxonomy datafield definitions exist. As soon as the first taxonomy is defined, it's automatically listed here. */
  not_applicable_as_no_taxonomy_datafield_definitions_exist?: InputMaybe<Scalars['NoDynamicValueDefined']>;
};

/** Length limited text from the product for use e.g. in result lists, previews, etc. */
export type ProductTeasers = {
  __typename?: 'ProductTeasers';
  /** The product's title limited to `length` characters */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the product for use e.g. in result lists, previews, etc. */
export type ProductTeasersTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Query = {
  __typename?: 'Query';
  /** Returns the active access control configuration */
  accessControlSettings: AccessControlSettings;
  /**
   * Get an access filter by it's unique id.
   *
   * Required permission\
   * Resource: **access-filters**\
   * Action: **query**
   */
  accessFilter?: Maybe<AccessFilter>;
  /**
   * Query `AccessFilter`s. Filters con be specified via the `filter` argument. See `AccessFilterFilter` for further details.
   *
   * Required permission\
   * Resource: **access-filters**\
   * Action: **query**
   */
  accessFilters: AccessFilterQueryResult;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  activities: ActivityQueryResult;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  activity?: Maybe<Activity>;
  /**
   * Query for building activity filter criteria for access filters. The result of the activity filter can be tested via this query
   *
   * Required permission\
   * Resource: **activities.access-filters**\
   * Action: **query**
   * @deprecated ActivityAccessFilter is only for internal use and will be removed in future versions
   */
  activityAccessFilter: ActivityQueryResult;
  /**
   * Query word-based autocomplete suggestions for activities.
   * Suggestions are found within activity titles and extracted texts
   * and must be at least 3 characters long. The result contains up to
   * 5 suggestions.
   *
   * Required permission\
   * Resource: **activities.autocomplete**\
   * Action: **query**
   */
  activityAutocomplete: ActivityAutocompleteResult;
  /** Returns details about the application providing this API */
  api?: Maybe<ComponentInfo>;
  /**
   * Returns the app key for the current user. For security reasons only metadata about the key is returned.
   *
   * Required permission\
   * Resource: **user.app-key**\
   * Action: **query**
   */
  appKey?: Maybe<ApiKey>;
  /**
   * Get an article it's unique id.
   *
   * Required permission\
   * FeatureId: **spare_parts**\
   * Resource: **article**\
   * Action: **query**
   */
  article?: Maybe<Article>;
  /**
   * Query word-based autocomplete suggestions for articles.
   * Suggestions are found within article titles
   * and must be at least 3 characters long. The result contains up to
   * 5 suggestions.
   * Enter as many available languages as you want and get search hits in the same order as the languages were specified.
   * Get hits by any languages if 'languages' left empty.
   *
   * Required permission\
   * Resource: **articles.autocomplete**\
   * Action: **query**
   */
  articleAutocomplete: ArticleAutocompleteResult;
  /**
   * Query `Articles`s. Filters con be specified via the `filter` argument. See `ArticleFilter` for further details.
   *
   * Required permission\
   * FeatureId: **spare_parts**\
   * Resource: **articles**\
   * Action: **query**
   */
  articles: ArticleQueryResult;
  /**
   * Get an asset it's unique id.
   *
   * Required permission\
   * Resource: **asset**\
   * Action: **query**
   */
  asset?: Maybe<Asset>;
  /**
   * Query for building asset filter criteria for access filters. The result of the asset filter can be tested via this query
   *
   * Required permission\
   * Resource: **assets.access-filters**\
   * Action: **query**
   */
  assetAccessFilter: AssetQueryResult;
  /**
   * Query word-based autocomplete suggestions for assets.
   * Suggestions are found within asset titles
   * and must be at least 3 characters long. The result contains up to
   * 5 suggestions.
   * Enter as many available languages as you want and get search hits in the same order as the languages were specified.
   * Get hits by any languages if 'languages' left empty.
   *
   * Required permission\
   * Resource: **assets.autocomplete**\
   * Action: **query**
   */
  assetAutocomplete: AssetAutocompleteResult;
  /**
   * Query `Assets`s. Filters con be specified via the `filter` argument. See `AssetFilter` for further details.
   *
   * Required permission\
   * Resource: **assets**\
   * Action: **query**
   */
  assets: AssetQueryResult;
  /**
   * Lists all available facets.
   * Including every possible datafield which can be used as
   * facet as well as the possible fields.
   *
   * Required permission\
   * Resource: **facets.available-definitions**\
   * Action: **query**
   */
  availableFacetDefinitions?: Maybe<Array<Maybe<Facet>>>;
  /**
   * Queries the available features for the tenant.
   *
   * Required permission\
   * Resource: **features**\
   * Action: **query**
   */
  availableFeatures?: Maybe<Array<Maybe<Feature>>>;
  /**
   * Fetch `Blob`s by filter criteria.
   *
   * Required permission\
   * Resource: **blobs**\
   * Action: **query**
   */
  blobs?: Maybe<BlobQueryResult>;
  /**
   * Get a single collection by its unique id.
   * This query allows access to public collections, personal collections of the requesting user as well as
   * personal collections of other users and thus can be used to get the details of a shared collection.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **query.by-id**
   */
  collection?: Maybe<Collection>;
  /**
   * Query `Collection`s. Filters can be specified via the `filter` argument. See `CollectionFilter` for further details.
   * The results can contain public collections as well as personal collections of the requesting user. Personal
   * collections of other users won't be returned.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **query**
   */
  collections: CollectionQueryResult;
  /**
   * Returns a configuration file of type Config of available configs
   *
   * Required permission\
   * Resource: **config**\
   * Action: **query**
   */
  config?: Maybe<Config>;
  /**
   * Query for building content filter criteria for access filters. The result of the content filter can be tested via this query
   *
   * Required permission\
   * Resource: **contents.access-filters**\
   * Action: **query**
   */
  contentAccessFilter: ContentQueryResult;
  /**
   * Query word-based autocomplete suggestions for contents.
   * Suggestions are found within content titles and extracted texts
   * and must be at least 3 characters long. The result contains up to
   * 5 suggestions.
   *
   * Required permission\
   * Resource: **contents.autocomplete**\
   * Action: **query**
   */
  contentAutocomplete: ContentAutocompleteResult;
  /**
   * Query the uses of a specific content in an use context (e.g. in a content map).
   *
   * Returns all uses of the content in the specified context as TOC entries.
   */
  contentInContext?: Maybe<Array<TocEdge>>;
  /** Query available `ContentMap`s. Filters can be specified via the `filter` argument. See `ContentMapFilter` for further details. */
  contentMaps: ContentMapQueryResult;
  /** Query available `Content`s. Filters can be specified via the `filter` argument. See `ContentFilter` for further details. */
  contents: ContentQueryResult;
  /**
   * Query a data display config by its unique id.
   *
   * Required permission\
   * Resource: **data-display-configs**\
   * Action: **query**
   */
  dataDisplayConfig?: Maybe<DataDisplayConfig>;
  /**
   * Queries all available fields and indexed fields which may be
   * used as entry in the data display config.
   *
   * Required permission\
   * Resource: **data-display-configs.available-entries**\
   * Action: **query**
   */
  dataDisplayConfigAvailableEntries?: Maybe<Array<DataDisplayConfigEntry>>;
  /**
   * Returns autocomplete suggestions from the values of the selected datafield definitions.
   * The query string is compared against the value's title and the first matching values are returned.
   *
   * Required permission\
   * Resource: **datafield-definitions.autocomplete**\
   * Action: **query**
   */
  datafieldAutocomplete: DatafieldAutocompleteResult;
  /**
   * Get a `DatafieldDefinition` by the definition's unique id
   *
   * Required permission\
   * Resource: **datafield-definitions**\
   * Action: **query**
   */
  datafieldDefinition?: Maybe<DatafieldDefinition>;
  /**
   * Returns all datafield definitions as datafield model,
   * compliant to the datafield model JSON schema (see `datafieldModelJSONSchema`)
   */
  datafieldModel?: Maybe<Scalars['JSONObject']>;
  /** Provides the datafield model JSON Schema */
  datafieldModelJSONSchema?: Maybe<Scalars['JSONObject']>;
  /**
   * Queries all available fields which may be
   * used as entry in different list configs.
   *
   * Required permission\
   * Resource: **datafield.type**\
   * Action: **query**
   */
  datafields?: Maybe<Datafields>;
  /**
   * Query `Collection`s which are editable by the current user.
   * Filters can be specified via the `filter` argument. See `CollectionFilter` for further details.
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **write**
   */
  editableCollections: CollectionQueryResult;
  /**
   * Returns autocomplete suggestions from the values of the selected filter selections.
   * The query string is compared against the value's title and the first matching values are returned.
   *
   * Required permission\
   * Resource: **datafield-definitions.autocomplete**\
   * Action: **query**
   */
  facetDatafieldAutocomplete: FacetDatafieldAutocompleteResult;
  /**
   * Query a facets collection by id.
   *
   * Required permission\
   * Resource: **facet-collections**\
   * Action: **query**
   */
  facetsCollection?: Maybe<FacetsCollection>;
  /**
   * Query `DatafieldDefinition`s. Filters can be specified via the `filter` argument. See `DatafieldDefinitionFilter` for further details.
   *
   * Required permission\
   * Resource: **datafield-definitions**\
   * Action: **query**
   */
  fieldDefinitions: DatafieldDefinitionQueryResult;
  /**
   * Get a single file by its unique id.
   *
   * Required permission\
   * Resource: **files**\
   * Action: **query**
   */
  file?: Maybe<File>;
  /**
   * Query `File`s. Filters con be specified via the `filter` argument. See `FileFilter` for further details.
   *
   * Required permission\
   * Resource: **files**\
   * Action: **query**
   */
  files: FileQueryResult;
  /**
   * Get the requesting users user settings. The settings may be filtered
   * by setting the `keys` argument to only receive the specified entries.
   * To get all settings omit the keys parameter.
   *
   * Required permission\
   * Resource: **users-settings**\
   * Action: **query**
   */
  getUserSettings?: Maybe<UserSettings>;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  insights: InsightsQueryResult;
  /** Query available pipelines */
  jobPipelines: JobPipelineQueryResult;
  /**
   * Returns the current user's data.
   *
   * Required permission\
   * Resource: **users.me**\
   * Action: **query**
   */
  me: User;
  /**
   * Get an mechanic object by it's unique id.
   *
   * Required permission\
   * FeatureId: **spare_parts**\
   * Resource: **mechanics**\
   * Action: **query**
   */
  mechanic?: Maybe<Mechanic>;
  /**
   * Query word-based autocomplete suggestions for mechanics.
   * Suggestions are found within mechanic titles
   * and must be at least 3 characters long. The result contains up to
   * 5 suggestions.
   * Enter as many available languages as you want and get search hits in the same order as the languages were specified.
   * Get hits by any languages if 'languages' left empty.
   *
   * Required permission\
   * Resource: **mechanics.autocomplete**\
   * Action: **query**
   */
  mechanicAutocomplete: MechanicAutocompleteResult;
  /**
   * Returns the mechanic objects matching the filter rules
   *
   * Use the `productId` argument to limit the mechanic results to a specific product.
   * Use the `assetId` argument to limit the mechanic results to a specific product asset.
   *
   * Please note: If `assetId` argument is used, `productId` argument is mandatory!
   *
   * Required permission\
   * FeatureId: **spare_parts**\
   * Resource: **mechanics**\
   * Action: **query**
   */
  mechanics: MechanicQueryResult;
  /**
   * Get a single note by its unique id.
   *
   * Required permission\
   * Resource: **notes**\
   * Action: **query**
   */
  note?: Maybe<Note>;
  /**
   * Query `Note`s. Filters con be specified via the `filter` argument. See `NoteFilter` for further details.
   *
   * Required permission\
   * Resource: **notes**\
   * Action: **query**
   */
  notes?: Maybe<NoteQueryResult>;
  /**
   * Query Notifications. Filters can be specified via the `filter` argument. See `NotificationFilter` for further details.
   *
   * Required permission\
   * Resource: **notifications**\
   * Action: **query**
   */
  notifications?: Maybe<NotificationQueryResult>;
  /**
   * Get a process by it's unique id.
   *
   * Required permission\
   * Resource: **processtypes**\
   * Action: **query**
   */
  process?: Maybe<Process>;
  /**
   * Get process-datafield-mappings by processType and columns.
   *
   * Required permission\
   * Resource: **processtypes**\
   * Action: **query**
   */
  processDatafieldMappings?: Maybe<Array<Maybe<ProcessDatafieldMapping>>>;
  /**
   * Get a all available process encoding types..
   *
   * Required permission\
   * Resource: **processtypes**\
   * Action: **query**
   */
  processEncodingTypes?: Maybe<Array<Maybe<ProcessEncodingType>>>;
  /**
   * Gets all available process types.
   *
   * Required permission\
   * Resource: **processtypes**\
   * Action: **query**
   */
  processTypes?: Maybe<Array<Maybe<ProcessType>>>;
  /**
   * Get a list of all processes.
   *
   * Required permission\
   * Resource: **processtypes**\
   * Action: **query**
   */
  processes?: Maybe<Array<Maybe<Process>>>;
  /**
   * Get a product by it's unique id.
   *
   * Required permission\
   * Resource: **product**\
   * Action: **query**
   */
  product?: Maybe<Product>;
  /**
   * Query for building product filter criteria for access filters. The result of the product filter can be tested via this query
   *
   * Required permission\
   * Resource: **products.access-filters**\
   * Action: **query**
   */
  productAccessFilter: ProductQueryResult;
  /**
   * Query word-based autocomplete suggestions for products.
   * Suggestions are found within product titles
   * and must be at least 3 characters long. The result contains up to
   * 5 suggestions.
   * Enter as many available languages as you want and get search hits in the same order as the languages were specified.
   * Get hits by any languages if 'languages' left empty.
   *
   * Required permission\
   * Resource: **products.autocomplete**\
   * Action: **query**
   */
  productAutocomplete: ProductAutocompleteResult;
  /**
   * Query `Product`s. Filters can be specified via the `filter` argument. See `ProductFilter` for further details.
   *
   * Required permission\
   * Resource: **products**\
   * Action: **query**
   */
  products: ProductQueryResult;
  /**
   * Query a search config by id.
   *
   * Required permission\
   * Resource: **search-configs**\
   * Action: **query**
   */
  searchConfig?: Maybe<SearchConfig>;
  /**
   * Get the search configs for searchable entries.
   *
   * Required permission\
   * Resource: **search-configs.searchable-entries**\
   * Action: **query**
   */
  searchConfigSearchableEntries?: Maybe<Array<SearchConfigEntry>>;
  /** Simulates a given user */
  simulateUser?: Maybe<User>;
  /**
   * Get an static page by page type.
   * The acceptedLanguages field can be used to tell the system which languages are accepted by the consumer.
   * Heads up: The order of the languages controls their priority in descending order, thus the foremost found language will be returned.
   *
   * Required permission\
   * Resource: **static-pages**\
   * Action: **query**
   */
  staticPage?: Maybe<StaticPage>;
  /**
   * Query the static page menu.
   *
   * Required permission\
   * Resource: **static-pages**\
   * Action: **query**
   */
  staticPageMenu?: Maybe<StaticPageMenu>;
  /**
   * Queries all existing page types.
   *
   * Required permission\
   * Resource: **static-pages**\
   * Action: **query**
   */
  staticPageTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Query `StaticPage`s. Filters con be specified via the `filter` argument. See `StaticPageFilter` for further details.
   *
   * Required permission\
   * Resource: **static-pages**\
   * Action: **query**
   */
  staticPages: StaticPageQueryResult;
  /**
   * Query status of export collection
   *
   * Required permission\
   * Resource: **collections**\
   * Action: **export**
   */
  statusExportCollection: CollectionExportResult;
  /** Returns the current tenant. */
  tenant: Tenant;
  /**
   * Queries the theme configuration.
   * If there's no custom theme defined, it will return the default theme.
   */
  theme?: Maybe<Theme>;
  /** Query available `Topic`s. Filters can be specified via the `filter` argument. See `TopicFilter` for further details. */
  topics: TopicQueryResult;
  /**
   * Get a user's data by the user's unique id
   *
   * Required permission\
   * Resource: **users**\
   * Action: **query**
   */
  user?: Maybe<User>;
  /**
   * Query `User`s. Filters can be specified via the `filter` argument. See `UserFilter` for further details.
   *
   * Required permission\
   * Resource: **users**\
   * Action: **query**
   */
  users: UserQueryResult;
  /**
   * Returns the server version.
   * Deprecated. Use query `api { version }` instead
   * @deprecated Field no longer supported
   */
  version?: Maybe<VersionInfo>;
  /**
   * Get a `WebhookConfig` by the config's unique id
   *
   * Required permission\
   * Resource: **webhook-configs**\
   * Action: **query**
   */
  webhookConfig?: Maybe<WebhookConfig>;
  /**
   * Query `WebhookConfig`s. Filters can be specified via the `filter` argument. See `WebhookConfigFilter` for further details.
   *
   * Required permission\
   * Resource: **webhook-configs**\
   * Action: **query**
   */
  webhookConfigs: WebhookConfigQueryResult;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryAccessFilterArgs = {
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryAccessFiltersArgs = {
  filter?: InputMaybe<AccessFilterFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryActivitiesArgs = {
  filter?: InputMaybe<ActivityFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryActivityArgs = {
  id: Scalars['String'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryActivityAccessFilterArgs = {
  filter: ActivityAccessFilter;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryActivityAutocompleteArgs = {
  filter?: InputMaybe<ActivityFilter>;
  query?: InputMaybe<Scalars['String']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryArticleArgs = {
  id: Scalars['String'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryArticleAutocompleteArgs = {
  filter?: InputMaybe<ArticleFilter>;
  languages?: InputMaybe<Array<InputMaybe<Language>>>;
  query?: InputMaybe<Scalars['String']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryArticlesArgs = {
  filter?: InputMaybe<ArticleFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryAssetArgs = {
  id: Scalars['String'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryAssetAccessFilterArgs = {
  filter: AssetAccessFilter;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryAssetAutocompleteArgs = {
  filter?: InputMaybe<AssetFilter>;
  languages?: InputMaybe<Array<InputMaybe<Language>>>;
  query?: InputMaybe<Scalars['String']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryAssetsArgs = {
  filter?: InputMaybe<AssetFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryAvailableFacetDefinitionsArgs = {
  objectType?: InputMaybe<Scalars['String']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryBlobsArgs = {
  filter?: InputMaybe<BlobFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryCollectionArgs = {
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryCollectionsArgs = {
  filter?: InputMaybe<CollectionFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryContentAccessFilterArgs = {
  filter: ContentAccessFilter;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryContentAutocompleteArgs = {
  filter?: InputMaybe<ContentFilter>;
  query?: InputMaybe<Scalars['String']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryContentInContextArgs = {
  contentId?: InputMaybe<Scalars['ID']>;
  rootContentId?: InputMaybe<Scalars['ID']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryContentMapsArgs = {
  filter?: InputMaybe<ContentMapFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryContentsArgs = {
  filter?: InputMaybe<ContentFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryDataDisplayConfigArgs = {
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryDatafieldAutocompleteArgs = {
  filter: DatafieldAutocompleteFilter;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryDatafieldDefinitionArgs = {
  id: Scalars['DatafieldDefinitionID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryEditableCollectionsArgs = {
  filter?: InputMaybe<CollectionFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryFacetDatafieldAutocompleteArgs = {
  filter: FacetDatafieldAutocompleteFilter;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryFacetsCollectionArgs = {
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryFieldDefinitionsArgs = {
  filter?: InputMaybe<DatafieldDefinitionFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryFileArgs = {
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryFilesArgs = {
  filter?: InputMaybe<FileFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryGetUserSettingsArgs = {
  keys?: InputMaybe<Array<Scalars['String']>>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryInsightsArgs = {
  role?: InputMaybe<InsightsRole>;
  search: InsightsSearchInput;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryJobPipelinesArgs = {
  filter?: InputMaybe<JobPipelineFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryMechanicArgs = {
  id: Scalars['String'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryMechanicAutocompleteArgs = {
  filter?: InputMaybe<MechanicFilter>;
  languages?: InputMaybe<Array<InputMaybe<Language>>>;
  query?: InputMaybe<Scalars['String']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryMechanicsArgs = {
  assetId?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<MechanicFilter>;
  productId?: InputMaybe<Scalars['ID']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryNoteArgs = {
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryNotesArgs = {
  filter?: InputMaybe<NoteFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryNotificationsArgs = {
  filter?: InputMaybe<NotificationFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryProcessArgs = {
  id: Scalars['String'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryProcessDatafieldMappingsArgs = {
  columns: Array<Scalars['String']>;
  processType: ProcessType;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryProductArgs = {
  id: Scalars['String'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryProductAccessFilterArgs = {
  filter: ProductAccessFilter;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryProductAutocompleteArgs = {
  filter?: InputMaybe<ProductFilter>;
  languages?: InputMaybe<Array<InputMaybe<Language>>>;
  query?: InputMaybe<Scalars['String']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryProductsArgs = {
  filter?: InputMaybe<ProductFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QuerySearchConfigArgs = {
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QuerySearchConfigSearchableEntriesArgs = {
  objectType?: InputMaybe<Scalars['String']>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QuerySimulateUserArgs = {
  userSpec: CreateUserInput;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryStaticPageArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
  pageType: Scalars['String'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryStaticPagesArgs = {
  filter?: InputMaybe<StaticPageFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryStatusExportCollectionArgs = {
  jobId: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryTopicsArgs = {
  filter?: InputMaybe<TopicFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryUserArgs = {
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilter>;
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryWebhookConfigArgs = {
  id: Scalars['ID'];
};


/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type QueryWebhookConfigsArgs = {
  filter?: InputMaybe<WebhookConfigFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type RangeDatafieldMapping = ProcessDatafieldMapping & {
  __typename?: 'RangeDatafieldMapping';
  /** Returns column(s) which also map to the same field. In this case a process cannot be created due to ambigiuousness. */
  ambiguousColumns?: Maybe<Array<Scalars['String']>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  column: Scalars['String'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  field?: Maybe<Datafield>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  operator: RangeOperator;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum RangeOperator {
  gte = 'gte',
  lte = 'lte'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type RawDatafield = {
  __typename?: 'RawDatafield';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  key: Scalars['String'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  values: Array<RawDatafieldValue>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type RawDatafieldSpec = {
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  key: Scalars['String'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  values: Array<RawDatafieldValueSpec>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type RawDatafieldValue = {
  __typename?: 'RawDatafieldValue';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  metaMetadata?: Maybe<Scalars['JSONObject']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  value: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type RawDatafieldValueSpec = {
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  metaMetadata?: InputMaybe<Scalars['JSONObject']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  value: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ResourceActionInput = {
  /** Allowed actions for the specified rendition */
  actions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Rendition of the allowed actions */
  rendition?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ResourceActions = {
  __typename?: 'ResourceActions';
  /** Allowed actions for the specified rendition */
  actions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Rendition of the allowed actions */
  rendition?: Maybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type SearchConfig = {
  __typename?: 'SearchConfig';
  /**
   * Entries of this search config
   * The Field is ordered and always returns the entries in the defined order.
   */
  entries?: Maybe<Array<SearchConfigEntry>>;
  /** The unique id */
  id: Scalars['ID'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type SearchConfigDatafieldEntry = SearchConfigEntry & {
  __typename?: 'SearchConfigDatafieldEntry';
  /** Boosts the score of all matches for this entry the specified factor. Please note that the resulting score boost isn't mathematically exact due to rounding errors */
  boost?: Maybe<Scalars['Float']>;
  /** The full indexed field definition */
  datafieldDefinition?: Maybe<DatafieldDefinition>;
  /** The indexed field definition id */
  datafieldId: Scalars['DatafieldDefinitionID'];
  /** The entries meta type */
  fieldType: FieldDefinitionTypes;
  /** The referenced indexed field definition id */
  referencedId: Scalars['ID'];
  /** Length limited text for the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<SearchConfigEntryTeasers>;
  /** The title of the indexed field definition */
  title?: Maybe<MultilingualString>;
  /** The entries type */
  type: DatafieldType;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type SearchConfigEntry = {
  /** Boosts the score of all matches for this entry the specified factor. Please note that the resulting score boost isn't mathematically exact due to rounding errors */
  boost?: Maybe<Scalars['Float']>;
  /** The entries meta type */
  fieldType: FieldDefinitionTypes;
  /** The id of the field, indexed field definition, etc. referenced by this entry */
  referencedId: Scalars['ID'];
  /** Length limited text for the entry for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<SearchConfigEntryTeasers>;
  /** The title of the entry */
  title?: Maybe<MultilingualString>;
  /** The entries type */
  type: DatafieldType;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type SearchConfigEntryInput = {
  /** Boost factor for this entry */
  boost?: InputMaybe<Scalars['Float']>;
  /** The id of the field, indexed field definition, etc. referenced by this entry */
  referencedId: Scalars['ID'];
  /** The entries type */
  type: DatafieldType;
};

/** Length limited text from the indexed field definition value for use e.g. in result lists, previews, etc. */
export type SearchConfigEntryTeasers = {
  __typename?: 'SearchConfigEntryTeasers';
  /**
   * The value's title limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the indexed field definition value for use e.g. in result lists, previews, etc. */
export type SearchConfigEntryTeasersTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type SearchConfigFieldEntry = SearchConfigEntry & {
  __typename?: 'SearchConfigFieldEntry';
  /** Boosts the score of all matches for this entry the specified factor. Please note that the resulting score boost isn't mathematically exact due to rounding errors */
  boost?: Maybe<Scalars['Float']>;
  /** The entries meta type */
  fieldType: FieldDefinitionTypes;
  /** The referenced field name */
  referencedId: Scalars['ID'];
  /** Length limited text for the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<SearchConfigEntryTeasers>;
  /** The title of the indexed field definition */
  title?: Maybe<MultilingualString>;
  /** The entries type */
  type: DatafieldType;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum SelectionMode {
  deep = 'DEEP',
  shallow = 'SHALLOW'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum SelectionModeSpec {
  deep = 'DEEP',
  shallow = 'SHALLOW'
}

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum SortOrder {
  /** Ascending */
  asc = 'asc',
  /** Descending */
  desc = 'desc'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type StaticPage = {
  __typename?: 'StaticPage';
  /** The content of the page */
  contents?: Maybe<Array<Maybe<BlobRef>>>;
  /** Unique identifier for the static page */
  id: Scalars['ID'];
  /** The language of the static page */
  locale?: Maybe<Scalars['String']>;
  /** The page type */
  pageType?: Maybe<Scalars['String']>;
  /** The title */
  title: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type StaticPageEdge = {
  __typename?: 'StaticPageEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** The result's static page. */
  node: StaticPage;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type StaticPageEqualsValueFilter = {
  /** Returns only static pages with the specified locale */
  locale?: InputMaybe<Scalars['String']>;
  /** Returns only static pages with the specified page type */
  pageType?: InputMaybe<Scalars['String']>;
  /** Returns only static pages with the specified title */
  title?: InputMaybe<Scalars['String']>;
};

/**
 * The ` StaticPageFilter` is used to specify filter criteria for static pages.
 * If you specify more than one criterion, all criteria are grouped together and connected with a logical AND operator.
 * Further logical operations are possible by using `not` `andGroup` and `orGroup`.
 */
export type StaticPageFilter = {
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<StaticPageFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns static pages that contain all of the exact values specified */
  equals?: InputMaybe<StaticPageEqualsValueFilter>;
  /** Returns static pages with one of the specified unique identifiers */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<StaticPageFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<StaticPageFilter>>>;
  /** Returns static pages that match all of the regular expressions */
  regex?: InputMaybe<StaticPageRegexFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type StaticPageMenu = {
  __typename?: 'StaticPageMenu';
  /** Unique id of the page menu */
  id: Scalars['ID'];
  /** The static pages referenced by this menu */
  pages?: Maybe<Array<Maybe<StaticPageMenuEntry>>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type StaticPageMenuEntry = {
  __typename?: 'StaticPageMenuEntry';
  /** The page type of the referenced static pages */
  pageType: Scalars['String'];
  /** The full static pages */
  staticPages?: Maybe<Array<Maybe<StaticPage>>>;
  /** Length limited text from the combined titles for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<StaticPageMenuEntryTeasers>;
  /** The combined titles of the differntly localized static pages */
  title?: Maybe<MultilingualString>;
};

/** Length limited text from the note for use e.g. in result lists, previews, etc. */
export type StaticPageMenuEntryTeasers = {
  __typename?: 'StaticPageMenuEntryTeasers';
  /** The static page's title limited to `length` characters */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the note for use e.g. in result lists, previews, etc. */
export type StaticPageMenuEntryTeasersTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type StaticPageQueryResult = {
  __typename?: 'StaticPageQueryResult';
  /**
   * The query's matching static page.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  staticPages: Array<Maybe<StaticPageEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type StaticPageQueryResultStaticPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<StaticPageSort>>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type StaticPageQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type StaticPageRegexFilter = {
  /** Returns only static pages whose display name matches the specified Regex */
  title?: InputMaybe<Scalars['FilterRegex']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type StaticPageSort = {
  /** Sort field */
  field: StaticPageSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum StaticPageSortFields {
  /** Sort by unique ID */
  id = 'id',
  /** Sort by locale */
  locale = 'locale',
  /** Sort by relevance score */
  score = 'score',
  /** Sort by pageTitle */
  title = 'title'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type StaticPagesUploadConfig = {
  __typename?: 'StaticPagesUploadConfig';
  /** Upload config for the static page contents */
  content?: Maybe<UploadConfig>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type SynonymOptions = {
  /**
   * Indicates if a fuzzy search should be performed on synonyms matching.
   *
   * Be aware: This options doesn't enable fuzzy searching on documents. When enabled
   * the matching synonyms for the query will be retrieved using fuzzy search. To configure fuzzy search
   * for the content search itself please refer to fuzzy parameter in the full text search options.
   */
  fuzzy?: InputMaybe<Scalars['Boolean']>;
  /** Indicates whether the synonyms are ignored in the search */
  ignore?: InputMaybe<Scalars['Boolean']>;
  /** The languages synonyms will be searched in */
  languages?: InputMaybe<Array<InputMaybe<Language>>>;
};

/** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum Target {
  blank = '_blank',
  self = '_self'
}

/** Defines a taxonomy datafield. */
export type TaxonomyDatafieldDefinition = DatafieldDefinition & {
  __typename?: 'TaxonomyDatafieldDefinition';
  /** Defines whether the taxonomy pretends to know unknown values or not */
  approveUnknownValues?: Maybe<Scalars['Boolean']>;
  /** The rule to be used when filtering using this taxonomy */
  evaluationType?: Maybe<TaxonomyEvaluationType>;
  /** Type of this indexed field definition */
  fieldType?: Maybe<FieldDefinitionTypes>;
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** The taxonomy's hierarchy of keys */
  keyHierarchy?: Maybe<Scalars['JSONObject']>;
  /** Text from the datafield definition for use e.g. in detail views, etc */
  localizations?: Maybe<DatafieldDefinitionLocalizations>;
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames?: Maybe<Array<Scalars['String']>>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: Maybe<Array<Maybe<ObjectType>>>;
  /** Length limited text from the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldDefinitionTeasers>;
  /** Human readable description of this indexed field definition */
  title?: Maybe<MultilingualString>;
  /** All of the taxonomy's values in a flattened list */
  values?: Maybe<Array<TaxonomyValue>>;
};

/**
 * Data to update a taxonomy datafield.
 * All fields except `id` are optional. Only provided fields will be updated.
 * If provided, they will replace the current value.
 */
export type TaxonomyDatafieldDefinitionDiff = {
  /**
   * If true the taxonomy-mapper returns true for unknown values
   * instead of ignoring them. Be carefull if you change this value because
   * there will be no reindex of existing objectTypes so the change will
   * only affect new imports.
   */
  approveUnknownValues?: InputMaybe<Scalars['Boolean']>;
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames?: InputMaybe<Array<Scalars['String']>>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: InputMaybe<Array<InputMaybe<ObjectType>>>;
  /** The taxonomy's root values */
  rootValues?: InputMaybe<Array<TaxonomyValueSpec>>;
  /** Human readable description of this indexed field definition */
  title?: InputMaybe<MultilingualStringInput>;
};

/** Data to create a taxonomy datafield. */
export type TaxonomyDatafieldDefinitionSpec = {
  /**
   * If true the taxonomy-mapper returns true for unknown values
   * instead of ignoring them.
   */
  approveUnknownValues?: InputMaybe<Scalars['Boolean']>;
  /**
   * The rule to be used when filtering using this taxonomy.
   * Cannot be changed after creation.
   */
  evaluationType: TaxonomyEvaluationType;
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames: Array<Scalars['String']>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: InputMaybe<Array<InputMaybe<ObjectType>>>;
  /** The taxonomy's root values */
  rootValues: Array<TaxonomyValueSpec>;
  /** Human readable description of this indexed field definition */
  title: MultilingualStringInput;
};

/** Available rules to be used when a filter uses a taxonomy */
export enum TaxonomyEvaluationType {
  /**
   * A filter will return all objects that either have the specified taxonomy value
   * itself or any of its ancestors or any of its descendants
   */
  ancestorsOrDescendantsOrSelf = 'AncestorsOrDescendantsOrSelf',
  /**
   * A filter will return all objects that either have the specified taxonomy value
   * itself or any of its ancestors
   */
  ancestorsOrSelf = 'AncestorsOrSelf',
  /**
   * A filter will return all objects that either have the specified taxonomy value
   * itself or any of its descendants
   */
  descendantsOrSelf = 'DescendantsOrSelf',
  /** A filter will return all objects have the specified taxonomy value itself */
  self = 'Self'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TaxonomyFacetDefinition = FacetDefinition & {
  __typename?: 'TaxonomyFacetDefinition';
  /** The datafield type of the field */
  fieldType: FieldDefinitionTypes;
  /** The taxonomy's hierarchy of keys */
  keyHierarchy?: Maybe<Scalars['JSONObject']>;
  /** All of the taxonomy's values in a flattened list */
  values?: Maybe<Array<TaxonomyValue>>;
};

/** A single value in the taxonomy */
export type TaxonomyValue = {
  __typename?: 'TaxonomyValue';
  /** Unique data value of this entry in this taxonomy */
  key: Scalars['String'];
  /**
   * The flattened path to this taxonomy value
   * The path contains all keys of ancestors and the value's own key
   */
  pathKeys?: Maybe<Array<Scalars['String']>>;
  /** Length limited text from the taxonomy value for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldValueTeasers>;
  /** Human readable description of this taxonomy value */
  title?: Maybe<MultilingualString>;
};

/** A single value in the taxonomy */
export type TaxonomyValueSpec = {
  /** Descendant values of this taxonomy value */
  childValues?: InputMaybe<Array<TaxonomyValueSpec>>;
  /** Unique id of the taxonomy value in this taxonomy */
  key: Scalars['String'];
  /** Human readable description of this taxonomy value */
  title: MultilingualStringInput;
};

/** A single tenant in Exegol */
export type Tenant = {
  __typename?: 'Tenant';
  /** The tenant authentication config */
  auth?: Maybe<TenantAuthConfig>;
  /** CORS options */
  corsOptions?: Maybe<TenantCorsOptions>;
  /** Domain name for the tenant's app and api as an alternative to the default sub-domain */
  customDomain?: Maybe<Scalars['String']>;
  /** Active custom features */
  customFeatures?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** InfoTwin specific custom links for the menu. */
  customLinks?: Maybe<Array<CustomLink>>;
  /** The tenant's provider specific identifier */
  displayName: Scalars['String'];
  /** The domain name under which the tenant's content delivery services are provided */
  domain: Scalars['String'];
  /** Date when the tenant will expire */
  expires?: Maybe<Scalars['DateTime']>;
  /** The tenant's feature plan ID */
  featurePlan: Scalars['String'];
  /** Additional html meta tags */
  htmlMetaTags?: Maybe<Array<Maybe<TenantHtmlMetaTag>>>;
  /** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Specific landing page for the tenant. Example: /docs */
  landingPage?: Maybe<Scalars['String']>;
  /** The tenant's language configuration */
  languages?: Maybe<LanguagesQueryResult>;
  /** Licensing information for the tenant. */
  license?: Maybe<License>;
  /** Operation mode of the application. If set to other mode than online, requests to the API may be rejected. */
  operationMode?: Maybe<TenantOperationMode>;
  /** InfoTwin specific settings for the tenant. */
  tenantSettings?: Maybe<TenantSettings>;
  /** Upload configs */
  uploadConfig?: Maybe<TenantUploadConfig>;
};


/** A single tenant in Exegol */
export type TenantCustomLinksArgs = {
  language: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum TenantAccessMode {
  /** Only anonymous users may access the api */
  anonymous = 'anonymous',
  /** Only users authenticated via one of the configured authenticators can access the api */
  authenticated = 'authenticated',
  /** Both anonymous and users authenticated via one of the configured authenticators may access the api */
  mixed = 'mixed'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TenantAllowedMimeTypesInput = {
  /** Allowed mime types for note attachments */
  noteAttachments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TenantAuthConfig = {
  __typename?: 'TenantAuthConfig';
  /** The access mode for the tenant, which indicates which types of users should be able to authenticate */
  accessMode: TenantAccessMode;
  /** The available authenticators */
  authenticators?: Maybe<AuthenticatorQueryResult>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum TenantAuthenticatorStatus {
  /** Authenticator is active and visible to the user */
  active = 'active',
  /** Authenticator is marked as active but hidden. It should not be visible to the user. Frontend should not show it. */
  hidden = 'hidden'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TenantCorsOptions = {
  __typename?: 'TenantCorsOptions';
  /** The 'Access-Control-Allow-Headers' CORS header. */
  allowedHeaders?: Maybe<Array<Scalars['String']>>;
  /** The 'Access-Control-Allow-Credentials' CORS header. */
  credentials?: Maybe<Scalars['Boolean']>;
  /** The 'Access-Control-Expose-Headers' CORS header. */
  exposedHeaders?: Maybe<Array<Scalars['String']>>;
  /** The 'Access-Control-Max-Age' CORS header. */
  maxAge?: Maybe<Scalars['NonNegativeInt']>;
  /** The status code for the successful OPTIONS preflight request. */
  optionsSuccessStatus?: Maybe<Scalars['PositiveInt']>;
  /** The 'Access-Control-Allow-Origins' CORS header. */
  origin?: Maybe<Array<Scalars['String']>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TenantHtmlMetaTag = {
  __typename?: 'TenantHtmlMetaTag';
  /** The condition for the meta tag to be added to the html head */
  conditions?: Maybe<TenantHtmlMetaTagConditions>;
  /** The meta tag's content */
  content: Scalars['String'];
  /** The meta tag's name */
  name: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TenantHtmlMetaTagConditions = {
  __typename?: 'TenantHtmlMetaTagConditions';
  /** If true, meta tag is only added, when a custom domain is configured */
  customDomain?: Maybe<Scalars['Boolean']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TenantLanguagesInput = {
  /** Available languages for the tenant */
  available?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The tenant´s default language */
  default?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum TenantOperationMode {
  /** The application is in emergency mode and requests to the API are rejected */
  emergency = 'emergency',
  /** The application is online and requests to the API are accepted */
  online = 'online'
}

/** Sub graph: [https://tenant-settings-graph.qit-settings.svc.cluster.local:8443](https://tenant-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TenantSettings = {
  __typename?: 'TenantSettings';
  /** Docs route params as key value pair. */
  docsRouteParams?: Maybe<Scalars['JSONObject']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TenantUploadConfig = {
  __typename?: 'TenantUploadConfig';
  /** Upload configs for contents */
  contents?: Maybe<ContentUploadConfig>;
  /** Upload config for the general data upload */
  data?: Maybe<DataUploadConfig>;
  /** Upload configs for datafield uploads */
  datafield?: Maybe<DatafieldUploadConfig>;
  /** Ignore this field */
  dummy?: Maybe<Scalars['Boolean']>;
  /** Upload config for notes */
  notes?: Maybe<NotesUploadConfig>;
  /** Upload config for the static pages */
  staticPages?: Maybe<StaticPagesUploadConfig>;
  /** Upload config for the themes */
  themes?: Maybe<ThemeUploadConfig>;
};

/** Options to test an ad hoc webhook config via `testWebhookConfig` */
export type TestWebhookConfigInput = {
  /** The event type for this test */
  eventType?: InputMaybe<WebhookEventTypes>;
  /** The shared secret for the triggered webhook */
  sharedSecret?: InputMaybe<Scalars['String']>;
  /** The URL of the webhook to be triggered */
  url: Scalars['URL'];
};

/**
 * Defines a text datafield.
 * A text datafield is a string field which can be used for full text
 * filtering, fuzzy search, etc.
 */
export type TextDatafieldDefinition = DatafieldDefinition & {
  __typename?: 'TextDatafieldDefinition';
  /** Type of this indexed field definition */
  fieldType?: Maybe<FieldDefinitionTypes>;
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Text from the datafield definition for use e.g. in detail views, etc */
  localizations?: Maybe<DatafieldDefinitionLocalizations>;
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames?: Maybe<Array<Scalars['String']>>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: Maybe<Array<Maybe<ObjectType>>>;
  /** Length limited text from the indexed field definition for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<DatafieldDefinitionTeasers>;
  /** Human readable description of this indexed field definition */
  title?: Maybe<MultilingualString>;
};

/**
 * Data to update a text datafield.
 * A text datafield is a string field which can be used for full text
 * filtering, fuzzy search, etc.
 * All fields except `id` are optional. Only provided fields will be updated.
 * If provided, they will replace the current value.
 */
export type TextDatafieldDefinitionDiff = {
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames?: InputMaybe<Array<Scalars['String']>>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: InputMaybe<Array<InputMaybe<ObjectType>>>;
  /** Human readable description of this indexed field definition */
  title?: InputMaybe<MultilingualStringInput>;
};

/**
 * Data to create a text datafield.
 * A text datafield is a string field which can be used for full text
 * filtering, fuzzy search, etc.
 */
export type TextDatafieldDefinitionSpec = {
  /**
   * Unique identifier of this indexed field definition.
   * Is used as field name in all interfaces and must adhere to the
   * following pattern: ^[_A-Za-z][_0-9A-Za-z]{0,128}$
   */
  id: Scalars['DatafieldDefinitionID'];
  /** Datafield names which shall be mapped to this indexed field definition */
  mappedNames: Array<Scalars['String']>;
  /** List of all objectTypes this definition is assigned to */
  objectTypes?: InputMaybe<Array<InputMaybe<ObjectType>>>;
  /** Human readable description of this indexed field definition */
  title: MultilingualStringInput;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type Theme = {
  __typename?: 'Theme';
  /** The attachments of the theme */
  attachments?: Maybe<Array<Maybe<BlobRef>>>;
  /** The logo of the theme */
  logo?: Maybe<BlobRef>;
  /** Indicates if the title should be visible */
  showTitle: Scalars['Boolean'];
  /** The startpage logo of the theme */
  startPageLogo?: Maybe<BlobRef>;
  /** Length limited text from the theme for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<ThemeTeasers>;
  /** Theming JSONObject */
  theming?: Maybe<Scalars['JSONObject']>;
  /** The title of the portal */
  title: MultilingualString;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ThemeInput = {
  /** The reference to the blob used as logo */
  logo?: InputMaybe<BlobRefInput>;
  /** Indicates if the title should be visible */
  showTitle?: InputMaybe<Scalars['Boolean']>;
  /** The reference to the blob used as startpage logo */
  startPageLogo?: InputMaybe<BlobRefInput>;
  /** Theming JSONObject */
  theming?: InputMaybe<Scalars['JSONObject']>;
  /** The title for the portal */
  title: MultilingualStringInput;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ThemeTeasers = {
  __typename?: 'ThemeTeasers';
  /**
   * The theme's title limited to `length` characters.
   * Preferred language and the precedence of fallback languages can be specified via `acceptedLanguages`.
   * If no value is found for any of the accepted languages, `title` uses the English (`en`) value
   */
  title?: Maybe<Scalars['String']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ThemeTeasersTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<InputMaybe<LanguageWithWildcard>>>;
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type ThemeUploadConfig = {
  __typename?: 'ThemeUploadConfig';
  /** Upload config for fav icon */
  favIcon?: Maybe<UploadConfig>;
  /** Upload config for theme logos */
  logos?: Maybe<UploadConfig>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TocEdge = {
  __typename?: 'TocEdge';
  /** The Path to fetch this TOC entry's children */
  childPath: Scalars['String'];
  /** The TOC element's children */
  children?: Maybe<TocResult>;
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** True if this TOC element is a branch and has children, false if it's a leaf */
  hasChildren?: Maybe<Scalars['Boolean']>;
  /** Identifier of the referenced `Topic` or `ContentMap` */
  id: Scalars['ID'];
  /** The next child node in the table of contents. Traverses to the first child if the current node has children. */
  next?: Maybe<Content>;
  /** Referenced `Topic` or `ContentMap` */
  node?: Maybe<Content>;
  /** Paths of all entries on the track from root to this entry */
  parentPaths?: Maybe<Array<Scalars['String']>>;
  /** The Path in the TOC */
  path: Scalars['String'];
  /** The previous child node in the table of contents. Traverses to the last child of the previous sibling if the previous sibling has children. */
  previous?: Maybe<Content>;
};

/** Item in the content map's table of contents (TOC) */
export type TocMapEntry = {
  __typename?: 'TocMapEntry';
  /** Identifier of the referenced `Topic` or `ContentMap` */
  id: Scalars['ID'];
  /** Referenced `Topic` or `ContentMap` */
  node?: Maybe<Content>;
  /** The Path in the TOC */
  path: Scalars['String'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TocResult = {
  __typename?: 'TocResult';
  /**
   * The entries of a `ContentMap`'s TOC in the order they are defined.
   * To limit the number of results, set the `first` argument.
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   */
  tocEntries?: Maybe<Array<TocEdge>>;
  /** Returns the total count of entries. */
  total: TotalCount;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TocResultTocEntriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};

/**
 * A topic is the atomar unit of information. It primarily consists of
 * - a title
 * - indexed fields (custom fields)
 * - the content - optionally represented in different mime types
 * - attachments which are required by the content (e.g. images, style sheets, etc.)
 */
export type Topic = Content & {
  __typename?: 'Topic';
  /** The related article ids */
  articleIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The related asset ids */
  assetIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Attachments which are required by the content (e.g. images, style sheets, etc.) */
  attachments?: Maybe<Array<Maybe<BlobRef>>>;
  /** Unique identifier of the topic itself */
  contentId: Scalars['ID'];
  /** The topic's content - optionally represented in different mime types */
  contents?: Maybe<Array<Maybe<BlobRef>>>;
  /** Date and time when the content map was created */
  creationDate?: Maybe<Scalars['DateTime']>;
  /** Sub graph: [https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443](https://plusmeta-insights-graph.qit-copilot.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Language of the topic */
  language?: Maybe<Language>;
  /** Locale of the topic */
  locale?: Maybe<Scalars['String']>;
  /** Matching access filters for the content */
  matchingAccessFilters?: Maybe<AccessFilterQueryResult>;
  /** The file type of the asset this topic refers to */
  mimeType?: Maybe<Scalars['String']>;
  /** Date and time of the last modification */
  modificationDate?: Maybe<Scalars['DateTime']>;
  /**
   * Relevant notes for the topic.
   *
   * This field only shows private, pendingApproval notes created by the requesting user and any public notes
   * that reference the content.
   *
   * Even with administrative privileges this field only shows the above mentioned notes and therefore doesn't include any notes
   * which don't belong to the requesting user except the public ones. To view any note and filter them, refer to the
   * filter query for the notes.
   */
  notes?: Maybe<NoteQueryResult>;
  /** All users allowed to query the content */
  permittedUsers?: Maybe<UserQueryResult>;
  /** Flag that specifies if the topic is recommended */
  recommended?: Maybe<Scalars['Boolean']>;
  /** The source this content was generated from */
  source?: Maybe<Scalars['String']>;
  /** The unique id of the source */
  sourceId?: Maybe<Scalars['String']>;
  /** Length limited text from the topic for use e.g. in result lists, previews, etc. */
  teasers?: Maybe<TopicTeasers>;
  /** Extracted textual content of the topic */
  text?: Maybe<MultilingualString>;
  /** Title of the topic */
  title?: Maybe<MultilingualString>;
  /** The type of the node */
  type?: Maybe<Scalars['String']>;
  /** Most recent date and time when the content map was uploaded */
  uploadDate?: Maybe<Scalars['DateTime']>;
  /** Download URL to display the topic */
  url?: Maybe<Scalars['URL']>;
  /** The use context in which this topic is used */
  useContext?: Maybe<UseContext>;
  /** The technical version of the topic */
  version?: Maybe<Scalars['Long']>;
};


/**
 * A topic is the atomar unit of information. It primarily consists of
 * - a title
 * - indexed fields (custom fields)
 * - the content - optionally represented in different mime types
 * - attachments which are required by the content (e.g. images, style sheets, etc.)
 */
export type TopicMatchingAccessFiltersArgs = {
  filter?: InputMaybe<AccessFilterFilter>;
};


/**
 * A topic is the atomar unit of information. It primarily consists of
 * - a title
 * - indexed fields (custom fields)
 * - the content - optionally represented in different mime types
 * - attachments which are required by the content (e.g. images, style sheets, etc.)
 */
export type TopicNotesArgs = {
  filter?: InputMaybe<NoteFilter>;
};


/**
 * A topic is the atomar unit of information. It primarily consists of
 * - a title
 * - indexed fields (custom fields)
 * - the content - optionally represented in different mime types
 * - attachments which are required by the content (e.g. images, style sheets, etc.)
 */
export type TopicPermittedUsersArgs = {
  filter?: InputMaybe<UserFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TopicEdge = {
  __typename?: 'TopicEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** Highlighted matches for equality and full text filters. Matched terms are wrapped in <em>...</em> Tags. */
  highlights?: Maybe<TopicHighlights>;
  /** The result's topic. */
  node: Topic;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TopicEqualsValueFilter = {
  /** Returns only topics with the specified creation date */
  creationDate?: InputMaybe<Scalars['DateTime']>;
  /** Returns only topics with the specified language */
  language?: InputMaybe<Language>;
  /** Returns only topics with the specified locale */
  locale?: InputMaybe<Scalars['String']>;
  /** Returns only topics with the specified mime type */
  mimeType?: InputMaybe<Scalars['String']>;
  /** Returns only topics with the specified modification date */
  modificationDate?: InputMaybe<Scalars['DateTime']>;
  /** Returns only topics with the recommended flag set */
  recommended?: InputMaybe<Scalars['Boolean']>;
  /** Returns only topics used by the specified root content id */
  rootContentId?: InputMaybe<Scalars['ID']>;
  /** Returns only contents with the specified source */
  source?: InputMaybe<Scalars['String']>;
  /** Returns only contents with the specified source id */
  sourceId?: InputMaybe<Scalars['String']>;
  /** Returns only topics with the specified upload date */
  uploadDate?: InputMaybe<Scalars['DateTime']>;
  /** Returns only contents with the specified version */
  version?: InputMaybe<Scalars['Long']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum TopicExistsValueFields {
  /** Returns only contents which have one or more article ids specified */
  articleIds = 'articleIds',
  /** Returns only contents which have one or more asset ids specified */
  assetIds = 'assetIds',
  /** Returns only topics which have a locale specified */
  locale = 'locale',
  /** Returns only topics which have a mime type specified */
  mimeType = 'mimeType',
  /** Returns only topics which have a source specified */
  source = 'source',
  /** Returns only topics which have a source id specified */
  sourceId = 'sourceId',
  /** Returns only topics which have a version specified */
  version = 'version'
}

/**
 * The `TopicFilter` is used to specify filter criteria for topics.
 * If you specify more than one criterion, all criteria are grouped together and connected with a logical AND operator.
 * Further logical operations are possible by using `not` `andGroup` and `orGroup`.
 */
export type TopicFilter = {
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<TopicFilter>>>;
  /** Returns only contents with one of the specified article ids */
  articleIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Returns only contents with one of the specified asset ids */
  assetIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns topics with one of the specified content identifiers */
  contentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Returns topics that contain all of the exact values specified */
  equals?: InputMaybe<TopicEqualsValueFilter>;
  /** Returns topics that have a value in all of the specified fields */
  exists?: InputMaybe<Array<InputMaybe<TopicExistsValueFields>>>;
  /** Returns topics that match all of the specified full text searches */
  fullText?: InputMaybe<TopicFullTextFilter>;
  /** Returns topics with one of the specified unique identifiers */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<TopicFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<TopicFilter>>>;
  /** Returns topics with values within all of the ranges specified */
  range?: InputMaybe<TopicRangeFilter>;
  /** Returns topics that match all of the regular expressions */
  regex?: InputMaybe<TopicRegexFilter>;
  /** Returns topics that match a taxonomy value according to the taxonomy's evaluation type */
  taxonomy?: InputMaybe<TopicTaxonomyFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TopicFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: InputMaybe<Scalars['Float']>;
  /** Match the query against any tile language and rank the results with the specified boost */
  text?: InputMaybe<Scalars['Float']>;
  /** Match the query against any tile language and rank the results with the specified boost */
  title?: InputMaybe<Scalars['Float']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TopicFullTextFilter = {
  /** Fields to search in with their respective score weights */
  fields?: InputMaybe<TopicFullTextFields>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: InputMaybe<Scalars['Boolean']>;
  /** Specifies if either all or any query words need to match */
  operator?: InputMaybe<FullTextOperator>;
  /**
   * The text to search for.
   * Be aware: The Limit for full text search queries is 1024 characters and
   * anything above that will result in an error.
   */
  query: Scalars['FullTextSearchQuery'];
  /** Type of fulltext search to perform */
  searchType?: InputMaybe<FullTextSearchType>;
  /** Options to configure synonyms search */
  synonymOptions?: InputMaybe<SynonymOptions>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TopicHighlights = ContentHighlights & {
  __typename?: 'TopicHighlights';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  text?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TopicHighlightsTextArgs = {
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TopicHighlightsTitleArgs = {
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TopicQueryResult = {
  __typename?: 'TopicQueryResult';
  /**
   * Matched datafield values and number of matching topics per value
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   */
  aggregations: Aggregations;
  /**
   * The query's matching topics.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  topics: Array<Maybe<TopicEdge>>;
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TopicQueryResultTopicsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<TopicSort>>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TopicQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TopicRangeFilter = {
  /** Returns only topics with a creation date in the specified range */
  creationDate?: InputMaybe<DateRange>;
  /** Returns only topics with a modification date in the specified range */
  modificationDate?: InputMaybe<DateRange>;
  /** Returns only topics with a upload date in the specified range */
  uploadDate?: InputMaybe<DateRange>;
  /** Returns only topics with a version within the specified range */
  version?: InputMaybe<LongRange>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TopicRegexFilter = {
  /** No regex filterable indexed field definitions exist. As soon as the first matching indexed field definition is defined, it's automatically listed here. */
  not_applicable_as_no_regex_filterable_field_definitions_exist?: InputMaybe<Scalars['NoDynamicValueDefined']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TopicSort = {
  /** Sort field */
  field: TopicSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum TopicSortFields {
  /** Sort by content id */
  contentId = 'contentId',
  /** Sort by creation date */
  creationDate = 'creationDate',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by language */
  language = 'language',
  /** Sort by locale */
  locale = 'locale',
  /** Sort by modification date */
  modificationDate = 'modificationDate',
  /** Sort by recommended flag */
  recommended = 'recommended',
  /** Sort by relevance score */
  score = 'score',
  /** Sort by source */
  source = 'source',
  /** Sort by source id */
  sourceId = 'sourceId',
  /** Sort by title */
  title = 'title',
  /** Sort by upload date */
  uploadDate = 'uploadDate',
  /** Sort by version */
  version = 'version'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TopicTaxonomyFilter = {
  /** No taxonomy indexed field definitions exist. As soon as the first taxonomy is defined, it's automatically listed here. */
  not_applicable_as_no_taxonomy_field_definitions_exist?: InputMaybe<Scalars['NoDynamicValueDefined']>;
};

/** Length limited text from the topic for use e.g. in result lists, previews, etc. */
export type TopicTeasers = ContentTeasers & {
  __typename?: 'TopicTeasers';
  /** The topic's text limited to `length` characters */
  text?: Maybe<Scalars['String']>;
  /** The topic's title limited to `length` characters */
  title?: Maybe<Scalars['String']>;
};


/** Length limited text from the topic for use e.g. in result lists, previews, etc. */
export type TopicTeasersTextArgs = {
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Length limited text from the topic for use e.g. in result lists, previews, etc. */
export type TopicTeasersTitleArgs = {
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type TotalCount = {
  __typename?: 'TotalCount';
  /**
   * The total count of results.
   *
   * See `CountRelation` for further information on how to interpret the count.
   */
  count: Scalars['NonNegativeInt'];
  /**
   * Indicator on how the count should be interpreted.
   *
   * The count may not be accurate so the relation gives information whether the real
   * actual count deviates from the provided count or is equal to.
   *
   * See `CountRelation` for further information about the possible deviations.
   */
  countRelation?: Maybe<CountRelation>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UnresolvedArticleActivityReference = {
  __typename?: 'UnresolvedArticleActivityReference';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  localizations?: Maybe<UnresolvedArticleActivityReferenceLocalizations>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  teasers?: Maybe<UnresolvedArticleActivityReferenceTeasers>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  title?: Maybe<MultilingualString>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UnresolvedArticleActivityReferenceLocalizations = {
  __typename?: 'UnresolvedArticleActivityReferenceLocalizations';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  title?: Maybe<Scalars['String']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UnresolvedArticleActivityReferenceLocalizationsTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UnresolvedArticleActivityReferenceTeasers = {
  __typename?: 'UnresolvedArticleActivityReferenceTeasers';
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  title?: Maybe<Scalars['String']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UnresolvedArticleActivityReferenceTeasersTitleArgs = {
  acceptedLanguages?: InputMaybe<Array<LanguageWithWildcard>>;
  length?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UpdateAccessFilterInput = {
  /** Comment containing optional information for the access filter */
  comment?: InputMaybe<Scalars['String']>;
  /**
   * Graphql query specifying the content affected by the access filter.
   *
   * Please be aware: Empty filter (e.g. "{}") are evaluated as none filters, which means nothing will be matched.
   * If the filter should match every content use the all filter (e.g. "{ all: true }").
   *
   * This property is deprecated and will be removed in a future version.
   * For now it will act the same as `query`.
   * If both values are configured as input the contentQuery will be ignored.
   * @deprecated Please use 'query' in combination with 'domains' instead
   */
  contentQuery?: InputMaybe<Scalars['String']>;
  /** Readable name for the access filter */
  displayName?: InputMaybe<Scalars['String']>;
  /**
   * All domains this access filter applies to. If empty, no domain is affected, but filter will be generated.
   *
   * If this value is empty the contentQuery must be provided. The domains will be extracted from the contentQuery.
   */
  domains?: InputMaybe<Array<AccessFilterDomains>>;
  /** Unique identifier for the access filter. `id` itself can't be updated. */
  id: Scalars['ID'];
  /**
   * Graphql query specifying the content affected by the access filter.
   *
   * Please be aware: Empty filter (e.g. "{}") are evaluated as none filters, which means nothing will be matched.
   * If the filter should match every content possible use the all filter (e.g. "{ all: true }").
   */
  query?: InputMaybe<Scalars['String']>;
  /** Elastic search query for the affected users, created from the userQuery */
  resourceActions?: InputMaybe<Array<InputMaybe<ResourceActionInput>>>;
  /**
   * Graphql query specifying the users affected by the access filter for the new access filter.
   *
   * Please be aware: Empty filter (e.g. "{}") are evaluated as none filters, which means nothing will be matched.
   * If the filter should match every content use the all filter (e.g. "{ all: true }").
   */
  userQuery?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UpdateActivityProcessInput = {
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  activityId: Scalars['ID'];
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  completedActivity?: InputMaybe<Scalars['Boolean']>;
  /** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
  navContext?: InputMaybe<ActivityNavigationContextInput>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UpdateBlobInput = {
  /** The unique id */
  id: Scalars['ID'];
  /**
   * The Protection class of the blob.
   * See `BlobProtectionClass` for further information.
   */
  protectionClass?: InputMaybe<BlobProtectionClass>;
};

/** Sub graph: [https://collections-graph.qit-user-generated-data.svc.cluster.local:8443](https://collections-graph.qit-user-generated-data.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UpdateCollectionInput = {
  /** The detailed description */
  description?: InputMaybe<Scalars['String']>;
  /** The display name */
  displayName?: InputMaybe<Scalars['String']>;
  /** The unique id */
  id: Scalars['ID'];
  /** The indicator whether the collection should be displayed in the dashboard */
  showInDashboard?: InputMaybe<Scalars['Boolean']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UpdateNoteCommentInput = {
  /** Unique id of the comment */
  id: Scalars['ID'];
  /** The text of the comment */
  text?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UpdateNoteInput = {
  /** The unique id */
  id: Scalars['ID'];
  /** The note text */
  text?: InputMaybe<MultilingualStringInput>;
  /** The visibility for the note. See `VisibilityTypes` */
  visibility?: InputMaybe<NoteVisibilityTypesInput>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UpdateStaticPageInput = {
  /** The unique id of the static page */
  id: Scalars['ID'];
  /** The title of the static page */
  title?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UpdateStaticPageMenuInput = {
  /**
   * Page types which are included in the menu.
   * The order of the array can be used to retrieve
   * information on which order the entries should be displayed.
   */
  pageTypes?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UpdateTenantInput = {
  /** Allowed mime types */
  allowedMimeTypes?: InputMaybe<TenantAllowedMimeTypesInput>;
  /** Active custom features */
  customFeatures?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The tenant's provider specific identifier */
  displayName?: InputMaybe<Scalars['String']>;
  /** Date when the tenant will expire */
  expires?: InputMaybe<Scalars['DateTime']>;
  /** The tenant's feature plan ID */
  featurePlan?: InputMaybe<Scalars['String']>;
  /** Available languages */
  languages?: InputMaybe<TenantLanguagesInput>;
};

/**
 * Options to update a user via `updateUser`.
 * All fields are optional. Only provided fields will be updated.
 * If provided, they will replace the current value.
 */
export type UpdateUserInput = {
  /** The user's email address */
  email?: InputMaybe<Scalars['EmailAddress']>;
  /** The unique identifier of the user to update. `id` itself can't be updated. */
  id: Scalars['ID'];
  /** The new user's directly assigned roles */
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/**
 * Options to update a webhook config via `updateWebhookConfig`.
 * All fields are optional. Only provided fields will be updated.
 * If provided, they will replace the current value.
 */
export type UpdateWebhookConfigInput = {
  /** Readable name for the webhook config */
  displayName?: InputMaybe<Scalars['String']>;
  /** The event type for which this webhook config shall be used */
  eventType?: InputMaybe<WebhookEventTypes>;
  /** The unique identifier of the webhook config to update. `id` itself can't be updated. */
  id: Scalars['ID'];
  /** The shared secret for the triggered webhook */
  sharedSecret?: InputMaybe<Scalars['String']>;
  /** The URL of the webhook to be triggered */
  url?: InputMaybe<Scalars['URL']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UploadConfig = {
  __typename?: 'UploadConfig';
  /** Are all mime types allowed */
  allowAllMimeTypes?: Maybe<Scalars['Boolean']>;
  /** The allowed file extensions */
  allowedFileExtensions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The allowed mime types */
  allowedMimeTypes: Array<Maybe<Scalars['String']>>;
  /** The limits for the files to upload */
  limits: UploadLimits;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UploadLimits = {
  __typename?: 'UploadLimits';
  /** The maximal allowed size in bytes per file */
  maxFileSize?: Maybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UploadMultipleConfig = {
  __typename?: 'UploadMultipleConfig';
  /** Are all mime types allowed */
  allowAllMimeTypes?: Maybe<Scalars['Boolean']>;
  /** The allowed file extensions */
  allowedFileExtensions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The allowed mime types */
  allowedMimeTypes: Array<Maybe<Scalars['String']>>;
  /** The limits for the files to upload */
  limits: UploadMultipleLimits;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UploadMultipleLimits = {
  __typename?: 'UploadMultipleLimits';
  /** The maximal allowed size in bytes per file */
  maxFileSize?: Maybe<Scalars['NonNegativeInt']>;
  /** The maximal number of files per upload request */
  maxFilesPerUpload?: Maybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UseContext = {
  __typename?: 'UseContext';
  /** ID of the content map in which this context roots */
  rootContentId: Scalars['ID'];
  /** Content map in which this context roots */
  rootContentMap?: Maybe<ContentMap>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UseContextAggregations = {
  __typename?: 'UseContextAggregations';
  /** Provides composite aggregations with pagination support */
  composite?: Maybe<UseContextCompositeAggregation>;
  /**
   * Provides terms aggregations for the use context.
   * Supports prefix search and the results are ordered by count.
   */
  terms?: Maybe<UseContextTermsAggregation>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UseContextCompositeAggregation = {
  __typename?: 'UseContextCompositeAggregation';
  /** Provides aggregations for the rootContentId field */
  rootContentId?: Maybe<Array<AggregationEdge>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UseContextCompositeAggregationRootContentIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UseContextSort = {
  /** Sort field */
  field: UseContextSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum UseContextSortFields {
  /** Sort by unique ID */
  rootContentId = 'rootContentId'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UseContextTermsAggregation = {
  __typename?: 'UseContextTermsAggregation';
  /** Provides aggregations for the rootContentId field */
  rootContentId?: Maybe<Array<AggregationEdge>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UseContextTermsAggregationRootContentIdArgs = {
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  prefix?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UsedInAssets = {
  __typename?: 'UsedInAssets';
  /**
   * Query `Assets`s. Filters con be specified via the `filter` argument. See `AssetFilter` for further details.
   *
   * Required permission\
   * Resource: **assets**\
   * Action: **query**
   */
  assets: AssetQueryResult;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UsedInAssetsAssetsArgs = {
  filter?: InputMaybe<AssetFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UsedInProducts = {
  __typename?: 'UsedInProducts';
  /**
   * Query products. Filters can be specified via the `filter` argument. See `ProductFilter` for further details.
   *
   * Required permission\
   * Resource: **products**\
   * Action: **query**
   */
  products: ProductQueryResult;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UsedInProductsProductsArgs = {
  filter?: InputMaybe<ProductFilter>;
};

/** A single user in Exegol */
export type User = {
  __typename?: 'User';
  /** Contents the user is able to query */
  accessibleContents?: Maybe<ContentQueryResult>;
  /** Indicates if the user is the anonymous user */
  anonymous: Scalars['Boolean'];
  /** The user's API keys */
  apiKeys?: Maybe<Array<ApiKey>>;
  /** The user's claims */
  claims?: Maybe<UserClaims>;
  /** Readable name for the user */
  displayName?: Maybe<Scalars['String']>;
  /** The user's assigned and inherited roles */
  effectiveRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The user's email address */
  email?: Maybe<Scalars['EmailAddress']>;
  /** The logout Url based on the last authenticator used */
  endSessionUrl?: Maybe<Scalars['String']>;
  /** Sub graph: [https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443](https://job-pipelines-graph.qcc-job-pipelines.svc.cluster.local:8443/.schemas/admin/graphql) */
  id: Scalars['ID'];
  /** The claims received from the identity provider */
  identityProviderClaims?: Maybe<Scalars['String']>;
  /** The users notes */
  notes?: Maybe<NoteQueryResult>;
  /** All of the user's claims */
  rawClaims?: Maybe<Scalars['JSONObject']>;
  /** The user's assigned roles */
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The provider which served the user information */
  sourceProvider?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The user's provider specific identifier */
  sourceUserIdentifier: Scalars['String'];
};


/** A single user in Exegol */
export type UserAccessibleContentsArgs = {
  filter?: InputMaybe<ContentFilter>;
};


/** A single user in Exegol */
export type UserNotesArgs = {
  filter?: InputMaybe<NoteFilter>;
};

/** A user's claims */
export type UserClaims = {
  __typename?: 'UserClaims';
  /** No claims are currently defined. As soon as the first claims are defined, they are automatically listed here. */
  not_applicable_as_no_claims_defined?: Maybe<Scalars['NoDynamicValueDefined']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** The result's user. */
  node: User;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UserEqualsValueFilter = {
  /** Returns only users with the specified display name */
  displayName?: InputMaybe<Scalars['String']>;
  /** Returns only users with the specified email address */
  email?: InputMaybe<Scalars['String']>;
  /** Returns only users with the specified directly assigned role */
  role?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum UserExistsValueFields {
  /** Returns only users which have a email address specified */
  email = 'email',
  /** Returns only users which have at least one role specified */
  role = 'role'
}

/**
 * The `UserFilter` is used to specify filter criteria for users.
 * If you specify more than one criterion, all criteria are grouped together and connected with a logical AND operator.
 * Further logical operations are possible by using `not` `andGroup` and `orGroup`.
 */
export type UserFilter = {
  /** Returns all users if set to true */
  all?: InputMaybe<Scalars['Boolean']>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<UserFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns only users which have all of the specified role, either by direct assignment or by inheritance */
  effectiveRoles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Returns users that contain all of the exact values specified */
  equals?: InputMaybe<UserEqualsValueFilter>;
  /** Returns users that have a value in all of the specified fields */
  exists?: InputMaybe<Array<InputMaybe<UserExistsValueFields>>>;
  /** Returns users that match all of the specified full text searches */
  fullText?: InputMaybe<UserFullTextFilter>;
  /** Returns users with one of the specified unique identifiers */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<UserFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<UserFilter>>>;
  /** Returns users that match all of the regular expressions */
  regex?: InputMaybe<UserRegexFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UserFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: InputMaybe<Scalars['Float']>;
  /** Match the query against any tile language and rank the results with the specified boost */
  displayName?: InputMaybe<Scalars['Float']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UserFullTextFilter = {
  /** Fields to search in with their respective score weights */
  fields?: InputMaybe<UserFullTextFields>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: InputMaybe<Scalars['Boolean']>;
  /** Specifies if either all or any query words need to match */
  operator?: InputMaybe<FullTextOperator>;
  /**
   * The text to search for.
   * Be aware: The Limit for full text search queries is 1024 characters and
   * anything above that will result in an error.
   */
  query: Scalars['FullTextSearchQuery'];
  /** Type of fulltext search to perform */
  searchType?: InputMaybe<FullTextSearchType>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UserQueryResult = {
  __typename?: 'UserQueryResult';
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
  /**
   * The query's matching users.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  users: Array<Maybe<UserEdge>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UserQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UserQueryResultUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<UserSort>>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UserRegexFilter = {
  /** Returns only users whose display name matches the specified Regex */
  displayName?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only users whose email address matches the specified Regex */
  email?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only users where at least one directly assigned role matches the specified Regex */
  role?: InputMaybe<Scalars['String']>;
};

/** Sub graph: [https://users-settings-graph.qit-settings.svc.cluster.local:8443](https://users-settings-graph.qit-settings.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UserSettings = {
  __typename?: 'UserSettings';
  /**
   * The user settings as a string key value map.
   *
   * Be aware: The entries are not interpreted by the backend.
   */
  settings?: Maybe<Scalars['JSONObject']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type UserSort = {
  /** Sort field */
  field: UserSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum UserSortFields {
  /** Sort by title */
  displayName = 'displayName',
  /** Sort by email address */
  email = 'email',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by relevance score */
  score = 'score'
}

/** Sub graph: [https://licensing-graph.qcc-licensing.svc.cluster.local:8443](https://licensing-graph.qcc-licensing.svc.cluster.local:8443/.schemas/admin/graphql) */
export type VersionInfo = {
  __typename?: 'VersionInfo';
  /** Major version */
  major?: Maybe<Scalars['Int']>;
  /** Minor version */
  minor?: Maybe<Scalars['Int']>;
  /** Patch level */
  patch?: Maybe<Scalars['Int']>;
  /** Full version info as a semver string. E.g '1.15.8' */
  versionString?: Maybe<Scalars['String']>;
};

/** The result of a webhook call */
export type WebhookCallResult = {
  __typename?: 'WebhookCallResult';
  /** The HTTP response body returned by the webhook */
  responseBody?: Maybe<Scalars['JSON']>;
  /**
   * All HTTP headers the webhook sent with its response as a keyed JSON object.
   * The keys of the object are the header names and the values are the respective header values.
   * All header names are lowercase.
   */
  responseHeaders?: Maybe<Scalars['JSONObject']>;
  /** The HTTP status code the webhook returned */
  status?: Maybe<Scalars['Int']>;
  /** The HTTP status text of the returned HTTP status */
  statusText?: Maybe<Scalars['String']>;
  /** True if the call was successful, otherwise false */
  success?: Maybe<Scalars['Boolean']>;
  /** The time the webhook call took in milliseconds */
  tookMs?: Maybe<Scalars['Int']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type WebhookConfig = {
  __typename?: 'WebhookConfig';
  /** Readable name of the webhook config */
  displayName: Scalars['String'];
  /** The event type for which this webhook config is used */
  eventType?: Maybe<WebhookEventTypes>;
  /** Unique identifier of the webhook config */
  id: Scalars['ID'];
  /** The shared secret for the triggered webhook */
  sharedSecret?: Maybe<Scalars['String']>;
  /** The URL of the webhook to be triggered */
  url?: Maybe<Scalars['URL']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type WebhookConfigEdge = {
  __typename?: 'WebhookConfigEdge';
  /** Unique cursor for pagination. See the query's `after` argument */
  cursor: Scalars['String'];
  /** The result's webhook config */
  node: WebhookConfig;
  /** Calculated relevance score. */
  score: Scalars['NonNegativeFloat'];
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type WebhookConfigEqualsValueFilter = {
  /** Returns only webhook configs with the specified display name */
  displayName?: InputMaybe<Scalars['String']>;
  /** Returns only webhook configs with the specified event type */
  eventType?: InputMaybe<WebhookEventTypes>;
};

/** The `WebhookConfigFilter` is used to specify filter criteria for webhook configs. */
export type WebhookConfigFilter = {
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical AND operator */
  andGroup?: InputMaybe<Array<InputMaybe<WebhookConfigFilter>>>;
  /** Boosts the score of all matches in this group by the specified factor. Heads up: The resulting score boost isn't mathematically exact due to rounding errors */
  boost?: InputMaybe<Scalars['Float']>;
  /** Returns webhook configs that contain all of the exact values specified */
  equals?: InputMaybe<WebhookConfigEqualsValueFilter>;
  /** Returns webhook configs that match all of the specified full text searches */
  fullText?: InputMaybe<WebhookConfigFullTextFilter>;
  /** Returns webhook configs with one of the specified unique identifiers */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Allows you to specify logically negated filter criteria */
  not?: InputMaybe<WebhookConfigFilter>;
  /** Allows the specification of further filter criteria, which are grouped together and connected with a logical OR operator */
  orGroup?: InputMaybe<Array<InputMaybe<WebhookConfigFilter>>>;
  /** Returns webhook configs that match all of the regular expressions */
  regex?: InputMaybe<WebhookConfigRegexFilter>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type WebhookConfigFullTextFields = {
  /** Match the query against any full text searchable field and rank the results with the specified boost */
  any?: InputMaybe<Scalars['Float']>;
  /** Match the query against any tile language and rank the results with the specified boost */
  displayName?: InputMaybe<Scalars['Float']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type WebhookConfigFullTextFilter = {
  /** Fields to search in with their respective score weights */
  fields?: InputMaybe<WebhookConfigFullTextFields>;
  /** Indicates if a fuzzy search should be performed. */
  fuzzy?: InputMaybe<Scalars['Boolean']>;
  /** Specifies if either all or any query words need to match */
  operator?: InputMaybe<FullTextOperator>;
  /**
   * The text to search for.
   * Be aware: The Limit for full text search queries is 1024 characters and
   * anything above that will result in an error.
   */
  query: Scalars['FullTextSearchQuery'];
  /** Type of fulltext search to perform */
  searchType?: InputMaybe<FullTextSearchType>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type WebhookConfigQueryResult = {
  __typename?: 'WebhookConfigQueryResult';
  /**
   * Returns the total count of results.
   *
   * Use the `trackLimit` argument to limit the track of the total count up to the specified amount.
   * If the result has more hits than the specified limit the total count is inaccurate.
   * See `CountRelation` for further information on how to interpret the result.
   *
   * Heads up: Setting the `trackLimit` to a high value may decrease performance.
   */
  total: TotalCount;
  /**
   * The query's webhook configs.
   *
   * Use the `sort` argument to specify the list of fields and the order by which the results should be sorted.
   * Multiple fields and orders may be specified and are evaluated in the provided order.
   *
   * To limit the number of results, set the `first` argument.
   *
   * Pagination is possible by setting the `after` argument to the previous last result's `cursor` field value.
   * Please be careful not to change the query's `filter` or the `sort` argument between paginated calls.
   */
  webhookConfigs: Array<Maybe<WebhookConfigEdge>>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type WebhookConfigQueryResultTotalArgs = {
  trackLimit?: InputMaybe<Scalars['NonNegativeInt']>;
};


/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type WebhookConfigQueryResultWebhookConfigsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  sort?: InputMaybe<Array<InputMaybe<WebhookConfigSort>>>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type WebhookConfigRegexFilter = {
  /** Returns only webhook configs whose display name matches the specified Regex */
  displayName?: InputMaybe<Scalars['FilterRegex']>;
  /** Returns only webhook configs whose event matches the specified Regex */
  eventType?: InputMaybe<Scalars['FilterRegex']>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export type WebhookConfigSort = {
  /** Sort field */
  field: WebhookConfigSortFields;
  /** Sort order */
  order?: InputMaybe<SortOrder>;
};

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum WebhookConfigSortFields {
  /** Sort by display name */
  displayName = 'displayName',
  /** Sort by event type */
  eventType = 'eventType',
  /** Sort by unique ID */
  id = 'id',
  /** Sort by relevance score */
  score = 'score'
}

/** Sub graph: [https://graph-api.qit.svc.cluster.local:8443](https://graph-api.qit.svc.cluster.local:8443/.schemas/admin/graphql) */
export enum WebhookEventTypes {
  /** Triggered when one or more activities got deleted */
  activitiesDeleted = 'activitiesDeleted',
  /** Triggered when one or more activities got imported */
  activitiesImported = 'activitiesImported',
  /** Triggered when an activity got completed */
  activityCompleted = 'activityCompleted',
  /** Triggered when article got imported */
  articlesImported = 'articlesImported',
  /** Triggered when an asset option got imported */
  assetOptionsImported = 'assetOptionsImported',
  /** Triggered when an asset got deleted */
  assetsDeleted = 'assetsDeleted',
  /** Triggered when an asset got imported */
  assetsImported = 'assetsImported',
  /** Triggered when a public collection got created */
  collectionCreated = 'collectionCreated',
  /** Triggered when a public collection got deleted */
  collectionDeleted = 'collectionDeleted',
  /** Triggered when entries got added to a public collection */
  collectionEntriesAdded = 'collectionEntriesAdded',
  /** Triggered when entries got removed from a public collection */
  collectionEntriesRemoved = 'collectionEntriesRemoved',
  /** Triggered when a content map got deleted */
  contentMapsDeleted = 'contentMapsDeleted',
  /** Triggered when a content map got imported */
  contentMapsImported = 'contentMapsImported',
  /** Triggered when one or more datafield definitions got created, changed or deleted */
  datafieldDefinitionsChanged = 'datafieldDefinitionsChanged',
  /** Triggered when a file got deleted */
  filesDeleted = 'filesDeleted',
  /** Triggered when a file got imported */
  filesImported = 'filesImported',
  /** Triggered when mechanic got deleted */
  mechanicsDeleted = 'mechanicsDeleted',
  /** Triggered when mechanic got imported */
  mechanicsImported = 'mechanicsImported',
  /** Triggered when the approval for a note got rejected */
  noteApprovalRejected = 'noteApprovalRejected',
  /** Triggered when an approval for a note is requested */
  noteApprovalRequested = 'noteApprovalRequested',
  /** Triggered when the approval for a note got revoked */
  noteApprovalRevoked = 'noteApprovalRevoked',
  /** Triggered when a note got approved */
  noteApproved = 'noteApproved',
  /** Triggered when a note got created */
  noteCreated = 'noteCreated',
  /** Triggered when a product got deleted */
  productsDeleted = 'productsDeleted',
  /** Triggered when a product got imported */
  productsImported = 'productsImported',
  /** Triggered when a user got created */
  usersCreated = 'usersCreated',
  /** Triggered when a user got deleted */
  usersDeleted = 'usersDeleted'
}

export type DeleteActivitiesMutationVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
}>;


export type DeleteActivitiesMutation = { __typename?: 'Mutation', deleteActivities?: boolean | null };

export type CreateDataProcessMutationVariables = Exact<{
  spec: ProcessRefSpec;
  filenameSchemaName?: InputMaybe<Scalars['String']>;
  mappingOptions?: InputMaybe<Array<InputMaybe<MappingOptionSpec>> | InputMaybe<MappingOptionSpec>>;
  delimiter?: InputMaybe<Scalars['String']>;
  escape?: InputMaybe<Scalars['String']>;
  encoding?: InputMaybe<ProcessEncodingType>;
}>;


export type CreateDataProcessMutation = { __typename?: 'Mutation', createProcess?: string | null };

export type DeleteDataProcessMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteDataProcessMutation = { __typename?: 'Mutation', deleteProcess?: string | null };

export type UpdateDataProcessMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  columns?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  filenameSchemaName?: InputMaybe<Scalars['String']>;
  mappingOptions?: InputMaybe<Array<InputMaybe<MappingOptionSpec>> | InputMaybe<MappingOptionSpec>>;
  delimiter?: InputMaybe<Scalars['String']>;
  escape?: InputMaybe<Scalars['String']>;
  encoding?: InputMaybe<ProcessEncodingType>;
}>;


export type UpdateDataProcessMutation = { __typename?: 'Mutation', updateProcess?: string | null };

export type DeleteJobPipelineMutationVariables = Exact<{
  pipelineId: Scalars['ID'];
}>;


export type DeleteJobPipelineMutation = { __typename?: 'Mutation', deleteJobPipeline?: { __typename?: 'JobPipeline', id: string } | null };

export type DeleteJobPipelinesMutationVariables = Exact<{
  filter: JobPipelineFilter;
}>;


export type DeleteJobPipelinesMutation = { __typename?: 'Mutation', deleteJobPipelines: { __typename?: 'JobPipeline', id: string } };

export type DataUploadConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type DataUploadConfigQuery = { __typename?: 'Query', tenant: { __typename?: 'Tenant', uploadConfig?: { __typename?: 'TenantUploadConfig', data?: { __typename?: 'DataUploadConfig', dataUploadConfig?: { __typename?: 'UploadMultipleConfig', allowedMimeTypes: Array<string | null>, allowAllMimeTypes?: boolean | null, allowedFileExtensions?: Array<string | null> | null, limits: { __typename?: 'UploadMultipleLimits', maxFileSize?: any | null } } | null } | null } | null } };

export type DatafieldModelJsonSchemaQueryVariables = Exact<{ [key: string]: never; }>;


export type DatafieldModelJsonSchemaQuery = { __typename?: 'Query', datafieldModelJSONSchema?: any | null };

export type CreateKeywordDatafieldDefinitionMutationVariables = Exact<{
  spec?: InputMaybe<KeywordDatafieldDefinitionSpec>;
}>;


export type CreateKeywordDatafieldDefinitionMutation = { __typename?: 'Mutation', createKeywordDatafieldDefinition: any };

export type UpdateKeywordDatafieldDefinitionMutationVariables = Exact<{
  diff?: InputMaybe<KeywordDatafieldDefinitionDiff>;
}>;


export type UpdateKeywordDatafieldDefinitionMutation = { __typename?: 'Mutation', updateKeywordDatafieldDefinition?: { __typename?: 'KeywordDatafieldDefinition', id: any } | null };

export type CreateDateTimeDatafieldDefinitionMutationVariables = Exact<{
  spec?: InputMaybe<DateTimeDatafieldDefinitionSpec>;
}>;


export type CreateDateTimeDatafieldDefinitionMutation = { __typename?: 'Mutation', createDateTimeDatafieldDefinition: any };

export type UpdateDateTimeDatafieldDefinitionMutationVariables = Exact<{
  diff?: InputMaybe<DateTimeDatafieldDefinitionDiff>;
}>;


export type UpdateDateTimeDatafieldDefinitionMutation = { __typename?: 'Mutation', updateDateTimeDatafieldDefinition?: { __typename?: 'DateTimeDatafieldDefinition', id: any } | null };

export type CreateEnumDatafieldDefinitionMutationVariables = Exact<{
  spec?: InputMaybe<EnumDatafieldDefinitionSpec>;
}>;


export type CreateEnumDatafieldDefinitionMutation = { __typename?: 'Mutation', createEnumDatafieldDefinition: any };

export type UpdateEnumDatafieldDefinitionMutationVariables = Exact<{
  diff?: InputMaybe<EnumDatafieldDefinitionDiff>;
}>;


export type UpdateEnumDatafieldDefinitionMutation = { __typename?: 'Mutation', updateEnumDatafieldDefinition?: { __typename?: 'EnumDatafieldDefinition', id: any } | null };

export type CreateNumberDatafieldDefinitionMutationVariables = Exact<{
  spec?: InputMaybe<NumberDatafieldDefinitionSpec>;
}>;


export type CreateNumberDatafieldDefinitionMutation = { __typename?: 'Mutation', createNumberDatafieldDefinition: any };

export type UpdateNumberDatafieldDefinitionMutationVariables = Exact<{
  diff?: InputMaybe<NumberDatafieldDefinitionDiff>;
}>;


export type UpdateNumberDatafieldDefinitionMutation = { __typename?: 'Mutation', updateNumberDatafieldDefinition?: { __typename?: 'NumberDatafieldDefinition', id: any } | null };

export type CreateTaxonomyDatafieldDefinitionMutationVariables = Exact<{
  spec?: InputMaybe<TaxonomyDatafieldDefinitionSpec>;
}>;


export type CreateTaxonomyDatafieldDefinitionMutation = { __typename?: 'Mutation', createTaxonomyDatafieldDefinition: any };

export type UpdateTaxonomyDatafieldDefinitionMutationVariables = Exact<{
  diff?: InputMaybe<TaxonomyDatafieldDefinitionDiff>;
}>;


export type UpdateTaxonomyDatafieldDefinitionMutation = { __typename?: 'Mutation', updateTaxonomyDatafieldDefinition?: { __typename?: 'TaxonomyDatafieldDefinition', id: any } | null };

export type CreateTextDatafieldDefinitionMutationVariables = Exact<{
  spec?: InputMaybe<TextDatafieldDefinitionSpec>;
}>;


export type CreateTextDatafieldDefinitionMutation = { __typename?: 'Mutation', createTextDatafieldDefinition: any };

export type UpdateTextDatafieldDefinitionMutationVariables = Exact<{
  diff?: InputMaybe<TextDatafieldDefinitionDiff>;
}>;


export type UpdateTextDatafieldDefinitionMutation = { __typename?: 'Mutation', updateTextDatafieldDefinition?: { __typename?: 'TextDatafieldDefinition', id: any } | null };

export type DeleteDatafieldDefinitionsMutationVariables = Exact<{
  ids: Array<Scalars['DatafieldDefinitionID']> | Scalars['DatafieldDefinitionID'];
}>;


export type DeleteDatafieldDefinitionsMutation = { __typename?: 'Mutation', deleteDatafieldDefinitions: boolean };

export type DeleteFilesMutationVariables = Exact<{
  objectIds: Array<Scalars['String']> | Scalars['String'];
  fileTypes?: InputMaybe<Array<InputMaybe<FileType>> | InputMaybe<FileType>>;
}>;


export type DeleteFilesMutation = { __typename?: 'Mutation', deleteFiles?: boolean | null };

export type DeleteEntitiesMutationVariables = Exact<{
  entityTypes?: InputMaybe<Array<InputMaybe<ProcessType>> | InputMaybe<ProcessType>>;
}>;


export type DeleteEntitiesMutation = { __typename?: 'Mutation', deleteEntities?: boolean | null };

export type JobPipelinesQueryVariables = Exact<{
  filter?: InputMaybe<JobPipelineFilter>;
  first?: InputMaybe<Scalars['NonNegativeInt']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type JobPipelinesQuery = { __typename?: 'Query', jobPipelines: { __typename?: 'JobPipelineQueryResult', jobPipelines: Array<{ __typename?: 'JobPipelineEdge', cursor: string, node: { __typename?: 'JobPipeline', id: string, type: string, creationDate: any, displayType?: string | null, status: JobPipelineStatus, startDate?: any | null, bag?: any | null, endDate?: any | null, displayName?: string | null } } | null> } };

export type JobPipelineQueryVariables = Exact<{
  filter?: InputMaybe<JobPipelineFilter>;
}>;


export type JobPipelineQuery = { __typename?: 'Query', jobPipelines: { __typename?: 'JobPipelineQueryResult', jobPipelines: Array<{ __typename?: 'JobPipelineEdge', node: { __typename?: 'JobPipeline', id: string, type: string, creationDate: any, displayType?: string | null, status: JobPipelineStatus, startDate?: any | null, bag?: any | null, endDate?: any | null, displayName?: string | null, dependencyGraph: string, jobs: Array<{ __typename?: 'JobPipelineJob', id: string, displayType?: string | null, displayName?: string | null, creationDate: any, creator?: { __typename?: 'User', id: string, displayName?: string | null } | null, currentExecution: { __typename?: 'JobPipelineJobExecution', startDate?: any | null, status: JobPipelineJobExecutionStatus, id: string, creationDate: any, endDate?: any | null, error?: any | null, log: { __typename?: 'JobPipelineJobExecutionLogQueryResult', edges: Array<{ __typename?: 'JobPipelineJobExecutionLogEdge', node: { __typename?: 'JobPipelineJobExecutionLog', message: Array<string>, timestamp: any } } | null> } } }>, creator?: { __typename?: 'User', displayName?: string | null, id: string } | null } } | null> } };

export type DeleteContentMapsByIdMutationVariables = Exact<{
  contentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
}>;


export type DeleteContentMapsByIdMutation = { __typename?: 'Mutation', deleteContentMapsByFilter: boolean };

export type CreateProcessMechanicMutationVariables = Exact<{
  spec: ProcessRefSpec;
  columns?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  filenameSchemaName?: InputMaybe<Scalars['String']>;
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  delimiter?: InputMaybe<Scalars['String']>;
  escape?: InputMaybe<Scalars['String']>;
  encoding?: InputMaybe<ProcessEncodingType>;
}>;


export type CreateProcessMechanicMutation = { __typename?: 'Mutation', createProcess?: string | null };

export type UpdateProcessMechanicMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  columns?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  filenameSchemaName?: InputMaybe<Scalars['String']>;
  deleteAll?: InputMaybe<Scalars['Boolean']>;
  delimiter?: InputMaybe<Scalars['String']>;
  escape?: InputMaybe<Scalars['String']>;
  encoding?: InputMaybe<ProcessEncodingType>;
}>;


export type UpdateProcessMechanicMutation = { __typename?: 'Mutation', updateProcess?: string | null };

export type CreateOrUpdateConfig3dLatticeMutationVariables = Exact<{
  config3dSpec?: InputMaybe<Config3dLatticeSpec>;
}>;


export type CreateOrUpdateConfig3dLatticeMutation = { __typename?: 'Mutation', createOrUpdateConfig3dLattice?: boolean | null };

export type DeleteConfig3dLatticeMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteConfig3dLatticeMutation = { __typename?: 'Mutation', deleteConfig3dLattice?: boolean | null };

export type CreateOrUpdateConfig3dHoopsMutationVariables = Exact<{
  config3dSpec?: InputMaybe<Config3dHoopsSpec>;
}>;


export type CreateOrUpdateConfig3dHoopsMutation = { __typename?: 'Mutation', createOrUpdateConfig3dHoops?: boolean | null };

export type DeleteConfig3dHoopsMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteConfig3dHoopsMutation = { __typename?: 'Mutation', deleteConfig3dHoops?: boolean | null };

export type DeleteMechanicsMutationVariables = Exact<{
  productIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
}>;


export type DeleteMechanicsMutation = { __typename?: 'Mutation', deleteMechanics?: boolean | null };

export type DeleteProductsMutationVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
}>;


export type DeleteProductsMutation = { __typename?: 'Mutation', deleteProducts?: boolean | null };

export type DeleteAssetsMutationVariables = Exact<{
  productId: Scalars['ID'];
  assetIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
}>;


export type DeleteAssetsMutation = { __typename?: 'Mutation', deleteAssets?: boolean | null };

export type CreateProcessProductAssetMutationVariables = Exact<{
  spec: ProcessRefSpec;
  columns?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  filenameSchemaName?: InputMaybe<Scalars['String']>;
  delimiter?: InputMaybe<Scalars['String']>;
  escape?: InputMaybe<Scalars['String']>;
  encoding?: InputMaybe<ProcessEncodingType>;
}>;


export type CreateProcessProductAssetMutation = { __typename?: 'Mutation', createProcess?: string | null };

export type UpdateProcessProductAssetMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  columns?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  filenameSchemaName?: InputMaybe<Scalars['String']>;
  delimiter?: InputMaybe<Scalars['String']>;
  escape?: InputMaybe<Scalars['String']>;
  encoding?: InputMaybe<ProcessEncodingType>;
}>;


export type UpdateProcessProductAssetMutation = { __typename?: 'Mutation', updateProcess?: string | null };

export type CreateOrUpdateConfigProductAndAssetMutationVariables = Exact<{
  configProductAndAssetSpec?: InputMaybe<ConfigProductAndAssetSpec>;
}>;


export type CreateOrUpdateConfigProductAndAssetMutation = { __typename?: 'Mutation', createOrUpdateConfigProductAndAsset?: boolean | null };

export type DeleteConfigProductAndAssetMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteConfigProductAndAssetMutation = { __typename?: 'Mutation', deleteConfigProductAndAsset?: boolean | null };

export type ProcessEncodingTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProcessEncodingTypesQuery = { __typename?: 'Query', processEncodingTypes?: Array<ProcessEncodingType | null> | null };


export const DeleteActivitiesDocument = gql`
    mutation deleteActivities($ids: [ID]) {
  deleteActivities(ids: $ids)
}
    `;

/**
 * __useDeleteActivitiesMutation__
 *
 * To run a mutation, you first call `useDeleteActivitiesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivitiesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteActivitiesMutation({
 *   variables: {
 *     ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteActivitiesMutation(options: VueApolloComposable.UseMutationOptions<DeleteActivitiesMutation, DeleteActivitiesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteActivitiesMutation, DeleteActivitiesMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteActivitiesMutation, DeleteActivitiesMutationVariables>(DeleteActivitiesDocument, options);
}
export type DeleteActivitiesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteActivitiesMutation, DeleteActivitiesMutationVariables>;
export const CreateDataProcessDocument = gql`
    mutation CreateDataProcess($spec: ProcessRefSpec!, $filenameSchemaName: String, $mappingOptions: [MappingOptionSpec], $delimiter: String, $escape: String, $encoding: ProcessEncodingType) {
  createProcess(
    spec: $spec
    filenameSchemaName: $filenameSchemaName
    mappingOptions: $mappingOptions
    delimiter: $delimiter
    escape: $escape
    encoding: $encoding
  )
}
    `;

/**
 * __useCreateDataProcessMutation__
 *
 * To run a mutation, you first call `useCreateDataProcessMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataProcessMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateDataProcessMutation({
 *   variables: {
 *     spec: // value for 'spec'
 *     filenameSchemaName: // value for 'filenameSchemaName'
 *     mappingOptions: // value for 'mappingOptions'
 *     delimiter: // value for 'delimiter'
 *     escape: // value for 'escape'
 *     encoding: // value for 'encoding'
 *   },
 * });
 */
export function useCreateDataProcessMutation(options: VueApolloComposable.UseMutationOptions<CreateDataProcessMutation, CreateDataProcessMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateDataProcessMutation, CreateDataProcessMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateDataProcessMutation, CreateDataProcessMutationVariables>(CreateDataProcessDocument, options);
}
export type CreateDataProcessMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateDataProcessMutation, CreateDataProcessMutationVariables>;
export const DeleteDataProcessDocument = gql`
    mutation DeleteDataProcess($id: String!) {
  deleteProcess(id: $id)
}
    `;

/**
 * __useDeleteDataProcessMutation__
 *
 * To run a mutation, you first call `useDeleteDataProcessMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataProcessMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteDataProcessMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDataProcessMutation(options: VueApolloComposable.UseMutationOptions<DeleteDataProcessMutation, DeleteDataProcessMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteDataProcessMutation, DeleteDataProcessMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteDataProcessMutation, DeleteDataProcessMutationVariables>(DeleteDataProcessDocument, options);
}
export type DeleteDataProcessMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteDataProcessMutation, DeleteDataProcessMutationVariables>;
export const UpdateDataProcessDocument = gql`
    mutation UpdateDataProcess($id: String!, $name: String!, $columns: [String], $filenameSchemaName: String, $mappingOptions: [MappingOptionSpec], $delimiter: String, $escape: String, $encoding: ProcessEncodingType) {
  updateProcess(
    id: $id
    name: $name
    columns: $columns
    filenameSchemaName: $filenameSchemaName
    mappingOptions: $mappingOptions
    delimiter: $delimiter
    escape: $escape
    encoding: $encoding
  )
}
    `;

/**
 * __useUpdateDataProcessMutation__
 *
 * To run a mutation, you first call `useUpdateDataProcessMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataProcessMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateDataProcessMutation({
 *   variables: {
 *     id: // value for 'id'
 *     name: // value for 'name'
 *     columns: // value for 'columns'
 *     filenameSchemaName: // value for 'filenameSchemaName'
 *     mappingOptions: // value for 'mappingOptions'
 *     delimiter: // value for 'delimiter'
 *     escape: // value for 'escape'
 *     encoding: // value for 'encoding'
 *   },
 * });
 */
export function useUpdateDataProcessMutation(options: VueApolloComposable.UseMutationOptions<UpdateDataProcessMutation, UpdateDataProcessMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateDataProcessMutation, UpdateDataProcessMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateDataProcessMutation, UpdateDataProcessMutationVariables>(UpdateDataProcessDocument, options);
}
export type UpdateDataProcessMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateDataProcessMutation, UpdateDataProcessMutationVariables>;
export const DeleteJobPipelineDocument = gql`
    mutation DeleteJobPipeline($pipelineId: ID!) {
  deleteJobPipeline(pipelineId: $pipelineId) {
    id
  }
}
    `;

/**
 * __useDeleteJobPipelineMutation__
 *
 * To run a mutation, you first call `useDeleteJobPipelineMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobPipelineMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteJobPipelineMutation({
 *   variables: {
 *     pipelineId: // value for 'pipelineId'
 *   },
 * });
 */
export function useDeleteJobPipelineMutation(options: VueApolloComposable.UseMutationOptions<DeleteJobPipelineMutation, DeleteJobPipelineMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteJobPipelineMutation, DeleteJobPipelineMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteJobPipelineMutation, DeleteJobPipelineMutationVariables>(DeleteJobPipelineDocument, options);
}
export type DeleteJobPipelineMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteJobPipelineMutation, DeleteJobPipelineMutationVariables>;
export const DeleteJobPipelinesDocument = gql`
    mutation DeleteJobPipelines($filter: JobPipelineFilter!) {
  deleteJobPipelines(filter: $filter) {
    id
  }
}
    `;

/**
 * __useDeleteJobPipelinesMutation__
 *
 * To run a mutation, you first call `useDeleteJobPipelinesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobPipelinesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteJobPipelinesMutation({
 *   variables: {
 *     filter: // value for 'filter'
 *   },
 * });
 */
export function useDeleteJobPipelinesMutation(options: VueApolloComposable.UseMutationOptions<DeleteJobPipelinesMutation, DeleteJobPipelinesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteJobPipelinesMutation, DeleteJobPipelinesMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteJobPipelinesMutation, DeleteJobPipelinesMutationVariables>(DeleteJobPipelinesDocument, options);
}
export type DeleteJobPipelinesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteJobPipelinesMutation, DeleteJobPipelinesMutationVariables>;
export const DataUploadConfigDocument = gql`
    query dataUploadConfig {
  tenant {
    uploadConfig {
      data {
        dataUploadConfig {
          limits {
            maxFileSize
          }
          allowedMimeTypes
          allowAllMimeTypes
          allowedFileExtensions
        }
      }
    }
  }
}
    `;

/**
 * __useDataUploadConfigQuery__
 *
 * To run a query within a Vue component, call `useDataUploadConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataUploadConfigQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDataUploadConfigQuery();
 */
export function useDataUploadConfigQuery(options: VueApolloComposable.UseQueryOptions<DataUploadConfigQuery, DataUploadConfigQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DataUploadConfigQuery, DataUploadConfigQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DataUploadConfigQuery, DataUploadConfigQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<DataUploadConfigQuery, DataUploadConfigQueryVariables>(DataUploadConfigDocument, {}, options);
}
export function useDataUploadConfigLazyQuery(options: VueApolloComposable.UseQueryOptions<DataUploadConfigQuery, DataUploadConfigQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DataUploadConfigQuery, DataUploadConfigQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DataUploadConfigQuery, DataUploadConfigQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<DataUploadConfigQuery, DataUploadConfigQueryVariables>(DataUploadConfigDocument, {}, options);
}
export type DataUploadConfigQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<DataUploadConfigQuery, DataUploadConfigQueryVariables>;
export const DatafieldModelJsonSchemaDocument = gql`
    query datafieldModelJSONSchema {
  datafieldModelJSONSchema
}
    `;

/**
 * __useDatafieldModelJsonSchemaQuery__
 *
 * To run a query within a Vue component, call `useDatafieldModelJsonSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatafieldModelJsonSchemaQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useDatafieldModelJsonSchemaQuery();
 */
export function useDatafieldModelJsonSchemaQuery(options: VueApolloComposable.UseQueryOptions<DatafieldModelJsonSchemaQuery, DatafieldModelJsonSchemaQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DatafieldModelJsonSchemaQuery, DatafieldModelJsonSchemaQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DatafieldModelJsonSchemaQuery, DatafieldModelJsonSchemaQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<DatafieldModelJsonSchemaQuery, DatafieldModelJsonSchemaQueryVariables>(DatafieldModelJsonSchemaDocument, {}, options);
}
export function useDatafieldModelJsonSchemaLazyQuery(options: VueApolloComposable.UseQueryOptions<DatafieldModelJsonSchemaQuery, DatafieldModelJsonSchemaQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<DatafieldModelJsonSchemaQuery, DatafieldModelJsonSchemaQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<DatafieldModelJsonSchemaQuery, DatafieldModelJsonSchemaQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<DatafieldModelJsonSchemaQuery, DatafieldModelJsonSchemaQueryVariables>(DatafieldModelJsonSchemaDocument, {}, options);
}
export type DatafieldModelJsonSchemaQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<DatafieldModelJsonSchemaQuery, DatafieldModelJsonSchemaQueryVariables>;
export const CreateKeywordDatafieldDefinitionDocument = gql`
    mutation CreateKeywordDatafieldDefinition($spec: KeywordDatafieldDefinitionSpec) {
  createKeywordDatafieldDefinition(spec: $spec)
}
    `;

/**
 * __useCreateKeywordDatafieldDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateKeywordDatafieldDefinitionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateKeywordDatafieldDefinitionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateKeywordDatafieldDefinitionMutation({
 *   variables: {
 *     spec: // value for 'spec'
 *   },
 * });
 */
export function useCreateKeywordDatafieldDefinitionMutation(options: VueApolloComposable.UseMutationOptions<CreateKeywordDatafieldDefinitionMutation, CreateKeywordDatafieldDefinitionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateKeywordDatafieldDefinitionMutation, CreateKeywordDatafieldDefinitionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateKeywordDatafieldDefinitionMutation, CreateKeywordDatafieldDefinitionMutationVariables>(CreateKeywordDatafieldDefinitionDocument, options);
}
export type CreateKeywordDatafieldDefinitionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateKeywordDatafieldDefinitionMutation, CreateKeywordDatafieldDefinitionMutationVariables>;
export const UpdateKeywordDatafieldDefinitionDocument = gql`
    mutation UpdateKeywordDatafieldDefinition($diff: KeywordDatafieldDefinitionDiff) {
  updateKeywordDatafieldDefinition(diff: $diff) {
    id
  }
}
    `;

/**
 * __useUpdateKeywordDatafieldDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateKeywordDatafieldDefinitionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKeywordDatafieldDefinitionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateKeywordDatafieldDefinitionMutation({
 *   variables: {
 *     diff: // value for 'diff'
 *   },
 * });
 */
export function useUpdateKeywordDatafieldDefinitionMutation(options: VueApolloComposable.UseMutationOptions<UpdateKeywordDatafieldDefinitionMutation, UpdateKeywordDatafieldDefinitionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateKeywordDatafieldDefinitionMutation, UpdateKeywordDatafieldDefinitionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateKeywordDatafieldDefinitionMutation, UpdateKeywordDatafieldDefinitionMutationVariables>(UpdateKeywordDatafieldDefinitionDocument, options);
}
export type UpdateKeywordDatafieldDefinitionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateKeywordDatafieldDefinitionMutation, UpdateKeywordDatafieldDefinitionMutationVariables>;
export const CreateDateTimeDatafieldDefinitionDocument = gql`
    mutation CreateDateTimeDatafieldDefinition($spec: DateTimeDatafieldDefinitionSpec) {
  createDateTimeDatafieldDefinition(spec: $spec)
}
    `;

/**
 * __useCreateDateTimeDatafieldDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateDateTimeDatafieldDefinitionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateDateTimeDatafieldDefinitionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateDateTimeDatafieldDefinitionMutation({
 *   variables: {
 *     spec: // value for 'spec'
 *   },
 * });
 */
export function useCreateDateTimeDatafieldDefinitionMutation(options: VueApolloComposable.UseMutationOptions<CreateDateTimeDatafieldDefinitionMutation, CreateDateTimeDatafieldDefinitionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateDateTimeDatafieldDefinitionMutation, CreateDateTimeDatafieldDefinitionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateDateTimeDatafieldDefinitionMutation, CreateDateTimeDatafieldDefinitionMutationVariables>(CreateDateTimeDatafieldDefinitionDocument, options);
}
export type CreateDateTimeDatafieldDefinitionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateDateTimeDatafieldDefinitionMutation, CreateDateTimeDatafieldDefinitionMutationVariables>;
export const UpdateDateTimeDatafieldDefinitionDocument = gql`
    mutation UpdateDateTimeDatafieldDefinition($diff: DateTimeDatafieldDefinitionDiff) {
  updateDateTimeDatafieldDefinition(diff: $diff) {
    id
  }
}
    `;

/**
 * __useUpdateDateTimeDatafieldDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateDateTimeDatafieldDefinitionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDateTimeDatafieldDefinitionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateDateTimeDatafieldDefinitionMutation({
 *   variables: {
 *     diff: // value for 'diff'
 *   },
 * });
 */
export function useUpdateDateTimeDatafieldDefinitionMutation(options: VueApolloComposable.UseMutationOptions<UpdateDateTimeDatafieldDefinitionMutation, UpdateDateTimeDatafieldDefinitionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateDateTimeDatafieldDefinitionMutation, UpdateDateTimeDatafieldDefinitionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateDateTimeDatafieldDefinitionMutation, UpdateDateTimeDatafieldDefinitionMutationVariables>(UpdateDateTimeDatafieldDefinitionDocument, options);
}
export type UpdateDateTimeDatafieldDefinitionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateDateTimeDatafieldDefinitionMutation, UpdateDateTimeDatafieldDefinitionMutationVariables>;
export const CreateEnumDatafieldDefinitionDocument = gql`
    mutation CreateEnumDatafieldDefinition($spec: EnumDatafieldDefinitionSpec) {
  createEnumDatafieldDefinition(spec: $spec)
}
    `;

/**
 * __useCreateEnumDatafieldDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateEnumDatafieldDefinitionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnumDatafieldDefinitionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateEnumDatafieldDefinitionMutation({
 *   variables: {
 *     spec: // value for 'spec'
 *   },
 * });
 */
export function useCreateEnumDatafieldDefinitionMutation(options: VueApolloComposable.UseMutationOptions<CreateEnumDatafieldDefinitionMutation, CreateEnumDatafieldDefinitionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateEnumDatafieldDefinitionMutation, CreateEnumDatafieldDefinitionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateEnumDatafieldDefinitionMutation, CreateEnumDatafieldDefinitionMutationVariables>(CreateEnumDatafieldDefinitionDocument, options);
}
export type CreateEnumDatafieldDefinitionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateEnumDatafieldDefinitionMutation, CreateEnumDatafieldDefinitionMutationVariables>;
export const UpdateEnumDatafieldDefinitionDocument = gql`
    mutation UpdateEnumDatafieldDefinition($diff: EnumDatafieldDefinitionDiff) {
  updateEnumDatafieldDefinition(diff: $diff) {
    id
  }
}
    `;

/**
 * __useUpdateEnumDatafieldDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateEnumDatafieldDefinitionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnumDatafieldDefinitionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateEnumDatafieldDefinitionMutation({
 *   variables: {
 *     diff: // value for 'diff'
 *   },
 * });
 */
export function useUpdateEnumDatafieldDefinitionMutation(options: VueApolloComposable.UseMutationOptions<UpdateEnumDatafieldDefinitionMutation, UpdateEnumDatafieldDefinitionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateEnumDatafieldDefinitionMutation, UpdateEnumDatafieldDefinitionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateEnumDatafieldDefinitionMutation, UpdateEnumDatafieldDefinitionMutationVariables>(UpdateEnumDatafieldDefinitionDocument, options);
}
export type UpdateEnumDatafieldDefinitionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateEnumDatafieldDefinitionMutation, UpdateEnumDatafieldDefinitionMutationVariables>;
export const CreateNumberDatafieldDefinitionDocument = gql`
    mutation CreateNumberDatafieldDefinition($spec: NumberDatafieldDefinitionSpec) {
  createNumberDatafieldDefinition(spec: $spec)
}
    `;

/**
 * __useCreateNumberDatafieldDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateNumberDatafieldDefinitionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateNumberDatafieldDefinitionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateNumberDatafieldDefinitionMutation({
 *   variables: {
 *     spec: // value for 'spec'
 *   },
 * });
 */
export function useCreateNumberDatafieldDefinitionMutation(options: VueApolloComposable.UseMutationOptions<CreateNumberDatafieldDefinitionMutation, CreateNumberDatafieldDefinitionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateNumberDatafieldDefinitionMutation, CreateNumberDatafieldDefinitionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateNumberDatafieldDefinitionMutation, CreateNumberDatafieldDefinitionMutationVariables>(CreateNumberDatafieldDefinitionDocument, options);
}
export type CreateNumberDatafieldDefinitionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateNumberDatafieldDefinitionMutation, CreateNumberDatafieldDefinitionMutationVariables>;
export const UpdateNumberDatafieldDefinitionDocument = gql`
    mutation UpdateNumberDatafieldDefinition($diff: NumberDatafieldDefinitionDiff) {
  updateNumberDatafieldDefinition(diff: $diff) {
    id
  }
}
    `;

/**
 * __useUpdateNumberDatafieldDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateNumberDatafieldDefinitionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNumberDatafieldDefinitionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateNumberDatafieldDefinitionMutation({
 *   variables: {
 *     diff: // value for 'diff'
 *   },
 * });
 */
export function useUpdateNumberDatafieldDefinitionMutation(options: VueApolloComposable.UseMutationOptions<UpdateNumberDatafieldDefinitionMutation, UpdateNumberDatafieldDefinitionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateNumberDatafieldDefinitionMutation, UpdateNumberDatafieldDefinitionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateNumberDatafieldDefinitionMutation, UpdateNumberDatafieldDefinitionMutationVariables>(UpdateNumberDatafieldDefinitionDocument, options);
}
export type UpdateNumberDatafieldDefinitionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateNumberDatafieldDefinitionMutation, UpdateNumberDatafieldDefinitionMutationVariables>;
export const CreateTaxonomyDatafieldDefinitionDocument = gql`
    mutation CreateTaxonomyDatafieldDefinition($spec: TaxonomyDatafieldDefinitionSpec) {
  createTaxonomyDatafieldDefinition(spec: $spec)
}
    `;

/**
 * __useCreateTaxonomyDatafieldDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateTaxonomyDatafieldDefinitionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaxonomyDatafieldDefinitionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateTaxonomyDatafieldDefinitionMutation({
 *   variables: {
 *     spec: // value for 'spec'
 *   },
 * });
 */
export function useCreateTaxonomyDatafieldDefinitionMutation(options: VueApolloComposable.UseMutationOptions<CreateTaxonomyDatafieldDefinitionMutation, CreateTaxonomyDatafieldDefinitionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateTaxonomyDatafieldDefinitionMutation, CreateTaxonomyDatafieldDefinitionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateTaxonomyDatafieldDefinitionMutation, CreateTaxonomyDatafieldDefinitionMutationVariables>(CreateTaxonomyDatafieldDefinitionDocument, options);
}
export type CreateTaxonomyDatafieldDefinitionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateTaxonomyDatafieldDefinitionMutation, CreateTaxonomyDatafieldDefinitionMutationVariables>;
export const UpdateTaxonomyDatafieldDefinitionDocument = gql`
    mutation UpdateTaxonomyDatafieldDefinition($diff: TaxonomyDatafieldDefinitionDiff) {
  updateTaxonomyDatafieldDefinition(diff: $diff) {
    id
  }
}
    `;

/**
 * __useUpdateTaxonomyDatafieldDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateTaxonomyDatafieldDefinitionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaxonomyDatafieldDefinitionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateTaxonomyDatafieldDefinitionMutation({
 *   variables: {
 *     diff: // value for 'diff'
 *   },
 * });
 */
export function useUpdateTaxonomyDatafieldDefinitionMutation(options: VueApolloComposable.UseMutationOptions<UpdateTaxonomyDatafieldDefinitionMutation, UpdateTaxonomyDatafieldDefinitionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateTaxonomyDatafieldDefinitionMutation, UpdateTaxonomyDatafieldDefinitionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateTaxonomyDatafieldDefinitionMutation, UpdateTaxonomyDatafieldDefinitionMutationVariables>(UpdateTaxonomyDatafieldDefinitionDocument, options);
}
export type UpdateTaxonomyDatafieldDefinitionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateTaxonomyDatafieldDefinitionMutation, UpdateTaxonomyDatafieldDefinitionMutationVariables>;
export const CreateTextDatafieldDefinitionDocument = gql`
    mutation CreateTextDatafieldDefinition($spec: TextDatafieldDefinitionSpec) {
  createTextDatafieldDefinition(spec: $spec)
}
    `;

/**
 * __useCreateTextDatafieldDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateTextDatafieldDefinitionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateTextDatafieldDefinitionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateTextDatafieldDefinitionMutation({
 *   variables: {
 *     spec: // value for 'spec'
 *   },
 * });
 */
export function useCreateTextDatafieldDefinitionMutation(options: VueApolloComposable.UseMutationOptions<CreateTextDatafieldDefinitionMutation, CreateTextDatafieldDefinitionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateTextDatafieldDefinitionMutation, CreateTextDatafieldDefinitionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateTextDatafieldDefinitionMutation, CreateTextDatafieldDefinitionMutationVariables>(CreateTextDatafieldDefinitionDocument, options);
}
export type CreateTextDatafieldDefinitionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateTextDatafieldDefinitionMutation, CreateTextDatafieldDefinitionMutationVariables>;
export const UpdateTextDatafieldDefinitionDocument = gql`
    mutation UpdateTextDatafieldDefinition($diff: TextDatafieldDefinitionDiff) {
  updateTextDatafieldDefinition(diff: $diff) {
    id
  }
}
    `;

/**
 * __useUpdateTextDatafieldDefinitionMutation__
 *
 * To run a mutation, you first call `useUpdateTextDatafieldDefinitionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTextDatafieldDefinitionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateTextDatafieldDefinitionMutation({
 *   variables: {
 *     diff: // value for 'diff'
 *   },
 * });
 */
export function useUpdateTextDatafieldDefinitionMutation(options: VueApolloComposable.UseMutationOptions<UpdateTextDatafieldDefinitionMutation, UpdateTextDatafieldDefinitionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateTextDatafieldDefinitionMutation, UpdateTextDatafieldDefinitionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateTextDatafieldDefinitionMutation, UpdateTextDatafieldDefinitionMutationVariables>(UpdateTextDatafieldDefinitionDocument, options);
}
export type UpdateTextDatafieldDefinitionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateTextDatafieldDefinitionMutation, UpdateTextDatafieldDefinitionMutationVariables>;
export const DeleteDatafieldDefinitionsDocument = gql`
    mutation deleteDatafieldDefinitions($ids: [DatafieldDefinitionID!]!) {
  deleteDatafieldDefinitions(ids: $ids)
}
    `;

/**
 * __useDeleteDatafieldDefinitionsMutation__
 *
 * To run a mutation, you first call `useDeleteDatafieldDefinitionsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDatafieldDefinitionsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteDatafieldDefinitionsMutation({
 *   variables: {
 *     ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteDatafieldDefinitionsMutation(options: VueApolloComposable.UseMutationOptions<DeleteDatafieldDefinitionsMutation, DeleteDatafieldDefinitionsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteDatafieldDefinitionsMutation, DeleteDatafieldDefinitionsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteDatafieldDefinitionsMutation, DeleteDatafieldDefinitionsMutationVariables>(DeleteDatafieldDefinitionsDocument, options);
}
export type DeleteDatafieldDefinitionsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteDatafieldDefinitionsMutation, DeleteDatafieldDefinitionsMutationVariables>;
export const DeleteFilesDocument = gql`
    mutation DeleteFiles($objectIds: [String!]!, $fileTypes: [FileType]) {
  deleteFiles(objectIds: $objectIds, fileTypes: $fileTypes)
}
    `;

/**
 * __useDeleteFilesMutation__
 *
 * To run a mutation, you first call `useDeleteFilesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFilesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteFilesMutation({
 *   variables: {
 *     objectIds: // value for 'objectIds'
 *     fileTypes: // value for 'fileTypes'
 *   },
 * });
 */
export function useDeleteFilesMutation(options: VueApolloComposable.UseMutationOptions<DeleteFilesMutation, DeleteFilesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteFilesMutation, DeleteFilesMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteFilesMutation, DeleteFilesMutationVariables>(DeleteFilesDocument, options);
}
export type DeleteFilesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteFilesMutation, DeleteFilesMutationVariables>;
export const DeleteEntitiesDocument = gql`
    mutation DeleteEntities($entityTypes: [ProcessType]) {
  deleteEntities(entityTypes: $entityTypes)
}
    `;

/**
 * __useDeleteEntitiesMutation__
 *
 * To run a mutation, you first call `useDeleteEntitiesMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEntitiesMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteEntitiesMutation({
 *   variables: {
 *     entityTypes: // value for 'entityTypes'
 *   },
 * });
 */
export function useDeleteEntitiesMutation(options: VueApolloComposable.UseMutationOptions<DeleteEntitiesMutation, DeleteEntitiesMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteEntitiesMutation, DeleteEntitiesMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteEntitiesMutation, DeleteEntitiesMutationVariables>(DeleteEntitiesDocument, options);
}
export type DeleteEntitiesMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteEntitiesMutation, DeleteEntitiesMutationVariables>;
export const JobPipelinesDocument = gql`
    query jobPipelines($filter: JobPipelineFilter, $first: NonNegativeInt, $after: String) {
  jobPipelines(filter: $filter) {
    jobPipelines(
      first: $first
      after: $after
      sort: {field: creationDate, order: desc}
    ) {
      cursor
      node {
        id
        type
        creationDate
        displayType
        status
        startDate
        bag
        endDate
        displayName
      }
    }
  }
}
    `;

/**
 * __useJobPipelinesQuery__
 *
 * To run a query within a Vue component, call `useJobPipelinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPipelinesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useJobPipelinesQuery({
 *   filter: // value for 'filter'
 *   first: // value for 'first'
 *   after: // value for 'after'
 * });
 */
export function useJobPipelinesQuery(variables: JobPipelinesQueryVariables | VueCompositionApi.Ref<JobPipelinesQueryVariables> | ReactiveFunction<JobPipelinesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<JobPipelinesQuery, JobPipelinesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<JobPipelinesQuery, JobPipelinesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<JobPipelinesQuery, JobPipelinesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<JobPipelinesQuery, JobPipelinesQueryVariables>(JobPipelinesDocument, variables, options);
}
export function useJobPipelinesLazyQuery(variables: JobPipelinesQueryVariables | VueCompositionApi.Ref<JobPipelinesQueryVariables> | ReactiveFunction<JobPipelinesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<JobPipelinesQuery, JobPipelinesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<JobPipelinesQuery, JobPipelinesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<JobPipelinesQuery, JobPipelinesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<JobPipelinesQuery, JobPipelinesQueryVariables>(JobPipelinesDocument, variables, options);
}
export type JobPipelinesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<JobPipelinesQuery, JobPipelinesQueryVariables>;
export const JobPipelineDocument = gql`
    query jobPipeline($filter: JobPipelineFilter) {
  jobPipelines(filter: $filter) {
    jobPipelines(sort: {field: creationDate, order: desc}) {
      node {
        id
        type
        creationDate
        displayType
        status
        startDate
        jobs {
          id
          displayType
          displayName
          creationDate
          creator {
            id
            displayName
          }
          currentExecution {
            log {
              edges {
                node {
                  message
                  timestamp
                }
              }
            }
            startDate
            status
            id
            creationDate
            endDate
            error
          }
        }
        creator {
          displayName
          id
        }
        bag
        endDate
        displayName
        dependencyGraph
      }
    }
  }
}
    `;

/**
 * __useJobPipelineQuery__
 *
 * To run a query within a Vue component, call `useJobPipelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPipelineQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useJobPipelineQuery({
 *   filter: // value for 'filter'
 * });
 */
export function useJobPipelineQuery(variables: JobPipelineQueryVariables | VueCompositionApi.Ref<JobPipelineQueryVariables> | ReactiveFunction<JobPipelineQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<JobPipelineQuery, JobPipelineQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<JobPipelineQuery, JobPipelineQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<JobPipelineQuery, JobPipelineQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<JobPipelineQuery, JobPipelineQueryVariables>(JobPipelineDocument, variables, options);
}
export function useJobPipelineLazyQuery(variables: JobPipelineQueryVariables | VueCompositionApi.Ref<JobPipelineQueryVariables> | ReactiveFunction<JobPipelineQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<JobPipelineQuery, JobPipelineQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<JobPipelineQuery, JobPipelineQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<JobPipelineQuery, JobPipelineQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<JobPipelineQuery, JobPipelineQueryVariables>(JobPipelineDocument, variables, options);
}
export type JobPipelineQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<JobPipelineQuery, JobPipelineQueryVariables>;
export const DeleteContentMapsByIdDocument = gql`
    mutation deleteContentMapsById($contentIds: [ID]) {
  deleteContentMapsByFilter(filter: {contentIds: $contentIds})
}
    `;

/**
 * __useDeleteContentMapsByIdMutation__
 *
 * To run a mutation, you first call `useDeleteContentMapsByIdMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentMapsByIdMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteContentMapsByIdMutation({
 *   variables: {
 *     contentIds: // value for 'contentIds'
 *   },
 * });
 */
export function useDeleteContentMapsByIdMutation(options: VueApolloComposable.UseMutationOptions<DeleteContentMapsByIdMutation, DeleteContentMapsByIdMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteContentMapsByIdMutation, DeleteContentMapsByIdMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteContentMapsByIdMutation, DeleteContentMapsByIdMutationVariables>(DeleteContentMapsByIdDocument, options);
}
export type DeleteContentMapsByIdMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteContentMapsByIdMutation, DeleteContentMapsByIdMutationVariables>;
export const CreateProcessMechanicDocument = gql`
    mutation CreateProcessMechanic($spec: ProcessRefSpec!, $columns: [String], $filenameSchemaName: String, $deleteAll: Boolean, $delimiter: String, $escape: String, $encoding: ProcessEncodingType) {
  createProcess(
    spec: $spec
    columns: $columns
    filenameSchemaName: $filenameSchemaName
    deleteAll: $deleteAll
    delimiter: $delimiter
    escape: $escape
    encoding: $encoding
  )
}
    `;

/**
 * __useCreateProcessMechanicMutation__
 *
 * To run a mutation, you first call `useCreateProcessMechanicMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateProcessMechanicMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateProcessMechanicMutation({
 *   variables: {
 *     spec: // value for 'spec'
 *     columns: // value for 'columns'
 *     filenameSchemaName: // value for 'filenameSchemaName'
 *     deleteAll: // value for 'deleteAll'
 *     delimiter: // value for 'delimiter'
 *     escape: // value for 'escape'
 *     encoding: // value for 'encoding'
 *   },
 * });
 */
export function useCreateProcessMechanicMutation(options: VueApolloComposable.UseMutationOptions<CreateProcessMechanicMutation, CreateProcessMechanicMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateProcessMechanicMutation, CreateProcessMechanicMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateProcessMechanicMutation, CreateProcessMechanicMutationVariables>(CreateProcessMechanicDocument, options);
}
export type CreateProcessMechanicMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateProcessMechanicMutation, CreateProcessMechanicMutationVariables>;
export const UpdateProcessMechanicDocument = gql`
    mutation UpdateProcessMechanic($id: String!, $name: String!, $columns: [String], $filenameSchemaName: String, $deleteAll: Boolean, $delimiter: String, $escape: String, $encoding: ProcessEncodingType) {
  updateProcess(
    id: $id
    name: $name
    columns: $columns
    filenameSchemaName: $filenameSchemaName
    deleteAll: $deleteAll
    delimiter: $delimiter
    escape: $escape
    encoding: $encoding
  )
}
    `;

/**
 * __useUpdateProcessMechanicMutation__
 *
 * To run a mutation, you first call `useUpdateProcessMechanicMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProcessMechanicMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateProcessMechanicMutation({
 *   variables: {
 *     id: // value for 'id'
 *     name: // value for 'name'
 *     columns: // value for 'columns'
 *     filenameSchemaName: // value for 'filenameSchemaName'
 *     deleteAll: // value for 'deleteAll'
 *     delimiter: // value for 'delimiter'
 *     escape: // value for 'escape'
 *     encoding: // value for 'encoding'
 *   },
 * });
 */
export function useUpdateProcessMechanicMutation(options: VueApolloComposable.UseMutationOptions<UpdateProcessMechanicMutation, UpdateProcessMechanicMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateProcessMechanicMutation, UpdateProcessMechanicMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateProcessMechanicMutation, UpdateProcessMechanicMutationVariables>(UpdateProcessMechanicDocument, options);
}
export type UpdateProcessMechanicMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateProcessMechanicMutation, UpdateProcessMechanicMutationVariables>;
export const CreateOrUpdateConfig3dLatticeDocument = gql`
    mutation createOrUpdateConfig3dLattice($config3dSpec: Config3dLatticeSpec) {
  createOrUpdateConfig3dLattice(config3dSpec: $config3dSpec)
}
    `;

/**
 * __useCreateOrUpdateConfig3dLatticeMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateConfig3dLatticeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateConfig3dLatticeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOrUpdateConfig3dLatticeMutation({
 *   variables: {
 *     config3dSpec: // value for 'config3dSpec'
 *   },
 * });
 */
export function useCreateOrUpdateConfig3dLatticeMutation(options: VueApolloComposable.UseMutationOptions<CreateOrUpdateConfig3dLatticeMutation, CreateOrUpdateConfig3dLatticeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateOrUpdateConfig3dLatticeMutation, CreateOrUpdateConfig3dLatticeMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateOrUpdateConfig3dLatticeMutation, CreateOrUpdateConfig3dLatticeMutationVariables>(CreateOrUpdateConfig3dLatticeDocument, options);
}
export type CreateOrUpdateConfig3dLatticeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateOrUpdateConfig3dLatticeMutation, CreateOrUpdateConfig3dLatticeMutationVariables>;
export const DeleteConfig3dLatticeDocument = gql`
    mutation deleteConfig3dLattice {
  deleteConfig3dLattice
}
    `;

/**
 * __useDeleteConfig3dLatticeMutation__
 *
 * To run a mutation, you first call `useDeleteConfig3dLatticeMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConfig3dLatticeMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteConfig3dLatticeMutation();
 */
export function useDeleteConfig3dLatticeMutation(options: VueApolloComposable.UseMutationOptions<DeleteConfig3dLatticeMutation, DeleteConfig3dLatticeMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteConfig3dLatticeMutation, DeleteConfig3dLatticeMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteConfig3dLatticeMutation, DeleteConfig3dLatticeMutationVariables>(DeleteConfig3dLatticeDocument, options);
}
export type DeleteConfig3dLatticeMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteConfig3dLatticeMutation, DeleteConfig3dLatticeMutationVariables>;
export const CreateOrUpdateConfig3dHoopsDocument = gql`
    mutation createOrUpdateConfig3dHoops($config3dSpec: Config3dHoopsSpec) {
  createOrUpdateConfig3dHoops(config3dSpec: $config3dSpec)
}
    `;

/**
 * __useCreateOrUpdateConfig3dHoopsMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateConfig3dHoopsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateConfig3dHoopsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOrUpdateConfig3dHoopsMutation({
 *   variables: {
 *     config3dSpec: // value for 'config3dSpec'
 *   },
 * });
 */
export function useCreateOrUpdateConfig3dHoopsMutation(options: VueApolloComposable.UseMutationOptions<CreateOrUpdateConfig3dHoopsMutation, CreateOrUpdateConfig3dHoopsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateOrUpdateConfig3dHoopsMutation, CreateOrUpdateConfig3dHoopsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateOrUpdateConfig3dHoopsMutation, CreateOrUpdateConfig3dHoopsMutationVariables>(CreateOrUpdateConfig3dHoopsDocument, options);
}
export type CreateOrUpdateConfig3dHoopsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateOrUpdateConfig3dHoopsMutation, CreateOrUpdateConfig3dHoopsMutationVariables>;
export const DeleteConfig3dHoopsDocument = gql`
    mutation deleteConfig3dHoops {
  deleteConfig3dHoops
}
    `;

/**
 * __useDeleteConfig3dHoopsMutation__
 *
 * To run a mutation, you first call `useDeleteConfig3dHoopsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConfig3dHoopsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteConfig3dHoopsMutation();
 */
export function useDeleteConfig3dHoopsMutation(options: VueApolloComposable.UseMutationOptions<DeleteConfig3dHoopsMutation, DeleteConfig3dHoopsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteConfig3dHoopsMutation, DeleteConfig3dHoopsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteConfig3dHoopsMutation, DeleteConfig3dHoopsMutationVariables>(DeleteConfig3dHoopsDocument, options);
}
export type DeleteConfig3dHoopsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteConfig3dHoopsMutation, DeleteConfig3dHoopsMutationVariables>;
export const DeleteMechanicsDocument = gql`
    mutation DeleteMechanics($productIds: [ID]) {
  deleteMechanics(productIds: $productIds)
}
    `;

/**
 * __useDeleteMechanicsMutation__
 *
 * To run a mutation, you first call `useDeleteMechanicsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMechanicsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteMechanicsMutation({
 *   variables: {
 *     productIds: // value for 'productIds'
 *   },
 * });
 */
export function useDeleteMechanicsMutation(options: VueApolloComposable.UseMutationOptions<DeleteMechanicsMutation, DeleteMechanicsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteMechanicsMutation, DeleteMechanicsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteMechanicsMutation, DeleteMechanicsMutationVariables>(DeleteMechanicsDocument, options);
}
export type DeleteMechanicsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteMechanicsMutation, DeleteMechanicsMutationVariables>;
export const DeleteProductsDocument = gql`
    mutation DeleteProducts($ids: [ID]) {
  deleteProducts(ids: $ids)
}
    `;

/**
 * __useDeleteProductsMutation__
 *
 * To run a mutation, you first call `useDeleteProductsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteProductsMutation({
 *   variables: {
 *     ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteProductsMutation(options: VueApolloComposable.UseMutationOptions<DeleteProductsMutation, DeleteProductsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteProductsMutation, DeleteProductsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteProductsMutation, DeleteProductsMutationVariables>(DeleteProductsDocument, options);
}
export type DeleteProductsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteProductsMutation, DeleteProductsMutationVariables>;
export const DeleteAssetsDocument = gql`
    mutation DeleteAssets($productId: ID!, $assetIds: [ID]) {
  deleteAssets(productId: $productId, assetIds: $assetIds)
}
    `;

/**
 * __useDeleteAssetsMutation__
 *
 * To run a mutation, you first call `useDeleteAssetsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteAssetsMutation({
 *   variables: {
 *     productId: // value for 'productId'
 *     assetIds: // value for 'assetIds'
 *   },
 * });
 */
export function useDeleteAssetsMutation(options: VueApolloComposable.UseMutationOptions<DeleteAssetsMutation, DeleteAssetsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteAssetsMutation, DeleteAssetsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteAssetsMutation, DeleteAssetsMutationVariables>(DeleteAssetsDocument, options);
}
export type DeleteAssetsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteAssetsMutation, DeleteAssetsMutationVariables>;
export const CreateProcessProductAssetDocument = gql`
    mutation CreateProcessProductAsset($spec: ProcessRefSpec!, $columns: [String], $filenameSchemaName: String, $delimiter: String, $escape: String, $encoding: ProcessEncodingType) {
  createProcess(
    spec: $spec
    columns: $columns
    filenameSchemaName: $filenameSchemaName
    delimiter: $delimiter
    escape: $escape
    encoding: $encoding
  )
}
    `;

/**
 * __useCreateProcessProductAssetMutation__
 *
 * To run a mutation, you first call `useCreateProcessProductAssetMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateProcessProductAssetMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateProcessProductAssetMutation({
 *   variables: {
 *     spec: // value for 'spec'
 *     columns: // value for 'columns'
 *     filenameSchemaName: // value for 'filenameSchemaName'
 *     delimiter: // value for 'delimiter'
 *     escape: // value for 'escape'
 *     encoding: // value for 'encoding'
 *   },
 * });
 */
export function useCreateProcessProductAssetMutation(options: VueApolloComposable.UseMutationOptions<CreateProcessProductAssetMutation, CreateProcessProductAssetMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateProcessProductAssetMutation, CreateProcessProductAssetMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateProcessProductAssetMutation, CreateProcessProductAssetMutationVariables>(CreateProcessProductAssetDocument, options);
}
export type CreateProcessProductAssetMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateProcessProductAssetMutation, CreateProcessProductAssetMutationVariables>;
export const UpdateProcessProductAssetDocument = gql`
    mutation UpdateProcessProductAsset($id: String!, $name: String!, $columns: [String], $filenameSchemaName: String, $delimiter: String, $escape: String, $encoding: ProcessEncodingType) {
  updateProcess(
    id: $id
    name: $name
    columns: $columns
    filenameSchemaName: $filenameSchemaName
    delimiter: $delimiter
    escape: $escape
    encoding: $encoding
  )
}
    `;

/**
 * __useUpdateProcessProductAssetMutation__
 *
 * To run a mutation, you first call `useUpdateProcessProductAssetMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProcessProductAssetMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateProcessProductAssetMutation({
 *   variables: {
 *     id: // value for 'id'
 *     name: // value for 'name'
 *     columns: // value for 'columns'
 *     filenameSchemaName: // value for 'filenameSchemaName'
 *     delimiter: // value for 'delimiter'
 *     escape: // value for 'escape'
 *     encoding: // value for 'encoding'
 *   },
 * });
 */
export function useUpdateProcessProductAssetMutation(options: VueApolloComposable.UseMutationOptions<UpdateProcessProductAssetMutation, UpdateProcessProductAssetMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateProcessProductAssetMutation, UpdateProcessProductAssetMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateProcessProductAssetMutation, UpdateProcessProductAssetMutationVariables>(UpdateProcessProductAssetDocument, options);
}
export type UpdateProcessProductAssetMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateProcessProductAssetMutation, UpdateProcessProductAssetMutationVariables>;
export const CreateOrUpdateConfigProductAndAssetDocument = gql`
    mutation createOrUpdateConfigProductAndAsset($configProductAndAssetSpec: ConfigProductAndAssetSpec) {
  createOrUpdateConfigProductAndAsset(
    configProductAndAssetSpec: $configProductAndAssetSpec
  )
}
    `;

/**
 * __useCreateOrUpdateConfigProductAndAssetMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateConfigProductAndAssetMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateConfigProductAndAssetMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateOrUpdateConfigProductAndAssetMutation({
 *   variables: {
 *     configProductAndAssetSpec: // value for 'configProductAndAssetSpec'
 *   },
 * });
 */
export function useCreateOrUpdateConfigProductAndAssetMutation(options: VueApolloComposable.UseMutationOptions<CreateOrUpdateConfigProductAndAssetMutation, CreateOrUpdateConfigProductAndAssetMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateOrUpdateConfigProductAndAssetMutation, CreateOrUpdateConfigProductAndAssetMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateOrUpdateConfigProductAndAssetMutation, CreateOrUpdateConfigProductAndAssetMutationVariables>(CreateOrUpdateConfigProductAndAssetDocument, options);
}
export type CreateOrUpdateConfigProductAndAssetMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateOrUpdateConfigProductAndAssetMutation, CreateOrUpdateConfigProductAndAssetMutationVariables>;
export const DeleteConfigProductAndAssetDocument = gql`
    mutation deleteConfigProductAndAsset {
  deleteConfigProductAndAsset
}
    `;

/**
 * __useDeleteConfigProductAndAssetMutation__
 *
 * To run a mutation, you first call `useDeleteConfigProductAndAssetMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConfigProductAndAssetMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteConfigProductAndAssetMutation();
 */
export function useDeleteConfigProductAndAssetMutation(options: VueApolloComposable.UseMutationOptions<DeleteConfigProductAndAssetMutation, DeleteConfigProductAndAssetMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteConfigProductAndAssetMutation, DeleteConfigProductAndAssetMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteConfigProductAndAssetMutation, DeleteConfigProductAndAssetMutationVariables>(DeleteConfigProductAndAssetDocument, options);
}
export type DeleteConfigProductAndAssetMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteConfigProductAndAssetMutation, DeleteConfigProductAndAssetMutationVariables>;
export const ProcessEncodingTypesDocument = gql`
    query processEncodingTypes {
  processEncodingTypes
}
    `;

/**
 * __useProcessEncodingTypesQuery__
 *
 * To run a query within a Vue component, call `useProcessEncodingTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessEncodingTypesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProcessEncodingTypesQuery();
 */
export function useProcessEncodingTypesQuery(options: VueApolloComposable.UseQueryOptions<ProcessEncodingTypesQuery, ProcessEncodingTypesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProcessEncodingTypesQuery, ProcessEncodingTypesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProcessEncodingTypesQuery, ProcessEncodingTypesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ProcessEncodingTypesQuery, ProcessEncodingTypesQueryVariables>(ProcessEncodingTypesDocument, {}, options);
}
export function useProcessEncodingTypesLazyQuery(options: VueApolloComposable.UseQueryOptions<ProcessEncodingTypesQuery, ProcessEncodingTypesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProcessEncodingTypesQuery, ProcessEncodingTypesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProcessEncodingTypesQuery, ProcessEncodingTypesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ProcessEncodingTypesQuery, ProcessEncodingTypesQueryVariables>(ProcessEncodingTypesDocument, {}, options);
}
export type ProcessEncodingTypesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProcessEncodingTypesQuery, ProcessEncodingTypesQueryVariables>;